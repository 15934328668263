import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTheme } from 'styled-components';

import Box from 'components/atoms/Box/Box';
import Text from 'components/atoms/Text/Text';

import { checkPasswordStrength } from 'utils/validators/validators';

import { IPasswordStrengthMeterProps } from './PasswordStrengthMeter.interface';

function PasswordStrengthMeter({
  password = '',
  ...args
}: IPasswordStrengthMeterProps) {
  const {
    colors: { grey400, black },
  } = useTheme();
  const { hasLength, hasLowercase, hasNumberOrSymbol, hasUppercase } =
    checkPasswordStrength(password);
  return (
    <Box {...args}>
      <Row>
        <Col>
          <Text xs color={hasLength ? grey400 : black}>
            &#8226; Use at least 8 characters
          </Text>
        </Col>
        <Col>
          <Text xs color={hasNumberOrSymbol ? grey400 : black}>
            &#8226; One number or symbol
          </Text>
        </Col>
      </Row>
      <Row>
        <Col>
          <Text xs color={hasLowercase ? grey400 : black}>
            &#8226; One lowercase character
          </Text>
        </Col>
        <Col>
          <Text xs color={hasUppercase ? grey400 : black}>
            &#8226; One uppercase character
          </Text>
        </Col>
      </Row>
    </Box>
  );
}

export default PasswordStrengthMeter;
