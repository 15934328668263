import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IUser } from '../../interfaces/Iuser';
import { RootState } from '../store';

// Define a type for the slice state
interface IViewerState {
  user?: IUser | null;
}

// Define the initial state using that type
const initialState: IViewerState = {
  user: {
    id: '',
  }
};

const viewerSlice = createSlice({
  name: 'viewer',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setViewer: (state, action: PayloadAction<IUser | null>) => {
      // eslint-disable-next-line no-param-reassign
      state.user = action.payload ? action.payload : initialState.user;
    }    
  },
});

export const { setViewer } = viewerSlice.actions;

// selectors
export const selectViewer = (state: RootState) => state.viewer.user;

export default viewerSlice.reducer;
