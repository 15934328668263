import React from 'react';
import styled, { useTheme } from 'styled-components';

import Box from 'components/atoms/Box/Box';
import Flex from 'components/atoms/Flex/Flex';
import Text from 'components/atoms/Text/Text';

import { ProgressBarProps } from './ProgressBar.interface';

const ProgressBarStyled = styled(Flex)`
  gap: 12px;
  align-items: center;
`;
const ProgressBarProgress = styled(Flex)`
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.grey300};
`;
const ProgressBarProgressValue = styled(Flex)`
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.primary600};
`;
function ProgressBar({ label, progress, showPercent }: ProgressBarProps) {
  const theme = useTheme();
  const percentage = Math.round(progress * 100);
  return (
    <Box>
      {label && (
        <Text sm regular color={theme.colors.grey700}>
          {label}
        </Text>
      )}
      <ProgressBarStyled fullWidth my={2} h={8}>
        <ProgressBarProgress fullWidth h={8}>
          <ProgressBarProgressValue h={8} style={{ width: `${percentage}%` }} />
        </ProgressBarProgress>
        {showPercent && (
          <Text sm regular color={theme.colors.grey700}>
            {percentage}%
          </Text>
        )}
      </ProgressBarStyled>
    </Box>
  );
}

export default ProgressBar;
