import React, { FormEvent } from 'react';
import { Col, Form, Row } from 'react-bootstrap';

import FormControl from 'components/atoms/FormControl/FormControl';
import FormFeetback from 'components/atoms/FormFeetback/FormFeetback';
import ActionModal, {
  ButtonsAlignmentName,
} from 'components/molecules/ActionModal/ActionModal';

import { useRenameAssetMutation } from 'graph/generated.graphql';
import { FormErrorMessages, FormErrorTypes } from 'utils/constants/forms';
import {
  isAssetNameExistError,
  isAssetNameShouldBeWithOutExtension,
} from 'utils/errors/isGqlError';
import {
  useFormState,
  useFormValidation,
} from 'utils/hooks/useFormValidation/useFormValidation';
import { getFileNameWithoutExtension } from 'utils/transformers/text';

import { IRenameAssetFormProps } from './RenameAssetForm.interface';

enum FormFields {
  AssetName = 'assetName',
}

const FormFieldsList = [FormFields.AssetName];

function RenameAssetForm({ onModalClose, asset }: IRenameAssetFormProps) {
  const initialFormValue = {
    [FormFields.AssetName]: getFileNameWithoutExtension(asset?.name || ''),
  };
  const [formErrors, isFormValid, [setAssetNameError]] =
    useFormValidation(FormFieldsList);

  const [
    formState,
    [setAssetName],
    [formOnSubmit, formOnError, formOnSuccess],
  ] = useFormState(FormFieldsList, initialFormValue);

  const { isLoading, errorSlug } = formState;

  const [renameAsset, { loading: isAssetCreateLoading }] =
    useRenameAssetMutation({
      onCompleted() {
        formOnSuccess();
        onModalClose();
      },
      onError(error) {
        error.graphQLErrors.forEach((gqlError) => {
          if (isAssetNameShouldBeWithOutExtension(gqlError)) {
            formOnError();
            return setAssetNameError({
              type: FormErrorTypes.Invalid,
              message: gqlError.message,
            });
          }
          if (isAssetNameExistError(gqlError)) {
            formOnError();
            return setAssetNameError({
              type: FormErrorTypes.Invalid,
              message: gqlError.message,
            });
          }
          return formOnError(gqlError.message);
        });
      },
      refetchQueries: ['getAssets'],
    });

  const validAssetName = (value: string) => {
    if (!value) {
      setAssetNameError({
        type: FormErrorTypes.Invalid,
        message: FormErrorMessages.PROJECT_NAME_BLANK,
      });
      return false;
    }
    if (value.length > 150) {
      setAssetNameError({
        type: FormErrorTypes.Invalid,
        message: FormErrorMessages.MAX_150_CHARACTERS,
      });
      return false;
    }
    setAssetNameError(null);
    return true;
  };

  const onAssetNameTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAssetName(e.target.value);
    setAssetNameError(null);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const { assetName } = formState;
    if (!validAssetName(assetName)) {
      return null;
    }

    formOnSubmit();
    renameAsset({
      variables: { assetId: asset?.id, assetName },
    });
    return null;
  };

  return (
    <Form
      className="w-100"
      noValidate
      validated={isFormValid}
      onSubmit={handleSubmit}
    >
      <Row className="gx-3">
        <Col>
          <Form.Group>
            <FormControl
              placeholder="Enter new asset name"
              required
              value={formState.assetName}
              $errorType={formErrors[FormFields.AssetName]?.type}
              onChange={onAssetNameTextChange}
            />
            <FormFeetback type={formErrors[FormFields.AssetName]?.type}>
              {formErrors[FormFields.AssetName]?.message}
            </FormFeetback>
          </Form.Group>
        </Col>
      </Row>
      <ActionModal
        primaryButtonProps={{
          text: 'Rename',
          isLoading: isLoading || isAssetCreateLoading,
          type: 'submit',
          disabled: !isFormValid,
        }}
        secondaryButtonProps={{ text: 'Cancel', onClick: onModalClose }}
        buttonsAlignment={ButtonsAlignmentName.RIGHT}
        error={errorSlug}
      />
    </Form>
  );
}
export default RenameAssetForm;
