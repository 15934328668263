import React, { Ref } from 'react';
import styled from 'styled-components';

import type { IButtonProps } from './Button.interfaces';

import { boxStyle } from '../Box/Box';
import Spinner from '../Spinner/Spinner';
import { textStyle } from '../Text/Text';

const ButtonStyle = styled.button.attrs(({ type = 'button' }) => ({
  type,
}))<IButtonProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.base};

  border: none;
  background-color: ${({ theme }) => theme.colors.primary600};

  gap: 3px;
  border-radius: ${({ round }) => (round ? '8px' : '6px')};

  text-align: center;
  transition: all ease 200ms;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  &:hover:enabled {
    background-color: ${({ theme }) => theme.colors.primary700};
  }
  &:disabled {
    background-color: ${({ theme }) => theme.colors.primary200};
  }
  &:focus {
    outline: none;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #fee0b8;
  }
  ${({ secondary, theme }) =>
    secondary
      ? `
    background-color: ${theme.colors.base};
    color: ${theme.colors.grey700};
    border: 1px solid ${theme.colors.grey300};
    &:hover:enabled {
      background-color: ${theme.colors.grey50};
    }
    &:focus {
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #f2f4f7;
    }
  `
      : ''}
  ${({ noStyle }) =>
    noStyle
      ? `
    background-color: transparent;
    border: none;
    box-shadow: none;
    }
    &:hover:enabled {
      background-color: transparent;
    }
    &:focus {
      box-shadow: none;
    }
  `
      : ''};
  ${textStyle}
  ${boxStyle}
`;

const Button = React.forwardRef(
  (
    { isLoading, children, ...args }: { isLoading?: boolean } & IButtonProps,
    ref: Ref<HTMLButtonElement>
  ) => (
    <ButtonStyle ref={ref} {...args}>
      {children}
      {isLoading && <Spinner ml={1} />}
    </ButtonStyle>
  )
);
export default Button;
