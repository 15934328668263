import MuxPlayer from '@mux/mux-player-react/lazy';
import React, { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useLocation, useMatch, useNavigate, useParams } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

import AssetCardView from 'components/atoms/AssetCardView/AssetCardView';
import Box from 'components/atoms/Box/Box';
import Flex from 'components/atoms/Flex/Flex';
import RemovePopup from 'components/atoms/RemovePopup/RemovePopup';
import RenameInput from 'components/atoms/RenameInput/RenameInput';
import Spinner from 'components/atoms/Spinner/Spinner';
import Text from 'components/atoms/Text/Text';

import ViewAssetHeader from 'containers/Layout/components/ViewAssetHeader/ViewAssetHeader';
import {
  AssetFragment,
  FileType,
  useAssetsViewedByMutation,
  useGetAssetByIdQuery,
} from 'graph/generated.graphql';
import { useAppSelector } from 'redux/hooks';
import { selectViewMetaDataInfo, setModal } from 'redux/modal/modalSlice';
import { RoutesPath } from 'utils/constants/routes';
import {
  getIconNameByFileType,
  getThumbnailIconByFileType,
} from 'utils/helpers/workspace';
import { device, deviceMin } from 'utils/styles/device';
import {
  defaultDateTimeFormat,
  secondsToTimeWithHours,
} from 'utils/transformers/momentFormats';
import {
  convertBitesRate,
  convertBytes,
  getExtension,
} from 'utils/transformers/text';

import { isAssetSvg } from '../../utils/assets/getAssetPreviewImage';

const StyledMain = styled.main`
  background-color: ${({ theme }) => theme.colors.grey900};
  min-height: calc(100vh - 84px);
`;
const ContentWrapper = styled(Flex)`
  @media ${device.laptop} {
    flex-direction: column-reverse;
  }
  min-height: calc(100vh - 84px);
  align-content: stretch;
`;
const MetadataInfoWrapper = styled(Flex)<{ show: boolean | undefined }>`
  @media ${device.laptop} {
    height: ${({ show }) => (show ? '100%' : '0px')};
    width: 100%;
    ${({ show }) => (!show ? 'padding: 0;' : 'padding-bottom: 12px;')}
    ${({ show, theme }) =>
      show ? `border-bottom: 1px solid ${theme.colors.grey400};` : null}
  }
  @media ${deviceMin.laptop} {
    width: ${({ show }) => (show ? '360px' : '0px')};
    ${({ show }) => (!show ? 'padding: 0;' : null)};
    ${({ show, theme }) =>
      show ? `border-left: 1px solid ${theme.colors.grey400};` : null};
  }
  transition: 200ms all ease;
  overflow-y: auto;
`;
const StyledImage = styled(Image)`
  max-height: calc(100vh - 84px);
  width: 100%;
  max-width: 100vw;
  object-fit: contain;
`;
const TitleBox = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey700};
`;
const AssetViewWrapper = styled(Flex)`
  flex: auto;
  @media ${deviceMin.laptop} {
    width: calc(100vw - 360px);
  }
`;

function ViewAssetPage() {
  const { state } = useLocation();
  const { projectId, assetId, key } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [shouldFire, setShouldFire] = useState(true);

  const { data, loading: isGetAssetsLink } = useGetAssetByIdQuery({
    variables: { assetId, key },
    onCompleted() {
      if (isSharePage) {
        return;
      }
      if (shouldFire) {
        assetsViewedBy({ variables: { assetsId: [assetId] } });
      }
      setShouldFire(false);
    },
    onError() {
      if (!state.data) navigate(RoutesPath.PageNotFound);
    },
  });
  const asset = data?.asset.asset || (state?.data as AssetFragment);
  const [assetsViewedBy] = useAssetsViewedByMutation();

  const show = useAppSelector(selectViewMetaDataInfo);
  const [isAssetError, setIsAssetViewError] = useState(false);

  useEffect(() => {
    dispatch(
      setModal({
        sharedLinkData: {
          projectId,
          assetIds: [assetId],
          isProject: false,
        },
      })
    );
  }, [projectId, assetId, dispatch]);

  const isSharePage = useMatch(RoutesPath.ViewSharedAssetById);
  return (
    <>
      <ViewAssetHeader asset={asset || {}} />
      <StyledMain>
        {isGetAssetsLink ? (
          <Flex
            h="calc(100vh - 138px)"
            justifyContent="center"
            alignItems="center"
          >
            <Spinner size={54} color={theme.colors.base} />
          </Flex>
        ) : (
          <ContentWrapper
            justifyContent="center"
            alignItems="stretch"
            fullWidth
          >
            <AssetViewWrapper justifyContent="center" alignItems="center">
              {(asset?.type === FileType.Video ||
                asset?.type === FileType.Cutdown) &&
              !isAssetError ? (
                <MuxPlayer
                  streamType="on-demand"
                  playbackId={asset.playbackId}
                  metadata={{
                    video_id: asset.id,
                    video_title: asset.name,
                  }}
                  style={{ aspectRatio: 16 / 9 }}
                  onError={() => setIsAssetViewError(true)}
                />
              ) : (asset?.type === FileType.Picture || isAssetSvg(asset)) &&
                !isAssetError ? (
                <StyledImage
                  src={asset.s3DownloadUrl}
                  onError={() => setIsAssetViewError(true)}
                />
              ) : (
                <AssetCardView
                  asset={asset}
                  textIcon={getIconNameByFileType(asset?.type)}
                  thumbnailIcon={getThumbnailIconByFileType(asset?.type)}
                />
              )}
            </AssetViewWrapper>
            <MetadataInfoWrapper flexDirection="column" show={show} px={3}>
              <TitleBox fullWidth px={4} py={3}>
                <Text sm bold color={theme.colors.grey100}>
                  INFO
                </Text>
              </TitleBox>
              {!!asset?.name && (
                <Box px={4} py={4}>
                  <RenameInput asset={asset} minWidth={290} />
                </Box>
              )}
              {!!asset?.projectName && (
                <Flex px={4} py={1}>
                  <Box w={120} mr={4}>
                    <Text sm bold color={theme.colors.grey400}>
                      Project
                    </Text>
                  </Box>
                  <Box>
                    <Text sm medium color={theme.colors.grey100}>
                      {asset.projectName}
                    </Text>
                  </Box>
                </Flex>
              )}
              {!!asset?.lastModifiedAt && (
                <Flex px={4} py={1}>
                  <Box w={120} mr={4}>
                    <Text sm bold color={theme.colors.grey400}>
                      Modified
                    </Text>
                  </Box>
                  <Box>
                    <Text sm medium color={theme.colors.grey100}>
                      {defaultDateTimeFormat(asset.lastModifiedAt)}
                    </Text>
                  </Box>
                </Flex>
              )}
              {!!asset?.size && (
                <Flex px={4} py={1}>
                  <Box w={120} mr={4}>
                    <Text sm bold color={theme.colors.grey400}>
                      Size
                    </Text>
                  </Box>
                  <Box>
                    <Text sm medium color={theme.colors.grey100}>
                      {convertBytes(asset.size)}
                    </Text>
                  </Box>
                </Flex>
              )}
              {!!asset?.name && (
                <Flex px={4} py={1}>
                  <Box w={120} mr={4}>
                    <Text sm bold color={theme.colors.grey400}>
                      Extension
                    </Text>
                  </Box>
                  <Box>
                    <Text sm medium color={theme.colors.grey100}>
                      {getExtension(asset.name || '')?.toLowerCase()}
                    </Text>
                  </Box>
                </Flex>
              )}
              {(!!asset?.maxHeight || !!asset?.maxWidth) && (
                <Flex px={4} py={1}>
                  <Box w={120} mr={4}>
                    <Text sm bold color={theme.colors.grey400}>
                      Dimensions
                    </Text>
                  </Box>
                  <Box>
                    <Text sm medium color={theme.colors.grey100}>
                      {`${asset.maxWidth} x ${asset.maxHeight}`}
                    </Text>
                  </Box>
                </Flex>
              )}
              {!!asset?.createdAt && (
                <Flex px={4} py={1}>
                  <Box w={120} mr={4}>
                    <Text sm bold color={theme.colors.grey400}>
                      Created
                    </Text>
                  </Box>
                  <Box>
                    <Text sm medium color={theme.colors.grey100}>
                      {defaultDateTimeFormat(asset.createdAt)}
                    </Text>
                  </Box>
                </Flex>
              )}
              {!!asset?.duration && (
                <Flex px={4} py={1}>
                  <Box w={120} mr={4}>
                    <Text sm bold color={theme.colors.grey400}>
                      Duration
                    </Text>
                  </Box>
                  <Box>
                    <Text sm medium color={theme.colors.grey100}>
                      {secondsToTimeWithHours(asset?.duration)}
                    </Text>
                  </Box>
                </Flex>
              )}
              {!!asset?.aspectRatio && (
                <Flex px={4} py={1}>
                  <Box w={120} mr={4}>
                    <Text sm bold color={theme.colors.grey400}>
                      Aspect Ratio
                    </Text>
                  </Box>
                  <Box>
                    <Text sm medium color={theme.colors.grey100}>
                      {asset.aspectRatio}
                    </Text>
                  </Box>
                </Flex>
              )}
              {!!asset?.frameRate && (
                <Flex px={4} py={1}>
                  <Box w={120} mr={4}>
                    <Text sm bold color={theme.colors.grey400}>
                      Frame Rate
                    </Text>
                  </Box>
                  <Box>
                    <Text sm medium color={theme.colors.grey100}>
                      {asset.frameRate} fps
                    </Text>
                  </Box>
                </Flex>
              )}
              {!!asset?.colorSpace && (
                <Flex px={4} py={1}>
                  <Box w={120} mr={4}>
                    <Text sm bold color={theme.colors.grey400}>
                      Color Space
                    </Text>
                  </Box>
                  <Box>
                    <Text sm medium color={theme.colors.grey100}>
                      {asset.colorSpace}
                    </Text>
                  </Box>
                </Flex>
              )}
              {!!asset?.bitRate && (
                <Flex px={4} py={1}>
                  <Box w={120} mr={4}>
                    <Text sm bold color={theme.colors.grey400}>
                      Bit Rate
                    </Text>
                  </Box>
                  <Box>
                    <Text sm medium color={theme.colors.grey100}>
                      {convertBitesRate(asset.bitRate)}
                    </Text>
                  </Box>
                </Flex>
              )}
              {!!asset?.audio && (
                <Flex px={4} py={1}>
                  <Box w={120} mr={4}>
                    <Text sm bold color={theme.colors.grey400}>
                      Audio
                    </Text>
                  </Box>
                  <Box>
                    <Text sm medium color={theme.colors.grey100}>
                      {asset.audio}
                    </Text>
                  </Box>
                </Flex>
              )}
              {!!asset?.codec && (
                <Flex px={4} py={1}>
                  <Box w={120} mr={4}>
                    <Text sm bold color={theme.colors.grey400}>
                      Codec
                    </Text>
                  </Box>
                  <Box>
                    <Text sm medium color={theme.colors.grey100}>
                      {asset.codec}
                    </Text>
                  </Box>
                </Flex>
              )}
              {!!asset?.dotPerInch && (
                <Flex px={4} py={1}>
                  <Box w={120} mr={4}>
                    <Text sm bold color={theme.colors.grey400}>
                      Dots Per Inch
                    </Text>
                  </Box>
                  <Box>
                    <Text sm medium color={theme.colors.grey100}>
                      {`${asset.dotPerInch}x${asset.dotPerInch}`}
                    </Text>
                  </Box>
                </Flex>
              )}
            </MetadataInfoWrapper>
            <RemovePopup />
          </ContentWrapper>
        )}
      </StyledMain>
    </>
  );
}

export default ViewAssetPage;
