import React from 'react';
import { useTheme } from 'styled-components';

import { Alert } from 'components/atoms/Alert/Alert';
import Box from 'components/atoms/Box/Box';
import Flex from 'components/atoms/Flex/Flex';
import H from 'components/atoms/H/H';
import Logo from 'components/atoms/Logo/Logo';
import Text from 'components/atoms/Text/Text';

import { QUERY_PARAM } from 'utils/constants/common';
import { useSearchQuery } from 'utils/hooks/useSearchQuery/useSearchQuery';
import { useValidateToken } from 'utils/hooks/useValidateToken/useValidateToken';

import ResetPasswordForm from './components/ResetPasswordForm/ResetPasswordForm';
import SetNewPasswordForm from './components/SetNewPasswordForm/SetNewPasswordForm';

function ResetPassword() {
  const theme = useTheme();
  const query = useSearchQuery();
  const token = query.get(QUERY_PARAM.Token) || '';
  const { message } = useValidateToken({ token });
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      w={420}
    >
      <Box mb={12}>
        <Logo />
      </Box>
      <Flex flexDirection="column" alignItems="center" mb={8}>
        <Box mb={3}>
          <H sm bold color={theme.colors.vidicoBlack}>
            {token ? 'Enter your new password' : 'Forgot your password?'}
          </H>
        </Box>
        <Text textAlign="center" md regular color={theme.colors.grey500}>
          {token
            ? 'Don’t worry, happens to the best of us! '
            : 'No problem! Enter the email address that you signed up with to reset it.'}
        </Text>
      </Flex>
      <Alert
        variant={message.type}
        iconName={message.type === 'success' ? 'checkCircle' : 'infoCircle'}
        show={message.show}
      >
        {message.message}
      </Alert>
      {token ? (
        <SetNewPasswordForm
          token={token}
          isDisable={message.type === 'warning'}
        />
      ) : (
        <ResetPasswordForm />
      )}
    </Flex>
  );
}

export default ResetPassword;
