import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Box from 'components/atoms/Box/Box';
import Flex from 'components/atoms/Flex/Flex';
import H from 'components/atoms/H/H';
import Logo from 'components/atoms/Logo/Logo';
import Text from 'components/atoms/Text/Text';

import Footer from 'containers/Layout/components/Footer/Footer';
import { RoutesPath } from 'utils/constants/routes';
import { device } from 'utils/styles/device';

import SignUpForm from './components/SignUpForm/SignUpForm';

const SignUpWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.base};
  margin-right: auto;
  max-width: 836px;
  min-height: 100vh;
  max-height: 1024px;
  @media ${device.laptop} {
    margin: 0;
  }
`;
const FlexStyled = styled(Flex)`
  @media ${device.laptopL} {
    margin: 0;
  }
`;
const LogoWrapper = styled.div`
  margin-bottom: ${(props) => props.theme.size(12)};
`;
const SingUpFormTitle = styled(H)`
  color: ${(props) => props.theme.colors.vidicoBlack};
`;
const SingUpFormParagraph = styled(Text)`
  margin-bottom: ${({ theme }) => theme.size(13)};

  color: #313033;
`;
const LinkStyled = styled(Link)`
  color: ${({ theme }) => theme.colors.primary600};
`;
const TextStyled = styled(Text)`
  color: ${({ theme }) => theme.colors.black};
  text-align: center;
`;
const AStyled = styled.a`
  text-decoration: underline;
`;
function SignUp() {
  return (
    <SignUpWrapper flexDirection="column" justifyContent="space-between">
      <FlexStyled
        flex="inherit"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        p={9}
        mx={11}
      >
        <LogoWrapper>
          <Logo />
        </LogoWrapper>
        <Flex flexDirection="column" alignItems="center">
          <SingUpFormTitle lg bold>
            Sign up
          </SingUpFormTitle>
          <SingUpFormParagraph md regular>
            It’s quick and easy.
          </SingUpFormParagraph>
        </Flex>

        <Flex w={488}>
          <SignUpForm />
        </Flex>
        <Flex justifyContent="center" mb={3}>
          <Box mr={1}>
            <TextStyled sm>Already have an account?</TextStyled>
          </Box>
          <LinkStyled to={RoutesPath.SignIn}>
            <Text sm bold>
              Log in
            </Text>
          </LinkStyled>
        </Flex>
        <Flex justifyContent="center" w={488}>
          <TextStyled sm>
            Signing up for Vidico’s Video Store you agree to the{' '}
            <AStyled href="https://vidico.com/privacy-policy/" target="_blank">
              Privacy Policy
            </AStyled>{' '}
            and{' '}
            <AStyled
              href="https://vidico.com/terms-and-conditions/"
              target="_blank"
            >
              Terms of Service
            </AStyled>
            .
          </TextStyled>
        </Flex>
      </FlexStyled>
      <Footer />
    </SignUpWrapper>
  );
}

export default SignUp;
