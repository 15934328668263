import DragSelect, { DSInputElement, Settings } from 'dragselect';
import React, { useState, createContext, useMemo, useCallback } from 'react';

type ProviderProps = {
  children: React.ReactNode;
};
const defaultSettings = {
  draggability: false,
};
const DragSelectContext = createContext<{
  ds: DragSelect<DSInputElement> | undefined;
  setSettings: Function;
}>({
  ds: undefined,
  setSettings: () => {},
});

function DragSelectProvider({ children }: ProviderProps) {
  const [ds, setDS] = useState<DragSelect<DSInputElement>>();

  const setSettings = useCallback(
    (settings: Settings<DSInputElement>) => {
      setDS(
        new DragSelect({
          ...defaultSettings,
          ...settings,
        })
      );
    },
    [setDS]
  );

  const value = useMemo(() => ({ ds, setSettings }), [ds, setSettings]);

  // @ts-ignore react/jsx-no-constructed-context-values
  return (
    <DragSelectContext.Provider value={value}>
      {children}
    </DragSelectContext.Provider>
  );
}

export { DragSelectProvider, DragSelectContext };
