import React, { useState } from 'react';
import { useTheme } from 'styled-components';

import Flex from 'components/atoms/Flex/Flex';
import Icon from 'components/atoms/Icon/Icon';
import Text from 'components/atoms/Text/Text';
import ActionModal, {
  ButtonsAlignmentName,
} from 'components/molecules/ActionModal/ActionModal';
import { Modal } from 'components/molecules/Modal/Modal';

import { useDeleteSharedLinkMutation } from 'graph/generated.graphql';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  selectLinkId,
  selectSharedLinkDeleteModal,
  setModal,
} from 'redux/modal/modalSlice';

function DeleteSharedLinkModal() {
  const [errorMessage, setErrorMessage] = useState('');
  const theme = useTheme();
  const show = useAppSelector(selectSharedLinkDeleteModal);
  const linkId = useAppSelector(selectLinkId);
  const dispatch = useAppDispatch();
  const onCloseModal = () =>
    dispatch(
      setModal({
        showSharedLinkDeleteModal: false,
      })
    );
  const [deleteSharedLink, { loading: isDeleteSharedLinkLoading }] =
    useDeleteSharedLinkMutation({
      onCompleted() {
        dispatch(
          setModal({
            showSharedLinkDeleteModal: false,
          })
        );
      },
      onError(error) {
        setErrorMessage(error.message);
      },
    });
  const handleButtonClick = () => {
    deleteSharedLink({ variables: { linkId } });
  };
  return (
    <Modal show={show} onHide={onCloseModal} width={490}>
      <Flex fullWidth justifyContent="left" mb={4}>
        <Icon
          color={theme.colors.primary600}
          size={28}
          name="deleteFilled"
          mr={2}
        />

        <Text lg bold>
          Delete share link
        </Text>
      </Flex>
      <Flex fullWidth justifyContent="left" flexDirection="column" mb={8}>
        <Text sm medium color={theme.colors.grey600}>
          People will no longer be able to access this asset.
        </Text>
      </Flex>
      <ActionModal
        primaryButtonProps={{
          text: 'Delete link',
          type: 'button',
          isLoading: isDeleteSharedLinkLoading,
          onClick: handleButtonClick,
        }}
        secondaryButtonProps={{ text: 'Cancel', onClick: onCloseModal }}
        buttonsAlignment={ButtonsAlignmentName.RIGHT}
        error={errorMessage}
      />
    </Modal>
  );
}

export default DeleteSharedLinkModal;
