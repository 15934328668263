import React, { FormEvent, useCallback } from 'react';
import { Form } from 'react-bootstrap';
import styled, { useTheme } from 'styled-components';

import Box from 'components/atoms/Box/Box';
import Button from 'components/atoms/Button/Button';
import FormControl from 'components/atoms/FormControl/FormControl';
import FormFeetback from 'components/atoms/FormFeetback/FormFeetback';
import Icon from 'components/atoms/Icon/Icon';
import { IOption, Select } from 'components/atoms/Select/Select';
import Text from 'components/atoms/Text/Text';
import ActionModal, {
  ButtonsAlignmentName,
} from 'components/molecules/ActionModal/ActionModal';

import {
  InviteNewUserMutation,
  Roles,
  useInviteNewUserMutation,
} from 'graph/generated.graphql';
import { useAppSelector } from 'redux/hooks';
import { selectViewer } from 'redux/viewer/viewerSlice';
import { FormErrorMessages, FormErrorTypes } from 'utils/constants/forms';
import {
  isCompanyNotExistError,
  isEmailAllReadyExist,
  isNoAssesToThisCompany,
  isRoleToHigh,
} from 'utils/errors/isGqlError';
import {
  useDynamicFormValidation,
  useFormState,
} from 'utils/hooks/useFormValidation/useFormValidation';
import { isDomainInList, isEmail } from 'utils/validators/validators';

import { IAddMemberFormProps } from './AddMemberForm.interface';

enum FormFields {
  Email = 'email',
  Role = 'role',
  Send = 'send',
}

const FormFieldsList = ['invites'];
const FormValidationFieldsList = [FormFields.Email];
const FormControlWrapper = styled(Box)`
  position: relative;
`;
const SelectWrapper = styled(Box)`
  position: absolute;
  right: 0;
  top: 2px;
  & .react-select {
    &__menu {
      border: 1px solid ${({ theme }) => theme.colors.grey100};
      box-shadow: ${({ theme }) => theme.shadow.mdShadow};
      border-radius: 20px;
      padding: 8px;
    }
    &__option {
      margin-top: 4px;
      margin-bottom: 4px;
      border-radius: 12px;
      &--is-selected {
        background-color: ${({ theme }) => theme.colors.base} !important;
      }
      &--is-focused {
        background-color: ${({ theme }) => theme.colors.grey100} !important;
      }
    }
  }
`;
const EmailControl = styled(FormControl)`
  padding-right: 190px;
`;
function AddMemberForm({
  onModalClose,
  companyId,
  domainNames,
}: IAddMemberFormProps) {
  const theme = useTheme();
  const user = useAppSelector(selectViewer);

  const initialValue = {
    invites: [
      {
        [FormFields.Email]: '',
        [FormFields.Role]: Roles.Member,
        [FormFields.Send]: true,
      },
    ],
  };
  const [inviteNewUser, { loading: isInviteNewUser }] =
    useInviteNewUserMutation({
      onError(error) {
        error.graphQLErrors.forEach((gqlError) => {
          if (isCompanyNotExistError(gqlError)) {
            return formOnError(
              FormErrorMessages.EMAIL_NOT_IN_DOMAIN_APPROVE_LIST
            );
          }
          if (isEmailAllReadyExist(gqlError)) {
            return formOnError(FormErrorMessages.EMAIL_NOT_UNIQUE);
          }
          if (isRoleToHigh(gqlError)) {
            return formOnError(FormErrorMessages.ROLE_TO_HIGH);
          }
          if (isNoAssesToThisCompany(gqlError)) {
            return formOnError(FormErrorMessages.GENERAL);
          }
          return formOnError(gqlError.message);
        });
      },
      onCompleted(data) {
        onSuccess(data);
      },
    });

  const options: IOption[] = [
    {
      label: 'Workspace owner',
      value: Roles.Owner,
      subText:
        'Workspace owners can add and remove users and manage organisation-level settings.',
      isDisabled: user?.roles === Roles.Admin || user?.roles === Roles.Member,
      hideIcon: true,
    },
    {
      label: 'Admin',
      value: Roles.Admin,
      subText: 'Admins can add and remove users.',
      isDisabled: user?.roles === Roles.Member,
      hideIcon: true,
    },
    {
      label: 'Member',
      value: Roles.Member,
      subText: 'Members can access, share, view and download assets.',
      hideIcon: true,
    },
  ];

  const [formErrors, isFormValid, [setEmailError]] = useDynamicFormValidation(
    FormValidationFieldsList
  );

  const [formState, [setInvites], [formOnSubmit, formOnError, formOnSuccess]] =
    useFormState(FormFieldsList, initialValue);
  const { invites, errorSlug } = formState;

  const validEmail = (value: string, index: number) => {
    if (!value) {
      setEmailError(
        {
          type: FormErrorTypes.Invalid,
          message: FormErrorMessages.EMAIL_BLANK,
        },
        index
      );
      return false;
    }
    if (!isEmail(value)) {
      setEmailError(
        {
          type: FormErrorTypes.Invalid,
          message: FormErrorMessages.EMAIL_INVALID,
        },
        index
      );
      return false;
    }

    if (!isDomainInList(value, domainNames)) {
      setEmailError(
        {
          type: FormErrorTypes.Invalid,
          message: FormErrorMessages.EMAIL_NOT_IN_DOMAIN_APPROVE_LIST,
        },
        index
      );
      return false;
    }
    if (value.length > 40) {
      setEmailError(
        {
          type: FormErrorTypes.Invalid,
          message: FormErrorMessages.MAX_40_CHARACTERS,
        },
        index
      );
      return false;
    }
    setEmailError(null, index);
    return true;
  };
  const onEmailTextChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { value } = e.target;
    const data = [...invites];
    data[index][FormFields.Email] = value;
    setInvites(data);
    setEmailError(null, index);
    formOnSuccess();
  };

  const onSuccess = (data: InviteNewUserMutation) => {
    const response = data.inviteNewUser;
    if (response.isUnSuccess) {
      response.inviteResponses.forEach((item, index) =>
        setEmailError(
          item.isSuccess
            ? {
                type: FormErrorTypes.Valid,
                message: FormErrorMessages.INVITATION_SENT_SUCCESSFULLY,
              }
            : {
                type: FormErrorTypes.Invalid,
                message: FormErrorMessages.EMAIL_NOT_UNIQUE,
              },
          index
        )
      );
    } else {
      formOnSuccess();
      onModalClose();
    }
  };

  const onRoleChange = useCallback(
    (value: IOption, index: number) => {
      const data = [...invites];
      data[index][FormFields.Role] = value.value;
      setInvites(data);
      formOnSuccess();
    },
    [formOnSuccess, invites, setInvites]
  );

  const handleAddFormField = () => {
    const data = [...invites];
    data.push(initialValue.invites[0]);
    setInvites(data);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (
      invites.filter(
        (item: any, index: number) => !validEmail(item[FormFields.Email], index)
      ).length
    ) {
      return null;
    }
    inviteNewUser({
      variables: {
        companyId,
        invites,
      },
      refetchQueries: ['getUsersByCompanyId'],
    });
    formOnSubmit();
    return null;
  };
  return (
    <Form className="w-100" onSubmit={handleSubmit}>
      {formState.invites &&
        formState.invites.map((_: any, index: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <Form.Group key={index} controlId="validationCustom01">
            <Form.Label>
              <Text color={theme.colors.grey900} sm semibold>
                Email
              </Text>
            </Form.Label>
            <FormControlWrapper>
              <EmailControl
                type="email"
                placeholder="Member to invite"
                required
                onChange={(e) => onEmailTextChange(e, index)}
              />
              <SelectWrapper>
                <Select
                  isSearchable={false}
                  noBorder
                  round
                  defaultValue={options[2]}
                  placeholder="Select Localisations"
                  options={options}
                  backgroundColor="transparent"
                  onChange={(newValue) => onRoleChange(newValue as any, index)}
                  minMenuWidth={324}
                />
              </SelectWrapper>
            </FormControlWrapper>
            {formErrors[FormFields.Email] &&
            formErrors[FormFields.Email][index] ? (
              <FormFeetback type={formErrors[FormFields.Email][index]?.type}>
                {formErrors[FormFields.Email][index]?.message}
              </FormFeetback>
            ) : (
              <Box mb={2} />
            )}
          </Form.Group>
        ))}
      <Button
        noStyle
        color={theme.colors.primary600}
        sm
        bold
        onClick={handleAddFormField}
      >
        <Icon size={20} name="plus" />
        Add another
      </Button>
      <ActionModal
        buttonsAlignment={ButtonsAlignmentName.RIGHT}
        error={errorSlug}
        primaryButtonProps={{
          text: 'Send Invites',
          type: 'submit',
          isLoading: isInviteNewUser,
          disabled: !isFormValid || !!errorSlug,
        }}
        secondaryButtonProps={{ text: 'Cancel', onClick: onModalClose }}
      />
    </Form>
  );
}

export default AddMemberForm;
