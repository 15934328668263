import { useEffect, RefObject } from 'react';

function useOnRightClick(
  ref: RefObject<HTMLElement>,
  handlerClick: () => void,
  handlerContextMenu: (event: MouseEvent) => void
) {
  useEffect(() => {
    let refValue: any = null;
    if (ref.current) {
      ref.current!.addEventListener('click', handlerClick);
      ref.current!.addEventListener('contextmenu', handlerContextMenu);
      refValue = ref.current;
    }
    return () => {
      if (refValue) {
        refValue.removeEventListener('click', handlerClick);
        refValue.removeEventListener('contextmenu', handlerContextMenu);
      }
    };
  }, [handlerClick, handlerContextMenu, ref]);
}

export { useOnRightClick };
