import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';

import Box from 'components/atoms/Box/Box';
import Flex from 'components/atoms/Flex/Flex';
import Icon from 'components/atoms/Icon/Icon';
import Spinner from 'components/atoms/Spinner/Spinner';
import Text from 'components/atoms/Text/Text';
import ActionModal, {
  ButtonsAlignmentName,
} from 'components/molecules/ActionModal/ActionModal';
import { Modal } from 'components/molecules/Modal/Modal';

import { useProjectCreateMutation } from 'graph/generated.graphql';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  selectRequestNewProjectFinalModal,
  selectRequestNewProjectModalInfo,
  setModal,
} from 'redux/modal/modalSlice';

const IconWrapper = styled(Flex)<{ isError: boolean }>`
  border-radius: 50%;
  background-color: ${({ theme, isError }) =>
    isError ? theme.colors.error100 : theme.colors.success100};
`;
function RequestNewProjectFinalModal({ refetch }: { refetch: () => void }) {
  const [isError, setIsError] = useState(false);
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const show = useAppSelector(selectRequestNewProjectFinalModal);
  const info = useAppSelector(selectRequestNewProjectModalInfo);
  const [createProject, { loading: isCreateProjectLoading }] =
    useProjectCreateMutation({
      onCompleted() {
        refetch();
      },
      onError() {
        setIsError(true);
      },
    });
  const onModalClose = () => {
    dispatch(setModal({ showRequestNewProjectFinalModal: false }));
    setIsError(false);
  };

  return (
    <Modal
      show={show}
      onHide={onModalClose}
      width={450}
      onEntered={() => {
        createProject({
          variables: info ? { ...info } : undefined,
        });
      }}
    >
      {isCreateProjectLoading ? (
        <Spinner />
      ) : (
        <>
          <Flex
            fullWidth
            justifyContent="left"
            alignItems="center"
            mb={isError ? 0 : 3}
            gap={8}
          >
            <IconWrapper
              isError={isError}
              w={32}
              h={32}
              justifyContent="center"
              alignItems="center"
            >
              <Icon
                color={
                  isError ? theme.colors.error600 : theme.colors.success600
                }
                size={16}
                name={isError ? 'xCircle' : 'checkCircle'}
              />
            </IconWrapper>
            <Text lg bold>
              {isError
                ? 'Something went wrong. Try again!'
                : 'Your project has been sent!'}
            </Text>
          </Flex>
          <Box mb={5}>
            {!isError && (
              <Text sm medium color={theme.colors.grey600}>
                You’ll get a response from the Vidico team within 24-hours.
              </Text>
            )}
          </Box>
          {!isError && (
            <ActionModal
              buttonsAlignment={ButtonsAlignmentName.RIGHT}
              primaryButtonProps={{
                text: 'Okay',
                type: 'button',
                onClick: onModalClose,
              }}
            />
          )}
        </>
      )}
    </Modal>
  );
}

export default RequestNewProjectFinalModal;
