enum ErrorCodes {
  COMPANY_NAME_ALREADY_EXIST = 'company_name_already_exist',
  COMPANY_DOMAIN_ALREADY_EXIST = 'company_domain_allready_exist',
  COMPANY_NOT_EXIST = 'company_not_exist',
  PROJECT_NAME_ALREADY_EXIST = 'project_allready_exist',
  PREDICATE_VALIDATOR = 'PredicateValidator',
  HC0018 = 'HC0018',
  TOKEN_INVALID = 'link_invalid',
  TOKEN_EXPIRED = 'link_expired',
  LINK_IS_USED = 'link_already_used',
  AUTHORIZATION_FAILED = 'authorization_failed',
  SHARED_LINK_NOT_EXIST = 'shared_link_not_exist',
  EMAIL_ALREADY_USE = 'email_already_use',
  ROLE_TO_HIGH = 'assigned_role_to_high',
  NO_ACCESS_TO_THIS_COMPANY = 'not_access_to_this_company',
  WRONG_PASSWORD = 'wrong_password',
  MINIMUM_LENGTH_VALIDATOR = 'MinimumLengthValidator',
  ASSET_NAME_ALREADY_EXIST = 'aasset_already_exists',
  OWNER_TRY_DELETE_HIS_ACCOUNT = 'error_deleting_owner',
}
enum InputVariables {
  FILE = 'file',
  EMAIL = 'Email',
  ASSET_NAME = 'AssetName',
}
enum ErrorsMessage {
  tokenInvalid = 'Link is invalid',
  tokenExpired = 'Link has expired',
  success = 'Successfully confirmed email',
}

export { ErrorCodes, InputVariables, ErrorsMessage };
