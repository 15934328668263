import { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

import Flex from 'components/atoms/Flex/Flex';
import Spinner from 'components/atoms/Spinner/Spinner';

import CompleatSignUpContainer from 'containers/Authentification/CompleatSignUp/CompleatSignUpContainer';
import Footer from 'containers/Layout/components/Footer/Footer';
import { useValidateTokenLazyQuery } from 'graph/generated.graphql';
import { QUERY_PARAM } from 'utils/constants/common';
import { RoutesPath } from 'utils/constants/routes';
import {
  isTokenExpiredError,
  isTokenInvalidError,
} from 'utils/errors/isGqlError';
import { useSearchQuery } from 'utils/hooks/useSearchQuery/useSearchQuery';

const StyledContainer = styled(Container)`
  min-height: calc(100vh - 84px);
`;
const SpinnerContainer = styled(Container)`
  min-height: calc(100vh - 84px);
  height: calc(100vh - 84px);
`;
const StyledFlex = styled(Flex)`
  min-height: 100%;
`;

function CompleatSignUpPage() {
  const query = useSearchQuery();
  const navigate = useNavigate();
  const theme = useTheme();
  const [getData, { loading: isValidateTokenLoading }] =
    useValidateTokenLazyQuery({
      onCompleted() {},
      onError(error) {
        error.graphQLErrors.forEach((gqlError) => {
          if (isTokenExpiredError(gqlError)) {
            navigate(`${RoutesPath.SignIn}?error=tokenExpired`);
          }
          if (isTokenInvalidError(gqlError)) {
            navigate(`${RoutesPath.SignIn}?error=tokenExpired`);
          }
        });
      },
    });
  const token = query.get(QUERY_PARAM.Token) || '';
  useEffect(() => {
    getData({
      variables: {
        token,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main>
      {!isValidateTokenLoading ? (
        <>
          <StyledContainer fluid="xl">
            <CompleatSignUpContainer
              token={token}
              firstName={query.get(QUERY_PARAM.FirstName) || ''}
              lastName={query.get(QUERY_PARAM.LastName) || ''}
            />
          </StyledContainer>
          <Footer />
        </>
      ) : (
        <SpinnerContainer fluid="xl">
          <StyledFlex w={640} alignItems="center" justifyContent="center">
            <Spinner size={50} color={theme.colors.primary600} />
          </StyledFlex>
        </SpinnerContainer>
      )}
    </main>
  );
}

export default CompleatSignUpPage;
