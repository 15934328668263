import React from 'react';
import styled from 'styled-components';

import Header from 'containers/Layout/components/Header/Header';
import WorkspaceListTable from 'modules/admin/components/WorkspaceListTable/WorkspaceListTable';

const MainStyled = styled.main`
  min-height: calc(100vh - 92px);
`;

function WorkspaceListPage() {
  return (
    <>
      <Header />
      <MainStyled>
        <WorkspaceListTable />
      </MainStyled>
    </>
  );
}

export default WorkspaceListPage;
