import { capitalizeFirstLetter } from 'utils/helpers/capitalizeFirstLetter';

export const transformLocalizationToLocalizationName = (
  value: string
): string => {
  const [firstItem, ...rest] = value.split('_');
  return `${firstItem} · ${rest.map(item => item.toLowerCase()).map(capitalizeFirstLetter).join(' ')}`;
};
export const transformFileTypeToFileTypeName = (value: string): string =>
  capitalizeFirstLetter(value.toLocaleLowerCase());
