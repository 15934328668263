import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

import Avatar from 'components/atoms/Avatar/Avatar';
import Box from 'components/atoms/Box/Box';
import Button from 'components/atoms/Button/Button';
import { DropdownDivider } from 'components/atoms/DropdownDivider/DropdownDivider';
import Flex from 'components/atoms/Flex/Flex';
import Icon from 'components/atoms/Icon/Icon';
import LabelGroup from 'components/atoms/LabelGroup/LabelGroup';
import Text from 'components/atoms/Text/Text';

import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { setModal } from 'redux/modal/modalSlice';
import { selectViewer } from 'redux/viewer/viewerSlice';
import { RoutesPath } from 'utils/constants/routes';

export type CustomToggleProps = {
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {};
};
const MenuStyled = styled(Button)`
  background-color: ${({ theme }) => theme.colors.base};
  border: none;
  padding: 0;

  display: flex;
  align-items: flex-start;
  &:first-child:hover {
    background-color: ${({ theme }) => theme.colors.base};
  }

  &:hover,
  :focus {
    box-shadow: none;
  }
`;

const CustomMenuStyled = styled(Box)`
  background-color: ${({ theme }) => theme.colors.base};
  border: 1px solid ${({ theme }) => theme.colors.grey200};
  box-shadow: ${({ theme }) => theme.shadow.lgShadow};
  border-radius: 8px;
`;

const CustomToggle = React.forwardRef(
  (props: CustomToggleProps, ref: React.Ref<HTMLButtonElement>) => {
    const user = useAppSelector(selectViewer);
    return user?.id ? (
      <MenuStyled
        p={0}
        h={56}
        ref={ref}
        noStyle
        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          e.preventDefault();
          props.onClick(e);
        }}
      >
        <Flex>
          <Avatar text={user.company?.name} src={user.company?.logo || ''} />
        </Flex>
      </MenuStyled>
    ) : null;
  }
);

export type CustomMenuProps = {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  labeledBy?: string;
};

const CustomMenu = React.forwardRef(
  (props: CustomMenuProps, ref: React.Ref<HTMLDivElement>) => (
    <CustomMenuStyled
      p={1}
      ref={ref}
      style={props.style}
      className={props.className}
      aria-labelledby={props.labeledBy}
    >
      {React.Children.toArray(props.children)}
    </CustomMenuStyled>
  )
);

function ProfileMenu({ showAddMember }: { showAddMember: boolean }) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectViewer);
  const userName = `${user?.firstName} ${user?.lastName}`;
  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components" />

      <Dropdown.Menu as={CustomMenu}>
        <Dropdown.Item key="profile" eventKey="profile">
          <Flex my={2} mr={4}>
            <Box mr={3}>
              <Avatar
                color="primary"
                xs
                text={userName}
                src={user?.avatar || ''}
              />
            </Box>

            <LabelGroup
              title={user?.company?.name || ''}
              subTitle={userName}
              text={user?.email || ''}
              alignItems="flex-start"
            />
          </Flex>
        </Dropdown.Item>
        <DropdownDivider />
        <Dropdown.Item
          className="p-0 hover"
          as="div"
          key="settings"
          eventKey="settings"
        >
          <Link className="hover" to={RoutesPath.Settings}>
            <Flex alignItems="center" px={4} py={2} gap={12}>
              <Flex>
                <Icon size={24} name="settings" />
              </Flex>
              <Text color={theme.colors.grey900} sm medium>
                Settings
              </Text>
            </Flex>
          </Link>
        </Dropdown.Item>
        {showAddMember && (
          <Dropdown.Item
            className="p-0 hover"
            as="div"
            onClick={() => dispatch(setModal({ showAddMemberModal: true }))}
            key="add-member"
            eventKey="add-member"
          >
            <Flex alignItems="center" px={4} py={2}>
              <Flex mr={3}>
                <Icon size={24} name="personAddOutline" />
              </Flex>
              <Text color={theme.colors.grey900} sm medium>
                Add Member
              </Text>
            </Flex>
          </Dropdown.Item>
        )}
        <DropdownDivider />
        <Dropdown.Item
          className="p-0"
          onClick={() => dispatch(setModal({ showLogOutModal: true }))}
          key="sign-out"
          eventKey="sign-out"
        >
          <Box px={4} py={2}>
            <Text color={theme.colors.grey900} sm medium>
              Sign out
            </Text>
          </Box>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default ProfileMenu;
