import React, { useState } from 'react';
import { useTheme } from 'styled-components';

import Box from 'components/atoms/Box/Box';
import Flex from 'components/atoms/Flex/Flex';
import Icon from 'components/atoms/Icon/Icon';
import ImagesCascade from 'components/atoms/ImagesCascade/ImagesCascade';
import Text from 'components/atoms/Text/Text';
import ActionModal, {
  ButtonsAlignmentName,
} from 'components/molecules/ActionModal/ActionModal';
import { Modal } from 'components/molecules/Modal/Modal';

import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  selectAssetsToRemove,
  selectRemoveAssetsModal,
  setModal,
} from 'redux/modal/modalSlice';

import { getAssetPreviewImage } from '../../../utils/assets/getAssetPreviewImage';

function DeleteAssetsModal() {
  const [errorMessage, setErrorMessage] = useState('');
  const theme = useTheme();
  const show = useAppSelector(selectRemoveAssetsModal);
  const assets = useAppSelector(selectAssetsToRemove);
  const dispatch = useAppDispatch();
  const imageList = assets?.map(getAssetPreviewImage) as string[];

  const onCloseModal = () => {
    dispatch(
      setModal({
        showRemoveAssetsModal: false,
        assetsToRemove: [],
      })
    );

    setErrorMessage('');
  };

  const handleButtonClick = () => {
    dispatch(
      setModal({
        showRemoveAssetsModal: false,
        removePopupInfo: {
          showRemovePopup: true,
          isProjectRemoved: false,
        },
      })
    );
  };
  return (
    <Modal show={show} onHide={onCloseModal} width={480}>
      <Flex fullWidth justifyContent="left" mb={4}>
        <Icon
          color={theme.colors.primary600}
          size={28}
          name="deleteFilled"
          mr={2}
        />

        <Text lg bold>
          Delete {assets?.length} file(s)
        </Text>
      </Flex>
      <Flex fullWidth justifyContent="left" flexDirection="column" mb={5}>
        <Text sm medium color={theme.colors.grey600}>
          <b>Caution:</b> This will delete these files from your workspace.
        </Text>
      </Flex>
      <Box mb={5}>
        <ImagesCascade imagesList={imageList} />
      </Box>
      <ActionModal
        primaryButtonProps={{
          text: 'Delete',
          type: 'button',
          onClick: handleButtonClick,
        }}
        secondaryButtonProps={{ text: 'Cancel', onClick: onCloseModal }}
        buttonsAlignment={ButtonsAlignmentName.RIGHT}
        error={errorMessage}
      />
    </Modal>
  );
}

export default DeleteAssetsModal;
