import React from 'react';
import { useTheme } from 'styled-components';

import Box from 'components/atoms/Box/Box';
import Flex from 'components/atoms/Flex/Flex';
import Icon from 'components/atoms/Icon/Icon';
import ImagesCascade from 'components/atoms/ImagesCascade/ImagesCascade';
import Text from 'components/atoms/Text/Text';
import { Modal } from 'components/molecules/Modal/Modal';

import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  selectRenameAssetInfo,
  selectRenameAssetModal,
  setModal,
} from 'redux/modal/modalSlice';

import RenameAssetForm from './components/RenameAssetForm';

import { getAssetPreviewImage } from '../../../utils/assets/getAssetPreviewImage';

function RenameAssetModal() {
  const theme = useTheme();
  const asset = useAppSelector(selectRenameAssetInfo);
  const show = useAppSelector(selectRenameAssetModal);
  const dispatch = useAppDispatch();
  const onCloseModal = () => {
    dispatch(
      setModal({
        showRenameAssetModal: false,
        renameAssetInfo: undefined,
      })
    );
  };

  return (
    <Modal show={show} onHide={onCloseModal} width={480}>
      <Flex fullWidth justifyContent="left" mb={5}>
        <Icon
          color={theme.colors.primary600}
          size={28}
          name="driveFileRenameOutline"
          mr={2}
        />

        <Text lg bold>
          Rename Asset
        </Text>
      </Flex>
      <Box mb={5}>
        <ImagesCascade imagesList={[getAssetPreviewImage(asset)]} />
      </Box>
      <RenameAssetForm asset={asset} onModalClose={onCloseModal} />
    </Modal>
  );
}

export default RenameAssetModal;
