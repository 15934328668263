import { Form } from 'react-bootstrap';
import styled from 'styled-components';

import checkIcon from 'assets/svgs/check.svg';

import { getFont } from '../../../utils/theme/typography';

const FormCheck = styled(Form.Check)`
  .form-check-input {
    cursor: pointer;
    border-radius: 6px;
    border: 1px solid ${({ theme }) => theme.colors.grey900};
    :focus {
      box-shadow: 0px 0px 0px 4px ${({ theme }) => theme.colors.primary100};
    }
    :hover {
      background-color: ${({ theme }) => theme.colors.primary100};
      border: 1px solid ${({ theme }) => theme.colors.primary600};
    }
  }
  .form-check-input:checked[type='checkbox'] {
    background-image: url(${checkIcon});
    background-size: 10px;
    background-color: ${({ theme }) => theme.colors.primary50};
    border: 1px solid ${({ theme }) => theme.colors.primary600};
  }
  ${({ theme }) => getFont({ theme })}
  color: ${({ theme }) => theme.colors.grey700};
`;
export default FormCheck;
