import { useEffect, useRef, useState } from 'react';

import { FormErrorTypes } from '../../constants/forms';

export type FromError = {
  type: FormErrorTypes;
  message: string;
};
type FromState = { isLoading: boolean; errorSlug: string } & Record<
  string,
  any
>;

type FromErrors = Record<string, FromError | null>;

type DynamicFromErrors = Record<string, Record<number, FromError | null>>;

type useFormValidationType = [
  FromErrors,
  boolean,
  Array<(value: FromError | null) => void>
];
type useDynamicFormValidationType = [
  DynamicFromErrors,
  boolean,
  Array<(value: FromError | null, index: number) => void>
];

type useFormStateType = [
  FromState,
  Array<(value: any) => void>,
  Array<(value?: string) => void>
];

const useFormValidation = (fieldsList: string[]): useFormValidationType => {
  const [errors, setErrors] = useState<FromErrors>(
    fieldsList.reduce((obj, key) => ({ ...obj, [key]: null }), {})
  );
  /** use copy of errors state, to get correct value of errors in setters
   *  (this is need when we will call setters synchronously, to not overlap state object)
   */
  const errorsRef = useRef<FromErrors>({});
  const [isValid, setIsValid] = useState(false);
  const [isSetterCalls, setIsSetterCalls] = useState(false);

  const setters = fieldsList.map((key) => (value: FromError | null): void => {
    setIsSetterCalls(true);
    setErrors({
      ...errorsRef.current,
      [key]: value,
    });
    errorsRef.current[key] = value;
  });

  useEffect(() => {
    if (isSetterCalls) {
      setIsValid(
        !fieldsList.find(
          (key) =>
            Boolean(errors[key]?.type === FormErrorTypes.Invalid) ||
            Boolean(errors[key]?.type === FormErrorTypes.Blank)
        )
      );
    }
  }, [errors, fieldsList, isSetterCalls]);

  return [errors, isValid, setters];
};
const useDynamicFormValidation = (
  fieldsList: string[]
): useDynamicFormValidationType => {
  const [errors, setErrors] = useState<DynamicFromErrors>(
    fieldsList.reduce((obj, key) => ({ ...obj, [key]: null }), {})
  );
  /** use copy of errors state, to get correct value of errors in setters
   *  (this is need when we will call setters synchronously, to not overlap state object)
   */
  const errorsRef = useRef<DynamicFromErrors>({});
  const [isValid, setIsValid] = useState(false);
  const [isSetterCalls, setIsSetterCalls] = useState(false);

  const setters = fieldsList.map(
    (key) =>
      (value: FromError | null, index: number): void => {
        setIsSetterCalls(true);
        setErrors({
          ...errorsRef.current,
          [key]: { ...errorsRef.current[key], [index]: value },
        });
        errorsRef.current[key] = { ...errorsRef.current[key], [index]: value };
      }
  );

  useEffect(() => {
    if (isSetterCalls) {
      setIsValid(
        !fieldsList.find((key) =>
          Boolean(
            Object.values(errors[key]).find(
              (i) =>
                i?.type === FormErrorTypes.Invalid ||
                i?.type === FormErrorTypes.Blank
            )
          )
        )
      );
    }
  }, [errors, fieldsList, isSetterCalls]);

  return [errors, isValid, setters];
};

const useFormState = (
  fieldsList: string[],
  initialValue?: Record<string, string | any[]>
): useFormStateType => {
  const [formState, setFormState] = useState<FromState>({
    isLoading: false,
    errorSlug: '',
    ...fieldsList.reduce(
      (obj, key) => ({
        ...obj,
        [key]: initialValue ? initialValue[key] : null,
      }),
      {}
    ),
  });
  /** use copy of errors state, to get correct value of errors in setters
   *  (this is need when we will call setters synchronously, to not overlap state object)
   */
  const formRef = useRef<FromState>({
    isLoading: false,
    errorSlug: '',
    ...fieldsList.reduce(
      (obj, key) => ({
        ...obj,
        [key]: initialValue ? initialValue[key] : null,
      }),
      {}
    ),
  });

  const setters = fieldsList.map((key) => (value: any): void => {
    setFormState({
      ...formRef.current,
      [key]: value,
    });
    formRef.current[key] = value;
  });

  const onSubmit = () => {
    setFormState({ ...formState, isLoading: true, errorSlug: '' });
  };
  const onError = (message?: string) => {
    setFormState({ ...formState, isLoading: false, errorSlug: message || '' });
  };
  const onSuccess = () => {
    setFormState({ ...formState, isLoading: false, errorSlug: '' });
  };

  return [formState, setters, [onSubmit, onError, onSuccess]];
};

export { useFormValidation, useDynamicFormValidation, useFormState };
