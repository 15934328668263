import React, { useMemo } from 'react';
import { useMatch } from 'react-router-dom';
import { useTheme } from 'styled-components';

import Box from 'components/atoms/Box/Box';
import Flex from 'components/atoms/Flex/Flex';
import Icon from 'components/atoms/Icon/Icon';
import Text from 'components/atoms/Text/Text';
import { Modal } from 'components/molecules/Modal/Modal';

import { Roles, useGetCompanyByIdBaseInfoQuery } from 'graph/generated.graphql';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectAddMemberModal, setModal } from 'redux/modal/modalSlice';
import { selectViewer } from 'redux/viewer/viewerSlice';
import { RoutesPath } from 'utils/constants/routes';

import AddMemberForm from './component/AddMemberForm';

function AddMemberModal() {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const user = useAppSelector(selectViewer);

  const show = useAppSelector(selectAddMemberModal);
  const onModalClose = () => {
    dispatch(setModal({ showAddMemberModal: false }));
  };
  const isWorkspaceSelected = useMatch(RoutesPath.Projects);
  const isProjectInWorkspaceSelected = useMatch(RoutesPath.WorkspaceById);
  const companyId =
    isWorkspaceSelected?.params.companyId ||
    isProjectInWorkspaceSelected?.params.companyId;
  const isVidicoAdmin = user?.roles === Roles.VidicoAdmin;
  const { data: companyData, loading: isCompanyDataLoading } =
    useGetCompanyByIdBaseInfoQuery({
      variables: { companyId },
      skip: !isVidicoAdmin || !companyId,
    });
  const company = isVidicoAdmin
    ? companyData?.viewCompany.company
    : user?.company;
  const domainNames = useMemo(
    () => (isVidicoAdmin ? company?.domainNames : user?.company?.domainNames),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      company?.domainNames,
      isVidicoAdmin,
      user?.company?.domainNames,
      isCompanyDataLoading,
    ]
  );
  const formCompanyId = isVidicoAdmin ? companyId : user?.companyId;
  return (
    <Modal show={show} onHide={onModalClose} width={504}>
      <Flex fullWidth justifyContent="left" alignItems="center" mb={4}>
        <Box mr={2}>
          <Icon color={theme.colors.primary600} size={32} name="personAdd" />
        </Box>
        <Text lg bold>
          Add Members
        </Text>
      </Flex>
      <Box mb={5}>
        <Text sm medium color={theme.colors.grey600}>
          {`Invite your teammates to join ${company?.name}. Enter an email, choose the
          permission levels, and send. Only approved domains can be added.`}
        </Text>
      </Box>
      <AddMemberForm
        companyId={formCompanyId}
        domainNames={domainNames}
        onModalClose={onModalClose}
      />
    </Modal>
  );
}

export default AddMemberModal;
