import React, { useEffect, useRef, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import styled from 'styled-components';

import Text from 'components/atoms/Text/Text';
import { DotsDropDownMenu } from 'components/molecules/DotsDropDownMenu/DotsDropDownMenu';

import { useAppDispatch } from 'redux/hooks';
import { setModal } from 'redux/modal/modalSlice';

import { IShareInputProps } from './ShareInput.interface';

import Box from '../Box/Box';
import Flex from '../Flex/Flex';
import FormControl from '../FormControl/FormControl';
import IconButton from '../IconButton/IconButton';

const ShareControl = styled(FormControl)`
  padding-right: 80px;
`;
const Wrapper = styled(Box)`
  position: relative;
`;
const CopyButtonWrapper = styled(Flex)`
  position: absolute;
  top: 0;

  right: 0;
`;

function ShareInput({ linkId, value, ...args }: IShareInputProps) {
  const POP_UP_DELAY = 2000;
  const [sharePopupVisible, setSharePopupVisible] = useState(false);
  const dispatch = useAppDispatch();
  const inputValue = String(value) || '';
  const target = useRef(null);

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;
    if (sharePopupVisible) {
      timeoutId = setTimeout(() => {
        setSharePopupVisible(false);
      }, POP_UP_DELAY);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [sharePopupVisible]);

  return (
    <Wrapper fullWidth>
      <ShareControl value={value} {...args} readOnly />
      <CopyButtonWrapper alignItems="center" h="100%">
        <OverlayTrigger
          key={linkId}
          show={sharePopupVisible}
          overlay={
            <Tooltip id={linkId} style={{ inset: 'auto auto 10px 0px' }}>
              <Box p={1}>
                <Text medium xs>
                  Link copied
                </Text>
              </Box>
            </Tooltip>
          }
          trigger={['click']}
          onToggle={(props) => setSharePopupVisible(props)}
        >
          <IconButton
            iconSize={20}
            iconName="copy02"
            ref={target}
            onClick={() =>
              navigator.clipboard.writeText(`https://${inputValue}`)
            }
          />
        </OverlayTrigger>

        <Box pr={3}>
          <DotsDropDownMenu
            menuItems={[
              {
                icon: 'visibility',
                label: 'Preview link',
                key: 'prev_link',
                onClick: () => window.open(`https://${inputValue}`),
              },
              {
                icon: 'addLink',
                label: 'Generate a new link',
                key: 'generate_link',
                onClick: () =>
                  dispatch(
                    setModal({
                      showSharedLinkModal: false,
                      showSharedLinkGenerateModal: true,
                      isNew: true,
                      linkId,
                    })
                  ),
              },
              {
                icon: 'delete',
                label: 'Delete link',
                key: 'delete_link',
                onClick: () =>
                  dispatch(
                    setModal({
                      showSharedLinkModal: false,
                      showSharedLinkDeleteModal: true,
                      isNew: true,
                      linkId,
                    })
                  ),
              },
            ]}
          />
        </Box>
      </CopyButtonWrapper>
    </Wrapper>
  );
}

export default ShareInput;
