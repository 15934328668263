export interface UserInvitation {
  userId: any;
  disabled: boolean;
  invitationStatus: AccumulatorType;
  setInvitationStatus: React.Dispatch<React.SetStateAction<AccumulatorType>>;
}


export enum UserInvitationStatus {
  Invite = "INVITE",
  Inviting = "INVITING",
  Sent = "SENT"
}

export type AccumulatorType = {
  [key: string]: string;
};
