import styled from 'styled-components';

import { boxStyle, IBoxProps } from '../Box/Box';

const List = styled.ul<IBoxProps>`
  ${boxStyle}
  display: grid;
  width: 100%;
  gap: 24px;
  grid-template-columns: repeat(auto-fill, minmax(256px, 0.6fr));
  justify-content: center;
  overflow-y: auto;
`;

export default List;
