import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

import filterReducer from './filter/filterSlice';
import modalReducer from './modal/modalSlice';
import referralRewardReducer from './referralReward/referralrewardSlice';
import viewerReducer from './viewer/viewerSlice';

export const store = configureStore({
  reducer: {
    viewer: viewerReducer,
    modal: modalReducer,
    filter: filterReducer,
    referralReward: referralRewardReducer,
  },
  devTools: process.env.REACT_APP_ENV !== 'production',
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
