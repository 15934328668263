import React, { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';

import Box from 'components/atoms/Box/Box';
import Button from 'components/atoms/Button/Button';
import Flex from 'components/atoms/Flex/Flex';
import FlexList from 'components/atoms/FlexList/FlexList';
import H from 'components/atoms/H/H';
import Text from 'components/atoms/Text/Text';

import Header from 'containers/Layout/components/Header/Header';
import TabContent from 'containers/Settings/TabContent/TabContent';
import ViewSearchAssets from 'containers/ViewSearchAssets/ViewSearchAssets';
import {
  Roles,
  useGetAssetsQuery,
  useProjectsListQuery,
} from 'graph/generated.graphql';
import {
  reset,
  selectIsSearchActive,
  selectSearchFilterState,
  selectSearchOrderFilterState,
  setSelectedProjectOption,
} from 'redux/filter/filterSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectViewer } from 'redux/viewer/viewerSlice';
import { device } from 'utils/styles/device';

export enum SettingTab {
  MyProfile = 'myProfile',
  Password = 'password',
  TeamManagement = 'teamManagement',
}
const TabHeadingText: Record<SettingTab, { title: string; text: string }> = {
  myProfile: {
    title: 'My profile',
    text: 'Update your profile photo and details here.',
  },
  password: {
    title: 'Password',
    text: 'Please enter your current password to change your password.',
  },
  teamManagement: {
    title: 'Team management',
    text: 'Manage your team members and their account permissions here.',
  },
};

const MainStyled = styled.main`
  min-height: calc(100vh - 138px);
`;
const SettingTitleWrapper = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey200};
`;
const TabWrapper = styled(Flex)`
  @media ${device.laptop} {
    flex-direction: column;
  }
  flex-direction: row;
`;
const TabButton = styled(Button)<{ active: boolean }>`
  @media ${device.laptop} {
    margin-right: 12px;
    justify-content: center;
  }
  justify-content: left;
  color: ${({ theme }) => theme.colors.grey500};
  &:hover:enabled {
    color: ${({ theme }) => theme.colors.grey700};
    background-color: ${({ theme }) => theme.colors.grey50};
  }
  ${({ active, theme }) =>
    active
      ? `color: ${theme.colors.grey700};
    background-color: ${theme.colors.grey50};`
      : ''};
`;

const FlexListStyled = styled(FlexList)`
  @media ${device.laptop} {
    flex-direction: row;
    flex-basis: 100%;
    width: 100%;
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey200};
  }
  width: 280px;
`;
const FullWidthListItem = styled.li`
  width: 100%;
`;
function SettingPage() {
  const theme = useTheme();
  const user = useAppSelector(selectViewer);
  const initialTab =
    user?.roles === Roles.VidicoAdmin
      ? SettingTab.Password
      : SettingTab.MyProfile;

  const [activeTab, setActiveTab] = useState<SettingTab>(initialTab);
  const isSearchActive = useAppSelector(selectIsSearchActive);
  const searchOrderFilterState = useAppSelector(selectSearchOrderFilterState);
  const searchFilterState = useAppSelector(selectSearchFilterState);
  const dispatch = useAppDispatch();
  const companyIdToSearch =
    user?.roles !== Roles.VidicoAdmin ? user?.companyId : undefined;
  const { data: searchData, loading: isSearchDataLoading } = useGetAssetsQuery({
    variables: {
      take: 100,
      where: {
        ...searchFilterState,
        companyId: { eq: companyIdToSearch },
      },
      order: searchOrderFilterState,
    },
    skip: isSearchActive ? !searchFilterState || !searchOrderFilterState : true,
    fetchPolicy: 'network-only',
  });

  const { data: projectsListData, loading: isProjectListLoading } =
    useProjectsListQuery({
      variables: {
        take: 100,
        companyId: companyIdToSearch,
        where: {
          dealeted: { eq: false },
          companyId: { eq: companyIdToSearch },
        },
      },
    });
  const isLoading = isSearchDataLoading && isProjectListLoading;
  const projects = projectsListData?.projectsList?.items || [];
  const assets = searchData?.assetsList?.items || [];

  useEffect(() => {
    dispatch(setSelectedProjectOption([]));
    if (!isSearchActive) {
      dispatch(reset());
    }
  }, [dispatch, isSearchActive]);

  return (
    <>
      <Header />
      <MainStyled>
        {isSearchActive ? (
          <ViewSearchAssets
            assets={assets}
            projects={projects}
            isLoading={isLoading}
          />
        ) : (
          <>
            <SettingTitleWrapper mt={6} mx={8}>
              <Box mb={1}>
                <H sm semibold>
                  {TabHeadingText[activeTab].title}
                </H>
              </Box>
              <Box mb={5}>
                <Text md regular color={theme.colors.grey500}>
                  {TabHeadingText[activeTab].text}
                </Text>
              </Box>
            </SettingTitleWrapper>
            <TabWrapper mx={8}>
              <FlexListStyled py={8} flexDirection="column" gap={4}>
                {user?.roles !== Roles.VidicoAdmin && (
                  <FullWidthListItem>
                    <TabButton
                      w={154}
                      medium
                      sm
                      active={activeTab === SettingTab.MyProfile}
                      onClick={() => setActiveTab(SettingTab.MyProfile)}
                      noStyle
                      px={3}
                      py={2}
                    >
                      {TabHeadingText[SettingTab.MyProfile].title}
                    </TabButton>
                  </FullWidthListItem>
                )}
                <FullWidthListItem>
                  <TabButton
                    w={154}
                    medium
                    sm
                    active={activeTab === SettingTab.Password}
                    onClick={() => setActiveTab(SettingTab.Password)}
                    noStyle
                    px={3}
                    py={2}
                  >
                    {TabHeadingText[SettingTab.Password].title}
                  </TabButton>
                </FullWidthListItem>
                {user?.roles !== Roles.VidicoAdmin && (
                  <FullWidthListItem>
                    <TabButton
                      w={154}
                      medium
                      sm
                      active={activeTab === SettingTab.TeamManagement}
                      onClick={() => setActiveTab(SettingTab.TeamManagement)}
                      noStyle
                      px={3}
                      py={2}
                    >
                      {TabHeadingText[SettingTab.TeamManagement].title}
                    </TabButton>
                  </FullWidthListItem>
                )}
              </FlexListStyled>
              <Flex fullWidth p={8}>
                <TabContent type={activeTab} />
              </Flex>
            </TabWrapper>
          </>
        )}
      </MainStyled>
    </>
  );
}

export default SettingPage;
