import React from 'react';
import styled, { useTheme } from 'styled-components';

import Box from 'components/atoms/Box/Box';
import Flex from 'components/atoms/Flex/Flex';
import H from 'components/atoms/H/H';
import Icon from 'components/atoms/Icon/Icon';
import Logo from 'components/atoms/Logo/Logo';
import Text from 'components/atoms/Text/Text';

const IconWrapper = styled(Flex)`
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.primary100};
  border: 10px solid ${({ theme }) => theme.colors.primary50};
  box-sizing: content-box;
`;

function NotFoundShared() {
  const theme = useTheme();
  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      fullWidth
      pt={20}
    >
      <Box mb={19}>
        <Logo />
      </Box>
      <IconWrapper
        w={46}
        h={46}
        mb={8}
        justifyContent="center"
        alignItems="center"
      >
        <Icon color={theme.colors.primary600} size={28} name="alertCircle" />
      </IconWrapper>
      <Box mb={3}>
        <H sm bold>
          Page not found
        </H>
      </Box>
      <Text md regular color={theme.colors.grey500}>
        Sorry, the page you are looking for doesn&rsquo;t exist.
      </Text>
      <Text md regular color={theme.colors.grey500}>
        Contact
        <a href="mailto:hello@vidico.com">
          <Text as="span" bold regular color={theme.colors.grey500}>
            {' '}
            hello@vidico.com{' '}
          </Text>
        </a>
        for support.
      </Text>
    </Flex>
  );
}

export default NotFoundShared;
