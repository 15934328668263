import React, { useMemo, useState } from 'react';
import { components, OnChangeValue, OptionProps } from 'react-select';
import styled, { useTheme } from 'styled-components';

import Avatar from 'components/atoms/Avatar/Avatar';
import Box from 'components/atoms/Box/Box';
import Flex from 'components/atoms/Flex/Flex';
import FormCheck from 'components/atoms/FormCheck/FormCheck';
import Icon from 'components/atoms/Icon/Icon';
import { IOption, OptionWrapper, Select } from 'components/atoms/Select/Select';
import Text from 'components/atoms/Text/Text';

import {
  AssetSortInput,
  FileType,
  Roles,
  SortEnumType,
} from 'graph/generated.graphql';
import {
  selectSearchFilterState,
  selectSelectedProjectOption,
  setSearchFilterState,
  setSearchOrderFilterState,
} from 'redux/filter/filterSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectViewer } from 'redux/viewer/viewerSlice';
import { device } from 'utils/styles/device';
import { transformFileTypeToFileTypeName } from 'utils/transformers/transformLocalizationToLocalizationName';

import { ISearchFilterProps } from './SearchFilter.iterface';

const SearchFilterWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.grey50};
  @media ${device.tablet} {
    flex-direction: column;
  }
`;

const BoxStyled = styled(Box)`
  cursor: pointer;
`;

const SelectFilter = styled(Select)`
  .react-select {
    &__control {
      min-width: 200px;
    }
    &__option {
      font-size: 14px;
      padding: 8px 12px;
    }
    &__multi-value {
      &__label {
        padding: 0;
        padding-left: 8px;
      }
    }
    &__value-container {
      padding-top: 2px;
      padding-bottom: 2px;
    }
  }
`;
function Option(props: OptionProps<IOption>) {
  const { data, isSelected, isFocused, isDisabled, isMulti, children } = props;
  const theme = useTheme();
  return (
    <OptionWrapper
      onClick={data.onClick}
      isFocused={isFocused}
      isSelected={isSelected}
    >
      {data.icon && (
        <Box>
          <Flex
            h={24}
            w={24}
            my={2}
            ml={3}
            alignItems="center"
            justifyContent="center"
            flex="auto"
          >
            <Avatar
              indicator={data.indicator}
              xs
              src={data.icon}
              text={data.label}
            />
          </Flex>
        </Box>
      )}
      <Box fullWidth>
        <Text
          as="span"
          md
          regular
          color={isDisabled ? theme.colors.grey400 : theme.colors.grey900}
        >
          {data.onClick ? (
            <BoxStyled px={3} py={2}>
              {data.label}
            </BoxStyled>
          ) : (
            <components.Option {...props}>
              <Flex gap={4} mr={4} alignItems="center">
                {isMulti && (
                  <FormCheck
                    readOnly
                    id={data.value}
                    name={data.label}
                    type="checkbox"
                    checked={isSelected}
                  />
                )}
                <Text ellipsis>{children}</Text>
              </Flex>
            </components.Option>
          )}
        </Text>
        {data.subText && (
          <Box px={3} pt={1} pb={3}>
            <Text
              sm
              regular
              color={isDisabled ? theme.colors.grey400 : theme.colors.grey600}
            >
              {data.subText}
            </Text>
          </Box>
        )}
      </Box>
      {!isMulti && isSelected && (
        <Box mr={4} py={2}>
          <Icon
            name="check"
            style={{ alignSelf: 'center' }}
            color={theme.colors.primary600}
          />
        </Box>
      )}
    </OptionWrapper>
  );
}
function SearchFilter({ projects }: ISearchFilterProps) {
  const dispatch = useAppDispatch();
  const selectedProjectOption = useAppSelector(selectSelectedProjectOption);
  const viewer = useAppSelector(selectViewer);
  const [isFavorite, setIsFavorite] = useState(false);
  const projectOptions = useMemo(
    () =>
      projects.map((p) => ({
        value: p?.id || '',
        label: p?.name || '',
      })),
    [projects]
  );
  const searchFilterState = useAppSelector(selectSearchFilterState);
  const fileTypeOptions = useMemo(
    () =>
      Object.values(FileType).map((i) => ({
        value: i,
        label: transformFileTypeToFileTypeName(i),
      })),
    []
  );
  const userFavoriteAssetIds = viewer?.favorites
    ?.filter((f) => !f.asset?.dealeted)
    .map((f) => f.assetId);
  const sortOptions = useMemo(
    () => [
      {
        value: `{"createdAt": "${SortEnumType.Asc}"}`,
        label: 'Date added - new to old',
        hideIcon: true,
      },
      {
        value: `{"createdAt": "${SortEnumType.Desc}"}`,
        label: 'Date added - old to new',
        hideIcon: true,
      },
      {
        value: `{"name": "${SortEnumType.Asc}"}`,
        label: 'Title - A to Z',
        hideIcon: true,
      },
      {
        value: `{"name": "${SortEnumType.Desc}"}`,
        label: 'Title - Z to A',
        hideIcon: true,
      },
    ],
    []
  );
  const onFavoriteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsFavorite(e.target.checked);
    dispatch(
      setSearchFilterState({
        ...searchFilterState,
        id: isFavorite ? undefined : { in: userFavoriteAssetIds },
      })
    );
  };
  const onProjectChange = (values: OnChangeValue<IOption, boolean>) => {
    const selected = (values as IOption[]).map((v) => v.value);
    dispatch(
      setSearchFilterState({
        ...searchFilterState,
        projectId: selected.length === 0 ? undefined : { in: selected },
      })
    );
  };
  const onFileTypeChange = (values: OnChangeValue<IOption, boolean>) => {
    const selected = (values as IOption[]).map((v) => v.value as FileType);
    dispatch(
      setSearchFilterState({
        ...searchFilterState,
        type: selected.length === 0 ? undefined : { in: selected },
      })
    );
  };
  const onOrderChange = (values: any) => {
    dispatch(
      setSearchOrderFilterState(JSON.parse(values?.value) as AssetSortInput)
    );
  };
  const isVidicoAdmin = viewer?.roles === Roles.VidicoAdmin;

  return (
    <SearchFilterWrapper
      justifyContent="center"
      alignItems="center"
      gap={22}
      px={8}
      py={3}
    >
      {!isVidicoAdmin && (
        <FormCheck
          id="favorite"
          name="favorite"
          onChange={onFavoriteChange}
          type="checkbox"
          label="Favourite"
        />
      )}
      {!selectedProjectOption?.length && (
        <SelectFilter
          isClearable={false}
          isMulti
          placeholder="Projects"
          hideSelectedOptions={false}
          options={projectOptions}
          components={{
            DropdownIndicator: null,
            ClearIndicator: undefined,
            Option,
          }}
          closeMenuOnSelect={false}
          onChange={onProjectChange}
        />
      )}
      <SelectFilter
        isClearable={false}
        isMulti
        hideSelectedOptions={false}
        placeholder="File type"
        options={fileTypeOptions}
        components={{
          DropdownIndicator: null,
          ClearIndicator: undefined,
          Option,
        }}
        closeMenuOnSelect={false}
        onChange={onFileTypeChange}
      />
      <SelectFilter
        defaultValue={sortOptions[0]}
        options={sortOptions}
        onChange={onOrderChange}
      />
    </SearchFilterWrapper>
  );
}

export default SearchFilter;
