import type * as CSS from 'csstype';
import React, { ReactNode } from 'react';
import { Form } from 'react-bootstrap';
import styled from 'styled-components';

import { FormErrorTypes } from '../../../utils/constants/forms';

const FormFeedbackStyle = styled(Form.Control.Feedback)<{
  wordBreak?: CSS.Property.WordBreak;
}>`
  ${({ wordBreak }) => (wordBreak ? `word-break: ${wordBreak};` : '')};
  font-size: 14px;
  margin-top: ${({ theme }) => theme.size(1)};
  min-height: ${({ theme }) => theme.size(6)};
  &.blank-feedback {
    color: ${({ theme }) => theme.colors.grey400};
  }
  &.warning-feedback {
    color: ${({ theme }) => theme.colors.warning500};
  }
  &.invalid-feedback {
    display: inherit;
    color: ${({ theme }) => theme.colors.error500};
  }
  &.valid-feedback {
    display: inherit;
    color: ${({ theme }) => theme.colors.success500};
  }
`;
function FormFeedback({
  children,
  ...args
}: {
  children: ReactNode;
  wordBreak?: CSS.Property.WordBreak;
  type?: FormErrorTypes;
}) {
  return <FormFeedbackStyle {...args}>{children}</FormFeedbackStyle>;
}
export default FormFeedback;
