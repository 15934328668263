import React, { useMemo } from 'react';
import styled, { useTheme } from 'styled-components';

import Box from 'components/atoms/Box/Box';
import Flex from 'components/atoms/Flex/Flex';
import Spinner from 'components/atoms/Spinner/Spinner';

import { useGetCompanyByIdBaseInfoQuery } from 'graph/generated.graphql';
import { useAppSelector } from 'redux/hooks';
import { selectViewer } from 'redux/viewer/viewerSlice';

import TeamManagementForm from './components/TeamManagementForm';
import TeamManagementList from './components/TeamManagementList';

const StyledFlex = styled(Flex)`
  min-height: 100%;
`;
function TeamManagement() {
  const theme = useTheme();
  const user = useAppSelector(selectViewer);
  const {
    data: companyData,
    loading: isCompanyLoading,
    refetch: refetchCompany,
  } = useGetCompanyByIdBaseInfoQuery({
    variables: {
      companyId: user?.companyId,
    },
    skip: !user?.companyId,
  });
  const company = useMemo(
    () => companyData?.viewCompany.company || {},
    [companyData]
  );
  return isCompanyLoading ? (
    <StyledFlex w={640} alignItems="center" justifyContent="center">
      <Spinner size={50} color={theme.colors.primary600} />
    </StyledFlex>
  ) : (
    <Box fullWidth>
      <TeamManagementForm refetch={refetchCompany} company={company} />
      <TeamManagementList />
    </Box>
  );
}

export default TeamManagement;
