import { CompanyFragment } from 'graph/generated.graphql';

import {
  DOMAIN_EXP,
  EMAIL_EXP,
  HAS_LOWERCASE,
  HAS_NUMBER_OR_SYMBOL,
  HAS_UPPERCASE,
  IS_GUID_EXP,
  WHITE_SPACES_EXP,
} from './regulars';

import { FormErrorTypes } from '../constants/forms';

export type PasswordStrength = {
  hasLength: boolean;
  hasNumberOrSymbol: boolean;
  hasLowercase: boolean;
  hasUppercase: boolean;
};
export const isEmail = (value: string): boolean =>
  value ? EMAIL_EXP.test(value) : false;

export const isDomainInList = (
  email: string,
  domainList: CompanyFragment['domainNames'] | undefined
): boolean => {
  const domain = email.split('@')[1];
  return domainList ? domainList.includes(domain) : false;
};

export const isDomainName = (value: string): boolean =>
  value ? DOMAIN_EXP.test(value) : false;

export const checkPasswordStrongness = (value: string): FormErrorTypes => {
  if (!value) {
    return FormErrorTypes.Blank;
  }

  if (
    value.length >= 8 &&
    HAS_UPPERCASE.test(value) &&
    HAS_LOWERCASE.test(value) &&
    HAS_NUMBER_OR_SYMBOL.test(value)
  ) {
    return FormErrorTypes.Valid;
  }
  if (value.length >= 8) {
    return FormErrorTypes.Warning;
  }

  return FormErrorTypes.Invalid;
};

export const checkPasswordToWhiteSpace = (value: string): boolean =>
  WHITE_SPACES_EXP.test(value);

export const checkPasswordStrength = (password: string): PasswordStrength => ({
  hasLength: password ? password.length >= 8 : false,
  hasNumberOrSymbol: password ? HAS_NUMBER_OR_SYMBOL.test(password) : false,
  hasLowercase: password ? HAS_LOWERCASE.test(password) : false,
  hasUppercase: password ? HAS_UPPERCASE.test(password) : false,
});
export const isValidGUID = (guid: string) => IS_GUID_EXP.test(guid);
