import React, { useState } from 'react';
import { Image, OverlayTrigger, Tooltip } from 'react-bootstrap';
import styled, { useTheme } from 'styled-components';

import { DotsDropDownMenu } from 'components/molecules/DotsDropDownMenu/DotsDropDownMenu';

import { IFolderButtonProps } from './FolderButton.interface';

import {
  useGetCompanyByIdBaseInfoQuery,
  useUserByIdQuery,
} from '../../../graph/generated.graphql';
import Box from '../Box/Box';
import Flex from '../Flex/Flex';
import Icon from '../Icon/Icon';
import Text, { ellipsisStyle, textStyle } from '../Text/Text';

const ButtonStyled = styled(Box)<{ isSelected: boolean | undefined }>`
  ${textStyle}
  cursor: pointer;
  background-color: transparent;
  padding: 0;
  &:focus {
    box-shadow: unset;
  }
  &:hover:enabled {
    background-color: transparent;
    box-shadow: unset;
    & .content {
      background-color: ${({ theme }) => theme.colors.base};
      border: 1px solid ${({ theme }) => theme.colors.primary300};
      border-top-left-radius: 0px;
    }
    & .mask {
      border: 1px solid ${({ theme }) => theme.colors.primary300};

      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      border-bottom-color: ${({ theme }) => theme.colors.base};
    }
  }
  & .mask {
    background-color: ${({ theme }) => theme.colors.base};
    border-bottom: 0px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    z-index: 2;
    ${({ theme, isSelected }) =>
      isSelected
        ? `
      border: 1px solid ${theme.colors.primary600};
      border-bottom-color: ${theme.colors.base};`
        : ''};
  }
  & .content {
    margin-top: -1px;
    color: ${({ theme }) => theme.colors.grey700};
    border: 1px solid ${({ theme }) => theme.colors.base};
    background-color: ${({ theme }) => theme.colors.base};
    border-radius: 6px;
    border-top-left-radius: 0;
    ${({ theme, isSelected }) =>
      isSelected ? `border: 1px solid ${theme.colors.primary600}` : ''};
  }
`;
const StyledImage = styled(Image)`
  border-radius: 6px;
  object-fit: cover;
`;
const NoImageBoxWrapper = styled(Flex)`
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.grey100};
`;
const TitleWrapper = styled(Text)`
  text-align: left;
  ${ellipsisStyle}
`;
const DotMenuWrapper = styled(Flex)`
  align-self: center;
`;

const ProjectImageWrapper = styled(Box)`
  position: relative;
`;

const LockedProject = styled(Icon)`
  position: relative;
  background-color: white;
  border-radius: 50%;
`;

const AbsoluteBox = styled(Box)`
  position: absolute;
  right: 15px;
  top: 15px;
`;

const RelativeBox = styled(Box)`
  position: relative;
`;

function FolderButton({
  imageSrc,
  title,
  leftText,
  rightText,
  isSelected,
  titleColor: propTitleColor,
  textColor: propTextColor,
  isLocked,
  dropDownOption,
  companyId,
  ...args
}: IFolderButtonProps) {
  const theme = useTheme();
  const titleColor = isLocked
    ? theme.colors.grey400
    : propTitleColor || theme.colors.grey700;
  const textColor = isLocked
    ? theme.colors.grey400
    : propTextColor || theme.colors.grey600;
  const [isImageError, setIsImageError] = useState(false);

  const { data } = useGetCompanyByIdBaseInfoQuery({
    variables: { companyId },
  });
  const user = useUserByIdQuery({
    variables: { id: data?.viewCompany?.company?.managerId },
  });

  return (
    <ButtonStyled isSelected={isSelected} {...args}>
      <Flex flexDirection="column" w={364}>
        <Flex w={68} h={8} className="mask" />
        <Flex fullWidth p={1} className="content">
          <ProjectImageWrapper mr={4}>
            {!imageSrc || isImageError ? (
              <NoImageBoxWrapper
                w={60}
                h={60}
                justifyContent="center"
                alignItems="center"
              >
                <Icon size={32} name="folder" color={theme.colors.grey400} />
              </NoImageBoxWrapper>
            ) : (
              <StyledImage
                width={60}
                height={60}
                src={imageSrc}
                onError={() => setIsImageError(true)}
              />
            )}

            {isLocked && (
              <AbsoluteBox>
                <RelativeBox>
                  <OverlayTrigger
                    key={title}
                    overlay={
                      <Tooltip id={title} show placement="top-start">
                        <Text xs semibold>
                          This project is locked.
                        </Text>
                        <Text xs medium>
                          Contact {user?.data?.userById?.user?.email} to unlock.
                        </Text>
                      </Tooltip>
                    }
                  >
                    <RelativeBox>
                      <LockedProject size={32} name="lock" />
                    </RelativeBox>
                  </OverlayTrigger>
                </RelativeBox>
              </AbsoluteBox>
            )}
          </ProjectImageWrapper>
          <Flex flexDirection="column">
            <Box w={240} mb={1}>
              <TitleWrapper mb={1} lg semibold color={titleColor}>
                {title}
              </TitleWrapper>
            </Box>
            <Flex>
              <Box mr={8}>
                <Text xs medium color={textColor} opacity={0.7}>
                  {leftText}
                </Text>
              </Box>
              <Text xs medium color={textColor} opacity={0.7}>
                {rightText}
              </Text>
            </Flex>
          </Flex>
          {dropDownOption && (
            <DotMenuWrapper alignItems="center" justifyContent="center">
              <DotsDropDownMenu menuItems={dropDownOption} />
            </DotMenuWrapper>
          )}
        </Flex>
      </Flex>
    </ButtonStyled>
  );
}

export default FolderButton;
