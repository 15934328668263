import React from 'react';
import styled, { useTheme } from 'styled-components';

import Box from 'components/atoms/Box/Box';
import Flex from 'components/atoms/Flex/Flex';
import { Select } from 'components/atoms/Select/Select';
import Text from 'components/atoms/Text/Text';

import { LocalisationIcon } from 'utils/constants/forms';
import { transformLocalizationToLocalizationName } from 'utils/transformers/transformLocalizationToLocalizationName';

import { IShareContext } from './ShareContext.interface';

const Divider = styled(Box)`
  border-left: 1px solid ${({ theme }) => theme.colors.grey200};
`;

function ShareContext({ projectName, localization }: IShareContext) {
  const theme = useTheme();
  const value = {
    label: transformLocalizationToLocalizationName(localization),
    value: localization,
    icon: LocalisationIcon[localization],
  };
  return (
    <Flex px={8} py={3} alignItems="center" gap={16}>
      <Text lg semibold color={theme.colors.grey700}>
        {projectName}
      </Text>
      <Divider w={1} h={36} />
      <Text md semibold color={theme.colors.grey600}>
        Localisation
      </Text>
      <Select
        isSearchable={false}
        value={value}
        placeholder="Select localisation"
        backgroundColor={theme.colors.grey100}
        noBorder
        components={{
          DropdownIndicator: null,
        }}
        round
        isDisabled
        minMenuWidth={230}
      />
    </Flex>
  );
}

export default ShareContext;
