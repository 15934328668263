import React from 'react';

import NotFound from 'components/molecules/NotFound/NotFound';

import Header from 'containers/Layout/components/Header/Header';

function NotFountPage() {
  return (
    <>
      <Header />
      <NotFound />;
    </>
  );
}

export default NotFountPage;
