import React from 'react';
import styled from 'styled-components';

import { IFormControlProps } from './FormControl.interface';

import { FormErrorTypes } from '../../../utils/constants/forms';
import { getFont } from '../../../utils/theme/typography';
import Box from '../Box/Box';
import Icon from '../Icon/Icon';

type ThemeType = { colors: Record<string, string> };

const getErrorTypeColor = (
  theme: ThemeType,
  errorType?: FormErrorTypes
): string => {
  switch (errorType) {
    case FormErrorTypes.Blank:
      return theme.colors.grey400;
    case FormErrorTypes.Invalid:
      return theme.colors.error500;
    case FormErrorTypes.Warning:
      return theme.colors.warning500;
    case FormErrorTypes.Valid:
      return theme.colors.success500;
    default:
      return theme.colors.grey900;
  }
};

export const Control = styled.input<IFormControlProps>`
  display: block;
  padding: 10px 14px;
  ${({ preIcon }) => (preIcon ? 'padding-left: 42px;' : '')};
  gap: 10px;
  border-radius: 8px;
  outline: none;
  width: 100%;
  border: 1px solid
    ${({ theme, $errorType }) => getErrorTypeColor(theme, $errorType)};
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  ::placeholder {
    ${({ theme }) => getFont({ theme, weight: { regular: true } })}
    color: ${({ theme }) => theme.colors.grey900};
  }
  &:focus {
    margin: -1px;
    border: 2px solid ${({ theme }) => theme.colors.primary600};
  }
  &:focus-visible {
    border: ${({ theme, $errorType }) =>
      `2px solid ${
        getErrorTypeColor(theme, $errorType) === theme.colors.grey900
          ? theme.colors.primary600
          : getErrorTypeColor(theme, $errorType)
      }; margin: -1px;`};
  }
  &:disabled {
    color: ${({ theme }) => theme.colors.grey400};
    background-color: ${({ theme }) => theme.colors.grey50};
    border-color: ${({ theme }) => theme.colors.grey300};
  }
`;
const IconStyled = styled(Icon)`
  position: absolute;
  top: 13px;
  left: 14px;
`;
const FormControlWrapper = styled(Box)`
  position: relative;
`;
function FormControl({ preIcon, ...args }: IFormControlProps) {
  return (
    <FormControlWrapper>
      <Control preIcon={preIcon} {...args} />
      {preIcon && <IconStyled name={preIcon} size={20} />}
    </FormControlWrapper>
  );
}

export default FormControl;
export { getErrorTypeColor };
