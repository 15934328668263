import { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import {
  Column,
  SortingRule,
  TableState,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import styled, { useTheme } from 'styled-components';

import Box from 'components/atoms/Box/Box';
import Flex from 'components/atoms/Flex/Flex';
import FormSelect from 'components/atoms/FormSelect/FormSelect';
import Icon from 'components/atoms/Icon/Icon';
import Text from 'components/atoms/Text/Text';

type OnTableStateChangedType = (state: {
  pageIndex: number;
  pageSize: number;
  sortBy: SortingRule<object>[];
}) => void;

interface Props {
  columns: Array<Column<object>>;
  px?: number;
  py?: number;
  data: Array<object>;
  noBorders?: boolean | undefined;
  noBackgrounds?: boolean | undefined;
  initialState?: Partial<TableState>;
  pagination?: {
    total: number;
    pageSize?: number;
    pageSizeOptions?: Array<string>;
  };
  onTableStateChanged?: OnTableStateChangedType;
}
const FlexStyled = styled(Flex)`
  &:hover svg {
    transition: all ease 200ms;
    opacity: 1 !important;
  }
`;
const Paginator = styled(Flex)`
  border-top: 1px solid ${({ theme }) => theme.colors.grey200};
  .paginate {
    display: flex;
    list-style-type: none;
    margin-bottom: 0;
    padding: 0;
    & .previous,
    .next {
      display: none;
    }
  }
  .page-li {
    border-radius: 50%;
    &:hover,
    :focus {
      background-color: ${({ theme }) => theme.colors.grey50};
    }
  }
  .page-active {
    background-color: ${({ theme }) => theme.colors.grey50};
  }
  .page-link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
  }
`;
const Select = styled(FormSelect)`
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.grey300};
  box-shadow: ${({ theme }) => theme.shadow.xsShadow};
`;
const TableStyled = styled.table`
  width: 100%;
`;
const THeadStyled = styled.thead<{
  noBorders: boolean | undefined;
}>`
  ${({ theme, noBorders }) =>
    noBorders ? '' : `border-top: 1px solid ${theme.colors.grey200};`}

  border-bottom: 1px solid ${({ theme }) => theme.colors.grey200};
  height: ${({ theme }) => theme.size(11)};
`;
const TBodyStyled = styled.tbody<{
  noBorders: boolean | undefined;
}>`
  & > tr {
    ${({ theme, noBorders }) =>
      noBorders ? '' : `border-bottom: 1px solid  ${theme.colors.grey200};`}
  }
`;
const THStyled = styled.th`
  background-color: ${({ theme }) => theme.colors.grey50};
`;

const TRStyled = styled.tr<{ noBackgrounds: boolean | undefined }>`
  &:nth-child(even) {
    background-color: ${({ theme, noBackgrounds }) =>
      noBackgrounds ? 'none' : theme.colors.grey50};
  }
`;
const TableWrapper = styled(Flex)`
  min-height: inherit;
`;
function Table({
  columns,
  data,
  pagination,
  noBorders,
  noBackgrounds,
  initialState,
  px = 6,
  py = 4,
  onTableStateChanged,
}: Props) {
  const theme = useTheme();
  const totalCount = pagination ? pagination.total : 0;
  const { pageSize: initialPageSize = 50 } = pagination || {};
  const totalPerPageSize = Math.ceil(totalCount / initialPageSize);
  const [pageCountState, setPageCount] = useState(totalPerPageSize);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    pageCount,
    gotoPage,
    setPageSize,
    state,
  } = useTable(
    {
      columns,
      data,
      manualSortBy: true,
      initialState: {
        pageIndex: 0,
        pageSize: initialPageSize,
        ...initialState,
      },
      manualPagination: true,
      pageCount: pageCountState,
    },
    useSortBy,
    usePagination
  );

  const { pageIndex, pageSize, sortBy } = state;

  useEffect(() => {
    setPageCount(Math.ceil(totalCount / pageSize));
  }, [pageSize, setPageCount, totalCount]);

  useEffect(() => {
    if (onTableStateChanged) {
      onTableStateChanged({ pageIndex, pageSize, sortBy });
    }
  }, [onTableStateChanged, pageIndex, pageSize, sortBy]);

  return (
    <TableWrapper flexDirection="column" justifyContent="space-between">
      <TableStyled {...getTableProps()}>
        <THeadStyled noBorders>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <THStyled
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  title=""
                >
                  <FlexStyled px={px} py={py} alignItems="center">
                    <Box mr={1}>
                      {column.isSorted ? (
                        <Text as="span" xs bold color={theme.colors.grey500}>
                          {column.render('Header')}
                        </Text>
                      ) : (
                        <Text as="span" xs medium color={theme.colors.grey500}>
                          {column.render('Header')}
                        </Text>
                      )}
                    </Box>
                    <Flex>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <Icon
                            name="arrowNarrowUpBold"
                            color={theme.colors.grey500}
                          />
                        ) : (
                          <Icon
                            name="arrowNarrowDownBold"
                            color={theme.colors.grey500}
                          />
                        )
                      ) : (
                        <Icon style={{ opacity: 0 }} name="arrowNarrowDown" />
                      )}
                    </Flex>
                  </FlexStyled>
                </THStyled>
              ))}
            </tr>
          ))}
        </THeadStyled>
        <TBodyStyled {...getTableBodyProps()} noBorders={noBorders}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <TRStyled noBackgrounds={noBackgrounds} {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>
                    <Box py={py} px={px}>
                      <Text as="span" sm regular color={theme.colors.grey500}>
                        {cell.render('Cell')}
                      </Text>
                    </Box>
                  </td>
                ))}
              </TRStyled>
            );
          })}
        </TBodyStyled>
      </TableStyled>

      {pagination && (
        <Paginator
          fullWidth
          py={3}
          px={6}
          alignItems="center"
          justifyContent="space-between"
        >
          <ReactPaginate
            onPageChange={({ selected }) => gotoPage(selected)}
            forcePage={pageIndex}
            pageRangeDisplayed={10}
            pageCount={pageCount}
            marginPagesDisplayed={0}
            breakClassName="d-none"
            className="paginate"
            pageLinkClassName="page-link"
            activeClassName="page-active"
            pageClassName="page-li"
          />
          <Flex alignItems="center">
            <Select
              mr={3}
              w={70}
              px={3}
              py={2}
              defaultValue={String(initialPageSize)}
              onChange={(e: any) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {pagination.pageSizeOptions &&
                pagination?.pageSizeOptions.map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
            </Select>

            <Box>
              <Text sm medium color={theme.colors.grey500}>{`Results ${
                1 + pageIndex * pageSize
              } - ${
                totalCount > (pageIndex + 1) * pageSize
                  ? (pageIndex + 1) * pageSize
                  : totalCount
              } of ${totalCount}`}</Text>
            </Box>
          </Flex>
        </Paginator>
      )}
    </TableWrapper>
  );
}
export default Table;
export type { OnTableStateChangedType };
