const size = {
  mobileS: '319px',
  mobileM: '374px',
  mobileL: '424px',
  tablet: '767px',
  laptop: '1023px',
  laptopL: '1439px',
  desktop: '2559px',
};
const sizeMin = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
};
const device = {
  mobileS: `(max-width: ${size.mobileS})`,
  mobileM: `(max-width: ${size.mobileM})`,
  mobileL: `(max-width: ${size.mobileL})`,
  tablet: `(max-width: ${size.tablet})`,
  laptop: `(max-width: ${size.laptop})`,
  laptopL: `(max-width: ${size.laptopL})`,
  desktop: `(max-width: ${size.desktop})`,
  desktopL: `(max-width: ${size.desktop})`,
};
const deviceMin = {
  mobileS: `(min-width: ${sizeMin.mobileS})`,
  mobileM: `(min-width: ${sizeMin.mobileM})`,
  mobileL: `(min-width: ${sizeMin.mobileL})`,
  tablet: `(min-width: ${sizeMin.tablet})`,
  laptop: `(min-width: ${sizeMin.laptop})`,
  laptopL: `(min-width: ${sizeMin.laptopL})`,
  desktop: `(min-width: ${sizeMin.desktop})`,
  desktopL: `(min-width: ${sizeMin.desktop})`,
};
export { device, deviceMin };
