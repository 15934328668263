import { Image } from 'react-bootstrap';
import Select, {
  components,
  OptionProps,
  SingleValueProps,
  MultiValueGenericProps,
} from 'react-select';
import styled, { useTheme } from 'styled-components';

import Box from 'components/atoms/Box/Box';
import Flex from 'components/atoms/Flex/Flex';
import Icon from 'components/atoms/Icon/Icon';
import Text from 'components/atoms/Text/Text';

import { ISelectProps } from './Select.interface';

import Avatar from '../Avatar/Avatar';

export interface IOption {
  value: string;
  label: string;
  icon?: string;
  hideIcon?: boolean;
  indicator?: boolean;
  subText?: string;
  isDisabled?: boolean;
  onClick?: () => void;
}

const SelectWrapper = styled.div<ISelectProps>`
  .react-select {
    &__group {
      padding: 0;
    }
    &__group-heading {
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      color: ${({ theme }) => theme.colors.grey800};
      text-transform: none;
      padding: 8px 24px;
    }
    &-container {
    }
    &__placeholder {
      font-weight: ${({ theme }) => theme.typography.weight.regular};
      color: ${({ theme }) => theme.colors.grey400};
    }
    &__control {
      transition: all 0ms;
      box-shadow: none;
      ${({ backgroundColor }) =>
        backgroundColor ? `background-color: ${backgroundColor};` : ''};
      ${({ theme, noBorder }) =>
        noBorder
          ? 'border: none;'
          : `border: 1px solid ${theme.colors.grey900};`};
      outline: none !important;
      ${({ round }) =>
        round ? 'border-radius: 100px;' : 'border-radius: 8px;'}
      &:hover {
        ${({ theme, noBorder }) =>
          noBorder
            ? 'border: none;'
            : `border: 1px solid ${theme.colors.grey900};`};
      }
      &--is-focused,
      &--is-focused:hover {
        ${({ theme, noBorder }) =>
          noBorder
            ? 'border: none;'
            : `border: 2px solid ${theme.colors.primary600};
            margin: -1px;`};
      }
    }
    &__value-container {
      padding-top: 10px;
      padding-bottom: 10px;
    }
    &__indicators {
    }
    &__indicator-separator {
      display: none;
    }

    &__menu {
      border: 1px solid ${({ theme }) => theme.colors.grey200};
      box-shadow: ${({ theme }) => theme.shadow.smShadow};
      border-radius: 20px;
      min-width: ${({ minMenuWidth }) =>
        minMenuWidth ? `${minMenuWidth}px` : '300px'};
      overflow: hidden;
      z-index: 3;
    }
    &__menu-list {
      padding: 0;
    }
    &__option {
      cursor: pointer;
      &:active {
        background-color: ${({ theme }) => theme.colors.grey50};
      }
      &--is-selected,
      &--is-focused {
        background-color: ${({ theme }) => theme.colors.grey50};
        color: ${({ theme }) => theme.colors.grey900};
      }
      &--is-disabled {
        color: inherit;
      }
    }
    &__multi-value {
      background: linear-gradient(
          0deg,
          rgba(255, 194, 123, 0.24),
          rgba(255, 194, 123, 0.24)
        ),
        #ffffff;
      border-radius: 6px;
      border: 1px solid ${({ theme }) => theme.colors.primary600};
      &:hover {
        background: linear-gradient(
            0deg,
            rgba(255, 194, 123, 0.12),
            rgba(255, 194, 123, 0.12)
          ),
          #ffffff;
        border: 1px solid ${({ theme }) => theme.colors.vidicoAccent};
      }
      &__remove:hover {
        background-color: transparent;
        color: ${({ theme }) => theme.colors.primary600};
      }
      &__label {
        padding: 0;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 16px;
      }
    }
  }
`;
function MultiValueLabel({
  children,

  ...props
}: MultiValueGenericProps<IOption>) {
  const { data } = props;
  return (
    <components.MultiValueLabel {...props}>
      <Flex>
        <Text semibold xs>
          {children}
        </Text>
        {data.icon && (
          <IconWrapper
            h={16}
            w={16}
            ml={2}
            alignItems="center"
            justifyContent="center"
            flex="auto"
          >
            <Image height={16} src={data.icon} />
          </IconWrapper>
        )}
      </Flex>
    </components.MultiValueLabel>
  );
}
function SingleValue({ children, ...props }: SingleValueProps<IOption>) {
  const { data } = props;
  const theme = useTheme();
  return (
    <components.SingleValue {...props}>
      <OptionWrapper>
        {!!data.icon && (
          <Box>
            <Flex
              h={24}
              w={24}
              mr={2}
              alignItems="center"
              justifyContent="center"
              flex="auto"
            >
              <Avatar xs src={data.icon} text={data.label} />
            </Flex>
          </Box>
        )}

        <Box fullWidth>
          <Text as="span" md regular color={theme.colors.grey700}>
            {children}
          </Text>
        </Box>
      </OptionWrapper>
    </components.SingleValue>
  );
}
export const OptionWrapper = styled(Flex)<{
  isFocused?: boolean;
  isSelected?: boolean;
}>``;
const IconWrapper = styled(Flex)`
  overflow: hidden;
  border-radius: 50%;
`;
export function Option(props: OptionProps<IOption>) {
  const { data, isSelected, isFocused, isDisabled, isMulti, children } = props;
  const theme = useTheme();
  return (
    <OptionWrapper
      onClick={data.onClick}
      isFocused={isFocused}
      isSelected={isSelected}
    >
      <Box fullWidth>
        <Text
          as="span"
          md
          regular
          color={
            isDisabled
              ? data.onClick
                ? theme.colors.grey900
                : theme.colors.grey400
              : theme.colors.grey900
          }
        >
          <components.Option {...props}>
            <Flex justifyContent="space-between" alignItems="start">
              <Flex alignItems="center" gap={8}>
                {!data.hideIcon && (
                  <Avatar
                    indicator={data.indicator}
                    xs
                    src={data.icon}
                    text={data.label}
                  />
                )}
                <Box>
                  {children}
                  {data.subText && (
                    <Text
                      sm
                      regular
                      color={
                        isDisabled ? theme.colors.grey400 : theme.colors.grey600
                      }
                    >
                      {data.subText}
                    </Text>
                  )}
                </Box>
              </Flex>
              {!isMulti && isSelected && (
                <Box>
                  <Icon
                    name="check"
                    style={{ alignSelf: 'start' }}
                    color={theme.colors.primary600}
                  />
                </Box>
              )}
            </Flex>
          </components.Option>
        </Text>
      </Box>
    </OptionWrapper>
  );
}

function CustomSelect({
  noBorder,
  round,
  backgroundColor,
  minMenuWidth,
  components: componentsProps,
  ...args
}: ISelectProps) {
  return (
    <SelectWrapper
      noBorder={noBorder}
      round={round}
      backgroundColor={backgroundColor}
      minMenuWidth={minMenuWidth}
    >
      <Select
        {...args}
        classNamePrefix="react-select"
        components={{
          Option,
          SingleValue,
          MultiValueLabel,
          ...componentsProps,
        }}
      />
    </SelectWrapper>
  );
}
export { CustomSelect as Select };
