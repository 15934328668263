import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';

import { IFilterButton } from './FilterButton.interface';

import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import Text from '../Text/Text';

const ButtonButton = styled(Button)<{ isSelected: boolean; badge?: number }>`
  border-radius: 100px;
  flex-shrink: 0;
  background-color: ${({ theme }) => theme.colors.base};
  ${({ isSelected, theme }) =>
    isSelected ? `background-color: ${theme.colors.grey100};` : ''};
  &:hover:enabled {
    background-color: ${({ theme }) => theme.colors.grey100};
  }
  ${({ badge, theme }) =>
    badge
      ? `
    position: relative;

    &:after {
      content: '${String(badge)}';
      position: absolute;
      top: 6px;
      left: 12px;
      border-radius: 50%;
      background-color: ${theme.colors.success500};
      box-sizing: content-box;
      font-weight: 700;
      font-size: 10px;
      line-height: 100%;
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 6px;
    }
  `
      : ''}
`;

function FilterButton({
  iconName,
  hoverIconName,
  children,
  onClick,
  badge,
  focusText,
  isSelected,
}: IFilterButton) {
  const theme = useTheme();
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return (
    <ButtonButton
      badge={badge}
      isSelected={isSelected}
      px={4}
      py={2}
      noStyle
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Icon
        size={24}
        name={isHovered || isSelected ? hoverIconName : iconName}
        color={theme.colors.grey500}
      />
      <Text xs regular color={theme.colors.grey600}>
        {isSelected && focusText ? focusText : children}
      </Text>
    </ButtonButton>
  );
}

export default FilterButton;
