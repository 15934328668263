import { Localizations } from 'graph/generated.graphql';

export const DEFAULT_COMPANY_AVATAR = '/assets/images/company.jpg';
export enum ENVIRONMENT_TYPE {
  Dev = 'development',
  Staging = 'staging',
  Production = 'production',
}
export enum SUBDOMAIN {
  App = 'app',
  Admin = 'admin',
}
export enum HTTP_PREFIX {
  Https = 'https://'
}
export enum SUB_DOMAINS {
  App = 'app',
  Admin = 'admin',
}
export enum QUERY_PARAM {
  Token = 'token',
  FirstName = 'firstName',
  LastName = 'lastName',
  Email = 'email',
  Success = 'success',
  Error = 'error',
  TokenInvalid = 'tokenInvalid',
  TokenExpired = 'tokenExpired',
  SharedKey = 'sharedKey',
}
export const videoType = ['MP4', 'MOV', 'AVI'];
export const imagesType = ['PNG', 'JPEG', 'JPG', 'GIF', 'RAW'];
export const imagesTypeForAvatar = ['PNG', 'JPEG', 'JPG', 'GIF'];
export const popularLocalisations = [
  Localizations.UsEnglish,
  Localizations.UkEnglish,
  Localizations.AuEnglish,
];

export const PENDING_STATUS = 'PENDING'
