import { AssetFragment } from '../../graph/generated.graphql';

export function getAssetPreviewImage(asset: Partial<AssetFragment> | undefined) {
  if(!asset) {
    return ''
  }
  const isSvg = asset.name?.includes('.svg');
  return isSvg && `${asset.s3DownloadUrl}` || `${asset.thumbnail}`;
}

export function isAssetSvg(asset: Partial<AssetFragment> | undefined) {
  if(!asset) {
    return false
  }
  return asset.s3DownloadUrl?.includes('.svg');
}
