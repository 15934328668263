import { gql } from '@apollo/client';

import { ERROR_FRAGMENT, PAGE_INFO } from 'graph/common/fragments';
import { CollectionSegmentInfoFragmentDoc } from 'graph/generated.graphql';
import { USER_BASE_FRAGMENT } from 'graph/users/fragments';

import {
  ASSET_FRAGMENT,
  COMPANY_BASE_FRAGMENT,
  PROJECT_BASE_FRAGMENT,
  PROJECT_FRAGMENT,
} from './fragments';

const COMPANY_LIST_QUERY = gql`
  query companiesList(
    $skip: Int
    $take: Int
    $order: [CompanySortInput!]
    $where: CompanyFilterInput
  ) {
    companiesList(skip: $skip, take: $take, order: $order, where: $where) {
      pageInfo {
        ...CollectionSegmentInfo
      }
      items {
        ...CompanyBase
      }
      totalCount
    }
  }
  ${COMPANY_BASE_FRAGMENT}
  ${PAGE_INFO}
`;
const VIEW_PROJECTS_QUERY = gql`
  query projectsList(
    $skip: Int
    $take: Int
    $order: [ProjectSortInput!]
    $where: ProjectFilterInput
    $companyId: UUID
  ) {
    projectsList(
      skip: $skip
      take: $take
      order: $order
      where: $where
      input: { companyId: $companyId }
    ) {
      pageInfo {
        ...CollectionSegmentInfo
      }
      items {
        ...ProjectBase
      }
      totalCount
    }
  }
  ${PROJECT_BASE_FRAGMENT}
  ${CollectionSegmentInfoFragmentDoc}
`;
const GET_ACCOUNT_MANAGERS = gql`
  query getAccountManagers {
    vidicoAdmin {
      vidicoAdmins {
        ...UserBase
      }
      errors {
        ...Error
      }
    }
  }
  ${USER_BASE_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

const ASSET_QUERY = gql`
  query getAssetById($assetId: UUID!, $key: String) {
    asset(input: { assetId: $assetId, key: $key  }) {
      asset {
        ...Asset
      }
      errors {
        ...Error
      }
    }
  }
  ${ASSET_FRAGMENT}
  ${ERROR_FRAGMENT}
`;
const VIEW_PROJECT_QUERY = gql`
  query getProjectById($projectId: UUID!) {
    viewProject(input: { projectId: $projectId }) {
      project {
        ...Project
      }
    }
  }
  ${PROJECT_FRAGMENT}
`;
const GET_USERS_BY_COMPANY_ID = gql`
  query getUsersByCompanyId(
    $companyId: UUID
    $order: [UserSortInput!]
    $where: UserFilterInput
  ) {
    usersList(input: { companyId: $companyId }, order: $order, where: $where) {
      ...UserBase
    }
  }
  ${USER_BASE_FRAGMENT}
`;
const GET_ASSETS_LIST_QUERY = gql`
  query getAssets(
    $skip: Int
    $take: Int
    $where: AssetFilterInput
    $order: [AssetSortInput!]
    $userId: UUID
  ) {
    assetsList(skip: $skip, take: $take, where: $where, order: $order) {
      items {
        ...Asset
        viewedBy(where: { id: { eq: $userId } }) {
          id
        }
      }
      pageInfo {
        ...CollectionSegmentInfo
      }
      totalCount
    }
  }
  ${ASSET_FRAGMENT}
`;
const GET_ASSETS_LIST_BY_SHARE_KEY = gql`
  mutation getSharedAssetsList($key: String!) {
    sharedLink(input: { key: $key }) {
      assets {
        ...Asset
      }
      errors {
        ...Error
      }
    }
  }
`;

const GET_NEW_ASSETS_INFO = gql`
  query newAssetsInfo($projectId: UUID!) {
    newAssetsInfo(input: { projectId: $projectId }) {
      US_ENGLISH {
        all
        videos
        cutdowns
        images
        captions
        editingFiles
      }
      US_ENGLISH {
        all
        videos
        cutdowns
        images
        captions
        editingFiles
      }
      AU_ENGLISH {
        all
        videos
        cutdowns
        images
        captions
        editingFiles
      }
      UK_ENGLISH {
        all
        videos
        cutdowns
        images
        captions
        editingFiles
      }
      CA_ENGLISH {
        all
        videos
        cutdowns
        images
        captions
        editingFiles
      }
      IR_ENGLISH {
        all
        videos
        cutdowns
        images
        captions
        editingFiles
      }
      EU_SPANISH {
        all
        videos
        cutdowns
        images
        captions
        editingFiles
      }
      US_SPANISH {
        all
        videos
        cutdowns
        images
        captions
        editingFiles
      }
      IT_ITALIAN {
        all
        videos
        cutdowns
        images
        captions
        editingFiles
      }
      JP_JAPANESE {
        all
        videos
        cutdowns
        images
        captions
        editingFiles
      }
      BR_PORTUGUESE {
        all
        videos
        cutdowns
        images
        captions
        editingFiles
      }
      PO_PORTUGUESE {
        all
        videos
        cutdowns
        images
        captions
        editingFiles
      }
      HK_CHINESE {
        all
        videos
        cutdowns
        images
        captions
        editingFiles
      }
      CH_MANDARIN {
        all
        videos
        cutdowns
        images
        captions
        editingFiles
      }
    }
  }
`;
export {
  COMPANY_LIST_QUERY,
  GET_ACCOUNT_MANAGERS,
  ASSET_QUERY,
  VIEW_PROJECT_QUERY,
  GET_USERS_BY_COMPANY_ID,
  GET_ASSETS_LIST_QUERY,
  GET_ASSETS_LIST_BY_SHARE_KEY,
  VIEW_PROJECTS_QUERY,
  GET_NEW_ASSETS_INFO,
};
