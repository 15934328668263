import { Localizations } from '../../graph/generated.graphql';

export const countriesOrder = [
  'US_ENGLISH',
  'AU_ENGLISH',
  'NZ_ENGLISH',
  'UK_ENGLISH',
  'CA_ENGLISH',
  'IR_ENGLISH',
  'EU_SPANISH',
  'US_SPANISH',
  'DE_GERMAN',
  'NL_DUTCH',
  'BE_DUTCH',
  'FR_FRENCH',
  'IT_ITALIAN',
  'BR_PORTUGUESE',
  'PO_PORTUGUESE',
  'JP_JAPANESE',
  'KR_KOREAN',
  'HK_CHINESE',
  'CH_MANDARIN',
  'ZA_SOUTH_AFRICA',
];

export const sortCountriesOrder =
  (order: string[]) => (a: Localizations, b: Localizations) =>
    order.indexOf(a) - order.indexOf(b);
