import React, { useEffect, useState } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import PopupMessage from 'components/atoms/PopupMessage/PopupMessage';
import DownloadProgress from 'components/molecules/DownloadProgress/DownloadProgress';

import CompleatSignUpPage from 'pages/CompleatSignUpPage/CompleatSignUpPage';
import HomePage from 'pages/HomePage/HomePage';
import NotFountPage from 'pages/NotFountPage/NotFountPage';
import ResendVerificationEmailPage from 'pages/ResendVerificationEmail/ResendVerificationEmailPage';
import ResetPasswordPage from 'pages/ResetPasswordPage/ResetPasswordPage';
import SettingPage from 'pages/SettingPage/SettingPage';
import SharedAssetsPage from 'pages/SharedAssetsPage/SharedAssetsPage';
import SignUp from 'pages/SignUpPage/SignUpPage';
import VerificationEmailPage from 'pages/VerificationEmail/VerificationEmailPage';
import ViewAssetPage from 'pages/ViewAssetPage/ViewAssetPage';

import LogOutModal from 'containers/Authentification/LogOutModal/LogOutModal';
import AddMemberModal from 'containers/Workspace/AddMemberModal/AddMemberModal';
import ChangeAccessLevelModal from 'containers/Workspace/ChangeAccessLevelModal/ChangeAccessLevelModal';
import DeleteAccountModal from 'containers/Workspace/DeleteAccountModal/DeleteAccountModal';
import DeleteAssetsModal from 'containers/Workspace/DeleteAssetsModal/DeleteAssetsModal';
import DeleteMemberModal from 'containers/Workspace/DeleteMemberModal/DeleteMemberModal';
import DeleteProjectModal from 'containers/Workspace/DeleteProjectModal/DeleteProjectModal';
import LogOutDeletedAccountModal from 'containers/Workspace/LogOutDeletedAccountModal/LogOutDeletedAccountModal';
import RenameAssetModal from 'containers/Workspace/RenameAssetModal/RenameAssetModal';
import RenameProjectModal from 'containers/Workspace/RenameProjectModal/RenameProjectModal';
import DeleteSharedLinkModal from 'containers/Workspace/ShareLinkModals/DeleteSharedLinkModal/DeleteSharedLinkModal';
import GenerateShareLinkModal from 'containers/Workspace/ShareLinkModals/GenerateShareLinkModal/GenerateShareLinkModal';
import ShareLinkModal from 'containers/Workspace/ShareLinkModals/ShareLinkModal/ShareLinkModal';
import ViewWorkspacePage from 'modules/admin/pages/ViewWorkspacePage/ViewWorkspacePage';
import WorkspaceListPage from 'modules/admin/pages/WorkspaceListPage/WorkspaceListPage';
import { reset } from 'redux/filter/filterSlice';
import { useAppDispatch } from 'redux/hooks';
import { SUBDOMAIN } from 'utils/constants/common';
import { RoutesPath } from 'utils/constants/routes';
import { getSubdomainName } from 'utils/getArrayOfSubdomainNames/getArrayOfSubdomainNames';
import { getSubdomainByEnv } from 'utils/helpers/getSubdomainByEnviroment';
import useInitViewer from 'utils/hooks/useInitViewer/useInitViewer';

import DownloadVideoModal from './components/molecules/DownloadVideoModal/DownloadVideoModal';
import ProtectedRout from './containers/Authentification/ProtectedRout/ProtectedRout';
import { setSessionState } from './utils/localStorage/viewer';

function App() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [subdomain, setSubdomain] = useState('');
  const dispatch = useAppDispatch();
  const location = useLocation();
  const subdomainArray = getSubdomainName();

  useEffect(() => {
    if (subdomainArray.length > 0) setSubdomain(subdomainArray[0]);

    // eslint-disable-next-line
  }, []);

  const [searchParams] = useSearchParams();

  const sessionState = searchParams.get('session_state');
  if (sessionState) {
    setSessionState(sessionState);
  }

  useEffect(() => {
    dispatch(reset());
  }, [dispatch, location]);

  const [isViewerReady] = useInitViewer();

  function VideoPlanner() {
    let url = process.env.REACT_APP_VIDEO_PLANNER_URL as string;
    if (!url) {
      url = `${window.location.origin}/video-planner/`;
    } else {
      url = `${url}/`;
    }

    const { companyId } = useParams();
    if (companyId) {
      url = `${url}${companyId}/`;
    }
    window.location.replace(url);
    return null;
  }

  return isViewerReady ? (
    <>
      <Routes>
        <Route
          path={RoutesPath.VideoPlannerCompany}
          element={<VideoPlanner />}
        />
        <Route path={RoutesPath.VideoPlanner} element={<VideoPlanner />} />
        {subdomain !== getSubdomainByEnv(SUBDOMAIN.Admin) &&
          subdomain !== getSubdomainByEnv(SUBDOMAIN.App) && (
            <>
              <Route path="*" element={<Navigate to={RoutesPath.Home} />} />
              <Route
                path={RoutesPath.Home}
                element={
                  <ProtectedRout>
                    <HomePage />
                  </ProtectedRout>
                }
              />
              <Route
                path={RoutesPath.HomeProject}
                element={
                  <ProtectedRout>
                    <HomePage />
                  </ProtectedRout>
                }
              />
              <Route
                path={RoutesPath.HomeViewAssetById}
                element={
                  <ProtectedRout>
                    <ViewAssetPage />
                  </ProtectedRout>
                }
              />
              <Route
                path={RoutesPath.Settings}
                element={
                  <ProtectedRout>
                    <SettingPage />
                  </ProtectedRout>
                }
              />
              <Route
                path={RoutesPath.SharedAssets}
                element={<SharedAssetsPage />}
              />
              <Route
                path={RoutesPath.ViewSharedAssetById}
                element={<ViewAssetPage />}
              />
              <Route
                path={RoutesPath.PageNotFound}
                element={<NotFountPage />}
              />
            </>
          )}
        {subdomain === getSubdomainByEnv(SUBDOMAIN.Admin) && (
          <>
            <Route path="*" element={<Navigate to={RoutesPath.Workspaces} />} />
            <Route
              path={RoutesPath.Workspaces}
              element={
                <ProtectedRout>
                  <WorkspaceListPage />
                </ProtectedRout>
              }
            />
            <Route
              path={RoutesPath.WorkspaceById}
              element={
                <ProtectedRout>
                  <ViewWorkspacePage />
                </ProtectedRout>
              }
            />
            <Route
              path={RoutesPath.Projects}
              element={
                <ProtectedRout>
                  <ViewWorkspacePage />
                </ProtectedRout>
              }
            />
            <Route
              path={RoutesPath.ViewAssetById}
              element={
                <ProtectedRout>
                  <ViewAssetPage />
                </ProtectedRout>
              }
            />
            <Route
              path={RoutesPath.Settings}
              element={
                <ProtectedRout>
                  <SettingPage />
                </ProtectedRout>
              }
            />
          </>
        )}

        {subdomain === getSubdomainByEnv(SUBDOMAIN.App) && (
          <>
            <Route path={RoutesPath.SignUp} element={<SignUp />} />
            <Route
              path={RoutesPath.ResendVerificationEmail}
              element={<ResendVerificationEmailPage />}
            />
            <Route
              path={RoutesPath.CompleatSignUp}
              element={<CompleatSignUpPage />}
            />
            <Route
              path={RoutesPath.EmailVerification}
              element={<VerificationEmailPage />}
            />
            <Route
              path={RoutesPath.ResetPassword}
              element={<ResetPasswordPage />}
            />
            <Route path="*" element={<Navigate to={RoutesPath.Home} />} />
            <Route
              path={RoutesPath.Home}
              element={
                <ProtectedRout>
                  <HomePage />
                </ProtectedRout>
              }
            />
          </>
        )}
      </Routes>
      <LogOutModal />
      <ShareLinkModal />
      <GenerateShareLinkModal />
      <DeleteSharedLinkModal />
      <AddMemberModal />
      <ChangeAccessLevelModal />
      <DeleteMemberModal />
      <DeleteAccountModal />
      <LogOutDeletedAccountModal />
      <RenameProjectModal />
      <DeleteProjectModal />
      <DeleteAssetsModal />
      <RenameAssetModal />
      <PopupMessage />
      <DownloadProgress />
      <DownloadVideoModal />
    </>
  ) : null;
}

export default App;
