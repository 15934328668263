import React, { useState } from 'react';
import { useTheme } from 'styled-components';

import Flex from 'components/atoms/Flex/Flex';
import Icon from 'components/atoms/Icon/Icon';
import Text from 'components/atoms/Text/Text';
import ActionModal, {
  ButtonsAlignmentName,
} from 'components/molecules/ActionModal/ActionModal';
import { Modal } from 'components/molecules/Modal/Modal';

import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  selectRemoveProjectInfo,
  selectRemoveProjectModal,
  setModal,
} from 'redux/modal/modalSlice';

function DeleteProjectModal() {
  const [errorMessage, setErrorMessage] = useState('');
  const theme = useTheme();
  const show = useAppSelector(selectRemoveProjectModal);
  const project = useAppSelector(selectRemoveProjectInfo);
  const dispatch = useAppDispatch();

  const onCloseModal = () => {
    dispatch(
      setModal({
        showRemoveProjectModal: false,
        removeProjectInfo: undefined,
      })
    );
    setErrorMessage('');
  };

  const handleButtonClick = () => {
    dispatch(
      setModal({
        showRemoveProjectModal: false,
        removePopupInfo: {
          showRemovePopup: true,
          isProjectRemoved: true,
        },
      })
    );
  };
  return (
    <Modal show={show} onHide={onCloseModal} width={480}>
      <Flex fullWidth justifyContent="left" mb={4}>
        <Icon
          color={theme.colors.primary600}
          size={28}
          name="deleteFilled"
          mr={2}
        />

        <Text lg bold>
          Delete {project?.name}
        </Text>
      </Flex>
      <Flex fullWidth justifyContent="left" flexDirection="column" mb={5}>
        <Text sm medium color={theme.colors.grey600}>
          <b>Caution:</b> This will delete all assets within this folder
          including videos, cutdowns, images, captions and design files.
        </Text>
      </Flex>
      <ActionModal
        primaryButtonProps={{
          text: 'Delete',
          type: 'button',
          onClick: handleButtonClick,
        }}
        secondaryButtonProps={{ text: 'Cancel', onClick: onCloseModal }}
        buttonsAlignment={ButtonsAlignmentName.RIGHT}
        error={errorMessage}
      />
    </Modal>
  );
}

export default DeleteProjectModal;
