import { ReactElement, useEffect, useState } from 'react';

import { useValidateTokenLazyQuery } from 'graph/generated.graphql';
import { ErrorsMessage } from 'utils/constants/errors';
import {
  isTokenExpiredError,
  isTokenInvalidError,
} from 'utils/errors/isGqlError';

function useValidateToken({ token }: { token: string }) {
  const [message, setMessage] = useState<{
    type: 'warning' | 'success';
    message: ReactElement<any, any> | string;
    show: boolean;
  }>({ type: 'success', message: '', show: false });

  const [getData] = useValidateTokenLazyQuery({
    onCompleted() {
      setMessage({ type: 'success', message: '', show: false });
    },
    onError(error) {
      error.graphQLErrors.forEach((gqlError) => {
        if (isTokenExpiredError(gqlError)) {
          setMessage({
            type: 'warning',
            message: ErrorsMessage.tokenExpired,
            show: true,
          });
        }
        if (isTokenInvalidError(gqlError)) {
          setMessage({
            type: 'warning',
            message: ErrorsMessage.tokenInvalid,
            show: true,
          });
        }
      });
    },
  });
  useEffect(() => {
    getData({
      variables: {
        token,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return { message };
}
export { useValidateToken };
