import styled from 'styled-components';

import Box from 'components/atoms/Box/Box';
import Button from 'components/atoms/Button/Button';
import Flex from 'components/atoms/Flex/Flex';
import { textStyle } from 'components/atoms/Text/Text';

import { ReactComponent as GoogleIconGrey } from 'assets/svgs/google-grey.svg';
import { ReactComponent as GoogleIcon } from 'assets/svgs/google.svg';

import type { IGoogleButtonProps } from './GoogleButton.interface';

const ButtonStyled = styled(Button)`
  background-color: ${({ theme }) => theme.colors.base};
  color: ${({ theme }) => theme.colors.grey700};
  border: 1px solid ${({ theme }) => theme.colors.grey300};
  &:hover:enabled {
    background-color: ${({ theme }) => theme.colors.grey50};
  }
  &:focus {
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #f2f4f7;
  }
  ${textStyle}
`;

function GoogleButton({ children, disabled, ...args }: IGoogleButtonProps) {
  return (
    <ButtonStyled {...args}>
      <Flex alignItems="center">
        <Box mr={3}>{disabled ? <GoogleIconGrey /> : <GoogleIcon />}</Box>
        <span>{children}</span>
      </Flex>
    </ButtonStyled>
  );
}

export default GoogleButton;
