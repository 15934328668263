import { Form, FormSelectProps } from 'react-bootstrap';
import styled from 'styled-components';

import { IBoxProps } from '../Box/Box';
import { getErrorTypeColor } from '../FormControl/FormControl';

interface Props extends FormSelectProps, IBoxProps {}

const FormSelect = styled(Form.Select)<Props>`
  ${({ w }) => (w ? `width:${w}px;` : '')}
  ${({ h }) => (h ? `height:${h}px;` : '')}
  ${({ p, theme }) => (p ? `padding:${p * theme.space}px;` : '')}
  ${({ p, theme }) => (p ? `padding:${p * theme.space}px;` : '')}
  ${({ py, theme }) =>
    py
      ? `padding-top:${py * theme.space}px;padding-bottom:${
          py * theme.space
        }px;`
      : ''}
  ${({ px, theme }) =>
    px
      ? `padding-right:${px * theme.space}px;padding-left:${
          px * theme.space
        }px;`
      : ''}
  ${({ pt, theme }) => (pt ? `padding-top:${pt * theme.space}px;` : '')}
  ${({ pb, theme }) => (pb ? `padding-bottom:${pb * theme.space}px;` : '')}
  ${({ pr, theme }) => (pr ? `padding-right:${pr * theme.space}px;` : '')}
  ${({ pl, theme }) => (pl ? `padding-left:${pl * theme.space}px;` : '')}
  ${({ m, theme }) => (m ? `margin:${m * theme.space}px;` : '')}
  ${({ my, theme }) =>
    my
      ? `margin-top:${my * theme.space}px;margin-bottom:${my * theme.space}px;`
      : ''}
  ${({ mx, theme }) =>
    mx
      ? `margin-right:${mx * theme.space}px;margin-left:${mx * theme.space}px;`
      : ''}
  ${({ mt, theme }) => (mt ? `margin-top:${mt * theme.space}px;` : '')}
  ${({ mb, theme }) => (mb ? `margin-bottom:${mb * theme.space}px;` : '')}
  ${({ mr, theme }) => (mr ? `margin-right:${mr * theme.space}px;` : '')}
  ${({ ml, theme }) => (ml ? `margin-left:${ml * theme.space}px;` : '')}
  ${({ gap }) => (gap ? `gap:${gap}px` : '')}
  ${({ fullWidth }) => (fullWidth ? `width: 100%` : '')}
  border: 1px solid
    ${({ theme, $errorType }) => getErrorTypeColor(theme, $errorType)};
`;
export default FormSelect;
