import React, { FormEvent, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTheme } from 'styled-components';

import { Alert } from 'components/atoms/Alert/Alert';
import Button from 'components/atoms/Button/Button';
import Flex from 'components/atoms/Flex/Flex';
import FormControl from 'components/atoms/FormControl/FormControl';
import FormFeetback from 'components/atoms/FormFeetback/FormFeetback';
import Text from 'components/atoms/Text/Text';

import { ISignInProps } from 'containers/Authentification/SignIn/SignIn.interface';
import { useRequestResetPasswordMutation } from 'graph/generated.graphql';
import { FormErrorMessages, FormErrorTypes } from 'utils/constants/forms';
import {
  useFormState,
  useFormValidation,
} from 'utils/hooks/useFormValidation/useFormValidation';
import { isEmail } from 'utils/validators/validators';

enum FormFields {
  Email = 'email',
}

const FormFieldsList = [FormFields.Email];

function ResetPasswordForm() {
  const theme = useTheme();

  const [message, setMessage] = useState<ISignInProps['message']>({
    type: 'warning',
    show: false,
    message: '',
  });

  const [formErrors, isFormValid, [setEmailError]] =
    useFormValidation(FormFieldsList);

  const [formState, [setEmail], [formOnSubmit, formOnError, formOnSuccess]] =
    useFormState(FormFieldsList);

  const [requestResetPassword, { loading: isRequestResetPasswordLoading }] =
    useRequestResetPasswordMutation({
      onError(error) {
        error.graphQLErrors.forEach((gqlError) =>
          formOnError(gqlError.message)
        );
      },
      onCompleted() {
        onSuccess();
      },
    });

  const { isLoading, errorSlug } = formState;

  const validEmail = (value: string) => {
    if (!value) {
      setEmailError({
        type: FormErrorTypes.Invalid,
        message: FormErrorMessages.EMAIL_BLANK,
      });
      return false;
    }
    if (!isEmail(value)) {
      setEmailError({
        type: FormErrorTypes.Invalid,
        message: FormErrorMessages.EMAIL_INVALID,
      });
      return false;
    }
    setEmailError(null);
    return true;
  };
  const onEmailTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setEmail(value);
    setEmailError(null);
    setMessage({
      type: 'success',
      show: false,
      message: '',
    });
  };

  const onSuccess = () => {
    formOnSuccess();
    setMessage({
      type: 'success',
      show: true,
      message: (
        <>
          We sent a reset password email to <b>{formState.email}</b>. Please
          click the reset password link to set your new password.
        </>
      ),
    });
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const { email } = formState;

    if (!validEmail(email)) {
      return null;
    }
    requestResetPassword({ variables: { email } });
    formOnSubmit();
    return null;
  };
  return (
    <Form className="w-100" noValidate onSubmit={handleSubmit}>
      <Flex justifyContent="center">
        <Alert
          variant={message.type}
          transition={false}
          iconName="checkCircle"
          show={message.show}
        >
          {message.message}
        </Alert>
      </Flex>
      <Form.Group controlId="validationCustom01">
        <Form.Label>
          <Text color={theme.colors.grey900} sm semibold>
            Email
          </Text>
        </Form.Label>
        <FormControl
          autoComplete="username"
          type="email"
          placeholder="hello@vidico.com"
          required
          onChange={onEmailTextChange}
        />
        <FormFeetback type={formErrors[FormFields.Email]?.type}>
          {formErrors[FormFields.Email]?.message}
        </FormFeetback>
      </Form.Group>
      <Button
        fullWidth
        round
        lg
        semibold
        py={4}
        type="submit"
        disabled={isLoading || !isFormValid || isRequestResetPasswordLoading}
        isLoading={isLoading || isRequestResetPasswordLoading}
      >
        Send reset instructions
      </Button>
      <Form.Group className="mb-1">
        <FormFeetback type={FormErrorTypes.Invalid}>{errorSlug}</FormFeetback>
      </Form.Group>
    </Form>
  );
}

export default ResetPasswordForm;
