import axios, { CancelTokenSource } from 'axios';
import JSZip from 'jszip';
import { useState, useEffect, useCallback, useRef } from 'react';

import { AssetDownloadMutation, AssetFragment } from 'graph/generated.graphql';
import { download, downloadFileConcurrently } from 'utils/helpers/workspace';

import { VideoSizeType } from '../../constants/video-size-type';

interface Value {
  data: number;
  cancelToken: CancelTokenSource;
}
const useFileDownloader = () => {
  const [totalProgress, setTotalProgress] = useState(0);
  const [errorState, setErrorState] = useState<string | null>(null);
  const [fileArrayLength, setFileArrayLength] = useState(0);
  const [isDownloading, setIsDownloading] = useState(false);
  const zip = new JSZip();

  const [values, setValues] = useState<Value[]>([]);
  const previousValuesRef = useRef<Value[]>([]);

  const addValues = (length: number): Value[] => {
    const newValues: Value[] = Array.from({ length }, () => {
      const cancelToken = axios.CancelToken.source();
      return { data: 0, cancelToken };
    });
    setValues((prevValues) => {
      const updatedValues = [...prevValues, ...newValues];
      previousValuesRef.current = updatedValues;
      return updatedValues;
    });
    return newValues;
  };

  const updateValue = (index: number, newValue: number): void => {
    setValues((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[index].data = newValue;
      previousValuesRef.current = updatedValues;
      return updatedValues;
    });
  };

  const resetStates = () => {
    setTotalProgress(0);
    setIsDownloading(false);
    setFileArrayLength(0);
  };

  const downloadFilesParallel = async (
    fileArray: AssetDownloadMutation['assetDownload']['assets'],
    videoSize?: VideoSizeType
    // eslint-disable-next-line consistent-return
  ) => {
    if (!fileArray || errorState) {
      setIsDownloading(false);
      return null;
    }
    const createdValues = addValues(fileArray.length);
    setFileArrayLength(fileArray.length);
    const firstFile = fileArray[0];

    try {
      if (fileArray && fileArray?.length === 1) {
        const { name } = fileArray[0];
        const response = await downloadFileConcurrently(
          getDownloadUrl(firstFile, videoSize),
          updateValue,
          0,
          createdValues[0].cancelToken
        );
        resetStates();
        if (!response) {
          throw new Error('Download failed');
        }
        return download(response, name);
      }

      await Promise.all(
        fileArray.map((file, index) =>
          downloadFileConcurrently(
            getDownloadUrl(file, videoSize),
            updateValue,
            index,
            createdValues[index].cancelToken
          )
            .then((response) => {
              if (!response) {
                throw new Error('Download failed');
              }
              console.log('file has been downloaded:', file.name);
              return zip.file(file.name, response);
            })
            .catch((error) => {
              throw error;
            })
        )
      );

      if (errorState) {
        return null;
      }
      const zipBlob = await zip.generateAsync({ type: 'blob' });
      const zipName = `Vidico x ${firstFile.projectName}.zip`;
      resetStates();
      setErrorState(null);
      setValues([]);
      return download(zipBlob, zipName);
    } catch (error) {
      console.log('error in parallel downloading:', error);
      if (!axios.isCancel(error)) {
        setErrorState('Failed to download file(s).');
        resetStates();
        setTimeout(() => {
          setErrorState(null);
        }, 1000);
      }
    }
  };

  const getDownloadUrl = (file: AssetFragment, videoSize?: VideoSizeType) => {
    if (videoSize === 'original') {
      return file.s3DownloadUrl;
    }
    if (videoSize === 'compressed') {
      return file.muxDownloadUrl;
    }

    return file.s3DownloadUrl || file.muxDownloadUrl;
  };

  const downloadFiles = (
    fileArray: AssetDownloadMutation['assetDownload']['assets'],
    videoSize?: VideoSizeType
  ) => {
    if (isDownloading) {
      return null;
    }
    setErrorState(null);
    resetStates();
    setValues([]);
    setIsDownloading(true);
    return downloadFilesParallel(fileArray, videoSize);
  };

  const cancelDownload = useCallback(() => {
    values.forEach(({ cancelToken }) =>
      cancelToken.cancel('Download canceled.')
    );

    setTotalProgress(0);
    setIsDownloading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.length]);

  useEffect(() => {
    if (errorState) {
      cancelDownload();
    }

    return () => {};
  }, [cancelDownload, errorState]);
  useEffect(() => {
    if (fileArrayLength && values.length) {
      setTotalProgress(
        Math.floor(
          values.reduce(
            (accumulator, currentValue) => accumulator + currentValue.data,
            0
          ) / fileArrayLength
        )
      );
    }
  }, [fileArrayLength, values]);

  return {
    downloadFiles,
    totalProgress,
    cancelDownload,
    errorState,
    isDownloading,
  };
};

export default useFileDownloader;
