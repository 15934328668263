import React from 'react';
import styled, { useTheme } from 'styled-components';

import Flex from 'components/atoms/Flex/Flex';
import Text from 'components/atoms/Text/Text';

import { ILabelGroupProps } from './LabelGroup.interface';

const Subtitle = styled(Text)<{ alignItems: ILabelGroupProps['alignItems'] }>`
  ${({ alignItems }) =>
    alignItems === 'center' ? 'text-align: center;' : null}
`;
function LabelGroup({
  title,
  subTitle,
  text,
  alignItems,
  ...args
}: ILabelGroupProps) {
  const theme = useTheme();
  return (
    <Flex {...args} alignItems={alignItems} flexDirection="column">
      <Text sm bold color={theme.colors.grey900}>
        {title}
      </Text>
      <Subtitle alignItems={alignItems} sm medium color={theme.colors.grey900}>
        {subTitle}
      </Subtitle>
      <Text xs regular color={theme.colors.grey900}>
        {text}
      </Text>
    </Flex>
  );
}

export default LabelGroup;
