import React from 'react';
import { ToastContainer } from 'react-bootstrap';
import { useTheme } from 'styled-components';

import Box from 'components/atoms/Box/Box';
import Flex from 'components/atoms/Flex/Flex';
import Notification from 'components/atoms/Notification/Notification';
import ProgressBar from 'components/atoms/ProgressBar/ProgressBar';
import Text from 'components/atoms/Text/Text';

import { useAppDispatch } from 'redux/hooks';
import { setModal } from 'redux/modal/modalSlice';

import { IDownloadNotificationProps } from './DownloadNotification.interface';

function DownloadNotification({
  totalProgress,
  ...args
}: IDownloadNotificationProps) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const onCloseModal = () =>
    dispatch(
      setModal({
        showDownloadCancelModal: true,
      })
    );

  return (
    <ToastContainer
      position="top-end"
      className="p-3 "
      style={{
        zIndex: 33,
        marginTop: '74px',
        background:
          'linear-gradient(202deg, rgba(0, 0, 0, 0.1) 0%, rgba(255, 255, 255, 0) 53.13%)',
      }}
    >
      <Notification
        {...args}
        title="Preparing your Download"
        onClose={onCloseModal}
      >
        <Flex flexDirection="column">
          <Text sm regular color={theme.colors.grey500}>
            Please wait while we download your file.
          </Text>
          <Box w={300}>
            <ProgressBar progress={totalProgress / 100} />
          </Box>
          <Box fullWidth pr={4}>
            <Text textAlign="right" sm medium color={theme.colors.grey700}>
              {totalProgress}% downloaded...
            </Text>
          </Box>
        </Flex>
      </Notification>
    </ToastContainer>
  );
}

export default DownloadNotification;
