import React from 'react';
import { useTheme } from 'styled-components';

import Box from 'components/atoms/Box/Box';
import Flex from 'components/atoms/Flex/Flex';
import H from 'components/atoms/H/H';
import Logo from 'components/atoms/Logo/Logo';
import Text from 'components/atoms/Text/Text';

import CompleatSignUpForm from './components/CompleatSignUpForm/CompleatSignUpForm';
import { ICompleatSignUpFormProps } from './components/CompleatSignUpForm/CompleatSignUpForm.interfaces';

function CompleatSignUpContainer({ ...args }: ICompleatSignUpFormProps) {
  const theme = useTheme();
  return (
    <Flex
      h="calc(100vh - 84px)"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Flex justifyContent="center" alignItems="center" flexDirection="column">
        <Box mb={8}>
          <Logo />
        </Box>
        <Flex flexDirection="column" alignItems="center" mb={8}>
          <Box mb={3}>
            <H as={2} sm bold color={theme.colors.grey900}>
              Finish sign up
            </H>
          </Box>
          <Text md regular color={theme.colors.grey500}>
            Almost there!
          </Text>
        </Flex>
        <Box w={420}>
          <CompleatSignUpForm {...args} />
        </Box>
      </Flex>
    </Flex>
  );
}

export default CompleatSignUpContainer;
