import React from 'react';
import { Link, useMatch } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

import Box from 'components/atoms/Box/Box';
import Button from 'components/atoms/Button/Button';
import Container from 'components/atoms/Container/Container';
import Flex from 'components/atoms/Flex/Flex';
import Icon from 'components/atoms/Icon/Icon';
import Logo from 'components/atoms/Logo/Logo';
import Text from 'components/atoms/Text/Text';

import {
  AssetFragment,
  Roles,
  useAssetDownloadMutation
} from 'graph/generated.graphql';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { setModal } from 'redux/modal/modalSlice';
import { selectViewer } from 'redux/viewer/viewerSlice';
import { RoutesPath } from 'utils/constants/routes';
import { convertBytesToMb } from 'utils/transformers/text';

import HeaderForm from './components/HeaderForm/HeaderForm';
import ProfileMenu from './components/ProfileMenu/ProfileMenu';

const HeaderStyled = styled.header`
  min-height: 92px;
  border-bottom: 2px solid ${({ theme }) => theme.colors.beige};
`;
const FlexStyled = styled(Flex)`
  flex-direction: row;
  padding: 25px 32px;
`;

const LogoWrapperSyled = styled.div`
  width: 126px;
`;

const AppsWrapperStyled = styled(Flex)`
  flex-direction: row;
  position: relative;
  top: 1px;
  margin-left: 36px;
`;

type AppsItemStyledProps = {
  active?: 'active' | 'inactive';
}

const AppsItemStyled = styled(Link)<AppsItemStyledProps>`
  font-weight: 600;
  font-size: 14px;
  line-height: calc(20 / 14);
  color: ${({ theme }) => theme.colors.grey500};

  path {
    stroke: ${({ theme }) => theme.colors.grey500};
  }

  strong {
    font-weight: 900;
  }

  &:not(:first-child) {
    margin-left: 32px;
  }

  &:hover {
    & path {
      stroke: ${({ theme }) => theme.colors.grey600};
    }

    color: ${({ theme }) => theme.colors.grey600};
  }

  ${({ active, theme }) => active === 'active' ? `color: ${theme.colors.grey700};
  path {
    stroke: ${theme.colors.grey700};
  }` : ''}
`;

const ProfileMenuWrapperStyled = styled.div`
  position: relative;
  height: 40px;
`;
const ProfileMenuStyled = styled(ProfileMenu)`
  position: absolute;
  top: 0;
  right: 0;
`;

interface IHeaderProps {
  assetsInfo?: {
    assetsTotalCount: number;
    size: number;
    assetsId: AssetFragment['id'][];
  };
}

function Header({ assetsInfo }: IHeaderProps) {
  const theme = useTheme();
  const isSharePage = useMatch(RoutesPath.SharedAssets);
  const isWorkspaceSelected = useMatch(RoutesPath.Projects);
  const isProjectInWorkspaceSelected = useMatch(RoutesPath.WorkspaceById);
  const viewer = useAppSelector(selectViewer);
  const dispatch = useAppDispatch();

  const showAddMember =
    viewer?.roles !== Roles.VidicoAdmin ||
    !!isProjectInWorkspaceSelected ||
    !!isWorkspaceSelected;

  const showVideoPlanner = viewer?.roles !== Roles.VidicoAdmin ||
    !!isProjectInWorkspaceSelected ||
    !!isWorkspaceSelected;

  const [assetsDownload, { loading: isDownloadLoading }] =
    useAssetDownloadMutation({
      onCompleted({ assetDownload }) {
        dispatch(
          setModal({
            showPopupMessage: true,
            popupMessageInfo: { text: 'Download started' },
            fileToDownload: assetDownload.assets
          })
        );
      },
      onError() {
        dispatch(
          setModal({
            showPopupMessage: true,
            popupMessageInfo: { text: 'Download failed' }
          })
        );
      }
    });

  return (
    <HeaderStyled>
      <Container>
        <FlexStyled
          justifyContent='space-between'
          alignItems='center'
          flexDirection='column'
        >
          <Flex alignItems='center' mr={6}>
            <Link to={RoutesPath.Home}>
              <LogoWrapperSyled>
                <Logo />
              </LogoWrapperSyled>
            </Link>
            {showVideoPlanner && (
              <AppsWrapperStyled>
                {viewer?.roles === Roles.VidicoAdmin && (
                  <AppsItemStyled
                    to={`${RoutesPath.VideoPlanner}/${isWorkspaceSelected?.params.companyId ?? isProjectInWorkspaceSelected?.params.companyId}`}>
                    <Icon
                      size={18}
                      mr={2}
                      name='calendar'
                    />
                    <strong>Video</strong>Planner
                  </AppsItemStyled>
                )}
                {viewer?.roles !== Roles.VidicoAdmin && (
                  <AppsItemStyled to={RoutesPath.VideoPlanner}>
                    <Icon
                      size={18}
                      mr={2}
                      name='playSquare'
                    /><strong>Video</strong>Planner
                  </AppsItemStyled>
                )}
                <AppsItemStyled active='active' to={RoutesPath.Home}>
                  <Icon
                    size={18}
                    mr={2}
                    name='playSquare'
                  /><strong>Video</strong>Store
                </AppsItemStyled>
              </AppsWrapperStyled>
            )}
          </Flex>
          <Flex alignItems='center'>
            <HeaderForm />
            {!isSharePage && (
              <ProfileMenuWrapperStyled>
                <ProfileMenuStyled showAddMember={showAddMember} />
              </ProfileMenuWrapperStyled>
            )}
            {!!assetsInfo?.assetsTotalCount && (
              <Flex alignItems='center'>
                <Box mr={3}>
                  <Text sm medium color={theme.colors.grey600}>{`${
                    assetsInfo.assetsTotalCount
                  } Assets ${convertBytesToMb(assetsInfo.size)}`}</Text>
                </Box>
                <Button
                  py={2}
                  px={3}
                  onClick={() =>
                    assetsDownload({
                      variables: {
                        assetsId: assetsInfo.assetsId,
                        sharedLinkKey: isSharePage
                          ? isSharePage.params.key
                          : undefined
                      }
                    })
                  }
                  isLoading={isDownloadLoading}
                >
                  <Icon size={20} name='download01' mr={2} />
                  Download all
                </Button>
              </Flex>
            )}
          </Flex>
        </FlexStyled>
      </Container>
    </HeaderStyled>
  );
}

export default Header;
