import React, { FormEvent } from 'react';
import { Form } from 'react-bootstrap';
import styled, { useTheme } from 'styled-components';

import Box from 'components/atoms/Box/Box';
import Divider from 'components/atoms/Divider/Divider';
import Flex from 'components/atoms/Flex/Flex';
import FormFeetback from 'components/atoms/FormFeetback/FormFeetback';
import InputPassword from 'components/atoms/InputPassword/InputPassword';
import Text from 'components/atoms/Text/Text';
import ActionModal, {
  ButtonsAlignmentName,
} from 'components/molecules/ActionModal/ActionModal';
import PasswordStrengthMeter from 'components/molecules/PasswordStrengthMeter/PasswordStrengthMeter';

import { useChangePasswordMutation } from 'graph/generated.graphql';
import { FormErrorMessages, FormErrorTypes } from 'utils/constants/forms';
import {
  isMinimumLengthValidatorError,
  isWrongPasswordError,
} from 'utils/errors/isGqlError';
import {
  useFormState,
  useFormValidation,
} from 'utils/hooks/useFormValidation/useFormValidation';
import {
  checkPasswordStrongness,
  checkPasswordToWhiteSpace,
} from 'utils/validators/validators';

enum FormFields {
  OldPassword = 'oldPassword',
  NewPassword = 'newPassword',
}

const FormFieldsList = [FormFields.OldPassword, FormFields.NewPassword];

const passwordMessageMap = {
  [FormErrorTypes.Blank]: FormErrorMessages.PASSWORD_BLANK,
  [FormErrorTypes.Warning]: FormErrorMessages.PASSWORD_WARNING,
  [FormErrorTypes.Invalid]: FormErrorMessages.PASSWORD_INVALID,
  [FormErrorTypes.Valid]: FormErrorMessages.PASSWORD_VALID,
};
const ControlWrapper = styled(Box)`
  flex: auto;
`;
function ChangePasswordForm() {
  const theme = useTheme();
  const initialFormValue = {
    [FormFields.OldPassword]: '',
    [FormFields.NewPassword]: '',
  };
  const [formErrors, isFormValid, [setOldPasswordError, setNewPasswordError]] =
    useFormValidation(FormFieldsList);
  const [changePassword, { loading: isChangePasswordLoading }] =
    useChangePasswordMutation({
      onCompleted() {
        formOnSuccess();
        setNewPassword('');
        setOldPassword('');
        return setNewPasswordError({
          type: FormErrorTypes.Valid,
          message: FormErrorMessages.PASSWORD_CHANGED,
        });
      },
      onError(error) {
        error.graphQLErrors.forEach((gqlError) => {
          if (isWrongPasswordError(gqlError)) {
            formOnError();
            return setOldPasswordError({
              type: FormErrorTypes.Invalid,
              message: FormErrorMessages.OLD_PASSWORD_WRONG,
            });
          }
          if (isMinimumLengthValidatorError(gqlError)) {
            formOnError();
            if (gqlError.extensions.property === 'OldPassword') {
              return setOldPasswordError({
                type: FormErrorTypes.Invalid,
                message: FormErrorMessages.PASSWORD_LESS_THAN_8,
              });
            }
            if (gqlError.extensions.property === 'NewPassword') {
              return setNewPasswordError({
                type: FormErrorTypes.Invalid,
                message: FormErrorMessages.COMPANY_NAME_NOT_UNIQUE,
              });
            }
          }
          return formOnError(gqlError.message);
        });
      },
    });

  const [
    formState,
    [setOldPassword, setNewPassword],
    [formOnSubmit, formOnError, formOnSuccess],
  ] = useFormState(FormFieldsList, initialFormValue);

  const validOldPassword = (value: string) => {
    if (!value) {
      setOldPasswordError({
        type: FormErrorTypes.Invalid,
        message: FormErrorMessages.PASSWORD_BLANK,
      });
      return false;
    }
    setOldPasswordError(null);
    return true;
  };

  const validNewPassword = (value: string) => {
    if (checkPasswordToWhiteSpace(value)) {
      setNewPasswordError({
        type: FormErrorTypes.Invalid,
        message: FormErrorMessages.SPACES_IN_PASSWORD,
      });
      return false;
    }
    const type = checkPasswordStrongness(value);
    setNewPasswordError({
      type,
      message: passwordMessageMap[type],
    });
    return type === FormErrorTypes.Valid || type === FormErrorTypes.Warning;
  };

  const onOldPasswordTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOldPassword(e.target.value);
    validOldPassword(e.target.value);
  };

  const onNewPasswordTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(e.target.value);
    validNewPassword(e.target.value);
  };
  const { isLoading, errorSlug, oldPassword, newPassword } = formState;

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    if (
      [validNewPassword(newPassword), validOldPassword(oldPassword)].filter(
        (v) => !v
      ).length
    ) {
      return null;
    }
    changePassword({ variables: { oldPassword, newPassword } });

    formOnSubmit();

    return null;
  };
  return (
    <Form className="w-100" onSubmit={handleSubmit}>
      <Form.Group className="mb-1" controlId="formOldPassword">
        <Flex mb={5}>
          <Box w={280} mr={8} mb={5}>
            <Form.Label>
              <Text color={theme.colors.grey700} sm medium>
                Current password
              </Text>
            </Form.Label>
          </Box>
          <ControlWrapper>
            <InputPassword
              autoComplete="current-password"
              $errorType={formErrors[FormFields.OldPassword]?.type}
              value={oldPassword}
              onChange={onOldPasswordTextChange}
            />
            <FormFeetback type={formErrors[FormFields.OldPassword]?.type}>
              {formErrors[FormFields.OldPassword]?.message}
            </FormFeetback>
          </ControlWrapper>
        </Flex>
      </Form.Group>
      <Form.Group className="mb-1" controlId="formNewPassword">
        <Flex mb={5}>
          <Box w={280} mr={8} mb={5}>
            <Form.Label>
              <Text color={theme.colors.grey700} sm medium>
                New password
              </Text>
            </Form.Label>
          </Box>
          <ControlWrapper>
            <InputPassword
              autoComplete="new-password"
              $errorType={formErrors[FormFields.NewPassword]?.type}
              value={newPassword}
              onChange={onNewPasswordTextChange}
            />
            <FormFeetback type={formErrors[FormFields.NewPassword]?.type}>
              {formErrors[FormFields.NewPassword]?.message}
            </FormFeetback>
            <PasswordStrengthMeter password={newPassword} mb={7} mt={2} />
          </ControlWrapper>
        </Flex>
      </Form.Group>

      <Divider fullWidth w={1} mb={5} />
      <Box mb={5}>
        <ActionModal
          buttonsAlignment={ButtonsAlignmentName.RIGHT}
          error={errorSlug}
          primaryButtonProps={{
            text: 'Update password',
            type: 'submit',
            isLoading: isChangePasswordLoading || isLoading,
            disabled: !isFormValid,
          }}
          secondaryButtonProps={{
            text: 'Cancel',
            onClick: () => {
              setNewPassword('');
              setOldPassword('');
            },
          }}
        />
      </Box>
    </Form>
  );
}

export default ChangePasswordForm;
