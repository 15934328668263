import { useCallback, useState, useEffect } from 'react';

const interval =
  (delay = 0) =>
  (callback: any) =>
    useEffect(() => {
      const id = setInterval(callback, delay);

      return () => clearInterval(id);
    }, [callback]);

const useOneSecond = interval(1e3);

export const useTimer = ({
  seconds: initialSeconds = 10,
  running: initiallyRunning = false,
} = {}) => {
  const [seconds, setSeconds] = useState(initialSeconds);
  const [running, setRunning] = useState(initiallyRunning);
  const tick = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    () => (running ? setSeconds((seconds) => seconds - 1) : undefined),
    [running]
  );
  const start = () => setRunning(true);
  const pause = () => setRunning(false);
  const reset = () => setSeconds(initialSeconds);
  const stop = () => {
    pause();
    reset();
  };

  useOneSecond(tick);

  return { pause, reset, running, seconds, start, stop };
};
