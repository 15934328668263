import React, { useEffect } from 'react';
import { useTheme } from 'styled-components';

import Box from 'components/atoms/Box/Box';
import Flex from 'components/atoms/Flex/Flex';
import Icon from 'components/atoms/Icon/Icon';
import Text from 'components/atoms/Text/Text';
import { Modal } from 'components/molecules/Modal/Modal';

import { Roles, useUserByIdQuery } from 'graph/generated.graphql';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  selectRequestNewLocalizationModal,
  setModal,
} from 'redux/modal/modalSlice';
import { selectViewer } from 'redux/viewer/viewerSlice';

import RequestNewLocalizationForm from './component/RequestNewLocalizationForm';

function RequestNewLocalizationModal() {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const show = useAppSelector(selectRequestNewLocalizationModal);
  const onCloseModal = () =>
    dispatch(setModal({ showRequestLocalizationModal: false }));
  const user = useAppSelector(selectViewer);
  const { data: accountManagerData } = useUserByIdQuery({
    variables: { id: user?.company?.managerId },
    skip: user?.roles === Roles.VidicoAdmin || !show,
  });

  useEffect(() => {
    if (accountManagerData?.userById.user) {
      dispatch(
        setModal({ calendlyModalInfo: accountManagerData?.userById.user })
      );
    }
  }, [accountManagerData, dispatch]);
  return (
    <Modal show={show} onHide={onCloseModal} width={582}>
      <Flex fullWidth justifyContent="left" mb={4}>
        <Box mr={2}>
          <Icon color={theme.colors.primary600} size={28} name="eA" />
        </Box>
        <Text lg bold>
          Add Localisation
        </Text>
      </Flex>
      <Box mb={5}>
        <Text sm medium color={theme.colors.grey600}>
          Ready for a global audience? Fill in this form below and book a
          meeting with us and let’s make it happen.
        </Text>
      </Box>
      <RequestNewLocalizationForm onModalClose={onCloseModal} />
    </Modal>
  );
}

export default RequestNewLocalizationModal;
