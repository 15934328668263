import React, { FormEvent, useEffect, useMemo } from 'react';
import { Form } from 'react-bootstrap';
import { components, InputProps, NoticeProps } from 'react-select';
import styled, { useTheme } from 'styled-components';

import Box from 'components/atoms/Box/Box';
import SelectCreatable from 'components/atoms/CreatableSelect/CreatableSelect';
import Divider from 'components/atoms/Divider/Divider';
import Flex from 'components/atoms/Flex/Flex';
import FormControl from 'components/atoms/FormControl/FormControl';
import FormFeetback from 'components/atoms/FormFeetback/FormFeetback';
import { IOption } from 'components/atoms/Select/Select';
import Text from 'components/atoms/Text/Text';
import ActionModal, {
  ButtonsAlignmentName,
} from 'components/molecules/ActionModal/ActionModal';

import {
  CompanyFragment,
  Roles,
  useCompanyEditMutation,
} from 'graph/generated.graphql';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { setModal } from 'redux/modal/modalSlice';
import { selectViewer } from 'redux/viewer/viewerSlice';
import { FormErrorMessages, FormErrorTypes } from 'utils/constants/forms';
import { isCompanyNameExistError } from 'utils/errors/isGqlError';
import {
  useFormState,
  useFormValidation,
} from 'utils/hooks/useFormValidation/useFormValidation';
import { createOption } from 'utils/transformers/mappers';
import { isDomainName } from 'utils/validators/validators';

enum FormFields {
  CompanyName = 'companyName',
  ListOfDomainNames = 'listOfDomainNames',
}

function NoOptionsMessage({ children, ...props }: NoticeProps<IOption>) {
  const theme = useTheme();
  return (
    <components.NoOptionsMessage {...props}>
      <Text sm medium color={theme.colors.grey700}>
        {children}
      </Text>
    </components.NoOptionsMessage>
  );
}
const FormFieldsList = [FormFields.CompanyName, FormFields.ListOfDomainNames];
const formatCreateLabel = (inputValue: string) => `+ add "${inputValue}"`;
const noOptionsMessage = () => 'Type to add an approved domain';
const ControlWrapper = styled(Box)`
  flex: 1;
  .react-select {
    &__menu {
      border: 1px solid ${({ theme }) => theme.colors.grey300};
      box-shadow: ${({ theme }) => theme.shadow.smShadow};
      border-radius: 8px;
      padding: 0;
    }
  }
`;
function Input(props: InputProps<IOption, true>) {
  return <components.Input {...props} maxLength={63} />;
}
function TeamManagementForm({
  company,
  refetch,
}: {
  company: Partial<CompanyFragment>;
  refetch: () => void;
}) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const initialFormValue = {
    [FormFields.CompanyName]: company.name || '',
  };
  const isWorkspaceOwner = useAppSelector(selectViewer)?.roles === Roles.Owner;

  const initialDomainNames = useMemo(
    () => company.domainNames?.map(createOption) || [],
    [company]
  );
  const [selectValue, setSelectValue] =
    React.useState<readonly IOption[]>(initialDomainNames);

  const [companyEdit, { loading: isCompanyEditLoading }] =
    useCompanyEditMutation({
      onCompleted() {
        formOnSuccess();
        refetch();
      },
      onError(error) {
        error.graphQLErrors.forEach((gqlError) => {
          if (isCompanyNameExistError(gqlError)) {
            formOnError();
            return setCompanyNameError({
              type: FormErrorTypes.Invalid,
              message: FormErrorMessages.COMPANY_NAME_NOT_UNIQUE,
            });
          }
          return formOnError(gqlError.message);
        });
      },
    });

  const [
    formErrors,
    isFormValid,
    [setCompanyNameError, setListOfDomainNamesError],
  ] = useFormValidation(FormFieldsList);

  const [
    formState,
    [setCompanyName],
    [formOnSubmit, formOnError, formOnSuccess],
  ] = useFormState(FormFieldsList, initialFormValue);

  const validCompany = (value: string) => {
    if (!value) {
      setCompanyNameError({
        type: FormErrorTypes.Invalid,
        message: FormErrorMessages.COMPANY_BLANK,
      });
      return false;
    }
    if (value.length > 50) {
      setCompanyNameError({
        type: FormErrorTypes.Invalid,
        message: FormErrorMessages.MAX_50_CHARACTERS,
      });
      return false;
    }
    setCompanyNameError(null);
    return true;
  };
  const validInput = (value: string) => {
    if (!isDomainName(value)) {
      dispatch(
        setModal({
          showPopupMessage: true,
          popupMessageInfo: { text: 'Must be a valid domain' },
        })
      );
      return false;
    }
    return true;
  };

  const validListOfDomainNames = (value: IOption[]) => {
    if (!value.length) {
      setListOfDomainNamesError({
        type: FormErrorTypes.Invalid,
        message: FormErrorMessages.LIST_OF_DOMAIN_NAMES_BLACK,
      });
      return false;
    }
    if (value.length > 3) {
      setListOfDomainNamesError({
        type: FormErrorTypes.Invalid,
        message: FormErrorMessages.LIST_OF_DOMAIN_NAMES_MAX_COUNT,
      });
      return false;
    }
    const errorList: string[] = [];
    value.forEach((option) => {
      if (!isDomainName(option.value)) {
        errorList.push(option.value);
      }
    });
    if (errorList.length) {
      setListOfDomainNamesError({
        type: FormErrorTypes.Invalid,
        message: `${errorList.join(', ')} is not valid domain name`,
      });
      return false;
    }

    setListOfDomainNamesError(null);
    return true;
  };

  const onCompanyTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyName(e.target.value);
    setCompanyNameError(null);
  };
  const onListOfDomainNamesChange = (newValue: any) => {
    validListOfDomainNames(newValue);
  };
  useEffect(() => {
    onListOfDomainNamesChange(selectValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectValue]);

  const domainNames = useMemo(
    () => selectValue.map((options) => options.value),
    [selectValue]
  );
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const { companyName } = formState;
    if (!validCompany(companyName)) {
      return null;
    }
    companyEdit({
      variables: { companyId: company.id, companyName, domainNames },
    });
    formOnSubmit();
    return null;
  };
  const isChangeMade = useMemo(
    () =>
      company.name !== formState.companyName ||
      JSON.stringify(company.domainNames) !== JSON.stringify(domainNames),
    [company, domainNames, formState]
  );
  return (
    <Form onSubmit={handleSubmit} className="w-100">
      <Form.Group className="w-100" controlId="validationCustom01">
        <Flex mb={5}>
          <Box w={280} mr={8}>
            <Form.Label>
              <Text sm medium color={theme.colors.grey700}>
                Workspace name
              </Text>
              <Text sm regular color={theme.colors.grey500}>
                Change the name of your workspace. Only Workspace Owner can
                change this.
              </Text>
            </Form.Label>
          </Box>
          <ControlWrapper>
            <FormControl
              onChange={onCompanyTextChange}
              value={formState.companyName}
              placeholder="Workspace Owner’s company"
              disabled={!isWorkspaceOwner}
            />
            <FormFeetback type={formErrors[FormFields.CompanyName]?.type}>
              {formErrors[FormFields.CompanyName]?.message}
            </FormFeetback>
          </ControlWrapper>
        </Flex>
      </Form.Group>
      <Divider fullWidth w={1} mb={5} />
      <Form.Group className="w-100" controlId="validationCustom01">
        <Flex>
          <Box w={280} mr={8} mb={5}>
            <Form.Label>
              <Text sm medium color={theme.colors.grey700}>
                Automatically approved domains
              </Text>
              <Text sm regular color={theme.colors.grey500}>
                Anyone with email addresses at these domains can automatically
                join your workspace. Only Workspace Owner can change this.
              </Text>
            </Form.Label>
          </Box>
          <ControlWrapper>
            <SelectCreatable
              components={{
                DropdownIndicator: null,
                ClearIndicator: undefined,
                Input,
                NoOptionsMessage,
              }}
              inputValidation={validInput}
              noOptionsMessage={noOptionsMessage}
              formatCreateLabel={formatCreateLabel}
              isValidNewOption={(value) => isDomainName(value)}
              isDisabled={!isWorkspaceOwner}
              onStateChange={setSelectValue}
              onChange={(newValue: any) => setSelectValue(newValue)}
              value={selectValue}
              placeholder="Type to add an approved domain"
            />
            <FormFeetback type={formErrors[FormFields.ListOfDomainNames]?.type}>
              {formErrors[FormFields.ListOfDomainNames]?.message}
            </FormFeetback>
          </ControlWrapper>
        </Flex>
      </Form.Group>
      {isChangeMade && (
        <Box mb={5}>
          <ActionModal
            buttonsAlignment={ButtonsAlignmentName.RIGHT}
            error={formState.errorSlug}
            primaryButtonProps={{
              text: 'Save changes',
              type: 'submit',
              isLoading: isCompanyEditLoading,
              disabled: !isFormValid,
            }}
            secondaryButtonProps={{
              text: 'Cancel',
              onClick: () => {
                setSelectValue(initialDomainNames);
                setCompanyName(company.name);
              },
            }}
          />
        </Box>
      )}
      <Divider fullWidth w={1} mb={5} />
    </Form>
  );
}

export default TeamManagementForm;
