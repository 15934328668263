import exifr from 'exifr';

export const getImageMeta = async (
  file: File
): Promise<{
  maxWidth: number;
  maxHeight: number;
}> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = async (e: any) => {
      try {
        const image = new Image();
        image.src = e.target.result;
        await image.decode();

        resolve({
          maxWidth: image.width,
          maxHeight: image.height,
        });
      } catch (error) {
        reject(error);
      }
    };
    reader.readAsDataURL(file);
  });

export const getImageWidthHeight = async (file: File) => {
  try {
    const res = await getImageMeta(file);
    return res;
  } catch (error) {
    return null;
  }
};

export const getExifData = async (file: File) => {
  try {
    const res = await exifr.parse(file, true);
    return res;
  } catch (error) {
    return null;
  }
};
export const getVideoBitrate = async (
  file: File
): Promise<{ bitRate: number }> =>
  new Promise((resolve, reject) => {
    const video = document.createElement('video');
    video.src = URL.createObjectURL(file);
    video.addEventListener('canplay', () => {
      const fileSizeInBits = file.size * 8;
      const durationInSeconds = video.duration;
      const bitRate = Math.round(fileSizeInBits / durationInSeconds / 1024);
      resolve({ bitRate });
      video.remove();
    });
    video.addEventListener('error', (error) => {
      reject(error);
      video.remove();
    });
  });
