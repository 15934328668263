import React, { useState } from 'react';
import { useTheme } from 'styled-components';

import Box from 'components/atoms/Box/Box';
import Flex from 'components/atoms/Flex/Flex';
import FormControl from 'components/atoms/FormControl/FormControl';
import Text from 'components/atoms/Text/Text';
import ActionModal, {
  ButtonsAlignmentName,
} from 'components/molecules/ActionModal/ActionModal';
import { Modal } from 'components/molecules/Modal/Modal';

import {
  DeleteReasons,
  useAccountDeleteMutation,
} from 'graph/generated.graphql';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectDeleteAccountModal, setModal } from 'redux/modal/modalSlice';
import { FormErrorMessages } from 'utils/constants/forms';
import { isOwnerTryDeleteHisAccountError } from 'utils/errors/isGqlError';

function DeleteAccountModal() {
  const [errorMessage, setErrorMessage] = useState('');
  const [inputValue, setInputValue] = useState('');
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const show = useAppSelector(selectDeleteAccountModal);
  const onCloseModal = () => {
    dispatch(
      setModal({
        showDeleteAccountModal: false,
      })
    );
    setErrorMessage('');
    setInputValue('');
  };

  const [deleteMember, { loading: isDeleteMemberLoading }] =
    useAccountDeleteMutation({
      onCompleted() {
        dispatch(
          setModal({
            showDeleteAccountModal: false,
            showLogOutDeletedAccountModal: true,
          })
        );
      },
      onError(error) {
        error.graphQLErrors.forEach((gqlError) => {
          if (isOwnerTryDeleteHisAccountError(gqlError)) {
            return setErrorMessage(
              FormErrorMessages.OWNER_TRY_DELETE_HIS_ACCOUNT
            );
          }
          return setErrorMessage(gqlError.message);
        });
      },
    });
  const handleButtonClick = () => {
    deleteMember({
      variables: { reason: DeleteReasons.DontNeed },
    });
  };
  return (
    <Modal show={show} onHide={onCloseModal} width={420}>
      <Flex fullWidth justifyContent="left" flexDirection="column" mb={5}>
        <Box mb={5}>
          <Text lg bold>
            We’re sorry to see you leaving
          </Text>
        </Box>
        <Text sm medium color={theme.colors.grey600}>
          Account deletion is final. There will be no way to restore your
          account. <br />
          <br />
          <b>Caution:</b> This will revoke your access to the workspace and log
          you out.
        </Text>
      </Flex>
      <Box fullWidth mb={5}>
        <FormControl
          fullWidth
          placeholder="Enter DELETE to delete account"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
        />
      </Box>
      <ActionModal
        primaryButtonProps={{
          text: 'Delete Account',
          type: 'button',
          isLoading: isDeleteMemberLoading,
          disabled: !(inputValue === 'DELETE'),
          onClick: handleButtonClick,
        }}
        secondaryButtonProps={{ text: 'Keep account', onClick: onCloseModal }}
        buttonsAlignment={ButtonsAlignmentName.RIGHT}
        error={errorMessage}
      />
    </Modal>
  );
}

export default DeleteAccountModal;
