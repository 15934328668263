/* eslint-disable no-useless-escape */
export const EMAIL_EXP =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export const STRONG_PASS_EXP =
  /^^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;

export const HAS_NUMBER_OR_SYMBOL = /[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;

export const HAS_LOWERCASE = /[a-z]/;

export const HAS_UPPERCASE = /[A-Z]/;

export const DOMAIN_EXP =
  /^(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export const WHITE_SPACES_EXP = /\s/;
export const IS_GUID_EXP =
  /^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/;
