import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

import AssetCard from 'components/atoms/AssetCard/AssetCard';
import Box from 'components/atoms/Box/Box';
import Button from 'components/atoms/Button/Button';
import Flex from 'components/atoms/Flex/Flex';
import Icon from 'components/atoms/Icon/Icon';
import List from 'components/atoms/List/List';
import ListItem from 'components/atoms/ListItem/ListItem';
import Spinner from 'components/atoms/Spinner/Spinner';
import Text from 'components/atoms/Text/Text';
import NotFoundShared from 'components/molecules/NotFoundShared/NotFoundShared';
import ShareContext from 'components/molecules/ShareContext/ShareContext';

import Header from 'containers/Layout/components/Header/Header';
import {
  Localizations,
  useGetSharedAssetsListMutation,
  useOnAssetsChangeSubscription,
} from 'graph/generated.graphql';
import { getSignUpUrl } from 'utils/authentication/getRedirectUrl/getRedirectUrl';
import { RoutesPath } from 'utils/constants/routes';
import { isSharedNotExist } from 'utils/errors/isGqlError';
import { buildLink } from 'utils/helpers/route';
import {
  getIconNameByFileType,
  getThumbnailIconByFileType,
} from 'utils/helpers/workspace';
import { device } from 'utils/styles/device';

import ReferralRewardContent from '../../components/molecules/ReferralRewardContent/ReferralRewardContent';
import ReferralRewardPopUp from '../../components/molecules/ReferralRewardPopUp/ReferralRewardPopUp';

const MainStyled = styled.main`
  @media ${device.tablet} {
    min-height: calc(100vh - 200px);
  }
  min-height: calc(100vh - 150px);
`;
const FooterText = styled(Text)`
  text-align: center;
`;
const Footer = styled.footer`
  border-top: 1px solid ${({ theme }) => theme.colors.grey400};
`;
function SharedAssetsPage() {
  const theme = useTheme();
  const [showNotFound, setShowNotFound] = useState(false);
  const { key = '' } = useParams();
  const [getSharedAssetsList, { data, loading: isLoading }] =
    useGetSharedAssetsListMutation({
      onError(error) {
        error.graphQLErrors.forEach((gqlError) => {
          if (isSharedNotExist(gqlError)) {
            return setShowNotFound(true);
          }
          return null;
        });
      },
      onCompleted({ sharedLink }) {
        if (sharedLink.assets?.length === 0) {
          return setShowNotFound(true);
        }
        return null;
      },
    });
  useEffect(() => {
    getSharedAssetsList({ variables: { key } });
  }, [getSharedAssetsList, key]);

  const [openReferralPopUp, setOpenReferralPopUp] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setOpenReferralPopUp(true);
    }, 5000);
  }, []);
  const handleCloseReferralReward = useCallback(() => {
    setOpenReferralPopUp(false);
  }, []);

  const assets = useMemo(() => data?.sharedLink.assets || [], [data]);
  const navigate = useNavigate();
  const size = useMemo(
    () => assets.reduce((acc, asset) => acc + asset.size, 0),
    [assets]
  );
  const { projectId, localization } = assets.length
    ? assets[0]
    : { projectId: '', localization: Localizations.AuEnglish };
  useOnAssetsChangeSubscription({
    variables: {
      projectId,
      localization,
    },
    shouldResubscribe: true,
    skip: !assets.length,
  });
  const assetsId = assets.map((asset) => asset.id);
  const assetsTotalCount = assets.length;
  const url = getSignUpUrl();
  return (
    <>
      <Header assetsInfo={{ assetsTotalCount, size, assetsId }} />
      <MainStyled>
        {isLoading ? (
          <Flex
            h="calc(100vh - 150px)"
            justifyContent="center"
            alignItems="center"
          >
            <Spinner size={54} color={theme.colors.grey400} />
          </Flex>
        ) : showNotFound ? (
          <NotFoundShared />
        ) : (
          <>
            {!!assets.length && (
              <ShareContext
                projectName={assets[0].projectName}
                localization={assets[0].localization}
              />
            )}
            <List px={8} py={2}>
              {assets.map((asset) => (
                <ListItem key={asset.id}>
                  <AssetCard
                    asset={asset}
                    textIcon={getIconNameByFileType(asset.type)}
                    thumbnailIcon={getThumbnailIconByFileType(asset.type)}
                    onDoubleClick={() =>
                      navigate(
                        buildLink(RoutesPath.ViewSharedAssetById, {
                          key,
                          assetId: asset.id,
                        }),
                        {
                          state: { data: asset },
                        }
                      )
                    }
                  />
                </ListItem>
              ))}
            </List>
          </>
        )}
      </MainStyled>
      <ReferralRewardPopUp
        isOpen={openReferralPopUp}
        callBack={handleCloseReferralReward}
      >
        <ReferralRewardContent />
      </ReferralRewardPopUp>
      <Footer>
        <Flex
          flexWrap="wrap"
          py={3}
          px={4}
          justifyContent="center"
          alignItems="center"
        >
          <Icon size={48} name="logo" color={theme.colors.grey400} />
          <Box py={3} mx={12}>
            <FooterText md medium color={theme.colors.grey400}>
              Power up, share and download your assets with the VideoStore
            </FooterText>
          </Box>
          <Button
            px={4}
            py={3}
            secondary
            color={theme.colors.grey400}
            onClick={() => window.open(url, '_blank')}
          >
            <Text md semibold color={theme.colors.grey400}>
              Create your account
            </Text>
          </Button>
        </Flex>
      </Footer>
    </>
  );
}

export default SharedAssetsPage;
