import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IOption } from 'components/atoms/Select/Select';

import {
  AssetFilterInput,
  AssetSortInput,
  QueryAssetsListArgs,
  SortEnumType,
} from 'graph/generated.graphql';

import { RootState } from '../store';

// Define a type for the slice state
interface IFilterState {
  typeFilter?: AssetFilterInput['type'];
  localizationFilter?: AssetFilterInput['localization'];
  selectedProjectOption?: IOption[] | undefined;
  isSearchActive?: boolean;
  searchFilterState?: QueryAssetsListArgs['where'];
  searchOrderFilterState?: AssetSortInput;
  isFavorite?: boolean;
}

// Define the initial state using that type
const initialState: IFilterState = {
  typeFilter: { eq: undefined },
  selectedProjectOption: undefined,
  localizationFilter: undefined,
  isSearchActive: false,
  searchFilterState: undefined,
  searchOrderFilterState: { createdAt: SortEnumType.Asc },
  isFavorite: false,
};

const filterSlice = createSlice({
  name: 'filter',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setTypeFilter: (
      state,
      action: PayloadAction<IFilterState['typeFilter'] | null>
    ) => {
      // eslint-disable-next-line no-param-reassign
      state.typeFilter = action.payload
        ? action.payload
        : initialState.typeFilter;
    },
    setLocalizationFilter: (
      state,
      action: PayloadAction<IFilterState['localizationFilter'] | null>
    ) => {
      // eslint-disable-next-line no-param-reassign
      state.localizationFilter = action.payload
        ? action.payload
        : initialState.localizationFilter;
    },
    setIsSearchActive: (
      state,
      action: PayloadAction<IFilterState['isSearchActive'] | null>
    ) => Object.assign(state, { isSearchActive: action.payload }),

    setSearchFilterState: (
      state,
      action: PayloadAction<IFilterState['searchFilterState'] | null>
    ) =>
      Object.assign(state, {
        searchFilterState: { ...action.payload, dealeted: { eq: false } },
      }),

    setIsFavorite: (
      state,
      action: PayloadAction<IFilterState['isFavorite'] | null>
    ) => Object.assign(state, { isFavorite: action.payload }),

    setSearchOrderFilterState: (
      state,
      action: PayloadAction<IFilterState['searchOrderFilterState'] | null>
    ) => Object.assign(state, { searchOrderFilterState: action.payload }),
    setSelectedProjectOption: (
      state,
      action: PayloadAction<IFilterState['selectedProjectOption'] | null>
    ) => Object.assign(state, { selectedProjectOption: action.payload }),
    reset: (state) => ({
      ...initialState,
      typeFilter: state.typeFilter,
      localizationFilter: state.localizationFilter,
    }),
  },
});

export const {
  setTypeFilter,
  setLocalizationFilter,
  setIsSearchActive,
  setSearchFilterState,
  setIsFavorite,
  setSearchOrderFilterState,
  setSelectedProjectOption,
  reset,
} = filterSlice.actions;

// selectors
export const selectFilterByType = (state: RootState) => state.filter.typeFilter;
export const selectFilterByLocalization = (state: RootState) =>
  state.filter.localizationFilter;
export const selectIsSearchActive = (state: RootState) =>
  state.filter.isSearchActive;
export const selectSearchFilterState = (state: RootState) =>
  state.filter.searchFilterState;
export const selectIsFavorite = (state: RootState) => state.filter.isFavorite;
export const selectSearchOrderFilterState = (state: RootState) =>
  state.filter.searchOrderFilterState;
export const selectSelectedProjectOption = (state: RootState) =>
  state.filter.selectedProjectOption;

export default filterSlice.reducer;
