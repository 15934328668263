import React from 'react';
import styled, { useTheme } from 'styled-components';

import Box from 'components/atoms/Box/Box';
import Flex from 'components/atoms/Flex/Flex';
import Icon from 'components/atoms/Icon/Icon';
import Text from 'components/atoms/Text/Text';
import ActionModal, {
  ButtonsAlignmentName,
} from 'components/molecules/ActionModal/ActionModal';
import { Modal } from 'components/molecules/Modal/Modal';

import { useLogOutMutation } from 'graph/generated.graphql';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  selectLogOutDeletedAccountModal,
  setModal,
} from 'redux/modal/modalSlice';
import { getSignUpUrl } from 'utils/authentication/getRedirectUrl/getRedirectUrl';
import { useTimer } from 'utils/hooks/useTimer/useTimer';

const IconWrapper = styled(Flex)`
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.success100};
`;
function LogOutDeletedAccountModal() {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const show = useAppSelector(selectLogOutDeletedAccountModal);
  const onLogOut = () => {
    window.location.assign(getSignUpUrl());
  };
  const [logout] = useLogOutMutation({
    onCompleted() {
      onLogOut();
    },
    onError() {
      onLogOut();
    },
  });

  const { seconds, start, pause } = useTimer();
  const handleLogOut = () => {
    logout();
    onCloseModal();
  };
  const onCloseModal = () => {
    pause();
    setTimeout(() => {
      dispatch(setModal({ showLogOutModal: false }));
    }, 100);
  };

  if (seconds < 1) {
    handleLogOut();
  }
  return (
    <Modal show={show} onEntered={start} onHide={handleLogOut} width={420}>
      <Flex
        fullWidth
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap={8}
        mb={14}
      >
        <IconWrapper w={32} h={32} justifyContent="center" alignItems="center">
          <Icon color={theme.colors.success600} size={16} name="checkCircle" />
        </IconWrapper>
        <Text lg bold>
          Your account is deleted
        </Text>
      </Flex>
      <Box mb={14}>
        <Text lg regular color={theme.colors.grey600} textAlign="center">
          Thank you for using our product! We look forward to seeing you again.
        </Text>
      </Box>
      <ActionModal
        buttonsAlignment={ButtonsAlignmentName.RIGHT}
        primaryButtonProps={{
          text: `Log out (${seconds}s)`,
          type: 'button',
          onClick: handleLogOut,
        }}
      />
    </Modal>
  );
}

export default LogOutDeletedAccountModal;
