import styled from 'styled-components';

import { flexStyle } from '../Flex/Flex';
import { IFlexProps } from '../Flex/Flex.interfaces';

const FlexList = styled.ul<IFlexProps>`
  ${flexStyle}
`;

export default FlexList;
