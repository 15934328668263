import React, { MouseEventHandler } from 'react';

import Button from 'components/atoms/Button/Button';
import Flex from 'components/atoms/Flex/Flex';
import FormFeetback from 'components/atoms/FormFeetback/FormFeetback';

import { FormErrorTypes } from '../../../utils/constants/forms';

export enum ButtonsAlignmentName {
  RIGHT = 'flex-end',
  CENTER = 'center',
}
export type ActionButtonProps = {
  primaryButtonProps?: {
    text: string;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    isLoading?: boolean;
    type?: 'submit' | 'reset' | 'button';
    disabled?: boolean;
    fullWidth?: boolean;
  };
  secondaryButtonProps?: {
    text: string;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    fullWidth?: boolean;
  };
  buttonsAlignment?: ButtonsAlignmentName;
  error?: string;
};

function ActionModal({
  primaryButtonProps,
  secondaryButtonProps,
  buttonsAlignment,
  error,
}: ActionButtonProps) {
  return (
    <Flex fullWidth flexDirection="column" alignItems={buttonsAlignment}>
      <Flex fullWidth justifyContent={buttonsAlignment}>
        {secondaryButtonProps && (
          <Button
            secondary
            py={2}
            px={4}
            mr={2}
            md
            semibold
            onClick={secondaryButtonProps.onClick}
            type="button"
            fullWidth={secondaryButtonProps.fullWidth}
          >
            {secondaryButtonProps.text}
          </Button>
        )}
        {primaryButtonProps && (
          <Button
            py={2}
            px={4}
            ml={2}
            md
            semibold
            onClick={primaryButtonProps.onClick}
            isLoading={primaryButtonProps.isLoading}
            type={primaryButtonProps.type}
            disabled={primaryButtonProps.disabled}
            fullWidth={primaryButtonProps.fullWidth}
          >
            {primaryButtonProps.text}
          </Button>
        )}
      </Flex>
      {error && (
        <Flex justifyContent={buttonsAlignment}>
          <FormFeetback type={FormErrorTypes.Invalid}>{error}</FormFeetback>
        </Flex>
      )}
    </Flex>
  );
}

export default ActionModal;
