import React, { useCallback } from 'react';
import { useTheme } from 'styled-components';

import Box from 'components/atoms/Box/Box';
import Button from 'components/atoms/Button/Button';
import Flex from 'components/atoms/Flex/Flex';
import Icon from 'components/atoms/Icon/Icon';
import ShareInput from 'components/atoms/ShareInput/ShareInput';
import Spinner from 'components/atoms/Spinner/Spinner';
import Text from 'components/atoms/Text/Text';
import { Modal } from 'components/molecules/Modal/Modal';

import {
  Roles,
  SharedLinkStatus,
  useCreateSharedLinkMutation,
  useDeleteSharedLinkMutation,
} from 'graph/generated.graphql';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  selectIsNew,
  selectShareLinkInfo,
  setModal,
} from 'redux/modal/modalSlice';
import { selectViewer } from 'redux/viewer/viewerSlice';

function ShareLinkModal() {
  const theme = useTheme();
  const user = useAppSelector(selectViewer);
  const { show, data } = useAppSelector(selectShareLinkInfo);
  const isNew = useAppSelector(selectIsNew);
  const dispatch = useAppDispatch();

  const [
    createSharedLink,
    { data: sharedLinkData, loading: isCreateSharedLinkLoading },
  ] = useCreateSharedLinkMutation();
  const [deleteSharedLink, { loading: isDeleteSharedLinkLoading }] =
    useDeleteSharedLinkMutation({
      onCompleted() {
        dispatch(
          setModal({
            showSharedLinkModal: false,
          })
        );
      },
    });
  const sharedLink = sharedLinkData?.createSharedLink.sharedLink;

  const onCloseModal = () => {
    if (sharedLink?.status === SharedLinkStatus.New && isNew) {
      return deleteSharedLink({ variables: { linkId: sharedLink.id } });
    }
    return dispatch(
      setModal({
        showSharedLinkModal: false,
        isNew: true,
      })
    );
  };

  const getData = useCallback(() => {
    createSharedLink({ variables: data });
  }, [createSharedLink, data]);

  return (
    <Modal show={show} onHide={onCloseModal} onEntered={getData} width={490}>
      {isCreateSharedLinkLoading || isDeleteSharedLinkLoading ? (
        <Spinner />
      ) : (
        <>
          <Flex fullWidth justifyContent="left" mb={4}>
            <Icon
              color={theme.colors.primary600}
              size={28}
              name="iosShare"
              mr={2}
            />

            <Text lg bold>
              Share link
            </Text>
          </Flex>
          <Flex fullWidth justifyContent="left" flexDirection="column">
            {sharedLink?.status === SharedLinkStatus.New && isNew && (
              <Flex fullWidth justifyContent="space-between">
                <Flex alignItems="center">
                  <Icon name="link02" size={16} mr={1} />
                  <Text sm medium>
                    No shareable link
                  </Text>
                </Flex>
                <Button
                  py={2}
                  px={4}
                  onClick={() =>
                    dispatch(
                      setModal({
                        isNew: false,
                      })
                    )
                  }
                >
                  Create link
                </Button>
              </Flex>
            )}
            {(!isNew || sharedLink?.status === SharedLinkStatus.Used) && (
              <>
                <Box mb={5}>
                  <Text sm medium color={theme.colors.grey600}>
                    Anyone with this link <b>can view</b>.
                  </Text>
                </Box>
                <Box fullWidth mb={2}>
                  <ShareInput
                    linkId={sharedLink?.id}
                    value={sharedLink?.link}
                  />
                </Box>
              </>
            )}
            {user?.roles !== Roles.VidicoAdmin && (
              <Flex>
                <Text xs medium>
                  Want to
                </Text>
                <Button
                  noStyle
                  xs
                  medium
                  color={theme.colors.primary600}
                  onClick={() => {
                    dispatch(setModal({ showAddMemberModal: true }));
                    onCloseModal();
                  }}
                >
                  share
                </Button>
                <Text xs medium>
                  this workspace?
                </Text>
              </Flex>
            )}
          </Flex>
        </>
      )}
    </Modal>
  );
}

export default ShareLinkModal;
