import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  AssetDownloadMutation,
  AssetFragment,
  ChangeRoleMutationVariables,
  CreateSharedLinkMutationVariables,
  EditProjectMutationVariables,
  Localizations,
  ProjectBaseFragment,
  ProjectCreateMutationVariables,
  ProjectFragment,
  UserBaseFragment,
  UserDeleteMutationVariables,
} from 'graph/generated.graphql';

import { VideoSizeType } from '../../utils/constants/video-size-type';
import { RootState } from '../store';

// Define a type for the slice state
interface IModalState {
  showLogOutModal?: boolean;
  showAddWorkspaceModal?: boolean;
  showAddProjectModal?: boolean;
  showEditWorkspaceModal?: boolean;
  showViewMetaDataInfo?: boolean;
  showSharedLinkModal?: boolean;
  showVideoTypeSelectionModal?: boolean;
  videoTypeSize?: VideoSizeType;
  videoTypeFileToDownload?: AssetDownloadMutation['assetDownload']['assets'];
  sharedLinkData?: CreateSharedLinkMutationVariables;
  showSharedLinkDeleteModal?: boolean;
  showSharedLinkGenerateModal?: boolean;
  linkId?: string;
  isNew?: boolean;
  showRemoveAssetsModal?: boolean;
  assetsToRemove?: Partial<AssetFragment>[];
  showRequestNewProjectModal?: boolean;
  requestNewProjectModalInfo?: ProjectCreateMutationVariables;
  showCalendlyModal?: boolean;
  calendlyModalInfo?: Partial<UserBaseFragment>;
  showRequestNewProjectFinalModal?: boolean;
  showRequestLocalizationModal?: boolean;
  requestLocalizationModalInfo?: EditProjectMutationVariables;
  selectedProject?: Partial<ProjectFragment>;
  showRequestLocalizationFinalModal?: boolean;
  showAddMemberModal?: boolean;
  changeAccessLevelInfo?: ChangeRoleMutationVariables;
  showChangeAccessLevelModal?: boolean;
  showDeleteMemberModal?: boolean;
  deleteMemberInfo?: { userName: string; info: UserDeleteMutationVariables };
  showDeleteAccountModal?: boolean;
  showLogOutDeletedAccountModal?: boolean;
  showRenameProjectModal?: boolean;
  renameProjectInfo?: Partial<ProjectFragment>;
  showRemoveProjectModal?: boolean;
  removeProjectInfo?: Partial<ProjectFragment>;
  showRenameAssetModal?: boolean;
  renameAssetInfo?: Partial<AssetFragment>;
  showPopupMessage?: boolean;
  popupMessageInfo?: { text: string; countOfSelected?: number };
  isEditProjectMode?: boolean;
  isAddLocalizationMode?: boolean;
  localizationToAdd?: Localizations | undefined;
  projectToEdit?: ProjectBaseFragment;
  removePopupInfo?: {
    showRemovePopup?: boolean;
    isProjectRemoved?: boolean;
  };
  fileToDownload?: AssetDownloadMutation['assetDownload']['assets'];
  showDownloadCancelModal?: boolean;
}

// Define the initial state using that type
const initialState: IModalState = {
  showLogOutModal: false,
  showAddWorkspaceModal: false,
  showAddProjectModal: false,
  showEditWorkspaceModal: false,
  showViewMetaDataInfo: false,
  showSharedLinkModal: false,
  showVideoTypeSelectionModal: false,
  videoTypeSize: null,
  videoTypeFileToDownload: null,
  showSharedLinkDeleteModal: false,
  isNew: true,
  showSharedLinkGenerateModal: false,
  sharedLinkData: {
    projectId: null,
    assetIds: [],
    isProject: false,
  },
  linkId: '',
  showRemoveAssetsModal: false,
  assetsToRemove: [],
  showRequestNewProjectModal: false,
  requestNewProjectModalInfo: undefined,
  showCalendlyModal: false,
  calendlyModalInfo: {},
  showRequestNewProjectFinalModal: false,
  showRequestLocalizationModal: false,
  requestLocalizationModalInfo: undefined,
  selectedProject: {},
  showRequestLocalizationFinalModal: false,
  showAddMemberModal: false,
  showChangeAccessLevelModal: false,
  changeAccessLevelInfo: undefined,
  showDeleteMemberModal: false,
  deleteMemberInfo: undefined,
  showDeleteAccountModal: false,
  showLogOutDeletedAccountModal: false,
  showRenameProjectModal: false,
  renameProjectInfo: undefined,
  showRemoveProjectModal: false,
  removeProjectInfo: undefined,
  showRenameAssetModal: false,
  renameAssetInfo: undefined,
  popupMessageInfo: { text: '' },
  showPopupMessage: false,
  isEditProjectMode: false,
  projectToEdit: undefined,
  isAddLocalizationMode: false,
  localizationToAdd: undefined,
  removePopupInfo: undefined,
  fileToDownload: undefined,
  showDownloadCancelModal: false,
};

const modalSlice = createSlice({
  name: 'modal',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setModal: (state, action: PayloadAction<IModalState | null>) =>
      Object.assign(state, action.payload),
  },
});

export const { setModal } = modalSlice.actions;

// selectors
export const selectLogoutModal = (state: RootState) =>
  state.modal.showLogOutModal;
export const selectAddWorkspaceModal = (state: RootState) =>
  state.modal.showAddWorkspaceModal;
export const selectAddProjectModal = (state: RootState) =>
  state.modal.showAddProjectModal;
export const selectEditWorkspaceModal = (state: RootState) =>
  state.modal.showEditWorkspaceModal;
export const selectViewMetaDataInfo = (state: RootState) =>
  state.modal.showViewMetaDataInfo;
export const selectShareLinkInfo = (
  state: RootState
): {
  show: IModalState['showSharedLinkModal'];
  data: IModalState['sharedLinkData'];
} => ({
  show: state.modal.showSharedLinkModal,
  data: state.modal.sharedLinkData,
});
export const selectVideoTypeSelectionModal = (state: RootState) =>
  state.modal.showVideoTypeSelectionModal;
export const selectVideoTypeSize = (state: RootState) =>
  state.modal.videoTypeSize;
export const selectVideoTypeFileToDownload = (state: RootState) =>
  state.modal.videoTypeFileToDownload;
export const selectSharedLinkDeleteModal = (state: RootState) =>
  state.modal.showSharedLinkDeleteModal;
export const selectSharedLinkGenerateModal = (state: RootState) =>
  state.modal.showSharedLinkGenerateModal;
export const selectLinkId = (state: RootState) => state.modal.linkId;
export const selectRemoveAssetsModal = (state: RootState) =>
  state.modal.showRemoveAssetsModal;
export const selectAssetsToRemove = (state: RootState) =>
  state.modal.assetsToRemove;
export const selectRequestNewProjectModal = (state: RootState) =>
  state.modal.showRequestNewProjectModal;
export const selectRequestNewProjectModalInfo = (state: RootState) =>
  state.modal.requestNewProjectModalInfo;
export const selectCalendlyModal = (state: RootState) =>
  state.modal.showCalendlyModal;
export const selectCalendlyModalInfo = (state: RootState) =>
  state.modal.calendlyModalInfo;
export const selectRequestNewProjectFinalModal = (state: RootState) =>
  state.modal.showRequestNewProjectFinalModal;
export const selectRequestNewLocalizationModal = (state: RootState) =>
  state.modal.showRequestLocalizationModal;
export const selectRequestNewLocalizationInfo = (state: RootState) =>
  state.modal.requestLocalizationModalInfo;
export const selectRequestNewLocalizationFinalModal = (state: RootState) =>
  state.modal.showRequestLocalizationFinalModal;
export const selectSelectedProject = (state: RootState) =>
  state.modal.selectedProject;
export const selectAddMemberModal = (state: RootState) =>
  state.modal.showAddMemberModal;
export const selectChangeAccessLevelModal = (state: RootState) =>
  state.modal.showChangeAccessLevelModal;
export const selectChangeAccessLevelInfo = (state: RootState) =>
  state.modal.changeAccessLevelInfo;
export const selectDeleteMemberInfo = (state: RootState) =>
  state.modal.deleteMemberInfo;
export const selectDeleteMemberModal = (state: RootState) =>
  state.modal.showDeleteMemberModal;
export const selectDeleteAccountModal = (state: RootState) =>
  state.modal.showDeleteAccountModal;
export const selectLogOutDeletedAccountModal = (state: RootState) =>
  state.modal.showLogOutDeletedAccountModal;
export const selectRenameProjectModal = (state: RootState) =>
  state.modal.showRenameProjectModal;
export const selectRenameProjectInfo = (state: RootState) =>
  state.modal.renameProjectInfo;
export const selectRemoveProjectModal = (state: RootState) =>
  state.modal.showRemoveProjectModal;
export const selectRemoveProjectInfo = (state: RootState) =>
  state.modal.removeProjectInfo;
export const selectRenameAssetModal = (state: RootState) =>
  state.modal.showRenameAssetModal;
export const selectRenameAssetInfo = (state: RootState) =>
  state.modal.renameAssetInfo;
export const selectIsNew = (state: RootState) => state.modal.isNew;
export const selectShowPopupMessage = (state: RootState) =>
  state.modal.showPopupMessage;
export const selectPopupMessageInfo = (state: RootState) =>
  state.modal.popupMessageInfo;
export const selectIsEditProjectMode = (state: RootState) =>
  state.modal.isEditProjectMode;
export const selectProjectToEdit = (state: RootState) =>
  state.modal.projectToEdit;
export const selectRemovePopupInfo = (state: RootState) =>
  state.modal.removePopupInfo;
export const selectIsAddLocalizationMode = (state: RootState) =>
  state.modal.isAddLocalizationMode;
export const selectLocalizationToAdd = (state: RootState) =>
  state.modal.localizationToAdd;
export const selectFileToDownload = (state: RootState) =>
  state.modal.fileToDownload;
export const selectShowDownloadCancelModal = (state: RootState) =>
  state.modal.showDownloadCancelModal;

export default modalSlice.reducer;
