import React from 'react';
import { useTheme } from 'styled-components';

import Box from 'components/atoms/Box/Box';
import Flex from 'components/atoms/Flex/Flex';
import Icon from 'components/atoms/Icon/Icon';
import Text from 'components/atoms/Text/Text';
import { Modal } from 'components/molecules/Modal/Modal';

import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectAddWorkspaceModal, setModal } from 'redux/modal/modalSlice';

import AddWorkspaceForm from './components/AddWorkspaceForm';

type AddWorkspaceModalProps = {
  refetch: () => void;
};
function AddWorkspaceModal({ refetch }: AddWorkspaceModalProps) {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const show = useAppSelector(selectAddWorkspaceModal);
  const onCloseModal = () =>
    dispatch(setModal({ showAddWorkspaceModal: false }));
  return (
    <Modal show={show} onHide={onCloseModal} width={582}>
      <Flex fullWidth justifyContent="left" mb={4}>
        <Box mr={2}>
          <Icon color={theme.colors.primary600} size={28} name="storefront" />
        </Box>
        <Text lg bold>
          Add Company
        </Text>
      </Flex>
      <AddWorkspaceForm refetch={refetch} onModalClose={onCloseModal} />
    </Modal>
  );
}

export default AddWorkspaceModal;
