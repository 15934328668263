import { Dispatch } from 'react';
import { AnyAction } from 'redux';

import { IDotsDropDownMenuOption } from '../../../../components/molecules/DotsDropDownMenu/DotsDropDownMenu.interface';
import { ProjectBaseFragment, ProjectStatus, Roles } from '../../../../graph/generated.graphql';
import { setModal } from '../../../../redux/modal/modalSlice';

export function getDropDownMenuItems(project: ProjectBaseFragment, dispatch: Dispatch<AnyAction>, roles: Roles | undefined, lockCallBack: any) {
  const baseMenuItems = [
    {
      icon: 'driveFileRenameOutline',
      label: 'Rename',
      key: `rename_project${project.id}`,
      onClick: () =>
        dispatch(
          setModal({
            showRenameProjectModal: true,
            renameProjectInfo: project
          })
        )
    },
    {
      icon: 'delete',
      label: 'Delete',
      key: `remove_project${project.id}`,
      onClick: () =>
        dispatch(
          setModal({
            showRemoveProjectModal: true,
            removeProjectInfo: project
          })
        )
    }
  ] as IDotsDropDownMenuOption[];

  const vidicoAdminMenuItems = [
    ...baseMenuItems,
    {
      icon: project.status === ProjectStatus.Locked ? 'unlock' : 'lock',
      label: project.status === ProjectStatus.Locked ? 'Unlock' : 'Lock',
      key: `unlock_project${project.id}`,
      onClick: () => lockCallBack(project)
    }
  ] as IDotsDropDownMenuOption[];


  return roles === Roles.VidicoAdmin ? vidicoAdminMenuItems : baseMenuItems;
}



