import React, { FormEvent } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Select, { OnChangeValue } from 'react-select';
import styled from 'styled-components';

import FormControl from 'components/atoms/FormControl/FormControl';
import FormFeetback from 'components/atoms/FormFeetback/FormFeetback';
import { IOption, Option } from 'components/atoms/Select/Select';
import Text from 'components/atoms/Text/Text';
import ActionModal, {
  ButtonsAlignmentName,
} from 'components/molecules/ActionModal/ActionModal';

import {
  Localizations,
  useProjectCreateMutation,
} from 'graph/generated.graphql';
import {
  FormErrorMessages,
  FormErrorTypes,
  LocalisationIcon,
} from 'utils/constants/forms';
import { RoutesPath } from 'utils/constants/routes';
import { isProjectNameExistError } from 'utils/errors/isGqlError';
import { buildLink } from 'utils/helpers/route';
import {
  useFormState,
  useFormValidation,
} from 'utils/hooks/useFormValidation/useFormValidation';
import { transformLocalizationToLocalizationName } from 'utils/transformers/transformLocalizationToLocalizationName';

import { IAddProjectFormProps } from './AddProjectForm.interface';

import MultiCountriesValueLabel, {
  SelectCountriesWrapper,
} from '../../../../../components/molecules/MultiCountrySelect/MultiCountrySelect';
import {
  countriesOrder,
  sortCountriesOrder,
} from '../../../../../utils/localization/localization';

const FormStyled = styled(Form)`
  width: 100%;
`;
enum FormFields {
  ProjectName = 'projectName',
  Localisation = 'localizations',
}

const FormFieldsList = [FormFields.ProjectName, FormFields.Localisation];

function AddProjectForm({
  onModalClose,
  refetch,
  companyId,
}: IAddProjectFormProps) {
  const [formErrors, isFormValid, [setProjectNameError, setLocalisationError]] =
    useFormValidation(FormFieldsList);
  const navigate = useNavigate();
  const [
    formState,
    [setProjectName, setLocalisation],
    [formOnSubmit, formOnError, formOnSuccess],
  ] = useFormState(FormFieldsList);
  const { isLoading, errorSlug } = formState;
  const [projectCreate, { loading: isProjectCreateLoading }] =
    useProjectCreateMutation({
      onCompleted({ projectCreate: projectCreateData }) {
        formOnSuccess();
        refetch();
        navigate(
          buildLink(RoutesPath.WorkspaceById, {
            companyId,
            projectId: projectCreateData?.project?.id,
          })
        );
        onModalClose();
      },
      onError(error) {
        error.graphQLErrors.forEach((gqlError) => {
          if (isProjectNameExistError(gqlError)) {
            formOnError();
            return setProjectNameError({
              type: FormErrorTypes.Invalid,
              message: FormErrorMessages.PROJECT_NAME_NOT_UNIQUE,
            });
          }

          return formOnError(gqlError.message);
        });
      },
    });

  const options: IOption[] = Object.values(Localizations)
    .sort(sortCountriesOrder(countriesOrder))
    .map((item) => ({
      label: transformLocalizationToLocalizationName(item),
      value: item,
      icon: LocalisationIcon[item],
    }));

  const validProjectName = (value: string) => {
    if (!value) {
      setProjectNameError({
        type: FormErrorTypes.Invalid,
        message: FormErrorMessages.PROJECT_NAME_BLANK,
      });
      return false;
    }
    if (value.length > 50) {
      setProjectNameError({
        type: FormErrorTypes.Invalid,
        message: FormErrorMessages.MAX_50_CHARACTERS,
      });
      return false;
    }
    setProjectNameError(null);
    return true;
  };
  const validLocalisation = (value: string[]) => {
    if (!value.length) {
      setLocalisationError({
        type: FormErrorTypes.Invalid,
        message: FormErrorMessages.NO_LOCALISATION_SELECTED,
      });
      return false;
    }
    setLocalisationError(null);
    return true;
  };

  const onProjectNameTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProjectName(e.target.value);
    validProjectName(e.target.value);
  };

  const onLocalizationselectChange = (
    values: OnChangeValue<IOption, boolean>
  ) => {
    const selected = (values as IOption[]).map((v) => v.value);
    setLocalisation(selected);
    validLocalisation(selected);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const { projectName, localizations } = formState;
    if (
      [validProjectName(projectName), validLocalisation(localizations)].filter(
        (v) => !v
      ).length
    ) {
      return null;
    }

    formOnSubmit();
    projectCreate({ variables: { projectName, companyId, localizations } });
    return null;
  };

  return (
    <FormStyled noValidate validated={isFormValid} onSubmit={handleSubmit}>
      <Row className="gx-3">
        <Col>
          <Form.Group controlId="validationCustom01">
            <Form.Label>
              <Text sm semibold>
                Project Name
              </Text>
            </Form.Label>
            <FormControl
              placeholder="Project name"
              required
              $errorType={formErrors[FormFields.ProjectName]?.type}
              onChange={onProjectNameTextChange}
            />
            <FormFeetback type={formErrors[FormFields.ProjectName]?.type}>
              {formErrors[FormFields.ProjectName]?.message}
            </FormFeetback>
          </Form.Group>
        </Col>
      </Row>
      <Row className="gx-3">
        <Col>
          <Form.Group controlId="validationSelect">
            <Form.Label>
              <Text sm semibold>
                Localisations
              </Text>
            </Form.Label>
            <SelectCountriesWrapper>
              <Select
                isSearchable={false}
                isMulti
                placeholder="Select Localisations"
                classNamePrefix="countries-select"
                options={options}
                components={{
                  Option,
                  MultiValueLabel: MultiCountriesValueLabel,
                }}
                onChange={onLocalizationselectChange}
              />
            </SelectCountriesWrapper>
            <FormFeetback type={formErrors[FormFields.Localisation]?.type}>
              {formErrors[FormFields.Localisation]?.message}
            </FormFeetback>
          </Form.Group>
        </Col>
      </Row>
      <ActionModal
        primaryButtonProps={{
          text: 'Add Project',
          isLoading: isLoading || isProjectCreateLoading,
          type: 'submit',
          disabled: !isFormValid,
        }}
        secondaryButtonProps={{ text: 'Cancel', onClick: onModalClose }}
        buttonsAlignment={ButtonsAlignmentName.RIGHT}
        error={errorSlug}
      />
    </FormStyled>
  );
}

export default AddProjectForm;
