import React, { FormEvent, useCallback, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { FileRejection } from 'react-dropzone';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import Avatar from 'components/atoms/Avatar/Avatar';
import Box from 'components/atoms/Box/Box';
import { FileUpload } from 'components/atoms/FileUpload/FileUpload';
import Flex from 'components/atoms/Flex/Flex';
import FormControl from 'components/atoms/FormControl/FormControl';
import FormFeetback from 'components/atoms/FormFeetback/FormFeetback';
import { IOption, Select } from 'components/atoms/Select/Select';
import Text from 'components/atoms/Text/Text';
import ActionModal, {
  ButtonsAlignmentName,
} from 'components/molecules/ActionModal/ActionModal';

import {
  CurrencyType,
  useCompanyCreateMutation,
  useGetAccountManagersQuery
} from 'graph/generated.graphql';
import { useAppSelector } from 'redux/hooks';
import { selectViewer } from 'redux/viewer/viewerSlice';
import {
  DEFAULT_COMPANY_AVATAR,
  imagesTypeForAvatar,
} from 'utils/constants/common';
import { FormErrorMessages, FormErrorTypes } from 'utils/constants/forms';
import { RoutesPath } from 'utils/constants/routes';
import {
  isCompanyDomainExistError,
  isCompanyNameExistError,
  isFileRequiresError,
} from 'utils/errors/isGqlError';
import {
  useFormState,
  useFormValidation,
} from 'utils/hooks/useFormValidation/useFormValidation';
import { mapUserToSelectOptions } from 'utils/transformers/mappers';
import { getExtension } from 'utils/transformers/text';
import { isEmail } from 'utils/validators/validators';

import Toggler from '../../../../components/atoms/Toggler/Toggler';

type AddWorkspaceFormProps = {
  refetch: () => void;
  onModalClose: () => void;
};

enum FormFields {
  FirstName = 'firstName',
  LastName = 'lastName',
  Email = 'email',
  Company = 'company',
  File = 'file',
  Currency = 'currency'
}

const FormFieldsList = [
  FormFields.FirstName,
  FormFields.LastName,
  FormFields.Email,
  FormFields.Company,
  FormFields.File,
  FormFields.Currency
];

function AddWorkspaceForm({ onModalClose, refetch }: AddWorkspaceFormProps) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { loading, data: accountManagersData } = useGetAccountManagersQuery();

  const [
    formErrors,
    isFormValid,
    [
      setFirstNameError,
      setLatNameError,
      setEmailError,
      setCompanyNameError,
      setFileError,
    ],
  ] = useFormValidation(FormFieldsList);

  const [
    formState,
    [setFirstName, setLatName, setEmail, setCompany, setFile],
    [formOnSubmit, formOnError, formOnSuccess],
  ] = useFormState(FormFieldsList);

  const user = useAppSelector(selectViewer);
  const listOfAdmins =
    accountManagersData?.vidicoAdmin?.vidicoAdmins?.filter(
      (u) => u.calendlyUrl
    ) || [];
  const options = listOfAdmins.map(mapUserToSelectOptions);

  const [accountManagerId, setAccountManagerId] = useState(user?.id);

  const getValue = (): IOption | undefined =>
    accountManagerId
      ? options.find((item: IOption) => item.value === accountManagerId)
      : undefined;

  const { isLoading, errorSlug } = formState;

  const [currency, setCurrency] = useState(false)
  const onCurrencyChanged = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrency(e.target.checked);
  }, [])

  const [companyCreate, { loading: isCompanyCreateLoading }] =
    useCompanyCreateMutation({
      onCompleted(data) {
        formOnSuccess();
        onModalClose();
        refetch();
        navigate(`${RoutesPath.Workspaces}/${data.companyCreate.company?.id}`);
      },
      onError(error) {
        error.graphQLErrors.forEach((gqlError) => {
          if (isCompanyNameExistError(gqlError)) {
            formOnError();
            return setCompanyNameError({
              type: FormErrorTypes.Invalid,
              message: FormErrorMessages.COMPANY_NAME_NOT_UNIQUE,
            });
          }
          if (isCompanyDomainExistError(gqlError)) {
            formOnError();
            return setEmailError({
              type: FormErrorTypes.Invalid,
              message: FormErrorMessages.EMAIL_DOMAIN_NOT_UNIQUE,
            });
          }
          if (isFileRequiresError(gqlError)) {
            formOnError();
            return setFileError({
              type: FormErrorTypes.Invalid,
              message: FormErrorMessages.FILE_REQUIRED,
            });
          }
          return formOnError(gqlError.message);
        });
      },
    });

  const validName = (type: 'first' | 'last', value: string) => {
    const nameValidator =
      type === 'first' ? setFirstNameError : setLatNameError;
    if (!value) {
      nameValidator({
        type: FormErrorTypes.Invalid,
        message:
          type === 'first'
            ? FormErrorMessages.FIRST_NAME_BLANK
            : FormErrorMessages.LAST_NAME_BLANK,
      });
      return false;
    }
    if (value.length > 40) {
      nameValidator({
        type: FormErrorTypes.Invalid,
        message: FormErrorMessages.MAX_40_CHARACTERS,
      });
      return false;
    }
    nameValidator(null);
    return true;
  };

  const validEmail = (value: string) => {
    if (!value) {
      setEmailError({
        type: FormErrorTypes.Invalid,
        message: FormErrorMessages.EMAIL_BLANK,
      });
      return false;
    }
    if (!isEmail(value)) {
      setEmailError({
        type: FormErrorTypes.Invalid,
        message: FormErrorMessages.EMAIL_INVALID,
      });
      return false;
    }
    setEmailError(null);
    return true;
  };

  const validCompany = (value: string) => {
    if (!value) {
      setCompanyNameError({
        type: FormErrorTypes.Invalid,
        message: FormErrorMessages.COMPANY_BLANK,
      });
      return false;
    }
    if (value.length > 50) {
      setCompanyNameError({
        type: FormErrorTypes.Invalid,
        message: FormErrorMessages.MAX_50_CHARACTERS,
      });
      return false;
    }
    setCompanyNameError(null);
    return true;
  };

  const validFile = (file: File) => {
    if (!file) {
      setFileError({
        type: FormErrorTypes.Invalid,
        message: FormErrorMessages.FILE_REQUIRED,
      });
      return false;
    }
    if (file.size > 5242880) {
      setFileError({
        type: FormErrorTypes.Invalid,
        message: FormErrorMessages.FILE_SIZE_ERROR,
      });
      return false;
    }
    if (!imagesTypeForAvatar.includes(getExtension(file?.name) || '')) {
      setFileError({
        type: FormErrorTypes.Invalid,
        message: FormErrorMessages.FILE_SIZE_ERROR,
      });
      return false;
    }
    setFileError(null);
    return true;
  };

  const onFirstNameTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(e.target.value);
    setFirstNameError(null);
  };

  const onLastNameTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLatName(e.target.value);
    setLatNameError(null);
  };

  const onEmailTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setEmailError(null);
  };

  const onCompanyTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCompany(e.target.value);
    validCompany(e.target.value);
  };

  const onFileDrop = <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[]
  ) => {
    setFile(acceptedFiles[0]);
    validFile(acceptedFiles[0]);
    if (fileRejections.length) {
      setFileError({
        type: FormErrorTypes.Invalid,
        message: FormErrorMessages.FILE_SIZE_ERROR,
      });
    }
  };

  const onAccountManagerSelectChange = (newValue: any) => {
    setAccountManagerId(newValue.value);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const { firstName, lastName, email, company, file } = formState;
    const currencyValue = currency ? CurrencyType.Usd : CurrencyType.Aud;

    if (
      [
        validName('first', firstName),
        validName('last', lastName),
        validEmail(email),
        validCompany(company),
        validFile(file),
      ].filter((v) => !v).length
    ) {
      return null;
    }

    formOnSubmit();

    companyCreate({
      variables: {
        company,
        email,
        lastName,
        firstName,
        file,
        managerId: accountManagerId,
        currency: currencyValue,
      },
    });
    return null;
  };
  const { file } = formState;
  return (
    <Form
      className="w-100"
      noValidate
      validated={isFormValid}
      onSubmit={handleSubmit}
    >
      <Row className="gx-3">
        <Col>
          <Form.Group controlId="validationCustom01">
            <Form.Label>
              <Text sm semibold>
                First name
              </Text>
            </Form.Label>
            <FormControl
              placeholder="Workspace Owner’s first name"
              required
              onChange={onFirstNameTextChange}
            />
            <FormFeetback type={formErrors[FormFields.FirstName]?.type}>
              {formErrors[FormFields.FirstName]?.message}
            </FormFeetback>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="validationCustom02">
            <Form.Label>
              <Text sm semibold>
                Last name
              </Text>
            </Form.Label>
            <FormControl
              placeholder="Workspace Owner’s last name"
              onChange={onLastNameTextChange}
            />
            <FormFeetback type={formErrors[FormFields.LastName]?.type}>
              {formErrors[FormFields.LastName]?.message}
            </FormFeetback>
          </Form.Group>
        </Col>
      </Row>
      <Row className='gx-3 pb-2'>
        <Col px={0}>
          <Flex alignItems='center' justifyContent='space-between'>
            <Box pr={1}>
              <Text sm semibold color={theme.colors.grey700}>
                Currency
              </Text>
              <Text sm regular color={theme.colors.grey500}>
                This is the currency the company pays.
              </Text>
            </Box>
            <Box w='158px'>
              <Toggler leftText='AUD' rightText='USD' leftIconSize={16} leftIcon='currency' rightIconSize={16}
                       rightIcon='currency' sliderWidth={75} sliderColumnWidth={83} onChange={onCurrencyChanged} checked={currency}/>
            </Box>
          </Flex>
        </Col>
      </Row>
      <Row className="gx-3 ">
        <Col>
          <Form.Group controlId="validationCustom02">
            <Form.Label>
              <Text sm semibold>
                Email address
              </Text>
            </Form.Label>
            <FormControl
              type="email"
              required
              onChange={onEmailTextChange}
              placeholder="Workspace Owner’s email"
            />
            <FormFeetback type={formErrors[FormFields.Email]?.type}>
              {formErrors[FormFields.Email]?.message}
            </FormFeetback>
          </Form.Group>
        </Col>
      </Row>
      <Row className="gx-3 ">
        <Col>
          <Form.Group controlId="validationCustom02">
            <Box mb={5}>
              <Form.Label>
                <Text sm semibold>
                  Company photo
                </Text>
                <Text sm regular color={theme.colors.grey600}>
                  This will be displayed as the company photo.
                </Text>
              </Form.Label>
            </Box>
            <Form.Group controlId="file">
              <Flex alignItems="center" justifyContent="center">
                <Box mr={5}>
                  <Avatar
                    src={
                      file ? URL.createObjectURL(file) : DEFAULT_COMPANY_AVATAR
                    }
                    xl
                  />
                </Box>
                <FileUpload onDrop={onFileDrop} />
              </Flex>
              <FormFeetback type={formErrors[FormFields.File]?.type}>
                {formErrors[FormFields.File]?.message}
              </FormFeetback>
            </Form.Group>
          </Form.Group>
        </Col>
      </Row>
      <Row className="gx-3">
        <Col>
          <Form.Group controlId="validationCustom01">
            <Form.Label>
              <Text sm semibold>
                Company name
              </Text>
            </Form.Label>
            <FormControl
              onChange={onCompanyTextChange}
              placeholder="Workspace Owner’s company"
              required
            />
            <FormFeetback type={formErrors[FormFields.Company]?.type}>
              {formErrors[FormFields.Company]?.message}
            </FormFeetback>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="validationSelect">
            <Form.Label>
              <Text sm semibold>
                Account manager
              </Text>
            </Form.Label>
            <Select
              isSearchable={false}
              placeholder="Select account manager"
              value={getValue()}
              isLoading={loading}
              options={options}
              onChange={onAccountManagerSelectChange}
            />
          </Form.Group>
        </Col>
      </Row>
      <ActionModal
        primaryButtonProps={{
          text: 'Add Company',
          disabled: !isFormValid,
          isLoading: isLoading || isCompanyCreateLoading,
          type: 'submit',
        }}
        secondaryButtonProps={{ text: 'Cancel', onClick: onModalClose }}
        buttonsAlignment={ButtonsAlignmentName.RIGHT}
        error={errorSlug}
      />
    </Form>
  );
}

export default AddWorkspaceForm;
