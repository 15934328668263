import React, {
  useEffect,
  useState,
  KeyboardEvent,
  MouseEventHandler,
} from 'react';
import { useMatch } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { useRenameAssetMutation } from 'graph/generated.graphql';
import { FormErrorTypes } from 'utils/constants/forms';
import { RoutesPath } from 'utils/constants/routes';
import { FromError } from 'utils/hooks/useFormValidation/useFormValidation';
import { getFileNameWithoutExtension } from 'utils/transformers/text';

import { IRenameInputProps } from './RenameInput.interface';

import Box from '../Box/Box';
import FakeInput from '../FakeInput/FakeInput';
import FormControlRenameAsset from '../FormControlRenameAsset/FormControlRenameAsset';
import FormFeedback from '../FormFeetback/FormFeetback';
import Text from '../Text/Text';

function RenameInput({ asset, minWidth }: IRenameInputProps) {
  const theme = useTheme();
  const isViewSharedAsset = useMatch(RoutesPath.ViewSharedAssetById);
  const [isInput, setIsInput] = useState(false);
  const initialInputValue = getFileNameWithoutExtension(asset?.name || '');
  const [inputValue, setInputValue] = useState('');
  const [inputValueError, setInputValueError] = useState<FromError | null>(
    null
  );
  useEffect(() => {
    setInputValue(initialInputValue);
  }, [initialInputValue]);

  const [renameAsset] = useRenameAssetMutation({
    onCompleted() {
      setIsInput(false);
    },
    onError(error) {
      error.graphQLErrors.forEach((gqlError) =>
        setInputValueError({
          message: gqlError.message,
          type: FormErrorTypes.Invalid,
        })
      );
    },
  });

  const onKeydownHandler = (e: KeyboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.key === 'Escape') {
      setInputValue(initialInputValue);
      setIsInput(false);
    }
    if (e.key === 'Enter' || e.key === 'Escape') {
      handleRenameAsset();
    }
  };
  const handleRenameAsset = () => {
    setIsInput(false);
    if (initialInputValue !== inputValue) {
      renameAsset({ variables: { assetId: asset?.id, assetName: inputValue } });
    }
  };
  const onFakeInputClick: MouseEventHandler<HTMLDivElement> = () => {
    if (!isViewSharedAsset) {
      setIsInput(true);
    }
  };
  return (
    <Box>
      {isInput ? (
        <>
          <FormControlRenameAsset
            minWidth={minWidth}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onBlur={handleRenameAsset}
            onKeyUp={onKeydownHandler}
          />
          {inputValueError && (
            <FormFeedback type={inputValueError.type}>
              {inputValueError.message}
            </FormFeedback>
          )}
        </>
      ) : (
        <FakeInput onClick={onFakeInputClick} py={1} px={2}>
          <Text lg semibold color={theme.colors.base}>
            {asset.name}
          </Text>
        </FakeInput>
      )}
    </Box>
  );
}

export default RenameInput;
