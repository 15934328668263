import {useContext} from "react";
import {AuthContext, IAuthContext} from "react-oauth2-code-pkce";

import Avatar from 'components/atoms/Avatar/Avatar';
import Box from 'components/atoms/Box/Box';
import Flex from 'components/atoms/Flex/Flex';
import LabelGroup from 'components/atoms/LabelGroup/LabelGroup';
import Logo from 'components/atoms/Logo/Logo';
import Text from 'components/atoms/Text/Text';
import ActionModal from 'components/molecules/ActionModal/ActionModal';
import { Modal } from 'components/molecules/Modal/Modal';

import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectLogoutModal, setModal } from 'redux/modal/modalSlice';
import { selectViewer, setViewer } from 'redux/viewer/viewerSlice';
import { useTimer } from 'utils/hooks/useTimer/useTimer';

import {useLogoutContext} from "../../../utils/context/logoutContext/logoutContext";
import { removeSessionState } from "../../../utils/localStorage/viewer";

// eslint-disable-next-line import/order
import './logOutModal.css';

function LogOutModal() {
  const dispatch = useAppDispatch();

  const user = useAppSelector(selectViewer);
  const userName = `${user?.firstName} ${user?.lastName}`;

  const { logOut, } = useContext<IAuthContext>(AuthContext);
  
  const { handleLogout } = useLogoutContext();

  const { seconds, start, stop } = useTimer();
  const show = useAppSelector(selectLogoutModal);

  const handleLogOut = () => {
    handleLogout()
    logOut();
    dispatch(
      setViewer(null)
    );
    removeSessionState();
    onCloseModal();
  };
  const onCloseModal = () => {
    stop();
    setTimeout(() => {
      dispatch(setModal({ showLogOutModal: false }));
    }, 100);
  };

  if (seconds < 1) {
    handleLogOut();
  }
  return (
    <Modal show={show} onEntered={start} onHide={onCloseModal} width={452}>
      <Box mb={6}>
        <Logo />
      </Box>
      <Flex flexDirection="column" alignItems="center" mb={6}>
        <Text lg bold>
          Sign out of your
        </Text>
        <Text lg bold>
          VideoStore account
        </Text>
      </Flex>
      <Box mb={1}>
        <Avatar lg src={user?.company?.logo || ''} text={user?.company?.name} />
      </Box>
      <LabelGroup
        mb={6}
        title={user?.company?.name || ''}
        subTitle={userName}
        text={user?.email || ''}
        alignItems="center"
      />
      <ActionModal
        primaryButtonProps={{
          text: `Sign out (${seconds}s)`,
          onClick: handleLogOut,
          fullWidth: true,
        }}
        secondaryButtonProps={{
          text: 'Cancel',
          onClick: onCloseModal,
          fullWidth: true,
        }}
      />
    </Modal>
  );
}
export default LogOutModal;
