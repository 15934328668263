import React from 'react';
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import styled, { useTheme } from 'styled-components';

import Box from 'components/atoms/Box/Box';
import Flex from 'components/atoms/Flex/Flex';
import Icon from 'components/atoms/Icon/Icon';
import IconButton from 'components/atoms/IconButton/IconButton';
import Text from 'components/atoms/Text/Text';

import { CustomMenuProps } from 'containers/Layout/components/Header/components/ProfileMenu/ProfileMenu';
import { Roles } from 'graph/generated.graphql';
import { useAppSelector } from 'redux/hooks';
import { selectViewer } from 'redux/viewer/viewerSlice';

import {
  ICustomToggleProps,
  IPermissionSelectionProps,
} from './PermissionSelection.interface';

const PermissionMenuStyled = styled(Box)`
  background-color: ${({ theme }) => theme.colors.base};
  border: 1px solid ${({ theme }) => theme.colors.grey100};
  box-shadow: ${({ theme }) => theme.shadow.mdShadow};
  border-radius: 20px;
`;

const CustomToggle = React.forwardRef(
  (props: ICustomToggleProps, ref: React.Ref<HTMLButtonElement>) => {
    const theme = useTheme();
    return (
      <OverlayTrigger
        key={props.id}
        overlay={<Tooltip id={props.id}>Permissions</Tooltip>}
      >
        <IconButton
          ref={ref}
          type="button"
          iconName="key01"
          iconSize={20}
          disabled={props.disabled}
          iconColor={theme.colors.grey500}
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            e.preventDefault();
            props.onClick(e);
          }}
        />
      </OverlayTrigger>
    );
  }
);
const PermissionMenu = React.forwardRef(
  (props: CustomMenuProps, ref: React.Ref<HTMLDivElement>) => (
    <PermissionMenuStyled
      p={2}
      ref={ref}
      w={304}
      style={props.style}
      className={props.className}
      aria-labelledby={props.labeledBy}
    >
      {React.Children.toArray(props.children)}
    </PermissionMenuStyled>
  )
);
const TextStyled = styled(Text)`
  white-space: normal;
`;

function PermissionSelection({
  id,
  selectedItem,
  userId,
  onChange,
  disabled,
}: IPermissionSelectionProps) {
  const theme = useTheme();
  const user = useAppSelector(selectViewer);

  return (
    <Dropdown className="position-static">
      <Dropdown.Toggle as={CustomToggle} id={id} disabled={disabled} />

      <Dropdown.Menu as={PermissionMenu}>
        <Dropdown.Item
          className="p-0 rounded-4"
          key={Roles.Owner}
          eventKey={Roles.Owner}
          disabled={user?.roles !== Roles.VidicoAdmin}
          onClick={() => onChange({ variables: { userId, role: Roles.Owner } })}
        >
          <Box p={3}>
            <Flex mb={1} justifyContent="space-between">
              <Text
                sm
                semibold
                color={
                  user?.roles !== Roles.VidicoAdmin
                    ? theme.colors.grey400
                    : theme.colors.grey600
                }
              >
                Workspace owner
              </Text>
              {selectedItem === Roles.Owner && (
                <Icon name="check" size={20} color={theme.colors.primary600} />
              )}
            </Flex>
            <Box>
              <TextStyled
                sm
                regular
                color={
                  user?.roles !== Roles.VidicoAdmin
                    ? theme.colors.grey400
                    : theme.colors.grey600
                }
              >
                Workspace owners can add and remove users and manage
                organisation-level settings.
              </TextStyled>
            </Box>
          </Box>
        </Dropdown.Item>
        <Dropdown.Item
          className="p-0 rounded-3"
          disabled={user?.roles === Roles.Member}
          key={Roles.Admin}
          eventKey={Roles.Admin}
          onClick={() => onChange({ variables: { userId, role: Roles.Admin } })}
        >
          <Box p={3}>
            <Flex mb={1} justifyContent="space-between">
              <Text
                sm
                semibold
                color={
                  user?.roles === Roles.Member
                    ? theme.colors.grey400
                    : theme.colors.grey600
                }
              >
                Admin
              </Text>
              {selectedItem === Roles.Admin && (
                <Icon name="check" size={20} color={theme.colors.primary600} />
              )}
            </Flex>
            <Box>
              <TextStyled
                sm
                regular
                color={
                  user?.roles === Roles.Member
                    ? theme.colors.grey400
                    : theme.colors.grey600
                }
              >
                Admins can add and remove users.
              </TextStyled>
            </Box>
          </Box>
        </Dropdown.Item>
        <Dropdown.Item
          className="p-0 rounded-3"
          key={Roles.Member}
          eventKey={Roles.Member}
          onClick={() =>
            onChange({ variables: { userId, role: Roles.Member } })
          }
        >
          <Box p={3}>
            <Flex mb={1} justifyContent="space-between">
              <Text sm semibold color={theme.colors.grey600}>
                Member
              </Text>
              {selectedItem === Roles.Member && (
                <Icon name="check" size={20} color={theme.colors.primary600} />
              )}
            </Flex>
            <Box>
              <TextStyled sm regular color={theme.colors.grey600}>
                Members can access, share, view and download assets.
              </TextStyled>
            </Box>
          </Box>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default PermissionSelection;
