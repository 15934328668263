import React from 'react';
import { Image } from 'react-bootstrap';
import styled from 'styled-components';

import { IImagesCascadeProps } from './ImagesCascade.interface';

import Box from '../Box/Box';

const Wrapper = styled(Box)`
  position: relative;
`;
const ImageWrapper = styled(Box)<{
  right?: number;
  left?: number;
  top?: number;
  bottom?: number;
}>`
  position: absolute;
  ${({ right }) => right && `right: ${right}px;`};
  ${({ left }) => left && `left: ${left}px;`};
  ${({ top }) => top && `top: ${top}px;`};
  ${({ bottom }) => bottom && `bottom: ${bottom}px;`};
  border: 1px solid ${({ theme }) => theme.colors.base};
  border-radius: 6px;
  overflow: hidden;
`;
const StyledImage = styled(Image)`
  width: 256px;
  height: 168px;
  object-fit: cover;
`;
function ImagesCascade({ imagesList }: IImagesCascadeProps) {
  const slicedList = imagesList.slice(0, 3);
  const listLength = slicedList.length;
  const wrapperWidth = 256 + (listLength * 12 - 12);
  const wrapperHeight = 168 + (listLength * 12 - 12);
  return (
    <Wrapper w={wrapperWidth} h={wrapperHeight}>
      {slicedList.map((image, index) => (
        <ImageWrapper
          key={image}
          w={256}
          h={168}
          top={index * 12}
          left={index * 12}
        >
          <StyledImage src={image} />
        </ImageWrapper>
      ))}
    </Wrapper>
  );
}

export default ImagesCascade;
