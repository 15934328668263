const FormErrorMessages = {
  EMAIL_BLANK: 'Email is empty',
  EMAIL_INVALID: 'Email is wrong format',
  EMAIL_NOT_UNIQUE: 'Email already used',
  EMAIL_DOMAIN_NOT_UNIQUE:
    'This domain already exists. Please, try another one.',
  PASSWORD_BLANK: 'Password is empty',
  PASSWORD_INVALID: 'Too weak. Must be at least 8 characters.',
  PASSWORD_WARNING: 'Could be stronger',
  PASSWORD_VALID: 'Strong password',
  SPACES_IN_PASSWORD: 'Spaces not allowed',
  OLD_PASSWORD_WRONG: 'Old password is wrong',
  PROJECT_NAME_NOT_UNIQUE: 'Project name is already exist',
  FIRST_NAME_BLANK: 'First name is empty',
  FILE_REQUIRED: 'Company photo is required',
  FILE_SIZE_ERROR:
    'Something went wrong. Please note that the photo should be smaller than 5 MB and in one of the following formats: PNG, JPG, GIF or JPEG',
  LAST_NAME_BLANK: 'Last name is empty',
  ACCOUNT_MANAGER_NOT_SET: 'Select account manager',
  MAX_40_CHARACTERS: 'Use maximum 40 characters',
  MAX_50_CHARACTERS: 'Use maximum 50 characters',
  MAX_150_CHARACTERS: 'Use maximum 150 characters',
  COMPANY_NAME_NOT_UNIQUE: 'Company name already in use',
  COMPANY_BLANK: 'Company name is empty',
  PROJECT_NAME_BLANK: 'Project name is empty',
  LOGIN_OR_PASSWORD_NOT_CORRECT:
    'The email or password you entered did not match our records. Please double-check and try again',
  NO_LOCALISATION_SELECTED: 'Select at least one localisation',
  BRIEF_DETAILS_BLANK: 'Brief details is required',
  LIST_OF_DOMAIN_NAMES_BLACK: 'Automatically approved domains is empty',
  LIST_OF_DOMAIN_NAMES_MAX_COUNT:
    'Automatically approved domains list can`t be more than three',
  EMAIL_NOT_IN_DOMAIN_APPROVE_LIST:
    'Only approved domains can be added. Contact the Workspace Owner to add a new domain.',
  ROLE_TO_HIGH:
    'You can`t add member to your workspace with role higher than your.',
  GENERAL: 'Something went wrong. Try again or contact administrator!',
  PASSWORD_LESS_THAN_8: 'Password must have at least 8 characters',
  PASSWORD_CHANGED: 'Password successfully changed!',
  RESET_INSTRUCTION_SEND_SUCCESSFULLY: 'Reset instructions sent to email',
  OWNER_TRY_DELETE_HIS_ACCOUNT:
    'Please reassign your Workspace Owner rights in order to delete your account.',
  INVITATION_SENT_SUCCESSFULLY: 'Invitation was sent successfully',
};

enum FormErrorTypes {
  Blank = 'blank',
  Warning = 'warning',
  Valid = 'valid',
  Invalid = 'invalid',
}

enum LocalisationIcon {
  US_ENGLISH = '/assets/svgs/US.svg',
  AU_ENGLISH = '/assets/svgs/AU.svg',
  NZ_ENGLISH = '/assets/svgs/NZ.svg',
  UK_ENGLISH = '/assets/svgs/GB.svg',
  CA_ENGLISH = '/assets/svgs/CA.svg',
  IR_ENGLISH = '/assets/svgs/IE.svg',
  EU_SPANISH = '/assets/svgs/ES.svg',
  US_SPANISH = '/assets/svgs/ES.svg',
  NL_DUTCH = '/assets/svgs/NL.svg',
  BE_DUTCH = '/assets/svgs/BE.svg',
  DE_GERMAN = '/assets/svgs/DE.svg',
  FR_FRENCH = '/assets/svgs/FR.svg',
  IT_ITALIAN = '/assets/svgs/IT.svg',
  BR_PORTUGUESE = '/assets/svgs/BR.svg',
  PO_PORTUGUESE = '/assets/svgs/PT.svg',
  JP_JAPANESE = '/assets/svgs/JP.svg',
  KR_KOREAN = '/assets/svgs/KR.svg',
  HK_CHINESE = '/assets/svgs/HK.svg',
  CH_MANDARIN = '/assets/svgs/CN.svg',
  ZA_SOUTH_AFRICA = '/assets/svgs/ZA.svg',
  ADD_LOCALIZATION = '/assets/svgs/Country.svg',
  MORE_LOCALIZATION = '/assets/svgs/EA.svg',
}

enum RolesNames {
  ADMIN = 'Admin',
  OWNER = 'Owner',
  MEMBER = 'User',
}
export { FormErrorTypes, FormErrorMessages, LocalisationIcon, RolesNames };
