import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { GroupBase } from 'react-select';
import styled, { useTheme } from 'styled-components';

import Box from 'components/atoms/Box/Box';
import FilterButton from 'components/atoms/FilterButton/FilterButton';
import Flex from 'components/atoms/Flex/Flex';
import { IOption, Select } from 'components/atoms/Select/Select';
import Text from 'components/atoms/Text/Text';

import { FileType, Localizations, Roles } from 'graph/generated.graphql';
import {
  selectFilterByLocalization,
  selectFilterByType,
  setLocalizationFilter,
  setTypeFilter,
} from 'redux/filter/filterSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { setModal } from 'redux/modal/modalSlice';
import { selectViewer } from 'redux/viewer/viewerSlice';
import { popularLocalisations } from 'utils/constants/common';
import { LocalisationIcon } from 'utils/constants/forms';
import { BackgroundUploadContext } from 'utils/context/backgroundUploadContext/backgroundUploadContext';
import { transformLocalizationToLocalizationName } from 'utils/transformers/transformLocalizationToLocalizationName';

import { IFilter } from './Filter.interface';

type FilterCount = {
  all: number;
  videos: number;
  cutdowns: number;
  images: number;
  captions: number;
  editingFiles: number;
};
const Divider = styled(Box)`
  border-left: 1px solid ${({ theme }) => theme.colors.grey200};
`;
const FilterButtonWrapper = styled(Flex)`
  overflow: auto;
`;
const FilterWrapper = styled(Flex)`
  row-gap: 8px;
  & .react-select {
    &__menu {
      border: 1px solid ${({ theme }) => theme.colors.grey200};
      box-shadow: ${({ theme }) => theme.shadow.mdShadow};
      border-radius: 20px;
      padding: 8px;
    }
    &__option {
      margin-top: 2px;
      margin-bottom: 2px;
      border-radius: 20px;
      &--is-focused,
      &--is-selected {
        background-color: ${({ theme }) => theme.colors.grey100} !important;
      }
    }
  }
`;
const SelectWrapper = styled(Box)`
  flex-shrink: 0;
`;
function Filter({ project, newAssetInfo }: IFilter) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectViewer);
  const initialStateUploadedAssetsCount = useMemo(
    () => ({
      all: 0,
      videos: 0,
      cutdowns: 0,
      images: 0,
      captions: 0,
      editingFiles: 0,
    }),
    []
  );
  const [uploadedAssetsCount, setUploadedAssetsCount] = useState<FilterCount>(
    initialStateUploadedAssetsCount
  );
  const { removeAssetsByTypeFromState } = useContext(BackgroundUploadContext);
  const selectedFilter = useAppSelector(selectFilterByType)?.eq;
  const selectedLocalization = useAppSelector(selectFilterByLocalization)?.eq;
  const onFilterButtonClick = (inputType: FileType | undefined) =>
    dispatch(setTypeFilter({ eq: inputType }));
  const { state } = useContext(BackgroundUploadContext);
  const isVidicoAdmin = user?.roles === Roles.VidicoAdmin;

  const arrayOfAssetFromUploadStateSelected = useMemo(
    () => Object.values(state[project.id] || {}).filter((a) => a.uploaded),
    [project, state]
  );
  const arrayOfUploadedLocalization = useMemo(
    () => arrayOfAssetFromUploadStateSelected.map((a) => a.asset?.localization),
    [arrayOfAssetFromUploadStateSelected]
  );

  const optionsCurrent: IOption[] = useMemo(
    () =>
      project.existingLocalizations.map((item) => ({
        label: transformLocalizationToLocalizationName(item),
        value: item,
        icon: LocalisationIcon[item],
        indicator: isVidicoAdmin
          ? arrayOfUploadedLocalization.includes(item)
          : !!newAssetInfo?.[item],
      })),

    [arrayOfUploadedLocalization, isVidicoAdmin, newAssetInfo, project]
  );
  const onClickOption = useCallback(
    (item: Localizations | undefined) =>
      isVidicoAdmin
        ? null
        : dispatch(
            setModal({
              showRequestLocalizationModal: true,
              requestLocalizationModalInfo: {
                projectId: project.id,
                projectName: project.name,
                localizations: item,
              },
            })
          ),
    [dispatch, isVidicoAdmin, project]
  );

  const optionsToAdd: IOption[] = useMemo(
    () =>
      popularLocalisations
        .filter((item) => !project?.existingLocalizations?.includes(item))
        .map((item) => ({
          label: transformLocalizationToLocalizationName(item),
          value: 'onClick',
          icon: LocalisationIcon.ADD_LOCALIZATION,
          isDisabled: true,
          onClick: () => onClickOption(item),
        })),

    [onClickOption, project]
  );

  const options: GroupBase<IOption>[] = useMemo(() => {
    if (isVidicoAdmin) {
      return [{ label: 'Current Localisations', options: optionsCurrent }];
    }
    return [
      { label: 'Current Localisations', options: optionsCurrent },
      {
        label: 'Add Localisations',
        options: [
          ...optionsToAdd,
          {
            label: 'More Localisations',
            value: 'onClick',
            isDisabled: true,
            icon: LocalisationIcon.MORE_LOCALIZATION,
            onClick: () => onClickOption(undefined),
          },
        ],
      },
    ];
  }, [isVidicoAdmin, onClickOption, optionsCurrent, optionsToAdd]);

  const onChangeHandler = useCallback(
    (newValue: any) => {
      if (newValue === 'onClick') {
        return;
      }
      dispatch(
        setLocalizationFilter({
          eq: newValue?.value as Localizations,
        })
      );
    },
    [dispatch]
  );
  const getValue = useMemo(
    (): IOption | undefined =>
      selectedLocalization
        ? optionsCurrent.find(
            (item: IOption) => item.value === selectedLocalization
          )
        : optionsCurrent[0],
    [selectedLocalization, optionsCurrent]
  );
  useEffect(() => {
    onChangeHandler(getValue);
  }, [getValue, onChangeHandler]);
  useEffect(() => {
    arrayOfAssetFromUploadStateSelected.filter(
      (a) => a.asset?.type === FileType.Video
    );
    if (isVidicoAdmin) {
      setUploadedAssetsCount(
        arrayOfAssetFromUploadStateSelected
          .filter((i) => i.asset?.localization === selectedLocalization)
          .reduce((obj: FilterCount, item) => {
            const result = { ...obj };
            result.all = obj.all + 1;
            result.videos =
              item.asset?.type === FileType.Video ? obj.videos + 1 : obj.videos;
            result.cutdowns =
              item.asset?.type === FileType.Cutdown
                ? obj.cutdowns + 1
                : obj.cutdowns;
            result.images =
              item.asset?.type === FileType.Picture
                ? obj.images + 1
                : obj.images;
            result.captions =
              item.asset?.type === FileType.Caption
                ? obj.captions + 1
                : obj.captions;
            result.editingFiles =
              item.asset?.type === FileType.Editing
                ? obj.editingFiles + 1
                : obj.editingFiles;
            return result;
          }, initialStateUploadedAssetsCount)
      );
    } else {
      setUploadedAssetsCount(
        newAssetInfo?.[selectedLocalization || Localizations.AuEnglish] ??
          initialStateUploadedAssetsCount
      );
    }
  }, [
    arrayOfAssetFromUploadStateSelected,
    initialStateUploadedAssetsCount,
    selectedLocalization,
    isVidicoAdmin,
    newAssetInfo,
  ]);
  const handleClickOnFilter = (type?: FileType | undefined) => {
    if (!type || !selectedLocalization) {
      onFilterButtonClick(undefined);
    } else {
      onFilterButtonClick(type);
      removeAssetsByTypeFromState(project.id, type, selectedLocalization);
    }
  };
  return (
    <FilterWrapper px={8} py={3} alignItems="center">
      <Box mr={4}>
        <Text md semibold color={theme.colors.grey600}>
          Localisation
        </Text>
      </Box>
      <SelectWrapper mr={4}>
        <Select
          isSearchable={false}
          value={getValue}
          placeholder="Select localisation"
          backgroundColor={theme.colors.grey100}
          noBorder
          round
          options={options}
          minMenuWidth={240}
          maxMenuHeight={340}
          onChange={onChangeHandler}
        />
      </SelectWrapper>
      <Divider w={1} h={36} mr={4} />
      <FilterButtonWrapper gap={6}>
        <FilterButton
          badge={uploadedAssetsCount.all}
          iconName="layers"
          hoverIconName="layersFilled"
          isSelected={!selectedFilter}
          onClick={() => handleClickOnFilter()}
        >
          All Assets
        </FilterButton>
        <FilterButton
          badge={uploadedAssetsCount.videos}
          iconName="videoFile"
          hoverIconName="videoFileFilled"
          isSelected={selectedFilter === FileType.Video}
          onClick={() => handleClickOnFilter(FileType.Video)}
        >
          Videos
        </FilterButton>
        <FilterButton
          badge={uploadedAssetsCount.cutdowns}
          iconName="cutdownsFiles"
          hoverIconName="cutdownsFilesFilled"
          isSelected={selectedFilter === FileType.Cutdown}
          onClick={() => handleClickOnFilter(FileType.Cutdown)}
        >
          Cutdowns
        </FilterButton>
        <FilterButton
          badge={uploadedAssetsCount.images}
          iconName="imageOutline"
          hoverIconName="imageFile"
          isSelected={selectedFilter === FileType.Picture}
          onClick={() => handleClickOnFilter(FileType.Picture)}
        >
          Images
        </FilterButton>
        <FilterButton
          badge={uploadedAssetsCount.captions}
          iconName="captionsFile"
          hoverIconName="captionsFileFilled"
          isSelected={selectedFilter === FileType.Caption}
          onClick={() => handleClickOnFilter(FileType.Caption)}
        >
          Captions
        </FilterButton>
        <FilterButton
          badge={uploadedAssetsCount.editingFiles}
          iconName="editingFile"
          hoverIconName="editingFileFilled"
          isSelected={selectedFilter === FileType.Editing}
          onClick={() => handleClickOnFilter(FileType.Editing)}
        >
          Editing Files
        </FilterButton>
      </FilterButtonWrapper>
    </FilterWrapper>
  );
}

export default Filter;
