import styled from 'styled-components';

import Box from '../Box/Box';

const FakeInput = styled(Box)`
  border-radius: 6px;
  border: 1px solid transparent;
  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.vidicoAccent};
    transition: border 200ms ease-in-out;
  }
`;
export default FakeInput;
