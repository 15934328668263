import styled from 'styled-components';

import { device } from 'utils/styles/device';

const Container = styled.div`
  @media ${device.laptop} {
  }

  @media ${device.desktop} {
  }
`;

export default Container;
