import React, { KeyboardEventHandler, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useMatch } from 'react-router-dom';
import { ActionMeta, components, MultiValueGenericProps } from 'react-select';
import styled from 'styled-components';

import Box from 'components/atoms/Box/Box';
import Button from 'components/atoms/Button/Button';
import Flex from 'components/atoms/Flex/Flex';
import Icon from 'components/atoms/Icon/Icon';
import SearchInput from 'components/atoms/SearchInput/SearchInput';
import { IOption } from 'components/atoms/Select/Select';
import Text from 'components/atoms/Text/Text';

import { Roles } from 'graph/generated.graphql';
import {
  reset,
  selectIsSearchActive,
  selectSearchFilterState,
  selectSelectedProjectOption,
  setIsSearchActive,
  setSearchFilterState,
  setSelectedProjectOption,
} from 'redux/filter/filterSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { setModal } from 'redux/modal/modalSlice';
import { selectViewer } from 'redux/viewer/viewerSlice';
import { excluderPath, RoutesPath } from 'utils/constants/routes';

const SearchInputWrapper = styled(Box)`
  transition: width 500ms;
`;
function MultiValueLabel({
  children,

  ...props
}: MultiValueGenericProps<IOption>) {
  const { data } = props;
  return (
    <components.MultiValueLabel {...props}>
      <Flex>
        {data.icon && (
          <Flex
            h={16}
            w={16}
            mr={2}
            alignItems="center"
            justifyContent="center"
            flex="auto"
          >
            <Icon name={data.icon} />
          </Flex>
        )}
        <Text semibold xs>
          {children}
        </Text>
      </Flex>
    </components.MultiValueLabel>
  );
}
function HeaderForm() {
  const viewer = useSelector(selectViewer);
  const isSearchActive = useAppSelector(selectIsSearchActive);
  const searchFilterState = useAppSelector(selectSearchFilterState);
  const selectedProjectOption = useAppSelector(selectSelectedProjectOption);
  const dispatch = useAppDispatch();
  const [showButton, setShowButton] = useState(!isSearchActive);
  const isAdmin = viewer?.roles === Roles.VidicoAdmin;
  const location = useLocation();
  const showAddWorkspaceModal = () =>
    dispatch(setModal({ showAddWorkspaceModal: true }));
  const showSharedLinkModal = () =>
    dispatch(setModal({ showSharedLinkModal: true }));
  const [inputValue, setInputValue] = useState('');
  const isProjectSelectedVidicoAdmin = useMatch(RoutesPath.WorkspaceById);
  const isCompanySelectedVidicoAdmin = useMatch(RoutesPath.Projects);
  const isProjectSelectedWorkspace = useMatch(RoutesPath.HomeProject);
  const isHomePage = useMatch(RoutesPath.Home);
  const isSettingPath = useMatch(RoutesPath.Settings);
  const isProjectSelected =
    isProjectSelectedVidicoAdmin ||
    (!excluderPath.includes(
      isProjectSelectedWorkspace?.params.projectId || ''
    ) &&
      isProjectSelectedWorkspace);
  const onReset = () => {
    dispatch(reset());
    setInputValue('');
  };
  const showSearchBar =
    isProjectSelectedVidicoAdmin ||
    (isProjectSelectedWorkspace &&
      !excluderPath.includes(
        isProjectSelectedWorkspace?.params.projectId || ''
      )) ||
    isSettingPath ||
    isCompanySelectedVidicoAdmin ||
    isHomePage;

  const currentCompanyId = isAdmin
    ? isProjectSelectedVidicoAdmin?.params.companyId ||
      isCompanySelectedVidicoAdmin?.params.companyId
    : viewer?.companyId;
  useEffect(() => {
    dispatch(
      setSearchFilterState({
        ...searchFilterState,
        companyId: { eq: currentCompanyId },
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCompanyId]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isSearchActive) {
      setShowButton(false);
    }
    if (!isSearchActive) {
      const timer = setTimeout(() => setShowButton(true), 300);
      return () => clearTimeout(timer);
    }
  }, [isSearchActive, showButton]);

  const handleKeyDown: KeyboardEventHandler = (event) => {
    switch (event.key) {
      case 'Enter':
        dispatch(
          setSearchFilterState({
            ...searchFilterState,
            normalizedName: { contains: inputValue.toLowerCase() },
          })
        );
        break;
      case 'Escape':
        onReset();
        break;
    }
  };
  const onSearchChange = (newValue: any, actionMeta: ActionMeta<IOption>) => {
    if (actionMeta.action === 'remove-value') {
      dispatch(setSelectedProjectOption(undefined));
      dispatch(
        setSearchFilterState({ ...searchFilterState, projectId: undefined })
      );
    }
  };
  return viewer?.id ? (
    <Flex alignItems="center">
      {showSearchBar && (
        <SearchInputWrapper
          w={isSearchActive ? 'calc(100vw - 320px)' : 240}
          mr={4}
        >
          <SearchInput
            prefixIcon="searchLg"
            postfixIconButton={{
              iconName: 'x',
              size: 20,
              onClick: onReset,
            }}
            fullWidth
            isClearable
            inputValue={inputValue}
            onStateChange={() => null}
            placeholder="Search"
            escapeClearsValue
            components={{
              DropdownIndicator: null,
              ClearIndicator: undefined,
              MultiValueLabel,
            }}
            value={isSearchActive ? selectedProjectOption : []}
            isSearchable
            menuIsOpen={false}
            onKeyDown={handleKeyDown}
            onChange={onSearchChange}
            onInputChange={(newValue, actionMeta) => {
              if (
                actionMeta.action === 'input-blur' ||
                actionMeta.action === 'menu-close'
              ) {
                return;
              }
              setInputValue(newValue);
            }}
            onFocus={() => dispatch(setIsSearchActive(true))}
            isMulti
          />
        </SearchInputWrapper>
      )}
      <Flex mr={3} style={{ flex: 1 }}>
        {isAdmin && location.pathname === RoutesPath.Workspaces && (
          <Button sm semibold py={2} px={4} onClick={showAddWorkspaceModal}>
            Add Company
          </Button>
        )}
        {isProjectSelected && showButton && (
          <Button py={2} px={4} onClick={showSharedLinkModal}>
            <Flex mr={2}>
              <Icon size={20} name="share01" />
            </Flex>
            <Text sm semibold>
              Share
            </Text>
          </Button>
        )}
      </Flex>
    </Flex>
  ) : null;
}

export default HeaderForm;
