import React, { useMemo } from 'react';
import { InlineWidget, useCalendlyEventListener } from 'react-calendly';
import { Prefill } from 'react-calendly/typings/calendly';
import { useTheme } from 'styled-components';

import Text from 'components/atoms/Text/Text';
import { Modal } from 'components/molecules/Modal/Modal';

import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  selectCalendlyModal,
  selectCalendlyModalInfo,
  selectRequestNewLocalizationInfo,
  selectRequestNewProjectModalInfo,
  selectSelectedProject,
  setModal,
} from 'redux/modal/modalSlice';
import { selectViewer } from 'redux/viewer/viewerSlice';
import { FormErrorMessages } from 'utils/constants/forms';

function CalendlyModal() {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const user = useAppSelector(selectViewer);
  const info = useAppSelector(selectRequestNewProjectModalInfo);
  const selectedProject = useAppSelector(selectSelectedProject);
  const infoLocalization = useAppSelector(selectRequestNewLocalizationInfo);
  const show = !!useAppSelector(selectCalendlyModal);
  const accountManagerInfo = useAppSelector(selectCalendlyModalInfo);
  const onModalClose = () =>
    dispatch(
      setModal({
        showCalendlyModal: false,
        requestNewProjectModalInfo: undefined,
        requestLocalizationModalInfo: undefined,
      })
    );
  const url = useMemo(
    () => accountManagerInfo?.calendlyUrl,
    [accountManagerInfo]
  );
  const newProjectDetail = useMemo(
    () =>
      `${info?.briefDetails ? `Brief details: ${info?.briefDetails}` : ''} ${
        info?.budget ? `\nBudget: ${info?.budget}` : ''
      } ${info?.powerUps ? `\nPower ups: ${info?.powerUps}` : ''}`,
    [info]
  );
  const newLocalizationDetails = useMemo(
    () =>
      `${selectedProject ? `Project name: ${selectedProject.name}` : ''} ${
        infoLocalization?.localizations?.length
          ? `\nLocalisation: ${infoLocalization.localizations}`
          : ''
      }`,
    [infoLocalization, selectedProject]
  );
  const prefill: Partial<Prefill> = {
    email: user?.email,
    firstName: user?.firstName,
    lastName: user?.lastName,
    name: `${user?.firstName} ${user?.lastName}`,
    customAnswers: {
      a1: `${infoLocalization ? newLocalizationDetails : newProjectDetail}`,
    },
  };

  useCalendlyEventListener({
    onEventScheduled: () => {
      infoLocalization
        ? dispatch(
            setModal({
              showCalendlyModal: false,
              showRequestLocalizationFinalModal: true,
            })
          )
        : dispatch(
            setModal({
              showCalendlyModal: false,
              showRequestNewProjectFinalModal: true,
            })
          );
    },
  });
  return (
    <Modal show={show} onHide={onModalClose} width={840}>
      {url ? (
        <InlineWidget
          url={url}
          prefill={prefill}
          styles={{ minWidth: '830px', height: '580px' }}
        />
      ) : (
        <Text md medium color={theme.colors.error500}>
          {FormErrorMessages.GENERAL}
        </Text>
      )}
    </Modal>
  );
}

export default CalendlyModal;
