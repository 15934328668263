import { createContext, useCallback, useContext, useMemo, useState } from "react";

interface LogoutContextProps {
  logoutIsNotInProgress: boolean;
  handleLogout: () => void;
}

const LogoutContext = createContext<LogoutContextProps>({
  logoutIsNotInProgress: true,
  handleLogout: () => { },
});

const useLogoutContext = () => useContext(LogoutContext);

function LogoutProvider({ children }: { children: JSX.Element }) {
  const [logoutIsNotInProgress, setlogoutIsNotInProgress] = useState(true);

  const handleLogout = useCallback(() => {
    setlogoutIsNotInProgress(false);
  }, []); // no dependencies, so this is only created once

  const value = useMemo(() => ({
    logoutIsNotInProgress,
    handleLogout,
  }), [logoutIsNotInProgress, handleLogout]);

  return (
    <LogoutContext.Provider value={value}>
      {children}
    </LogoutContext.Provider>
  );
};

export { LogoutProvider, LogoutContext, useLogoutContext }
