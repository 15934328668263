import React, { useEffect, useRef, useState } from 'react';
import styled, { useTheme } from 'styled-components';

import Box, { boxStyle, IBoxProps } from 'components/atoms/Box/Box';
import Button from 'components/atoms/Button/Button';
import FolderButton from 'components/atoms/FolderButton/FolderButton';
import Icon from 'components/atoms/Icon/Icon';
import NewProjectButton from 'components/atoms/NewProjectButton/NewProjectButton';
import Text from 'components/atoms/Text/Text';

import {
  ProjectBaseFragment,
  ProjectStatus,
  Roles,
  useEditProjectMutation,
} from 'graph/generated.graphql';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { setModal } from 'redux/modal/modalSlice';
import { selectViewer } from 'redux/viewer/viewerSlice';
import { defaultDateFormat } from 'utils/transformers/momentFormats';
import { convertBytesToMb } from 'utils/transformers/text';

import { getDropDownMenuItems } from './DropDownMenu.factory';
import { IProjectsListProps } from './ProjectsList.interface';

const ProjectsListWrapper = styled(Box)`
  background-color: ${({ theme }) => theme.colors.beige};
`;
const FirstListItem = styled.li`
  align-self: flex-end;
`;
const List = styled.ul<IBoxProps>`
  ${boxStyle}
  display: flex;
  overflow-x: scroll;
  max-width: 100vw;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const ArrowRight = styled(Button)<{ show: boolean }>`
  background: linear-gradient(
    270deg,
    rgba(241, 237, 233, 0.95) 60.71%,
    rgba(241, 237, 233, 0) 105%
  );
  z-index: 3;
  position: absolute;
  right: 0;
  transition: ease 200ms;
  opacity: 1;
  ${({ show }) => (show ? 'opacity: 0; pointer-events: none;' : null)}
`;
const ArrowLeft = styled(ArrowRight)`
  transform: matrix(-1, 0, 0, 1, 0, 0);
  left: 0;
`;
function ProjectsList({
  projects,
  onProjectChange,
  selectedProject,
}: IProjectsListProps) {
  const [isScrolledLeft, setIsScrolledLeft] = useState<boolean>(true);
  const [isScrolledRight, setIsScrolledRight] = useState<boolean>(true);
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectViewer);

  const [selectedFolderButtonRef, setSelectedFolderButtonRef] =
    useState<HTMLLIElement | null>(null);

  const showAddProjectModal = () =>
    dispatch(setModal({ showAddProjectModal: true }));
  const showRequestNewProjectModal = () =>
    dispatch(setModal({ showRequestNewProjectModal: true }));

  const ref = useRef<HTMLUListElement>(null);

  const leftArrowClickHandler = () => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      ref.current!.scrollLeft -= 40;
      scrollAmount += 10;
      if (scrollAmount >= 100) {
        window.clearInterval(slideTimer);
      }
    }, 25);
  };
  const rightArrowClickHandler = () => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      ref.current!.scrollLeft += 40;
      scrollAmount += 10;
      if (scrollAmount >= 100) {
        window.clearInterval(slideTimer);
      }
    }, 25);
  };

  useEffect(() => {
    window.addEventListener('resize', onScrollHandler);

    return () => {
      window.removeEventListener('resize', onScrollHandler);
    };
  }, []);

  const onScrollHandler = () => {
    setTimeout(() => {
      if (
        ref.current!.offsetWidth +
          ref.current!.scrollLeft -
          ref.current!.clientWidth <
        50
      ) {
        setIsScrolledLeft(true);
      }
      if (
        ref.current!.offsetWidth +
          ref.current!.scrollLeft -
          ref.current!.clientWidth >
        50
      ) {
        setIsScrolledLeft(false);
      }
      if (
        ref.current!.scrollLeft +
          ref.current!.clientWidth -
          ref.current!.scrollWidth >
        -50
      ) {
        setIsScrolledRight(true);
      }
      if (
        ref.current!.scrollLeft +
          ref.current!.clientWidth -
          ref.current!.scrollWidth <
        -50
      ) {
        setIsScrolledRight(false);
      }
    }, 300);
  };

  useEffect(() => {
    setIsScrolledRight(window.innerWidth > 390 * (projects.length + 1));
  }, [projects]);
  const onPendingProjectClick = (project: ProjectBaseFragment) => {
    if (user?.roles === Roles.VidicoAdmin) {
      dispatch(
        setModal({
          showAddProjectModal: true,
          isEditProjectMode: true,
          projectToEdit: project,
        })
      );
    }
  };

  useEffect(() => {
    if (selectedFolderButtonRef && ref) {
      selectedFolderButtonRef.scrollIntoView({
        behavior: 'smooth',
        inline: 'center',
      });
    }
  }, [selectedFolderButtonRef]);

  const [editMutation] = useEditProjectMutation();
  const dropDownLockMenuCallBack = (project: ProjectBaseFragment) => {
    if (selectedProject) {
      editMutation({
        variables: {
          status:
            project.status === ProjectStatus.Locked
              ? ProjectStatus.Approved
              : ProjectStatus.Locked,
          projectId: project.id,
        },
      });
    }
  };

  return (
    <ProjectsListWrapper px={8} pt={4}>
      <Box>
        <Box mb={1}>
          <Text md semibold color={theme.colors.grey700}>
            Projects
          </Text>
        </Box>
        <List onScroll={onScrollHandler} ref={ref} py={3} gap={24}>
          <ArrowLeft
            show={isScrolledLeft}
            noStyle
            w={94}
            h={88}
            onClick={leftArrowClickHandler}
          >
            <Icon
              size={24}
              ml={10}
              name="arrowForwardIos"
              color={theme.colors.vidicoBlack}
            />
          </ArrowLeft>
          <ArrowRight
            show={isScrolledRight}
            noStyle
            w={94}
            h={88}
            onClick={rightArrowClickHandler}
          >
            <Icon
              size={24}
              ml={10}
              name="arrowForwardIos"
              color={theme.colors.vidicoBlack}
            />
          </ArrowRight>
          {user?.roles !== Roles.VidicoAdmin && (
            <FirstListItem>
              <NewProjectButton onClick={showRequestNewProjectModal} />
            </FirstListItem>
          )}
          {projects.map((project) => (
            <li
              key={project.id}
              ref={
                project.id === selectedProject?.id
                  ? setSelectedFolderButtonRef
                  : null
              }
            >
              <Box>
                <FolderButton
                  imageSrc={project.thumbnail}
                  title={project.name}
                  leftText={
                    project.status === ProjectStatus.Approved ||
                    project.status === ProjectStatus.Locked
                      ? defaultDateFormat(project.createdAt)
                      : 'Pending'
                  }
                  titleColor={
                    project.status === ProjectStatus.Pending
                      ? theme.colors.grey400
                      : undefined
                  }
                  textColor={
                    project.status === ProjectStatus.Pending
                      ? theme.colors.grey500
                      : undefined
                  }
                  rightText={
                    project.status === ProjectStatus.Approved ||
                    project.status === ProjectStatus.Locked
                      ? `${project.assetsCount} files - ${convertBytesToMb(
                          project.assetsSize
                        )}`
                      : ''
                  }
                  isSelected={project.id === selectedProject?.id}
                  onClick={() =>
                    project.status !== ProjectStatus.Pending
                      ? onProjectChange(project)
                      : onPendingProjectClick(project)
                  }
                  dropDownOption={
                    user?.roles !== Roles.Member
                      ? getDropDownMenuItems(
                          project,
                          dispatch,
                          user?.roles,
                          dropDownLockMenuCallBack
                        )
                      : undefined
                  }
                  isLocked={project.status === ProjectStatus.Locked}
                  companyId={project.companyId}
                />
              </Box>
            </li>
          ))}
          {user?.roles === Roles.VidicoAdmin && (
            <li>
              <Box mx={3}>
                <FolderButton
                  title="+ Add Project"
                  onClick={showAddProjectModal}
                />
              </Box>
            </li>
          )}
        </List>
      </Box>
    </ProjectsListWrapper>
  );
}

export default ProjectsList;
