import styled, { css } from 'styled-components';

import { IBoxProps } from 'components/atoms/Box/Box.interfaces';

/**
 * Base styled component
 *   - space it's number witch will be multiply by theme.space (default 4)
 * @example
 * <Flex
 *   w={} - px
 *   h={} - px
 *   p={} - space
 *   px={} - space
 *   py={} - space
 *   pt={} - space
 *   pb={} - space
 *   pl={} - space
 *   pr={} - space
 *   m={} - space
 *   mx={} - space
 *   my={} - space
 *   mt={} - space
 *   mb={} - space
 *   ml={} - space
 *   mr={} - space
 *   gap={} - px
 * />
 * */

const boxStyle = css<IBoxProps>`
  ${({ w }) => (w ? `width:${w}${typeof w === 'number' ? 'px' : ''};` : '')}
  ${({ h }) => (h ? `height:${h}${typeof h === 'number' ? 'px' : ''};` : '')}
  ${({ p, theme }) => (p ? `padding:${p * theme.space}px;` : '')}
  ${({ p, theme }) => (p ? `padding:${p * theme.space}px;` : '')}
  ${({ py, theme }) =>
    py
      ? `padding-top:${py * theme.space}px;padding-bottom:${
          py * theme.space
        }px;`
      : ''}
  ${({ px, theme }) =>
    px
      ? `padding-right:${px * theme.space}px;padding-left:${
          px * theme.space
        }px;`
      : ''}
  ${({ pt, theme }) => (pt ? `padding-top:${pt * theme.space}px;` : '')}
  ${({ pb, theme }) => (pb ? `padding-bottom:${pb * theme.space}px;` : '')}
  ${({ pr, theme }) => (pr ? `padding-right:${pr * theme.space}px;` : '')}
  ${({ pl, theme }) => (pl ? `padding-left:${pl * theme.space}px;` : '')}
  ${({ m, theme }) => (m ? `margin:${m * theme.space}px;` : '')}
  ${({ my, theme }) =>
    my
      ? `margin-top:${my * theme.space}px;margin-bottom:${my * theme.space}px;`
      : ''}
  ${({ mx, theme }) =>
    mx
      ? `margin-right:${mx * theme.space}px;margin-left:${mx * theme.space}px;`
      : ''}
  ${({ mt, theme }) => (mt ? `margin-top:${mt * theme.space}px;` : '')}
  ${({ mb, theme }) => (mb ? `margin-bottom:${mb * theme.space}px;` : '')}
  ${({ mr, theme }) => (mr ? `margin-right:${mr * theme.space}px;` : '')}
  ${({ ml, theme }) => (ml ? `margin-left:${ml * theme.space}px;` : '')}
  ${({ fullWidth }) => (fullWidth ? `width: 100%` : '')};
  ${({ gap }) => (gap ? `gap:${gap}px` : '')};
`;

const Box = styled.div<IBoxProps>`
  ${boxStyle}
`;

export default Box;
export { boxStyle };
export type { IBoxProps };
