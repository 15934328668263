import { useTheme } from 'styled-components';

import Box from 'components/atoms/Box/Box';
import NoSuchAssetTypeCard from 'components/atoms/NoSuchAssetTypeCard/NoSuchAssetTypeCard';

import { FileType } from 'graph/generated.graphql';

function NoAssets({ type }: { type: FileType | undefined | null }) {
  const theme = useTheme();
  switch (type) {
    case FileType.Editing:
      return (
        <NoSuchAssetTypeCard
          icon="editingFileFilled"
          title="Live action editing files are huge."
          text="Way too big to send over the internet, but we can send it physically to you. Vidico can purchase a harddrive, load the footage, and send it to an address of your choosing."
          secondText={
            <>
              Send us an email at{' '}
              <a
                href="mailto:editingfiles@vidico.com"
                target="_blank"
                style={{ color: theme.colors.primary600 }}
                rel="noreferrer"
              >
                editingfiles@vidico.com
              </a>{' '}
              with your requirements, and we’ll get in touch.
            </>
          }
        />
      );
    case FileType.Cutdown:
      return (
        <NoSuchAssetTypeCard
          icon="cutdownsFilesFilled"
          title="Make your videos short, sharp and sharable."
          text="Deliver a more concise message with cut-downs. Video assets that are perfect for social media channels. Vidico can tailor your assets for 6s, 10s, 15s and 30s durations, or bundle them altogether."
          secondText={
            <>
              Get started today and send us an email at{' '}
              <a
                href="mailto:editingfiles@vidico.com"
                target="_blank"
                style={{ color: theme.colors.primary600 }}
                rel="noreferrer"
              >
                editingfiles@vidico.com
              </a>{' '}
              with your requirements, and we will be in touch.
            </>
          }
        />
      );
    case FileType.Caption:
      return (
        <NoSuchAssetTypeCard
          icon="captionsFileFilled"
          title="Make your videos accessible to all."
          text="Reach a wider audience, and show how much your care. Vidico can layer your video assets with closed caption SRT files. Improving accessibility, understanding and efficiency."
          secondText={
            <>
              Get started today and send us an email at{' '}
              <a
                href="mailto:editingfiles@vidico.com"
                target="_blank"
                style={{ color: theme.colors.primary600 }}
                rel="noreferrer"
              >
                editingfiles@vidico.com
              </a>{' '}
              with your requirements, and we will be in touch.
            </>
          }
        />
      );
    default:
      return <Box id="no-assets" w="100%" h="calc(100vh - 300px)" />;
  }
}

export default NoAssets;
