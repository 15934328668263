import React, { useState } from 'react';
import { Image } from 'react-bootstrap';
import styled from 'styled-components';

import { IImageProps } from './Image.interface';

import Box from '../Box/Box';
import Flex from '../Flex/Flex';
import Icon from '../Icon/Icon';

const StyledImage = styled(Image)<{ borderradius?: number }>`
  border-radius: ${({ borderradius }) => `${borderradius}px;`};
  object-fit: cover;
`;
const NoImageBoxWrapper = styled(Flex)<{ borderRadius?: number }>`
  border-radius: ${({ borderRadius }) => `${borderRadius}px;`};
  background-color: ${({ theme }) => theme.colors.grey100};
`;
function CustomImage({
  width,
  height,
  src,
  icon,
  borderRadius = 6,
  ...args
}: IImageProps) {
  const [isImageError, setIsImageError] = useState(false);
  return (
    <Box>
      {!src || isImageError ? (
        <NoImageBoxWrapper
          w={width}
          h={height}
          justifyContent="center"
          alignItems="center"
          borderRadius={borderRadius}
        >
          {icon && <Icon {...icon} />}
        </NoImageBoxWrapper>
      ) : (
        <StyledImage
          {...args}
          width={width}
          height={height}
          src={src}
          borderradius={borderRadius}
          onError={() => setIsImageError(true)}
        />
      )}
    </Box>
  );
}

export { CustomImage as Image };
