import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import styled, { useTheme } from 'styled-components';

import AssetCard from 'components/atoms/AssetCard/AssetCard';
import { AssetsUpload } from 'components/atoms/AssetsUpload/AssetsUpload';
import Box from 'components/atoms/Box/Box';
import Flex from 'components/atoms/Flex/Flex';
import IconButton from 'components/atoms/IconButton/IconButton';
import List from 'components/atoms/List/List';
import RemovePopup from 'components/atoms/RemovePopup/RemovePopup';
import Text from 'components/atoms/Text/Text';
import ContextMenu from 'components/molecules/ContextMenu/ContextMenu';
import { IDotsDropDownMenuOption } from 'components/molecules/DotsDropDownMenu/DotsDropDownMenu.interface';
import Filter from 'components/molecules/Filter/Filter';

import DeleteAssetsModal from 'containers/Workspace/DeleteAssetsModal/DeleteAssetsModal';
import {
  Asset,
  AssetFragment,
  FileType,
  Roles,
  useAssetDownloadMutation,
  useMarkAssetsAsFavoriteMutation,
  useNewAssetsInfoQuery,
  useOnAssetsChangeSubscription,
  usePrepareForDownloadMutation,
} from 'graph/generated.graphql';
import {
  selectFilterByLocalization,
  selectFilterByType,
} from 'redux/filter/filterSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { setModal } from 'redux/modal/modalSlice';
import { selectViewer } from 'redux/viewer/viewerSlice';
import { BackgroundUploadContext } from 'utils/context/backgroundUploadContext/backgroundUploadContext';
import { DragSelectContext } from 'utils/context/dragSelectContext/dragSelectContext';
import { getThumbnailIconByFileType } from 'utils/helpers/workspace';
import { useOnRightClick } from 'utils/hooks/useOnRightClick/useOnRightClick';

import ListItem from './components/ListItem/ListItem';
import NoAssets from './components/NoAssets/NoAssets';
import { IViewAssets } from './ViewAssets.interface';

import ReferralRewardContent from '../../components/molecules/ReferralRewardContent/ReferralRewardContent';
import ReferralRewardPopUp from '../../components/molecules/ReferralRewardPopUp/ReferralRewardPopUp';
import {
  selectIsFirstEnter,
  setFirstEnter,
} from '../../redux/referralReward/referralrewardSlice';
import { hasS3AndMux } from '../../utils/assets/hasS3AndMux';
import { DRAG_SELECT_END } from '../constants/drag-drop-asset.constant';

const OverlayMenu = styled(Flex)<{ show: boolean }>`
  ${({ show }) => (show ? null : 'opacity: 0; pointer-events: none;')};
  transition: 500ms all ease;
  z-index: 30 !important;
  position: fixed;
  bottom: 20px;
  left: 50%;
  background-color: ${({ theme }) => theme.colors.grey900};
  transform: translate(-50%);
  border-radius: 12px;
  color: ${({ theme }) => theme.colors.base};
`;
function ViewAssets({ assets, project, onRefetch, isLoading }: IViewAssets) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const viewer = useAppSelector(selectViewer);

  const isFirstEnter = useAppSelector(selectIsFirstEnter);
  const [openReferralPopUp, setOpenReferralPopUp] = useState(false);

  const dragAreaRef = useRef(null);
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
  const { state, handleUpload, setNewState } = useContext(
    BackgroundUploadContext
  );
  const projectName = project.name;
  const projectId = project.id;
  const localization = useAppSelector(selectFilterByLocalization)?.eq;
  const type = useAppSelector(selectFilterByType)?.eq;
  const uploadingAssets = Object.keys(state[projectId] || {});
  const { ds, setSettings } = useContext(DragSelectContext);
  const [selectedAssetIds, setSelectedAssetIds] = useState<string[]>([]);
  const isVidicoAdmin = viewer?.roles === Roles.VidicoAdmin;
  const isMember = viewer?.roles === Roles.Member;

  const assetIds: Array<string> = useMemo(
    () => assets.map(({ id }) => id || ''),
    [assets]
  );
  const assetIdsMap: Record<string, boolean> = useMemo(
    () => assetIds.reduce((obj, id) => ({ ...obj, [id]: true }), {}),
    [assetIds]
  );
  const { data: newAssetInfoData } = useNewAssetsInfoQuery({
    variables: {
      projectId: project.id,
    },
    fetchPolicy: 'no-cache',
  });
  const [prepareForDownload] = usePrepareForDownloadMutation();
  const [assetsDownload] = useAssetDownloadMutation({
    onCompleted({ assetDownload }) {
      if (hasS3AndMux(assetDownload)) {
        dispatch(
          setModal({
            showVideoTypeSelectionModal: true,
            videoTypeFileToDownload: assetDownload.assets,
          })
        );
      } else {
        dispatch(
          setModal({
            showPopupMessage: true,
            popupMessageInfo: { text: 'Download started' },
            fileToDownload: assetDownload.assets,
          })
        );
      }
    },
    onError() {
      dispatch(
        setModal({
          showPopupMessage: true,
          popupMessageInfo: { text: 'Download failed' },
        })
      );
    },
  });
  const [markAsFavorite] = useMarkAssetsAsFavoriteMutation({
    onCompleted() {
      const message =
        selectedAssetIds.length === 1
          ? `${assets.find((item) => item.id === selectedAssetIds[0])?.name} ${
              isStaredCountInSelectedAssets === selectedAssetIds.length
                ? 'removed'
                : 'added'
            }  to Favourites`
          : `${selectedAssetIds.length} assets added to Favourites`;
      dispatch(
        setModal({
          showPopupMessage: true,
          popupMessageInfo: { text: message },
        })
      );
      ds?.clearSelection(true);
    },
  });
  const onDrop = useCallback(
    (files: any) => {
      if (isVidicoAdmin) {
        handleUpload({ projectId, files, localization });
      }
    },
    [isVidicoAdmin, handleUpload, projectId, localization]
  );
  useOnAssetsChangeSubscription({
    variables: {
      projectId,
      localization: localization!,
    },
    skip: !localization,
    shouldResubscribe: true,
    onData(options) {
      const asset = options.data.data?.subscribeAsset as Asset;
      if (asset && state && state[asset.projectId][asset.id]) {
        setNewState(asset?.projectId, asset.id, {
          errorMessage: '',
          progress: 1,
          uploaded: true,
          asset,
        });
      }
    },
    onError(error) {
      // eslint-disable-next-line no-console
      console.log('subscription error: ', error);
    },
  });
  const onAssetsDelete = useCallback(() => {
    dispatch(
      setModal({
        showRemoveAssetsModal: true,
        assetsToRemove: assets.filter((a) =>
          selectedAssetIds.includes(a.id || '')
        ),
      })
    );
  }, [assets, dispatch, selectedAssetIds]);
  const singleSelectedMenu = useMemo(
    (): IDotsDropDownMenuOption[] => [
      {
        key: `contextShare`,
        icon: 'iosShareMenu',
        label: 'Share',
        onClick: () =>
          dispatch(
            setModal({
              showSharedLinkModal: true,
              sharedLinkData: {
                projectId,
                assetIds: selectedAssetIds,
                isProject: false,
              },
            })
          ),
      },
      {
        icon: 'driveFileRenameOutline',
        label: 'Rename',
        key: 'contextRename',
        hide: isMember,
        onClick: () =>
          dispatch(
            setModal({
              showRenameAssetModal: true,
              renameAssetInfo:
                assets.find(
                  (a: AssetFragment) => a.id === selectedAssetIds[0]
                ) || {},
            })
          ),
      },
      {
        key: 'contextDownload',
        icon: 'download',
        label: 'Download',
        onClick: () =>
          assetsDownload({
            variables: { assetsId: selectedAssetIds },
          }),
      },
      {
        key: 'contextDelete',
        icon: 'delete',
        label: 'Delete',
        hide: isMember,
        onClick: onAssetsDelete,
      },
      {
        key: `contextFavourite`,
        icon: 'star',
        label: 'Favourite',
        onClick: () =>
          markAsFavorite({ variables: { assetsId: selectedAssetIds } }),
        hide: isVidicoAdmin,
      },
    ],
    [
      assets,
      assetsDownload,
      dispatch,
      isMember,
      isVidicoAdmin,
      markAsFavorite,
      onAssetsDelete,
      projectId,
      selectedAssetIds,
    ]
  );
  const multiSelectedMenu = useMemo(
    (): IDotsDropDownMenuOption[] | undefined => [
      {
        key: 'contextShare',
        icon: 'iosShareMenu',
        label: 'Share',
        onClick: () =>
          dispatch(
            setModal({
              showSharedLinkModal: true,
              sharedLinkData: {
                projectId,
                assetIds: selectedAssetIds,
                isProject: false,
              },
            })
          ),
      },
      {
        key: 'contextDownload',
        icon: 'download',
        label: 'Download',
        onClick: () =>
          assetsDownload({
            variables: { assetsId: selectedAssetIds },
          }),
      },
      {
        key: 'contextDelete',
        icon: 'delete',
        label: 'Delete',
        hide: isMember,
        onClick: onAssetsDelete,
      },

      {
        key: 'contextFavourite',
        icon: 'star',
        label: 'Favourite',
        onClick: () =>
          markAsFavorite({ variables: { assetsId: selectedAssetIds } }),
        hide: isVidicoAdmin,
      },
    ],
    [
      assetsDownload,
      dispatch,
      isMember,
      isVidicoAdmin,
      markAsFavorite,
      onAssetsDelete,
      projectId,
      selectedAssetIds,
    ]
  );
  /** refetch assets mutation effect
   * when assets uploaded we need to refetch assets to apply filter */
  useEffect(() => {
    const diff = uploadingAssets.filter((x) => !assetIds.includes(x));
    const diffUploaded = diff.filter(
      (fileId) =>
        state[projectId][fileId].uploaded &&
        ((state[projectId][fileId].asset?.type !== FileType.Video &&
          state[projectId][fileId].asset?.type !== FileType.Cutdown &&
          state[projectId][fileId].asset?.thumbnail) ||
          state[projectId][fileId].asset?.type === FileType.Editing ||
         (state[projectId][fileId].asset?.muxDownloadUrl))
    );
    if (diffUploaded.length) {
      onRefetch();
    }
  }, [assetIds, onRefetch, projectId, state, uploadingAssets]);

  // ds shift hold select implementation
  const onItemClick = useCallback(
    // @ts-ignore
    (element) => (e) => {
      if (ds && element && e.shiftKey) {
        const firstSelected = assetIds.findIndex(
          (id) => id === selectedAssetIds[0]
        );

        if (firstSelected > -1) {
          const selectedIndex = assetIds.findIndex((id) => id === element.id);

          let toSelectIndex;
          if (selectedIndex < firstSelected) {
            toSelectIndex = Array.from({
              length: firstSelected - selectedIndex + 1,
            })
              .map((_, i) => selectedIndex + i)
              .reverse();
          } else {
            toSelectIndex = Array.from({
              length: selectedIndex - firstSelected + 1,
            }).map((_, i) => firstSelected + i);
          }
          const items = toSelectIndex.map(
            (index) => element.parentElement?.childNodes[index]
          );
          ds.setSelection(items, true);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedAssetIds, assetIds]
  );

  // ds subscribing to a callback
  useEffect(() => {
    ds?.clearSelection();
    ds?.subscribe(DRAG_SELECT_END, (e: any) => {
      const selectedIds = e.items.map((item: { id: string }) => item.id);
      if (!selectedIds.length) {
        setShowContextMenu(false);
      }
      setSelectedAssetIds(selectedIds);
    });
  }, [ds]);
  useEffect(() => {
    ds?.clearSelection(true);
  }, [ds, assets]);
  const { current } = dragAreaRef;
  useEffect(() => {
    setTimeout(() => {
      if (current) {
        setSettings({
          area: current,
        });
      }
    });
  }, [current, setSettings]);
  useEffect(() => {
    if (projectId && localization) {
      prepareForDownload({
        variables: { projectId, localization },
      });
    }
  }, [localization, prepareForDownload, projectId]);

  const handleContextMenu = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      if (
        (event.target as HTMLElement).id !== 'listOfAssets' &&
        selectedAssetIds.length
      ) {
        setAnchorPoint({ x: event.pageX, y: event.pageY });
        setShowContextMenu(true);
      }
    },
    [setAnchorPoint, selectedAssetIds]
  );

  const handleClick = useCallback(
    () => (showContextMenu ? setShowContextMenu(false) : null),
    [showContextMenu]
  );
  useOnRightClick(dragAreaRef, handleClick, handleContextMenu);

  useEffect(() => {
    setTimeout(() => {
      if (isFirstEnter && viewer?.roles !== Roles.VidicoAdmin) {
        setOpenReferralPopUp(true);
      }
    }, 5000);
  }, [isFirstEnter]);
  const handleCloseReferralReward = useCallback(() => {
    setOpenReferralPopUp(false);
    dispatch(setFirstEnter(false));
  }, []);

  const isOneSelected = selectedAssetIds.length === 1;
  const isStaredCountInSelectedAssets = useMemo(
    () =>
      viewer?.favorites?.filter((f) => selectedAssetIds.includes(f.assetId))
        .length || 0,
    [selectedAssetIds, viewer?.favorites]
  );
  const isNoAssets = !isLoading && !assets.length && !uploadingAssets.length;
  const notMemberRole = viewer?.roles !== Roles.Member;
  return (
    <>
      <Filter
        project={project}
        newAssetInfo={newAssetInfoData?.newAssetsInfo}
      />
      <Box>
        <AssetsUpload projectName={projectName} onDrop={onDrop}>
          {isNoAssets ? (
            <NoAssets type={type} />
          ) : (
            <List ref={dragAreaRef} px={8} py={4} id="listOfAssets">
              {assets.map((asset) => (
                <ListItem
                  key={asset.id}
                  asset={asset}
                  project={project}
                  onItemClick={onItemClick}
                />
              ))}
              <ReferralRewardPopUp
                isOpen={openReferralPopUp}
                callBack={handleCloseReferralReward}
              >
                <ReferralRewardContent />
              </ReferralRewardPopUp>
              {uploadingAssets?.map((fileId) => {
                // here we display only not uploaded assets
                // and exact localization and type
                const file = state[projectId][fileId];
                if (!file) {
                  return null;
                }
                const { asset, uploaded } = file;
                const matchFilter =
                  asset?.localization === localization &&
                  (asset?.type === type || type === undefined);
                const isUploading =
                  !uploaded ||
                  // !!!important we mark item
                  // as uploading till we do not get real assets from refetch query
                  (uploaded && !assetIdsMap[fileId]);
                return isUploading && matchFilter && !isLoading ? (
                  <li key={fileId}>
                    <AssetCard
                      thumbnailIcon={getThumbnailIconByFileType(
                        state[projectId][fileId].asset?.type
                      )}
                      progress={state[projectId][fileId].progress}
                      errorMessage={state[projectId][fileId].errorMessage}
                      asset={state[projectId][fileId].asset || {}}
                    />
                  </li>
                ) : null;
              })}
            </List>
          )}
        </AssetsUpload>
      </Box>
      <OverlayMenu
        alignItems="center"
        justifyContent="space-between"
        show={!!selectedAssetIds.length}
        w={540}
        px={12}
        py={3}
      >
        <Text sm regular>
          <b>{selectedAssetIds.length}</b>{' '}
          {`${isOneSelected ? 'item' : 'items'} selected`}
        </Text>

        <Flex gap={8}>
          <OverlayTrigger
            key="share-menu-item"
            popperConfig={{
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, 10],
                  },
                },
              ],
            }}
            overlay={
              <Tooltip
                style={{ inset: 'auto auto 10px 0px' }}
                id="share-menu-item"
              >
                <Box p={1}>Share</Box>
              </Tooltip>
            }
          >
            <IconButton
              buttonSize={24}
              iconSize={24}
              iconName="iosShareMenu"
              iconColor={theme.colors.base}
              hoverColor={theme.colors.hoverColor}
              onClick={() =>
                dispatch(
                  setModal({
                    showSharedLinkModal: true,
                    sharedLinkData: {
                      projectId,
                      assetIds: selectedAssetIds,
                      isProject: false,
                    },
                  })
                )
              }
            />
          </OverlayTrigger>
          {isOneSelected && notMemberRole && (
            <OverlayTrigger
              key="rename-menu-item"
              popperConfig={{
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, 10],
                    },
                  },
                ],
              }}
              overlay={
                <Tooltip
                  style={{ inset: 'auto auto 10px 0px' }}
                  id="rename-menu-item"
                >
                  <Box p={1}>Rename</Box>
                </Tooltip>
              }
            >
              <IconButton
                buttonSize={24}
                iconSize={24}
                iconName="driveFileRenameOutline"
                iconColor={theme.colors.base}
                hoverColor={theme.colors.hoverColor}
                onClick={() =>
                  dispatch(
                    setModal({
                      showRenameAssetModal: true,
                      renameAssetInfo:
                        assets.find(
                          (a: AssetFragment) => a.id === selectedAssetIds[0]
                        ) || {},
                    })
                  )
                }
              />
            </OverlayTrigger>
          )}
          <OverlayTrigger
            key="downloads-menu-item"
            popperConfig={{
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, 10],
                  },
                },
              ],
            }}
            overlay={
              <Tooltip
                style={{ inset: 'auto auto 10px 0px' }}
                id="downloads-menu-item"
              >
                <Box p={1}>Download</Box>
              </Tooltip>
            }
          >
            <IconButton
              buttonSize={24}
              iconSize={24}
              iconName="download"
              iconColor={theme.colors.base}
              hoverColor={theme.colors.hoverColor}
              onClick={() =>
                assetsDownload({
                  variables: { assetsId: selectedAssetIds },
                })
              }
            />
          </OverlayTrigger>
          {notMemberRole && !!selectedAssetIds.length && (
            <OverlayTrigger
              key="delete-menu-item"
              popperConfig={{
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, 10],
                    },
                  },
                ],
              }}
              overlay={
                <Tooltip
                  style={{ inset: 'auto auto 10px 0px' }}
                  id="delete-menu-item"
                >
                  <Box p={1}>Delete</Box>
                </Tooltip>
              }
            >
              <IconButton
                buttonSize={24}
                iconSize={24}
                iconName="delete"
                iconColor={theme.colors.base}
                hoverColor={theme.colors.hoverColor}
                onClick={() =>
                  dispatch(
                    setModal({
                      showRemoveAssetsModal: true,
                      assetsToRemove: assets.filter((a) =>
                        selectedAssetIds.includes(a.id || '')
                      ),
                    })
                  )
                }
              />
            </OverlayTrigger>
          )}
          {!isVidicoAdmin && (
            <OverlayTrigger
              key="favourite-menu-item"
              popperConfig={{
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, 10],
                    },
                  },
                ],
              }}
              overlay={
                <Tooltip
                  style={{ inset: 'auto auto 10px 0px' }}
                  id="favourite-menu-item"
                >
                  <Box p={1}>
                    {isStaredCountInSelectedAssets === selectedAssetIds.length
                      ? 'Unfavourite'
                      : 'Favourite'}
                  </Box>
                </Tooltip>
              }
            >
              <IconButton
                buttonSize={24}
                iconSize={24}
                iconName={
                  isStaredCountInSelectedAssets > 0
                    ? isStaredCountInSelectedAssets === selectedAssetIds.length
                      ? 'starFilled'
                      : 'starHalf'
                    : 'star'
                }
                iconColor={theme.colors.base}
                hoverColor={theme.colors.hoverColor}
                onClick={() =>
                  markAsFavorite({
                    variables: { assetsId: selectedAssetIds },
                  })
                }
              />
            </OverlayTrigger>
          )}
        </Flex>
      </OverlayMenu>
      {showContextMenu && (
        <ContextMenu
          anchorPoint={anchorPoint}
          menuTitle={
            isOneSelected
              ? projectName
              : `${selectedAssetIds.length} items selected`
          }
          menuItems={isOneSelected ? singleSelectedMenu : multiSelectedMenu}
        />
      )}
      <DeleteAssetsModal />
      <RemovePopup deselect={() => ds?.clearSelection(true)} />
    </>
  );
}

export default ViewAssets;
