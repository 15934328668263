import styled from 'styled-components';

import { getFont } from 'utils/theme/typography';

import { ITextareaProps } from './Textarea.interface';

import { getErrorTypeColor } from '../FormControl/FormControl';

const Textarea = styled.textarea<ITextareaProps>`
  /* resize: none; */
  display: block;
  padding: 10px 14px;
  gap: 10px;
  border-radius: 8px;
  outline: none;
  width: calc(100% - 28px);
  transition: all 0ms;
  box-sizing: content-box;
  border: 1px solid
    ${({ theme, $errorType }) => getErrorTypeColor(theme, $errorType)};
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  ::placeholder {
    ${({ theme }) => getFont({ theme, weight: { regular: true } })}
    color: ${({ theme }) => theme.colors.grey900};
  }
  &:focus {
    margin: -1px;
    border: 2px solid ${({ theme }) => theme.colors.primary600};
  }
  &:focus-visible {
    border: ${({ theme, $errorType }) =>
      `2px solid ${
        getErrorTypeColor(theme, $errorType) === theme.colors.grey900
          ? theme.colors.primary600
          : getErrorTypeColor(theme, $errorType)
      }; margin: -1px;`};
  }
`;

export default Textarea;
