import styled from 'styled-components';

import { boxStyle } from '../Box/Box';
import { IBoxProps } from '../Box/Box.interfaces';

const ListItem = styled.li<IBoxProps>`
  border-radius: 6px;
  overflow: hidden;
  border: 2px solid transparent;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.grey100};
    border: 2px solid ${({ theme }) => theme.colors.grey100};
    box-shadow: 0px 0px 0px 4px ${({ theme }) => theme.colors.grey100};
  }
  ${boxStyle}
`;
export default ListItem;
