import { Modal, ModalProps } from 'react-bootstrap';
import styled from 'styled-components';

import Flex from 'components/atoms/Flex/Flex';

const ModalHeader = styled(Modal.Header)`
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  border: 0;
`;

type Props = ModalProps & {
  width?: number;
};
const ModalStyled = styled(Modal)<Props>`
  .modal-container {
    max-width: ${({ width }) => `${width}px`} !important;
  }
  .modal-content {
    border: none;
    box-shadow: ${({ theme }) => theme.shadow.xlShadow};
  }
`;
function BaseModal({ onHide, children, ...args }: Props) {
  return (
    <ModalStyled
      {...args}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="modal-container"
    >
      <ModalHeader closeButton />
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        p={6}
      >
        {children}
      </Flex>
    </ModalStyled>
  );
}
export { BaseModal as Modal };
