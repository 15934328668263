import styled, { useTheme } from 'styled-components';

import Text from 'components/atoms/Text/Text';

import Box from '../../atoms/Box/Box';
import Flex from '../../atoms/Flex/Flex';

const StyledLink = styled.a<{ w?: number }>`
  ${({ w }) => (w ? `width:${w}${typeof w === 'number' ? 'px' : ''};` : '')}
  display: inline-block;
  color: ${({ theme }) => theme.colors.primary600};
`;

const StyledBorder = styled.div<{ height?: number; ml?: number }>`
  ${({ height }) =>
    height ? `height:${height}${typeof height === 'number' ? 'px' : ''};` : ''}
  margin-right: 40px;
  margin-left: ${({ ml }) => `${ml}px`};
  border-left: 1px solid ${({ theme }) => theme.colors.grey200};
  &:after {
    content: '';
  }
`;

const StyledText = styled(Text)<{ w?: number; minW?: number }>`
  display: inline-block;
  ${({ w }) => (w ? `width:${w}${typeof w === 'number' ? 'px' : ''};` : '')}
  ${({ w }) => (w ? `min-width:${w}${typeof w === 'number' ? 'px' : ''};` : '')}
`;

function ReferralRewardContent() {
  const theme = useTheme();
  const headerLeft = 'Come to a ‘lunch and learn’ with a video strategist';
  const headerRight = 'Refer a new video project';

  const linkLeft =
    'Get a free $100 gift card, or donate the amount to charity →';
  const linkRight = 'Earn up to $1,000 for referring a new project →';
  return (
    <Flex flexDirection="column">
      <Flex px={4} flexDirection="row" alignItems="center">
        <StyledText
          w={179.5}
          wordBreak="break-word"
          sm
          color={theme.colors.grey600}
        >
          {headerLeft}
        </StyledText>
        <StyledBorder height={31} ml={26} />
        <Text sm color={theme.colors.grey600}>
          {headerRight}
        </Text>
      </Flex>
      <Flex px={4} flexDirection="row" alignItems="center">
        <Box ml={50}>
          <Text sm color={theme.colors.grey500}>
            or
          </Text>
        </Box>
      </Flex>
      <Flex px={4} alignItems="center">
        <StyledLink
          w={180}
          href="https://vidico.com/lunch-learn/"
          target="_blank"
        >
          <Text sm>{linkLeft}</Text>
        </StyledLink>
        <StyledBorder height={50} ml={25.5} />
        <StyledLink
          w={121}
          href="https://vidico.com/partnership"
          target="_blank"
        >
          <Text sm>{linkRight}</Text>
        </StyledLink>
      </Flex>
    </Flex>
  );
}

export default ReferralRewardContent;
