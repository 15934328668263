import React, { useContext, useEffect } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

import {
  useDeleteProjectMutation,
  useRemoveAssetsMutation,
} from 'graph/generated.graphql';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  selectAssetsToRemove,
  selectRemovePopupInfo,
  selectRemoveProjectInfo,
  setModal,
} from 'redux/modal/modalSlice';
import { RoutesPath } from 'utils/constants/routes';
import { BackgroundUploadContext } from 'utils/context/backgroundUploadContext/backgroundUploadContext';
import { useTimer } from 'utils/hooks/useTimer/useTimer';

import { IRemovePopupProps } from './RemovePopup.interface';

import Box from '../Box/Box';
import Button from '../Button/Button';
import Flex from '../Flex/Flex';
import Text from '../Text/Text';

const PopupMessageWrapper = styled(Flex)<{ show: boolean }>`
  ${({ show }) => (show ? null : 'opacity: 0; pointer-events: none;')};
  transition: 600ms all ease;
  z-index: 30 !important;
  position: fixed;
  bottom: 80px;
  left: 50%;
  transform: translate(-50%);
  background-color: ${({ theme }) => theme.colors.grey900};
  border-radius: 9px;
`;
const ButtonStyled = styled(Button)`
  text-decoration: underline;
`;
function RemovePopup({ deselect, ...args }: IRemovePopupProps) {
  const theme = useTheme();
  const project = useAppSelector(selectRemoveProjectInfo);
  const info = useAppSelector(selectRemovePopupInfo);
  const assets = useAppSelector(selectAssetsToRemove);
  const dispatch = useAppDispatch();
  const isViewAssetById = useMatch(RoutesPath.ViewAssetById);
  const isHomeViewAssetById = useMatch(RoutesPath.HomeViewAssetById);
  const isSingleAssetDelete = isViewAssetById || isHomeViewAssetById;
  const navigate = useNavigate();
  const { seconds, start, stop, pause } = useTimer({ seconds: 5 });
  const assetsId = assets?.map((asset) => asset.id);
  const { removeAssetFromState } = useContext(BackgroundUploadContext);

  const [removeAssets] = useRemoveAssetsMutation({
    onCompleted() {
      if (isSingleAssetDelete) {
        navigate(-1);
      }
      if (assets) {
        assets.forEach(({ projectId, id }) =>
          removeAssetFromState(projectId, id)
        );
      }
      onCloseModal();
    },
    onError() {
      onCloseModal();
    },
  });
  const [removeProject] = useDeleteProjectMutation({
    onError() {
      onCloseModal();
    },
  });
  const onRemove = () => {
    info?.isProjectRemoved
      ? removeProject({
          variables: { projectId: project?.id },
          refetchQueries: ['projectsList', 'newAssetsInfo'],
        })
      : removeAssets({
          variables: { assetsId },
          refetchQueries: [
            'getAssets',
            'projectsList',
            'newAssetsInfo',
            'userById',
          ],
        });

    onCloseModal();
  };
  const onCloseModal = () => {
    stop();
    dispatch(setModal({ removePopupInfo: undefined }));
  };
  const { showRemovePopup = false } = info || {};
  useEffect(() => {
    if (info?.showRemovePopup) {
      start();
      if (deselect) {
        deselect();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showRemovePopup]);
  useEffect(() => {
    if (seconds === 0) {
      pause();
      onRemove();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seconds]);

  return (
    <PopupMessageWrapper
      show={showRemovePopup}
      px={12}
      py={3}
      alignItems="center"
      justifyContent="space-between"
      w={540}
      {...args}
    >
      <Box>
        {info?.isProjectRemoved ? (
          <Text sm regular color={theme.colors.base}>
            Deleted <b>{project?.name}</b>{' '}
          </Text>
        ) : (
          <Text sm regular color={theme.colors.base}>
            Deleted <b>{assets?.length}</b>{' '}
            {`${assets?.length === 1 ? 'item' : 'items'} deleted `}
          </Text>
        )}
      </Box>
      <Flex>
        <ButtonStyled sm regular noStyle onClick={onRemove}>
          close
        </ButtonStyled>
        <ButtonStyled sm regular noStyle onClick={onCloseModal}>
          {`undo (${seconds}s)`}
        </ButtonStyled>
      </Flex>
    </PopupMessageWrapper>
  );
}

export default RemovePopup;
