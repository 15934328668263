import {useContext, useEffect} from 'react';
import {AuthContext, IAuthContext} from "react-oauth2-code-pkce";
import { useSelector } from 'react-redux';

import { selectViewer } from 'redux/viewer/viewerSlice';

import {useLogoutContext} from "../../../utils/context/logoutContext/logoutContext";
import {SessionCheckIframeProvider} from "../../session-check-iframe";

function ProtectedRout({
  children,
}: {
  children: JSX.Element;
}): JSX.Element | null {
  const viewer = useSelector(selectViewer);
  const { token, loginInProgress, login, tokenData } = useContext<IAuthContext>(AuthContext)
  const { logoutIsNotInProgress } = useLogoutContext();
  useEffect(() => {
    if (!token && !loginInProgress && logoutIsNotInProgress) {     
       login();
    }
  });
  
  if (token && viewer?.id) {
    return <SessionCheckIframeProvider>{children}</SessionCheckIframeProvider>
  }

  return null
}

export default ProtectedRout;
