import React from 'react';
// import { useNavigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

import Flex from 'components/atoms/Flex/Flex';
import { Image } from 'components/atoms/Image/Image';
import Text from 'components/atoms/Text/Text';
import { DotsDropDownMenu } from 'components/molecules/DotsDropDownMenu/DotsDropDownMenu';

import { IAssetLineProps } from './AssetLine.interface';

import Button from '../../atoms/Button/Button';

const AssetLineWrapper = styled(Flex)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey200};
`;

function AssetLine({
                     boldText, text, image, menuItems, link
                   }: IAssetLineProps) {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <AssetLineWrapper
      fullWidth
      alignItems='center'
      justifyContent='space-between'
      py={1}
    >
      <Button noStyle color={theme.colors.inherit} onClick={() => {
          navigate(link);
      }}>
        <Flex alignItems='center' justifyContent='center' gap={12}>
          <Image {...image} />
          <Flex gap={4}>
            <Text sm bold>
              {`${boldText}`}
            </Text>
            <Text sm bold>
              ·
            </Text>
            <Text sm medium>
              {text}
            </Text>
          </Flex>
        </Flex>
      </Button>
      {menuItems && <DotsDropDownMenu menuItems={menuItems} />}
    </AssetLineWrapper>
  );
}

export default AssetLine;
