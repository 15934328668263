import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import styled, { useTheme } from 'styled-components';

import Box from 'components/atoms/Box/Box';
import Flex from 'components/atoms/Flex/Flex';
import IconButton from 'components/atoms/IconButton/IconButton';
import List from 'components/atoms/List/List';
import RemovePopup from 'components/atoms/RemovePopup/RemovePopup';
import Text from 'components/atoms/Text/Text';
import ContextMenu from 'components/molecules/ContextMenu/ContextMenu';
import { IDotsDropDownMenuOption } from 'components/molecules/DotsDropDownMenu/DotsDropDownMenu.interface';
import SearchFilter from 'components/molecules/SearchFilter/SearchFilter';

import DeleteAssetsModal from 'containers/Workspace/DeleteAssetsModal/DeleteAssetsModal';
import {
  AssetFragment,
  Roles,
  useAssetDownloadMutation,
  useMarkAssetsAsFavoriteMutation,
} from 'graph/generated.graphql';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { setModal } from 'redux/modal/modalSlice';
import { selectViewer } from 'redux/viewer/viewerSlice';
import { DragSelectContext } from 'utils/context/dragSelectContext/dragSelectContext';
import { useOnRightClick } from 'utils/hooks/useOnRightClick/useOnRightClick';

import ListItemForSearch from './components/ListItemForSearch/ListItemForSearch';
import { IViewSearchAssets } from './ViewSearchAssets.interface';

import { DRAG_SELECT_END } from '../constants/drag-drop-asset.constant';

const OverlayMenu = styled(Flex)<{ show: boolean }>`
  ${({ show }) => (show ? null : 'opacity: 0; pointer-events: none;')};
  transition: 500ms all ease;
  z-index: 3;
  position: fixed;
  bottom: 20px;
  left: 50%;
  background-color: ${({ theme }) => theme.colors.grey900};
  transform: translate(-50%);
  border-radius: 12px;
  color: ${({ theme }) => theme.colors.base};
`;
function ViewSearchAssets({ assets, projects }: IViewSearchAssets) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const viewer = useAppSelector(selectViewer);
  const dragAreaRef = useRef(null);
  const [isDownloaded, setIsDownloaded] = useState(false);
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
  const { ds, setSettings } = useContext(DragSelectContext);
  const [selectedAssetIds, setSelectedAssetIds] = useState<string[]>([]);
  const isVidicoAdmin = viewer?.roles === Roles.VidicoAdmin;

  const assetIds: Array<string> = useMemo(
    () => assets.map(({ id }) => id || ''),
    [assets]
  );

  const [assetsDownload] = useAssetDownloadMutation({
    onCompleted() {
      if (!isDownloaded) {
        onDownload();
        dispatch(
          setModal({
            showPopupMessage: true,
            popupMessageInfo: { text: 'Download started' },
          })
        );
      }
    },
    onError() {
      dispatch(
        setModal({
          showPopupMessage: true,
          popupMessageInfo: { text: 'Download failed' },
        })
      );
    },
  });
  const onDownload = () => {
    setTimeout(async () => {
      try {
        setIsDownloaded(true);
        const { data } = await assetsDownload({
          variables: { assetsId: selectedAssetIds },
        });
        dispatch(
          setModal({
            fileToDownload: data?.assetDownload.assets,
          })
        );
      } catch {
        dispatch(
          setModal({
            showPopupMessage: true,
            popupMessageInfo: { text: 'Download failed' },
          })
        );
      }
    }, 5000);
  };
  const [markAsFavorite] = useMarkAssetsAsFavoriteMutation({
    onCompleted() {
      const message =
        selectedAssetIds.length === 1
          ? `${assets.find((item) => item.id === selectedAssetIds[0])?.name} ${
              isStaredCountInSelectedAssets === selectedAssetIds.length
                ? 'removed'
                : 'added'
            }  to Favourites`
          : `${selectedAssetIds.length} assets added to Favourites`;
      dispatch(
        setModal({
          showPopupMessage: true,
          popupMessageInfo: { text: message },
        })
      );
      ds?.clearSelection(true);
    },
  });

  // ds shift hold select implementation
  const onItemClick = useCallback(
    // @ts-ignore
    (element) => (e) => {
      if (ds && element && e.shiftKey) {
        const firstSelected = assetIds.findIndex(
          (id) => id === selectedAssetIds[0]
        );

        if (firstSelected > -1) {
          const selectedIndex = assetIds.findIndex((id) => id === element.id);

          let toSelectIndex;
          if (selectedIndex < firstSelected) {
            toSelectIndex = Array.from({
              length: firstSelected - selectedIndex + 1,
            })
              .map((_, i) => selectedIndex + i)
              .reverse();
          } else {
            toSelectIndex = Array.from({
              length: selectedIndex - firstSelected + 1,
            }).map((_, i) => firstSelected + i);
          }
          const items = toSelectIndex.map(
            (index) => element.parentElement?.childNodes[index]
          );
          ds.setSelection(items, true);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedAssetIds, assetIds]
  );
  const singleSelectedMenu = useMemo(
    (): IDotsDropDownMenuOption[] => [
      {
        icon: 'driveFileRenameOutline',
        label: 'Rename',
        key: 'contextRename',
        onClick: () =>
          dispatch(
            setModal({
              showRenameAssetModal: true,
              renameAssetInfo:
                assets.find(
                  (a: AssetFragment) => a.id === selectedAssetIds[0]
                ) || {},
            })
          ),
      },
      {
        key: 'contextDownload',
        icon: 'download',
        label: 'Download',
        onClick: () =>
          assetsDownload({
            variables: { assetsId: selectedAssetIds },
          }),
      },
      {
        key: 'contextDelete',
        icon: 'delete',
        label: 'Delete',
        onClick: () =>
          dispatch(
            setModal({
              showRemoveAssetsModal: true,
              assetsToRemove: assets.filter((a) =>
                selectedAssetIds.includes(a.id || '')
              ),
            })
          ),
      },
      {
        key: `contextFavourite`,
        icon: 'star',
        label: 'Favourite',
        onClick: () =>
          markAsFavorite({ variables: { assetsId: selectedAssetIds } }),
        hide: isVidicoAdmin,
      },
    ],
    [
      assets,
      assetsDownload,
      dispatch,
      isVidicoAdmin,
      markAsFavorite,
      selectedAssetIds,
    ]
  );
  const multiSelectedMenu = useMemo(
    (): IDotsDropDownMenuOption[] | undefined => [
      {
        key: 'contextDownload',
        icon: 'download',
        label: 'Download',
        onClick: () =>
          assetsDownload({
            variables: { assetsId: selectedAssetIds },
          }),
      },
      {
        key: 'contextDelete',
        icon: 'delete',
        label: 'Delete',
        onClick: () =>
          dispatch(
            setModal({
              showRemoveAssetsModal: true,
              assetsToRemove: assets.filter((a) =>
                selectedAssetIds.includes(a.id || '')
              ),
            })
          ),
      },

      {
        key: 'contextFavourite',
        icon: 'star',
        label: 'Favourite',
        onClick: () =>
          markAsFavorite({ variables: { assetsId: selectedAssetIds } }),
        hide: isVidicoAdmin,
      },
    ],
    [
      assets,
      assetsDownload,
      dispatch,
      isVidicoAdmin,
      markAsFavorite,
      selectedAssetIds,
    ]
  );
  // ds subscribing to a callback
  useEffect(() => {
    ds?.clearSelection();
    ds?.subscribe(DRAG_SELECT_END, (e: any) => {
      const selectedIds = e.items.map((item: { id: string }) => item.id);
      setSelectedAssetIds(selectedIds);
    });
  }, [ds]);
  useEffect(() => {
    ds?.clearSelection(true);
  }, [ds, assets]);

  useEffect(() => {
    setTimeout(() => {
      if (dragAreaRef.current) {
        setSettings({
          area: dragAreaRef.current,
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleContextMenu = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      if (
        (event.target as HTMLElement).id !== 'listOfAssets' &&
        selectedAssetIds.length
      ) {
        setAnchorPoint({ x: event.pageX, y: event.pageY });
        setShowContextMenu(true);
      }
    },
    [setAnchorPoint, selectedAssetIds]
  );

  const handleClick = useCallback(
    () => (showContextMenu ? setShowContextMenu(false) : null),
    [showContextMenu]
  );
  useOnRightClick(dragAreaRef, handleClick, handleContextMenu);

  const isOneSelected = selectedAssetIds.length === 1;
  const isStaredCountInSelectedAssets = useMemo(
    () =>
      viewer?.favorites?.filter((f) => selectedAssetIds.includes(f.assetId))
        .length || 0,
    [selectedAssetIds, viewer?.favorites]
  );
  const notMemberRole = viewer?.roles !== Roles.Member;
  return (
    <>
      <SearchFilter projects={projects} />
      <Box>
        <List
          px={8}
          py={4}
          ref={dragAreaRef}
          h="calc(100vh - 136px)"
          id="listOfAssets"
        >
          {assets.map((asset) => (
            <ListItemForSearch
              key={asset.id}
              asset={asset}
              onItemClick={onItemClick}
            />
          ))}
        </List>
      </Box>
      <OverlayMenu
        alignItems="center"
        justifyContent="space-between"
        show={!!selectedAssetIds.length}
        w={540}
        px={12}
        py={3}
      >
        <Text sm regular>
          <b>{selectedAssetIds.length}</b>{' '}
          {`${isOneSelected ? 'item' : 'items'} selected`}
        </Text>
        <Flex gap={8}>
          {isOneSelected && notMemberRole && (
            <OverlayTrigger
              key="rename-menu-item"
              popperConfig={{
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, 10],
                    },
                  },
                ],
              }}
              overlay={
                <Tooltip
                  style={{ inset: 'auto auto 10px 0px' }}
                  id="rename-menu-item"
                >
                  <Box p={1}>Rename</Box>
                </Tooltip>
              }
            >
              <IconButton
                buttonSize={24}
                iconSize={24}
                iconName="driveFileRenameOutline"
                iconColor={theme.colors.base}
                hoverColor={theme.colors.hoverColor}
                onClick={() =>
                  dispatch(
                    setModal({
                      showRenameAssetModal: true,
                      renameAssetInfo:
                        assets.find(
                          (a: AssetFragment) => a.id === selectedAssetIds[0]
                        ) || {},
                    })
                  )
                }
              />
            </OverlayTrigger>
          )}
          <OverlayTrigger
            key="downloads-menu-item"
            popperConfig={{
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, 10],
                  },
                },
              ],
            }}
            overlay={
              <Tooltip
                style={{ inset: 'auto auto 10px 0px' }}
                id="downloads-menu-item"
              >
                <Box p={1}>Download</Box>
              </Tooltip>
            }
          >
            <IconButton
              buttonSize={24}
              iconSize={24}
              iconName="download"
              iconColor={theme.colors.base}
              hoverColor={theme.colors.hoverColor}
              onClick={() => {
                assetsDownload({
                  variables: { assetsId: selectedAssetIds },
                });
                setIsDownloaded(false);
              }}
            />
          </OverlayTrigger>
          {notMemberRole && !!selectedAssetIds.length && (
            <OverlayTrigger
              key="delete-menu-item"
              popperConfig={{
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, 10],
                    },
                  },
                ],
              }}
              overlay={
                <Tooltip
                  style={{ inset: 'auto auto 10px 0px' }}
                  id="delete-menu-item"
                >
                  <Box p={1}>Delete</Box>
                </Tooltip>
              }
            >
              <IconButton
                buttonSize={24}
                iconSize={24}
                iconName="delete"
                iconColor={theme.colors.base}
                hoverColor={theme.colors.hoverColor}
                onClick={() =>
                  dispatch(
                    setModal({
                      showRemoveAssetsModal: true,
                      assetsToRemove: assets.filter((a) =>
                        selectedAssetIds.includes(a.id || '')
                      ),
                    })
                  )
                }
              />
            </OverlayTrigger>
          )}
          {!isVidicoAdmin && (
            <OverlayTrigger
              key="favourite-menu-item"
              popperConfig={{
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, 10],
                    },
                  },
                ],
              }}
              overlay={
                <Tooltip
                  style={{ inset: 'auto auto 10px 0px' }}
                  id="favourite-menu-item"
                >
                  <Box p={1}>
                    {isStaredCountInSelectedAssets === selectedAssetIds.length
                      ? 'Unfavourite'
                      : 'Favourite'}
                  </Box>
                </Tooltip>
              }
            >
              <IconButton
                buttonSize={24}
                iconSize={24}
                iconName={
                  isStaredCountInSelectedAssets > 0
                    ? isStaredCountInSelectedAssets === selectedAssetIds.length
                      ? 'starFilled'
                      : 'starHalf'
                    : 'star'
                }
                iconColor={theme.colors.base}
                hoverColor={theme.colors.hoverColor}
                onClick={() =>
                  markAsFavorite({
                    variables: { assetsId: selectedAssetIds },
                  })
                }
              />
            </OverlayTrigger>
          )}
        </Flex>
      </OverlayMenu>
      {showContextMenu && (
        <ContextMenu
          anchorPoint={anchorPoint}
          menuTitle={`${selectedAssetIds.length} ${
            isOneSelected ? 'item' : 'items'
          } selected`}
          menuItems={isOneSelected ? singleSelectedMenu : multiSelectedMenu}
        />
      )}
      <DeleteAssetsModal />
      <RemovePopup deselect={() => ds?.clearSelection(true)} />
    </>
  );
}

export default ViewSearchAssets;
