import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import styled, { useTheme } from 'styled-components';

import { secondsToTime } from 'utils/transformers/momentFormats';
import {
  getExtension,
  getFileNameWithoutExtension,
} from 'utils/transformers/text';

import { IAssetCardViewProps } from './AssetCardView.interface';

import Box from '../Box/Box';
import Flex from '../Flex/Flex';
import Icon from '../Icon/Icon';
import Text, { ellipsisStyle } from '../Text/Text';

const TextWrapper = styled(Box)`
  color: ${({ theme }) => theme.colors.grey500};
`;
const FileNameText = styled(Text)`
  ${ellipsisStyle}
  user-select: none;
`;
const AssetWrapper = styled(Box)`
  background-color: ${({ theme }) => theme.colors.grey900};
  border: 5px solid ${({ theme }) => theme.colors.grey500};
  border-radius: 15px;
`;
const AssetCardWrapper = styled(Flex)`
  position: relative;
`;

const AspectRatioBox = styled(Box)`
  line-height: 14px;
  font-size: 12px;
  border: 1px solid ${({ theme }) => theme.colors.grey500};
`;

function AssetCardView({
  asset,
  textIcon,
  thumbnailIcon,
}: IAssetCardViewProps) {
  const theme = useTheme();
  const fileName = getFileNameWithoutExtension(asset?.name || '');
  const formattedDuration = asset?.duration
    ? ` · ${secondsToTime(asset?.duration)} · `
    : '';
  const fileExtension = getExtension(asset?.name || '');
  return (
    <AssetWrapper w={528} m={5}>
      <AssetCardWrapper
        h={400}
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Icon size={115} name={thumbnailIcon} color={theme.colors.grey500} />
      </AssetCardWrapper>
      <TextWrapper p={7}>
        <Flex alignItems="center">
          <OverlayTrigger
            key={fileName}
            overlay={<Tooltip>{fileName}</Tooltip>}
          >
            <FileNameText as="h3" doubleXl medium>
              {fileName}
            </FileNameText>
          </OverlayTrigger>
        </Flex>
        <Flex justifyContent="space-between">
          <Flex alignItems="center">
            {textIcon && <Icon size={32} mr={2} name={textIcon} />}
            <Text doubleXl medium>
              {fileExtension}
            </Text>
            {formattedDuration}
            {asset?.aspectRatio ? (
              <AspectRatioBox ml={1} p={0.5}>
                {asset?.aspectRatio}
              </AspectRatioBox>
            ) : (
              ''
            )}
          </Flex>
        </Flex>
      </TextWrapper>
    </AssetWrapper>
  );
}

export default AssetCardView;
