import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

import Box from 'components/atoms/Box/Box';
import Button from 'components/atoms/Button/Button';
import Flex from 'components/atoms/Flex/Flex';
import Icon from 'components/atoms/Icon/Icon';
import Text from 'components/atoms/Text/Text';
import AssetLine from 'components/molecules/AssetLine/AssetLine';

import {
  useAssetDownloadMutation,
  useMarkAssetsAsFavoriteMutation
} from 'graph/generated.graphql';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { setModal } from 'redux/modal/modalSlice';
import { selectViewer } from 'redux/viewer/viewerSlice';
import { RoutesPath } from 'utils/constants/routes';

import { IAccordionProps } from './Accordion.interface';

import { buildLink } from '../../../utils/helpers/route';

const AssetsContainer = styled(Box)<{ show: boolean }>`
  transition: 500ms all ease-in-out;
  overflow: hidden;
`;

const ShowMoreButton = styled(Button)<{
  showButton: boolean;
  showMore: boolean;
}>`
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  transition: 500ms all ease-in-out;
  ${({ showButton }) =>
    showButton
      ? 'visibility: visible; opacity: 1;'
      : 'visibility: hidden; opacity: 0;'};
  & svg {
    transition: 500ms all ease-in-out;
    transform: rotate(90deg);
    ${({ showButton }) =>
      showButton
        ? 'visibility: visible; opacity: 1;'
        : 'visibility: hidden; opacity: 0;'};
    ${({ showMore }) =>
      showMore ? 'transform: rotate(90deg);' : 'transform: rotate(270deg);'};
  }
`;

const ShowHideButton = styled(Button)`
  text-decoration: underline;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
`;
const ASSET_LINE_HEIGHT = 51;

function Accordion({ title, assets }: IAccordionProps) {
  const theme = useTheme();
  const viewer = useAppSelector(selectViewer);
  const [showAsset, setShowAsset] = useState(true);
  const [showMoreAsset, setShowMoreAsset] = useState(true);
  const [assetsDownload] = useAssetDownloadMutation({
    onCompleted({ assetDownload }) {
      dispatch(
        setModal({
          showPopupMessage: true,
          popupMessageInfo: { text: 'Download started' },
          fileToDownload: assetDownload.assets,
        })
      );
    },
    onError() {
      dispatch(
        setModal({
          showPopupMessage: true,
          popupMessageInfo: { text: 'Download failed' },
        })
      );
    },
  });
  const [markAsFavorite] = useMarkAssetsAsFavoriteMutation({
    refetchQueries: ['userById'],
  });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const toFiveCount =
    assets.length < 5
      ? assets.length * ASSET_LINE_HEIGHT
      : ASSET_LINE_HEIGHT * 5;
  const aboveFiveCount =
    assets.length < 30
      ? assets.length * ASSET_LINE_HEIGHT
      : ASSET_LINE_HEIGHT * 30;
  const showMoreCount = showMoreAsset ? toFiveCount : aboveFiveCount;
  return (
    <Box px={8} py={6}>
      <Flex justifyContent="space-between" pb={3}>
        <Text md semibold>
          {title}
        </Text>
        {showAsset ? (
          <ShowHideButton
            noStyle
            color={theme.colors.vidicoBlack}
            onClick={() => setShowAsset(false)}
          >
            Hide
          </ShowHideButton>
        ) : (
          <ShowHideButton
            noStyle
            color={theme.colors.vidicoBlack}
            onClick={() => setShowAsset(true)}
          >
            Show
          </ShowHideButton>
        )}
      </Flex>

      <AssetsContainer
        h={showAsset ? showMoreCount : 1}
        show={showAsset}
        mb={4}
      >
        {assets?.map((asset) => (
          <AssetLine
            key={asset?.id}
            image={{ src: asset?.thumbnail || '', width: 72, height: 42 }}
            boldText={asset?.projectName || asset?.ProjectName || ''}
            text={asset?.name || ''}
            link={buildLink(RoutesPath.HomeViewAssetById, {
              projectId: asset.projectId,
              assetId: asset.id
            })}
            menuItems={[
              {
                key: `${asset?.id}Download`,
                icon: 'download',
                label: 'Download',
                onClick: () =>
                  assetsDownload({
                    variables: { assetsId: asset?.id },
                  }),
              },
              {
                key: `${asset?.id}Favourite`,
                icon: 'star',
                label: `${
                  viewer?.favorites?.find((a) => a.assetId === asset.id)
                    ? 'Unfavourite'
                    : 'Favourite'
                }`,
                onClick: () =>
                  markAsFavorite({ variables: { assetsId: [asset?.id] } }),
              },
              {
                key: `${asset?.id}Delete`,
                icon: 'delete',
                label: 'Delete',
                onClick: () =>
                  dispatch(
                    setModal({
                      showRemoveAssetsModal: true,
                      assetsToRemove: [asset],
                    })
                  ),
              },
              {
                key: `${asset?.id}Share`,
                icon: 'iosShareMenu',
                label: 'Share',
                onClick: () =>
                  dispatch(
                    setModal({
                      showSharedLinkModal: true,
                      sharedLinkData: {
                        projectId: asset?.projectId,
                        assetIds: [asset?.id],
                        isProject: false,
                      },
                    })
                  ),
              },
              {
                key: `${asset?.id}View in folder`,
                icon: 'driveFileMove',
                label: 'View in folder',
                onClick: () =>
                  navigate(`${RoutesPath.Home}${asset?.projectId}`),
              },
            ]}
          />
        ))}
      </AssetsContainer>
      {assets.length > 5 && (
        <ShowMoreButton
          noStyle
          showButton={showAsset}
          showMore={showMoreAsset}
          color={theme.colors.vidicoBlack}
          onClick={() => setShowMoreAsset(!showMoreAsset)}
        >
          <Icon
            size={12}
            name="arrowForwardIos"
            color={theme.colors.vidicoBlack}
            mr={1}
          />
          {showMoreAsset ? 'Show more' : 'Show less'}
        </ShowMoreButton>
      )}
    </Box>
  );
}

export default Accordion;
