import { useGoogleLogin } from '@react-oauth/google';
import React, { FormEvent, useState } from 'react';
import Form from 'react-bootstrap/esm/Form';
import Row from 'react-bootstrap/Row';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { Alert } from 'components/atoms/Alert/Alert';
import Box from 'components/atoms/Box/Box';
import Button from 'components/atoms/Button/Button';
import Flex from 'components/atoms/Flex/Flex';
import FormControl from 'components/atoms/FormControl/FormControl';
import FormFeetback from 'components/atoms/FormFeetback/FormFeetback';
import GoogleButton from 'components/atoms/GoogleButton/GoogleButton';
import InputPassword from 'components/atoms/InputPassword/InputPassword';
import Text from 'components/atoms/Text/Text';
import PasswordStrengthMeter from 'components/molecules/PasswordStrengthMeter/PasswordStrengthMeter';

import {
  AccountTokenPayloadFragment,
  useRegisterUserMutation,
  useSignUpWithGoogleMutation,
} from 'graph/generated.graphql';
import { getRedirectUrlV3 } from 'utils/authentication/getRedirectUrl/getRedirectUrl';
import { FormErrorMessages, FormErrorTypes } from 'utils/constants/forms';
import { RoutesPath } from 'utils/constants/routes';
import {
  isCompanyNotExistError,
  isEmailValidError,
} from 'utils/errors/isGqlError';
import {
  useFormState,
  useFormValidation,
} from 'utils/hooks/useFormValidation/useFormValidation';
import {
  checkPasswordStrongness,
  checkPasswordToWhiteSpace,
  isEmail,
} from 'utils/validators/validators';

enum FormFields {
  FirstName = 'firstName',
  LastName = 'lastName',
  Email = 'email',
  Password = 'password',
}

const FormFieldsList = [
  FormFields.FirstName,
  FormFields.LastName,
  FormFields.Email,
  FormFields.Password,
];

const passwordMessageMap = {
  [FormErrorTypes.Blank]: FormErrorMessages.PASSWORD_BLANK,
  [FormErrorTypes.Warning]: FormErrorMessages.PASSWORD_WARNING,
  [FormErrorTypes.Invalid]: FormErrorMessages.PASSWORD_INVALID,
  [FormErrorTypes.Valid]: FormErrorMessages.PASSWORD_VALID,
};

function SignUpForm() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [isSignUpError, setIsSignUpError] = useState(false);
  const [register, { loading: isRegisterLoading }] = useRegisterUserMutation({
    onError(error) {
      error.graphQLErrors.forEach((gqlError) => {
        if (isEmailValidError(gqlError)) {
          formOnError();
          return setEmailError({
            type: FormErrorTypes.Invalid,
            message: FormErrorMessages.EMAIL_INVALID,
          });
        }
        if (isCompanyNotExistError(gqlError)) {
          formOnError();
          return setIsSignUpError(true);
        }
        return formOnError(gqlError.message);
      });
    },
    onCompleted(data) {
      formOnSuccess();
      navigate(
        `${RoutesPath.ResendVerificationEmail}?email=${data.registerUser.email}`
      );
    },
  });

  const [signUpWithGoogle, { loading: isSignUpWithGoogleLoading }] =
    useSignUpWithGoogleMutation({
      onError(error) {
        error.graphQLErrors.forEach((gqlError) => {
          if (isEmailValidError(gqlError)) {
            formOnError();
            return setEmailError({
              type: FormErrorTypes.Invalid,
              message: FormErrorMessages.EMAIL_INVALID,
            });
          }
          if (isCompanyNotExistError(gqlError)) {
            formOnError();
            return setIsSignUpError(true);
          }
          return formOnError(gqlError.message);
        });
      },
      onCompleted({ registerUser: registerData }) {
        formOnSuccess();
        onSuccess(registerData);
      },
    });

  const loginWithGoogle = useGoogleLogin({
    onSuccess: ({ access_token }) =>
      signUpWithGoogle({ variables: { access_token } }),

    onError: () => formOnError('Google signUp error'),
  });;

  const [
    formErrors,
    isFormValid,
    [setFirstNameError, setLastNameError, setEmailError, setPasswordError],
  ] = useFormValidation(FormFieldsList);
  const [
    formState,
    [setFirstName, setLatName, setEmail, setPassword],
    [formOnSubmit, formOnError, formOnSuccess],
  ] = useFormState(FormFieldsList);
  const { isLoading, errorSlug } = formState;

  const validName = (type: 'first' | 'last', value: string) => {
    const nameValidator =
      type === 'first' ? setFirstNameError : setLastNameError;
    if (!value) {
      nameValidator({
        type: FormErrorTypes.Invalid,
        message:
          type === 'first'
            ? FormErrorMessages.FIRST_NAME_BLANK
            : FormErrorMessages.LAST_NAME_BLANK,
      });
      return false;
    }
    nameValidator(null);
    return true;
  };

  const validEmail = (value: string) => {
    if (!value) {
      setEmailError({
        type: FormErrorTypes.Invalid,
        message: FormErrorMessages.EMAIL_BLANK,
      });
      return false;
    }
    if (!isEmail(value)) {
      setEmailError({
        type: FormErrorTypes.Invalid,
        message: FormErrorMessages.EMAIL_INVALID,
      });
      return false;
    }
    setEmailError(null);
    return true;
  };

  const validPassword = (value: string) => {
    if (checkPasswordToWhiteSpace(value)) {
      setPasswordError({
        type: FormErrorTypes.Invalid,
        message: FormErrorMessages.SPACES_IN_PASSWORD,
      });
      return false;
    }
    const type = checkPasswordStrongness(value);
    setPasswordError({
      type,
      message: passwordMessageMap[type],
    });
    return type === FormErrorTypes.Valid || type === FormErrorTypes.Warning;
  };

  const onFirstNameTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(e.target.value);
    setFirstNameError(null);
  };
  const onLastNameTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLatName(e.target.value);
    setLastNameError(null);
  };
  const onEmailTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setEmailError(null);
    setIsSignUpError(false);
  };

  const onPasswordTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    validPassword(e.target.value);
    setIsSignUpError(false);
  };
  const onSuccess = (data: AccountTokenPayloadFragment) => {
    const { role, subdomainName } = data;
    formOnSuccess();
    const domainWithSubDomain = getRedirectUrlV3(role, subdomainName);
    window.location.assign(domainWithSubDomain);
  };
  const { firstName, lastName, email, password } = formState;
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    if (
      [
        validName('first', firstName),
        validName('last', lastName),
        validEmail(email),
        validPassword(password),
      ].filter((v) => !v).length
    ) {
      return null;
    }
    formOnSubmit();

    register({
      variables: {
        email,
        firstName,
        lastName,
        password,
      },
    });
    return null;
  };
  return (
    <Form noValidate validated={isFormValid} onSubmit={handleSubmit}>
      <Alert iconName="infoCircle" variant="warning" show={isSignUpError}>
        This service is only available for clients of Vidico.
        <br />
        Contact <b>hello@vidico.com</b> for more information.
      </Alert>
      <Row>
        <Flex justifyContent="space-between">
          <Flex mr={6}>
            <Form.Group controlId="validationCustom01">
              <Form.Label>
                <Text color={theme.colors.grey900} sm semibold>
                  First name
                </Text>
              </Form.Label>
              <FormControl
                maxLength={40}
                placeholder="Steve"
                required
                onChange={onFirstNameTextChange}
              />
              <FormFeetback type={formErrors[FormFields.FirstName]?.type}>
                {formErrors[FormFields.FirstName]?.message}
              </FormFeetback>
            </Form.Group>
          </Flex>
          <Flex>
            <Form.Group controlId="validationCustom02">
              <Form.Label>
                <Text color={theme.colors.grey900} sm semibold>
                  Last name
                </Text>
              </Form.Label>
              <FormControl
                maxLength={40}
                placeholder="Spielberg"
                onChange={onLastNameTextChange}
              />
              <FormFeetback type={formErrors[FormFields.LastName]?.type}>
                {formErrors[FormFields.LastName]?.message}
              </FormFeetback>
            </Form.Group>
          </Flex>
        </Flex>
      </Row>
      <Form.Group className="mb-1" controlId="validationCustom01">
        <Form.Label>
          <Text color={theme.colors.grey900} sm semibold>
            Email
          </Text>
        </Form.Label>
        <FormControl
          autoComplete="username"
          type="email"
          placeholder="hello@vidico.com"
          maxLength={40}
          required
          onChange={onEmailTextChange}
        />
        <FormFeetback type={formErrors[FormFields.Email]?.type}>
          {formErrors[FormFields.Email]?.message}
        </FormFeetback>
      </Form.Group>
      <Form.Group className="mb-1" controlId="formBasicPassword">
        <Form.Label>
          <Text color={theme.colors.grey900} sm semibold>
            Password *
          </Text>
        </Form.Label>
        <InputPassword
          autoComplete="new-password"
          $errorType={formErrors[FormFields.Password]?.type}
          placeholder="Enter password"
          onChange={onPasswordTextChange}
        />
        <FormFeetback type={formErrors[FormFields.Password]?.type}>
          {formErrors[FormFields.Password]?.message}
        </FormFeetback>
      </Form.Group>

      <PasswordStrengthMeter password={password} mb={7} />
      <Box mb={4}>
        <Button
          fullWidth
          round
          lg
          semibold
          py={4}
          type="submit"
          disabled={isLoading || isRegisterLoading || !isFormValid}
          isLoading={isLoading || isRegisterLoading}
        >
          <Box>Create account</Box>
        </Button>
      </Box>
      <GoogleButton
        fullWidth
        round
        lg
        semibold
        py={4}
        onClick={() => loginWithGoogle()}
        isLoading={isSignUpWithGoogleLoading}
      >
        Sign up with Google
      </GoogleButton>
      <Form.Group>
        <FormFeetback type={FormErrorTypes.Invalid}>{errorSlug}</FormFeetback>
      </Form.Group>
    </Form>
  );
}

export default SignUpForm;
