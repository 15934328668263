import moment from 'moment';

export const defaultDateFormat = (date: Date) =>
  moment(date).format('DD MMM YYYY');

export const defaultDateTimeFormat = (date: Date) =>
  moment(date).format('DD/MM/YYYY, h:mm a');

export const secondsToTime = (seconds: number) =>
  moment.utc(seconds * 1000).format('mm:ss');

export const secondsToTimeWithHours = (seconds: number) =>
  moment.utc(seconds * 1000).format('HH:mm:ss');
