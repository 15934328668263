import React from 'react';
import styled from 'styled-components';

import Flex from 'components/atoms/Flex/Flex';
import Text from 'components/atoms/Text/Text';

const TextStyled = styled(Text)`
  color: ${({ theme }) => theme.colors.grey500};
`;
const FlexStyled = styled(Flex)`
  align-self: flex-start;
`;
function Footer() {
  return (
    <FlexStyled p={8}>
      <TextStyled sm>{`© Vidico ${new Date().getFullYear()}`}</TextStyled>
    </FlexStyled>
  );
}

export default Footer;
