import React from 'react';

import { SettingTab } from 'pages/SettingPage/SettingPage';

import { ITabContentProps } from './TabContent.interface';

import MyProfile from '../MyProfile/MyProfile';
import Password from '../Password/Password';
import TeamManagement from '../TeamManagement/TeamManagement';

function TabContent({ type }: ITabContentProps) {
  switch (type) {
    case SettingTab.MyProfile:
      return <MyProfile />;
    case SettingTab.Password:
      return <Password />;
    case SettingTab.TeamManagement:
      return <TeamManagement />;
    default:
      return null;
  }
}

export default TabContent;
