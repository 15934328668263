import styled from 'styled-components';

import FormControl, { getErrorTypeColor } from '../FormControl/FormControl';

const FormControlRenameAsset = styled(FormControl)<{ minWidth?: number }>`
  padding: 4px 8px;
  min-width: ${({ minWidth }) => (minWidth ? `${minWidth}px;` : '400px;')};
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.grey100};
  background-color: transparent;
  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.primary600};
    margin: 0;
  }
  &:focus-visible {
    border: ${({ theme, $errorType }) =>
      `1px solid ${
        getErrorTypeColor(theme, $errorType) === theme.colors.grey900
          ? theme.colors.primary600
          : getErrorTypeColor(theme, $errorType)
      }; margin: 0;`};
  }
  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.vidicoAccent};
    transition: border 200ms ease-in-out;
  }
`;
export default FormControlRenameAsset;
