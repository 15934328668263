import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone';
import styled, { useTheme } from 'styled-components';

import Box from 'components/atoms/Box/Box';
import Flex from 'components/atoms/Flex/Flex';
import Text from 'components/atoms/Text/Text';

import Icon from '../Icon/Icon';

export type FileUploadProps = {
  className?: string;
  buttonText?: string;
  text?: string;
  subText?: string;
  error?: boolean;
  onDrop?: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => void;
};

const DropZone = styled(Flex)<{ error?: boolean }>`
  gap: 4px;
  ${({ theme, error }) =>
    error
      ? `border: 2px solid ${theme.colors.error500};`
      : ` border: 1px solid ${theme.colors.grey200};`};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.base};
  cursor: pointer;
  position: relative;
`;
const DropZoneIcon = styled(Flex)`
  position: relative;
  color: ${({ theme }) => theme.colors.grey600};
  z-index: 1;
  transform-style: preserve-3d;
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) translateZ(-1px);
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.grey100};
    border: 6px solid ${({ theme }) => theme.colors.grey50};
    height: 100%;
    width: 100%;
  }
`;
const DropZoneInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 2;
`;
const ButtonStyled = styled(Button)`
  margin-right: 4px;
  text-decoration: none;
`;
const TextStyled = styled(Text)`
  text-align: center;
`;

export const FileUpload: React.FC<FileUploadProps> = React.memo((props) => {
  const theme = useTheme();
  const [error, setError] = useState(false);
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    multiple: false,
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'image/gif': [],
    },
    onError() {
      setError(true);
    },
    onDrop: props.onDrop,
  });
  useEffect(() => {
    setError(props.error || false);
  }, [props.error]);

  return (
    <Box {...getRootProps()} fullWidth className={props.className}>
      <DropZone
        error={error}
        alignItems="center"
        justifyContent="center"
        fullWidth
        px={6}
        py={4}
      >
        <DropZoneIcon
          justifyContent="center"
          alignItems="center"
          p={2}
          w={40}
          h={40}
        >
          <Icon size={17} color={theme.colors.grey600} name="uploadCloud02" />
        </DropZoneIcon>
        <Flex fullWidth flexDirection="column" alignItems="center">
          <Flex alignItems="center">
            <TextStyled sm regular color={theme.colors.grey500}>
              <ButtonStyled className="px-0 py-1" variant="link">
                <Text sm medium>
                  {props.buttonText ?? 'Click to upload'}
                </Text>
              </ButtonStyled>
              {props.text ?? 'or drag and drop'}
            </TextStyled>
          </Flex>
          <TextStyled sm regular color={theme.colors.grey500}>
            {props.subText ?? 'PNG, JPG, GIF or JPEG'}
          </TextStyled>
          <DropZoneInput type="file" {...getInputProps()} />
        </Flex>
      </DropZone>
    </Box>
  );
});
