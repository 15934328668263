import React from 'react';
import styled, { useTheme } from 'styled-components';

import Button from 'components/atoms/Button/Button';
import Flex from 'components/atoms/Flex/Flex';
import Icon from 'components/atoms/Icon/Icon';
import Text from 'components/atoms/Text/Text';

import { INewProjectButtonProps } from './NewProjectButton.interface';

import Box from '../Box/Box';

const ButtonWrapper = styled(Button)`
  background-color: ${({ theme }) => theme.colors.base};
  border: 1px solid ${({ theme }) => theme.colors.base};
  border-radius: 12px;
  justify-content: flex-start;
  &:hover:enabled {
    background-color: ${({ theme }) => theme.colors.base};
    & svg {
      color: ${({ theme }) => theme.colors.primary600};
    }
  }
`;
function NewProjectButton({ onClick }: INewProjectButtonProps) {
  const theme = useTheme();
  return (
    <ButtonWrapper onClick={onClick} py={2} px={4} w={208} noStyle>
      <Box>
        <Flex gap={6} mb={1}>
          <Icon size={24} name="createNewFolder" color={theme.colors.grey300} />
          <Text lg semibold color={theme.colors.grey700}>
            New Project
          </Text>
        </Flex>
        <Text xs medium color={theme.colors.grey600}>
          Start a new video project
        </Text>
      </Box>
    </ButtonWrapper>
  );
}

export default NewProjectButton;
