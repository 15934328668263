import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTheme } from 'styled-components';

import { UserInvitation, UserInvitationStatus } from './InvitationSender.interface';

import { useResendInviteToUserMutation } from '../../../graph/generated.graphql';
import IconButton from '../../atoms/IconButton/IconButton';


function InvitationSender({ userId, invitationStatus, setInvitationStatus, disabled }: UserInvitation) {
  const theme = useTheme();
  const SUCCESS_MESSAGE_DELAY = 1000

  const [resendInviteToUser] =  useResendInviteToUserMutation({
    onCompleted: () => {
      setInvitationStatus(prevInvitationStatus => ({
        ...prevInvitationStatus,
        [userId]: UserInvitationStatus.Inviting
      }))
      setTimeout(() => setInvitationStatus(prevInvitationStatus => ({
        ...prevInvitationStatus,
        [userId]: UserInvitationStatus.Sent
      })), SUCCESS_MESSAGE_DELAY)
    },
  });
  const handleResendInvitation = () => {
    resendInviteToUser(
      {
        variables: { userId }
      })
  }

  return (
        UserInvitationStatus.Inviting === invitationStatus[userId] ? <OverlayTrigger
        key={userId}
        overlay={<Tooltip id={userId}>Sent!</Tooltip>}
        show
      >
        <IconButton
          type='button'
          iconName='mail02'
          iconColor={theme.colors.grey500}
          iconSize={20}/>
      </OverlayTrigger> : <OverlayTrigger
        key={userId}
        overlay={UserInvitationStatus.Sent === invitationStatus[userId] ?
          <Tooltip id={userId}>Sent!</Tooltip> :
          <Tooltip id={userId}>Resend Invitation</Tooltip>
      }
      >
        <IconButton
          type='button'
          iconName='mail02'
          iconSize={20}
          disabled={disabled}
          iconColor={theme.colors.grey500}
          onClick= {() => {
            handleResendInvitation()
          }} />
      </OverlayTrigger>)
}

export default InvitationSender
