import { useContext, useEffect, useState } from 'react';
import { AuthContext, IAuthContext } from "react-oauth2-code-pkce";
import { useDispatch } from 'react-redux';

import { useUserByIdQuery } from 'graph/generated.graphql';

import { setViewer } from '../../../redux/viewer/viewerSlice';

const useInitViewer = () => {
  const [isReady, setIsReady] = useState(false);
  const dispatch = useDispatch();

  const { tokenData, loginInProgress } = useContext<IAuthContext>(AuthContext);

  const {
    loading: isViewerLoading,
    data: userData,
    error,
  } = useUserByIdQuery({
    skip: !tokenData?.sub || loginInProgress,
    variables: { id: tokenData?.sub },
  });
  const viewer = userData?.userById.user;

  useEffect(() => {
    if (!isViewerLoading) {
      
      if (viewer) {
         dispatch(
          setViewer({
            ...viewer,
          })
         );
      }
      if (error) {
        // eslint-disable-next-line
        console.error('---useInitUser error-->', error);
      }
      setIsReady(true);
    }
    // eslint-disable-next-line
  }, [isViewerLoading, viewer]);
  return [isReady];
};
export default useInitViewer;
