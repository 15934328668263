import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

import Box from 'components/atoms/Box/Box';
import Flex from 'components/atoms/Flex/Flex';
import H from 'components/atoms/H/H';
import Spinner from 'components/atoms/Spinner/Spinner';
import Text from 'components/atoms/Text/Text';

import ResendVerificationEmailContainer from 'containers/Authentification/ResendVerificationEmail/ResendVerificationEmailContainer';
import { useEmailConfirmationMutation } from 'graph/generated.graphql';
import { QUERY_PARAM } from 'utils/constants/common';
import { RoutesPath } from 'utils/constants/routes';
import { useSearchQuery } from 'utils/hooks/useSearchQuery/useSearchQuery';
import { device } from 'utils/styles/device';

const SignUpRightSide = styled(Flex)`
  background-image: ${({ theme }) => theme.backgrounds.gradientPrimary600};
  color: ${({ theme }) => theme.colors.base};
  overflow: hidden;
  min-height: 1024px;
  max-height: 100vh;

  @media ${device.laptopL} {
    padding-left: ${({ theme }) => theme.size(10)};
  }
`;
const BoxStyled = styled(Box)`
  display: inline-table;
  border-radius: 12px;
  height: 636px;
  border: 5px solid ${({ theme }) => theme.colors.vidicoBlack};
  overflow: hidden;
`;

function VerificationEmailPage() {
  const query = useSearchQuery();
  const theme = useTheme();
  const navigate = useNavigate();
  const token = query.get(QUERY_PARAM.Token) || '';
  const email = query.get(QUERY_PARAM.Email) || '';
  const [isError, setIsError] = useState(false);
  const [emailConfirmation, { loading: isLoading }] =
    useEmailConfirmationMutation({
      onCompleted() {
        setIsError(false);
        navigate(`${RoutesPath.SignIn}?success=true`);
      },
      onError() {
        setIsError(true);
      },
    });

  useEffect(() => {
    emailConfirmation({ variables: { key: token } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main>
      <Flex alignItems="stretch">
        {!isLoading ? (
          <ResendVerificationEmailContainer
            email={email}
            isErrorProp={isError}
          />
        ) : (
          <Flex w={640} alignItems="center" justifyContent="center">
            <Spinner size={50} color={theme.colors.primary600} />
          </Flex>
        )}
        <SignUpRightSide flexDirection="column" flex={1} pl={20} pt={38}>
          <Box mb={6} w={528}>
            <H lg bold>
              Start turning your ideas into reality.
            </H>
          </Box>
          <Box mb={26} w={528}>
            <Text lg medium>
              Create a free account and get full access to all features for
              30-days. No credit card needed. Get started in 2 minutes.
            </Text>
          </Box>
          <BoxStyled>
            <img
              width={1024}
              src="/assets/images/signUpImage.jpg"
              alt="SignUp web page"
            />
          </BoxStyled>
        </SignUpRightSide>
      </Flex>
    </main>
  );
}

export default VerificationEmailPage;
