import { IOption } from 'components/atoms/Select/Select';

import { UserBaseFragment } from 'graph/generated.graphql';

export const mapUserToSelectOptions = ({
  avatar,
  firstName,
  lastName,
  id,
}: UserBaseFragment): IOption => ({
  icon: avatar || '',
  label: `${firstName} ${lastName}`,
  value: id,
});

export const createOption = (label: string): IOption => ({
  label,
  value: label,
});
