enum RoutesPath {
  // Common routes
  AddUser = '/add-user',
  CompleatSignUp = '/complete-sign-up',
  EmailVerification = '/email-verification',
  Favourite = '/favourite',
  ResetPassword = '/reset-password',
  Home = '/',
  HomeProject = '/:projectId',
  HomeViewAssetById = '/:projectId/:assetId',
  Recent = '/recent',
  Settings = '/settings',
  SignIn = '/sign-in',
  ResendVerificationEmail = '/resend-verification-email',
  SignUp = '/sign-up',
  Workspaces = '/workspaces',
  WorkspaceById = '/workspaces/:companyId/projects/:projectId',
  ViewAssetById = '/workspaces/:companyId/projects/:projectId/:assetId',
  Projects = '/workspaces/:companyId/projects',
  SharedAssets = '/share/:key',
  ViewSharedAssetById = '/share/:key/:assetId',
  PageNotFound = '/404',
  VideoPlanner = '/video-planner',
  VideoPlannerCompany = '/video-planner/:companyId',
}
const excluderPath = ['workspaces', 'settings', 'recent'];

export { RoutesPath, excluderPath };
