import React, { KeyboardEventHandler, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import styled, { useTheme } from 'styled-components';

import { createOption } from 'utils/transformers/mappers';

import { ISelectCreatableProps } from './CreatableSelect.interface';

import Box from '../Box/Box';
import Icon from '../Icon/Icon';
import { IconKeys } from '../Icon/Icon.interfaces.generated';
import IconButton from '../IconButton/IconButton';

const customStyles = {
  multiValueRemove: (base: any, state: any) => ({
    ...base,
    display: state.selectProps.isDisabled ? 'none' : base.display,
  }),
};
const SelectCreatableWrapper = styled(Box)<{
  prefixIcon?: IconKeys;
  postfixIconButton?: ISelectCreatableProps['postfixIconButton'];
  isDisabled?: boolean;
}>`
  position: relative;
  .react-select {
    &__group {
      padding: 0;
    }
    &__group-heading {
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      color: ${({ theme }) => theme.colors.grey800};
      text-transform: none;
      padding: 8px 24px;
    }
    &-container {
    }
    &__input-container {
      max-width: 200px;
    }
    &__placeholder {
      font-weight: ${({ theme }) => theme.typography.weight.regular};
      color: ${({ theme }) => theme.colors.grey400};
    }
    &__control {
      transition: all 0ms;
      box-shadow: none;
      border: 1px solid ${({ theme }) => theme.colors.grey900};
      outline: none !important;
      border-radius: 8px;
      &--is-disabled {
        color: ${({ theme }) => theme.colors.grey400};
        background-color: ${({ theme }) => theme.colors.grey50};
        border-color: ${({ theme }) => theme.colors.grey300};
      }

      &:hover {
        border: 1px solid ${({ theme }) => theme.colors.grey900};
      }
      &--is-focused,
      &--is-focused:hover {
        border: 2px solid ${({ theme }) => theme.colors.primary600};
        margin: -1px;
      }
    }
    &__value-container {
      padding-top: 7px;
      padding-bottom: 7px;
      padding-left: ${({ prefixIcon }) => (prefixIcon ? '36px;' : '14px;')};
      padding-right: ${({ postfixIconButton }) =>
        postfixIconButton ? '36px;' : '14px;'};
    }
    &__indicators {
    }
    &__indicator-separator {
      display: none;
    }

    &__menu {
      border: 1px solid ${({ theme }) => theme.colors.grey200};
      box-shadow: ${({ theme }) => theme.shadow.smShadow};
      border-radius: 20px;
      min-width: 235px;
      overflow: hidden;
      padding-top: 6px;
      padding-bottom: 6px;
    }
    &__menu-list {
    }
    &__option {
      cursor: pointer;

      &--is-selected,
      &--is-focused {
        background-color: ${({ theme }) => theme.colors.grey50};
        color: ${({ theme }) => theme.colors.grey900};
      }
    }
    &__multi-value {
      max-width: 200px;
      background-color: ${({ theme }) => theme.colors.base};
      border-radius: 6px;
      border: 1px solid ${({ theme }) => theme.colors.grey300};
      &:hover {
        background-color: ${({ theme }) => theme.colors.base};
        border: 1px solid ${({ theme }) => theme.colors.grey500};
      }
      &__remove {
        color: ${({ theme }) => theme.colors.grey400};
      }
      &__remove:hover {
        background-color: transparent;
        color: ${({ theme }) => theme.colors.grey700};
      }
      &__label {
        padding: 0;
        padding-top: 2px;
        padding-bottom: 2px;
        padding-left: 8px;
        ${({ isDisabled }) => (isDisabled ? 'padding-right: 8px;' : null)};
      }
    }
  }
`;
const PrefixIcon = styled(Icon)`
  position: absolute;
  z-index: 3;
  top: 50%;
  left: 14px;
  transform: translateY(-50%);
`;
const PostfixIconButton = styled(IconButton)`
  position: absolute;
  z-index: 3;
  top: 50%;
  right: 14px;
  transform: translateY(-50%);
`;
function SelectCreatable({
  onStateChange,
  onKeyDown,
  onInputChange,
  inputValue,
  prefixIcon,
  iconColor,
  postfixIconButton,
  inputValidation,
  isDisabled,
  ...args
}: ISelectCreatableProps) {
  const [innerInputValue, setInnerInputValue] = useState('');
  const theme = useTheme();
  const inputValueChoose = inputValue || innerInputValue;
  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (!inputValueChoose) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        if (inputValidation ? !inputValidation(inputValueChoose) : false)
          return;
        onStateChange((prev) => [...prev, createOption(inputValueChoose)]);
        setInnerInputValue('');
        event.preventDefault();
    }
  };

  return (
    <SelectCreatableWrapper prefixIcon={prefixIcon} isDisabled={isDisabled}>
      {prefixIcon && (
        <PrefixIcon
          size={20}
          name={prefixIcon}
          color={iconColor || theme.colors.grey900}
        />
      )}
      <CreatableSelect
        {...args}
        isDisabled={isDisabled}
        classNamePrefix="react-select"
        inputValue={inputValueChoose}
        isClearable
        isMulti
        styles={customStyles}
        onInputChange={(newValue, actionMeta) =>
          onInputChange
            ? onInputChange(newValue, actionMeta)
            : setInnerInputValue(newValue)
        }
        onKeyDown={onKeyDown || handleKeyDown}
      />
      {postfixIconButton && (
        <PostfixIconButton
          iconName={postfixIconButton.iconName}
          onClick={postfixIconButton.onClick}
          buttonSize={postfixIconButton.size}
        />
      )}
    </SelectCreatableWrapper>
  );
}
export default SelectCreatable;
