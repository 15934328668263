import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

import Box from 'components/atoms/Box/Box';
import Button from 'components/atoms/Button/Button';
import Flex from 'components/atoms/Flex/Flex';
import H from 'components/atoms/H/H';
import Icon from 'components/atoms/Icon/Icon';
import Logo from 'components/atoms/Logo/Logo';
import Text from 'components/atoms/Text/Text';

import { RoutesPath } from 'utils/constants/routes';

const IconWrapper = styled(Flex)`
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.primary100};
  border: 10px solid ${({ theme }) => theme.colors.primary50};
  box-sizing: content-box;
`;

function NotFound() {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      fullWidth
      pt={20}
    >
      <Box mb={19}>
        <Logo />
      </Box>
      <IconWrapper
        w={46}
        h={46}
        mb={8}
        justifyContent="center"
        alignItems="center"
      >
        <Icon color={theme.colors.primary600} size={28} name="alertCircle" />
      </IconWrapper>
      <Box mb={6}>
        <H lg semibold>
          Page not found
        </H>
      </Box>
      <Box mb={12}>
        <Text xl regular color={theme.colors.grey500} textAlign="center">
          Sorry, the page you are looking for doesn&rsquo;t exist.
          <br /> Here are some helpful links:
        </Text>
      </Box>
      <Flex gap={12}>
        <Button
          md
          semibold
          secondary
          px={5}
          py={3}
          onClick={() => navigate(-1)}
        >
          <Icon size={20} name="arrowBack" />
          Go back
        </Button>
        <Button
          md
          semibold
          px={5}
          py={3}
          onClick={() => navigate(RoutesPath.Home)}
        >
          Take me home
        </Button>
      </Flex>
    </Flex>
  );
}

export default NotFound;
