import React, { useCallback, useEffect, useState } from 'react';
import styled, { DefaultTheme } from 'styled-components';

import Icon from 'components/atoms/Icon/Icon';

import { getInitialFromText } from 'utils/transformers/text';

import {
  IAvatarProps,
  IAvatarWithTypeProps,
  ImageType,
} from './Avatar.interface';

import Flex from '../Flex/Flex';
import { getSize } from '../Text/Text';
import { IFontSize } from '../Text/Text.interfaces';

const getAvatarSize = (
  theme: DefaultTheme,
  { xs, sm, md, lg, xl, doubleXl }: IFontSize,
  fieldType: 'size' | 'placeholder' | 'indicator'
) => {
  const sizeArray = theme.avatar[fieldType];
  const size = { xs, sm, md, lg, xl, doubleXl };
  // @ts-ignore
  const index = Object.keys(size).findIndex((key) => !!size[key]);
  return sizeArray[index] || sizeArray[2];
};

const AvatarWrapper = styled(Flex)<IAvatarWithTypeProps>`
  width: ${({ theme, xs, sm, md, lg, xl, doubleXl }) =>
    getAvatarSize(theme, { xs, sm, md, lg, xl, doubleXl }, 'size')}px;
  height: ${({ theme, xs, sm, md, lg, xl, doubleXl }) =>
    getAvatarSize(theme, { xs, sm, md, lg, xl, doubleXl }, 'size')}px;

  img {
    border-radius: 50%;
    overflow: hidden;
    appearance: none;
    height: ${({ theme, xs, sm, md, lg, xl, doubleXl }) =>
      getAvatarSize(theme, { xs, sm, md, lg, xl, doubleXl }, 'size')}px;

    &:after {
      display: none;
      z-index: 3;
    }
  }
  &:before {
    font-weight: 500;
    font-size: ${({ theme, xs, sm, md, lg, xl, doubleXl }) =>
      getSize(theme, { xs, sm, md, lg, xl, doubleXl }, 'fontSize')}px;
  }

  ${({ type, theme, color }) => {
    if (type === 'text') {
      return `
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background-color: ${
      color === 'primary' ? theme.colors.primary50 : theme.colors.grey50
    };};

    &:before {
      content: attr(data-text);
      color: ${
        color === 'primary' ? theme.colors.primary600 : theme.colors.grey600
      };
    }

    img {
      display: none;
    }
  `;
    }
    if (type === 'placeholder') {
      return `
     background-color: ${
       color === 'primary' ? theme.colors.primary50 : theme.colors.grey50
     };
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      display: none;
    }
  }
  `;
    }
    return null;
  }}

  // Indicator
  ${({ indicator, theme, xs, sm, md, lg, xl, doubleXl }) =>
    indicator
      ? `
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      border-radius: 50%;
      background-color: ${theme.colors.success500};
      border: 1.5px solid white;
      box-sizing: content-box;
      width: ${getAvatarSize(
        theme,
        { xs, sm, md, lg, xl, doubleXl },
        'indicator'
      )}px;
      height: ${getAvatarSize(
        theme,
        { xs, sm, md, lg, xl, doubleXl },
        'indicator'
      )}px;
    }
  `
      : ''}
`;
const IconPlaceholder = styled(Icon)<IAvatarProps>`
  color: ${({ theme, color }) =>
    color === 'primary' ? theme.colors.primary600 : theme.colors.grey600};
  width: ${({ theme, xs, sm, md, lg, xl, doubleXl }) =>
    getAvatarSize(theme, { xs, sm, md, lg, xl, doubleXl }, 'placeholder')}px;
  height: ${({ theme, xs, sm, md, lg, xl, doubleXl }) =>
    getAvatarSize(theme, { xs, sm, md, lg, xl, doubleXl }, 'placeholder')}px;
`;
const Avatar: React.FC<IAvatarProps> = React.memo((props) => {
  const [type, setType] = useState<ImageType>(
    props.src ? 'image' : props.text ? 'text' : 'placeholder'
  );

  const src = props.src === '' ? undefined : props.src;

  useEffect(() => {
    setType(props.src ? 'image' : props.text ? 'text' : 'placeholder');
  }, [props.src, props.text]);

  const handleImageError = useCallback(() => {
    if (type === 'image') {
      setType('text');
    } else {
      setType('placeholder');
    }
  }, [type]);

  return (
    <AvatarWrapper
      justifyContent="center"
      type={type}
      {...props}
      data-text={getInitialFromText(props.text || '')}
    >
      <img
        alt={props.alt}
        src={src}
        onError={handleImageError}
        style={{ verticalAlign: 'bottom' }}
      />
      {type === 'placeholder' && (
        <IconPlaceholder {...props} name={props.icon ? props.icon : 'user01'} />
      )}
    </AvatarWrapper>
  );
});
export default Avatar;
