import {
  ApolloClient,
  ApolloLink,
  DefaultOptions,
  InMemoryCache,
  ApolloProvider,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from "@apollo/client/link/error";
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createUploadLink } from 'apollo-upload-client';
import { createClient } from 'graphql-ws';
import { useContext } from 'react';
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';

function ApolloProviderComponent({ children }: { children: JSX.Element }) {
  const { login, token } = useContext<IAuthContext>(AuthContext);

  const GRAPHQL_URI = process.env.REACT_APP_GRAPHQL as string;
  const GRAPHQL_URI_WS = process.env.REACT_APP_GRAPHQL_WS as string;

  const wsLink = new GraphQLWsLink(
    createClient({
      url: GRAPHQL_URI_WS,
      connectionParams: {
        authToken: token,
      },
    })
  );
  const httpLink = createUploadLink({
    uri: GRAPHQL_URI,
  });

  const authLink = setContext((_, { headers }) => {

    const bearer = `Bearer ${token}`.replaceAll('"', '')

    // Return the headers to the context so batchHttpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? bearer : '',
      },
    };
  });

  // The split function takes three parameters:
  //
  // * A function that's called for each operation to execute
  // * The Link to use for an operation if the function returns a "truthy" value
  // * The Link to use for an operation if the function returns a "falsy" value
  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    authLink
  );

  const createErrorLink = (loginFunction: Function) => onError(({ graphQLErrors }) => {
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          switch (err.extensions?.code) {
            case 'AUTH_NOT_AUTHORIZED':
              loginFunction();
              return;
          }
        }
      }
    });

// Default options for fetch and error policies
  const defaultOptions: DefaultOptions = {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
  };
   const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: ApolloLink.from([createErrorLink(login), splitLink, httpLink,]),
    connectToDevTools: process.env.REACT_APP_ENV !== 'production',
    defaultOptions,
  });

  return (
    <ApolloProvider client={client}>
            {children}
    </ApolloProvider>
  )
}

export {ApolloProviderComponent}
