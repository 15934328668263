import { AccumulatorType } from './InvitationSender.interface';

import { PENDING_STATUS } from '../../../utils/constants/common';

export const mapUsersInviteState = (currentUserList: any[]) => currentUserList.reduce((acc: AccumulatorType, item) => {
  if(item.status === PENDING_STATUS) {
    acc[`${item.id}`] = `${item.status}`
  }

  return acc;
}, {})
