import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import RemovePopup from 'components/atoms/RemovePopup/RemovePopup';
import NotFound from 'components/molecules/NotFound/NotFound';

import Header from 'containers/Layout/components/Header/Header';
import ViewAssets from 'containers/ViewAssets/ViewAssets';
import ViewSearchAssets from 'containers/ViewSearchAssets/ViewSearchAssets';
import Accordion from 'containers/Workspace/Accordion/Accordion';
import AddProjectModal from 'containers/Workspace/AddProjectModal/AddProjectModal';
import CalendlyModal from 'containers/Workspace/CalendlyModal/CalendlyModal';
import RequestNewLocalizationFinalModal from 'containers/Workspace/RequestNewLocalizationFinalModal/RequestNewLocalizationFinalModal';
import RequestNewLocalizationModal from 'containers/Workspace/RequestNewLocalizationModal/RequestNewLocalizationModal';
import RequestNewProjectFinalModal from 'containers/Workspace/RequestNewProjectFinalModal/RequestNewProjectFinalModal';
import RequestNewProjectModal from 'containers/Workspace/RequestNewProjectModal/RequestNewProjectModal';
import {
  ProjectFragment,
  ProjectStatus,
  SortEnumType,
  useGetAssetsQuery,
  usePrepareForDownloadMutation,
  useProjectsListQuery,
  useUserByIdQuery
} from 'graph/generated.graphql';
import ProjectsList from 'modules/admin/components/ProjectsList/ProjectsList';
import {
  selectFilterByLocalization,
  selectFilterByType,
  selectIsSearchActive,
  selectSearchFilterState,
  selectSearchOrderFilterState,
  selectSelectedProjectOption,
  setSearchFilterState,
  setSelectedProjectOption,
} from 'redux/filter/filterSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { setModal } from 'redux/modal/modalSlice';
import { selectViewer } from 'redux/viewer/viewerSlice';
import { RoutesPath } from 'utils/constants/routes';
import { isValidGUID } from 'utils/validators/validators';

import { isProjectLocked } from '../../utils/projectLock/ProjectLock.helper';

const MainStyled = styled.main`
  min-height: calc(100vh - 92px);
`;

function HomePage() {
  const matchPath = useMatch(RoutesPath.HomeProject);
  const matchHomePath = useMatch(RoutesPath.Home);
  const [isNotFound, setIsNotFound] = useState(false);
  const navigate = useNavigate();
  const projectId = matchPath?.params.projectId;
  const assetsFilterByType = useAppSelector(selectFilterByType);
  const searchFilterState = useAppSelector(selectSearchFilterState);
  const assetsFilterByLocalization = useAppSelector(selectFilterByLocalization);
  const user = useAppSelector(selectViewer);
  const isSearchActive = useAppSelector(selectIsSearchActive);
  const searchOrderFilterState = useAppSelector(selectSearchOrderFilterState);
  const option = useAppSelector(selectSelectedProjectOption);
  const companyId = useMemo(() => user?.companyId, [user]);
  const dispatch = useAppDispatch();
  const [prepareForDownload] = usePrepareForDownloadMutation();

  const { data: projectsListData, refetch: refetchProjectsList } =
    useProjectsListQuery({
      variables: {
        companyId,
        take: 100,
        where: { dealeted: { eq: false }, companyId: { eq: companyId } },
      },
      skip: !companyId,
      fetchPolicy: 'network-only',
    });
  const projects = useMemo(
    () => projectsListData?.projectsList?.items || [],
    [projectsListData]
  );
  const sorterProjects = useMemo(
    () =>
      [...projects].sort((a, b) => {
        if (a.status > b.status) return 2;
        if (a.status < b.status) return -2;
        if (new Date(a.createdAt).getTime() > new Date(b.createdAt).getTime())
          return -1;
        if (new Date(a.createdAt).getTime() < new Date(b.createdAt).getTime())
          return 1;
        return 0;
      }),
    [projects]
  );

  const selectedProject = useMemo(
    () => projects.find((p) => p.id === projectId),
    [projectId, projects]
  );

  const {
    data: projectData,
    refetch: refetchProject,
    loading: loadingProject,
  } = useGetAssetsQuery({
    variables: {
      take: 100,
      order: { createdAt: SortEnumType.Desc },
      where: {
        projectId: { eq: projectId },
        type: { ...assetsFilterByType },
        localization: { ...assetsFilterByLocalization },
        dealeted: {
          eq: false,
        },
      },
      userId: user?.id,
    },
    skip:
      isSearchActive ||
      !assetsFilterByType ||
      !selectedProject ||
      !assetsFilterByLocalization?.eq,
    fetchPolicy: 'network-only',
  });
  const { data: searchData, loading: isSearchLoading } = useGetAssetsQuery({
    variables: {
      take: 100,
      where: { ...searchFilterState, companyId: { eq: companyId } },
      order: searchOrderFilterState,
    },
    skip: isSearchActive ? !searchFilterState || !searchOrderFilterState : true,

    fetchPolicy: 'network-only',
  });

  const { data: recentData, refetch: refetchRecent } = useGetAssetsQuery({
    variables: {
      where: {
        companyId: { eq: companyId },
        dealeted: {
          eq: false,
        },
      },
      order: { lastModifiedAt: SortEnumType.Desc },
      take: 30,
    },
    skip: !!projectId,
    fetchPolicy: 'network-only',
  });
  const { data: favoriteData, refetch: refetchFavorite } = useUserByIdQuery({
    variables: {
      id: user?.id,
    },
    skip: !!projectId,
    fetchPolicy: 'network-only',
  });
  const recentAsset = recentData?.assetsList?.items;
  const favoriteAssets = favoriteData?.userById.user?.favorites
    .sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    )
    .map((f) => f.asset)
    .filter((asset) => !asset?.dealeted && asset);
  const resentAssetAndFavoriteAssetsIds = useMemo(
    () => [
      ...(recentAsset?.map((asset) => asset.id) || []),
      ...(favoriteAssets?.map((asset) => asset?.id) || []),
    ],
    [favoriteAssets, recentAsset]
  );
  const onRefetch = useCallback(() => {
    refetchProjectsList({
      companyId,
      where: { dealeted: { eq: false }, companyId: { eq: companyId } },
    });
    refetchProject({
      where: {
        projectId: { eq: projectId },
        type: { ...assetsFilterByType },
        localization: { ...assetsFilterByLocalization },
        dealeted: { eq: false },
      },
    });
  }, [
    refetchProjectsList,
    companyId,
    refetchProject,
    projectId,
    assetsFilterByType,
    assetsFilterByLocalization,
  ]);

  const assets = useMemo(
    () =>
      isSearchActive
        ? searchData?.assetsList?.items || []
        : projectData?.assetsList?.items || [],
    [
      isSearchActive,
      projectData?.assetsList?.items,
      searchData?.assetsList?.items,
    ]
  );

  const assetIds = useMemo(() => assets.map((a) => a.id), [assets]);
  const isAssetsIsReadyToBeViewed =
    !!selectedProject?.id &&
    projectId &&
    selectedProject.status !== ProjectStatus.Pending;
  useEffect(() => {
    dispatch(
      setModal({
        sharedLinkData: {
          projectId,
          assetIds,
          isProject: false,
        },
      })
    );
    dispatch(setModal({ selectedProject }));
  }, [projectId, assetIds, dispatch, selectedProject]);

  useEffect(() => {
    if (projectId) {
      setIsNotFound(!isValidGUID(projectId));
    }
  }, [companyId, projectId]);

  useEffect(() => {
    if (!selectedProject) {
      refetchRecent();
      refetchFavorite();
    }
    if (!option && !isSearchActive && selectedProject) {
      dispatch(
        setSelectedProjectOption([
          {
            label: selectedProject?.name,
            value: selectedProject?.id,
            icon: 'folder',
          },
        ])
      );

      dispatch(
        setSearchFilterState({
          ...searchFilterState,
          projectId: { eq: selectedProject?.id },
          companyId: { eq: companyId },
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    refetchFavorite,
    refetchRecent,
    selectedProject,
    option,
    isSearchActive,
  ]);

  const onProjectChange = (project: Partial<ProjectFragment>) => {
    if(isProjectLocked(project, user?.roles)) {
      return;
    }
    navigate(`${RoutesPath.Home}${project.id}`);
    dispatch(
      project
        ? setSelectedProjectOption([
            {
              label: project?.name || '',
              value: project.id,
              icon: 'folder',
            },
          ])
        : setSelectedProjectOption([])
    );

    dispatch(
      setSearchFilterState({
        ...searchFilterState,
        projectId: { eq: project?.id },
        companyId: { eq: companyId },
      })
    );
  };
  useEffect(() => {
    if (resentAssetAndFavoriteAssetsIds.length) {
      prepareForDownload({
        variables: { assetsId: resentAssetAndFavoriteAssetsIds },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchHomePath]);

  return (
    <>
      <Header />
      <MainStyled>
        {!isSearchActive ? (
          <>
            <ProjectsList
              projects={sorterProjects}
              selectedProject={selectedProject}
              onProjectChange={onProjectChange}
            />
            {isAssetsIsReadyToBeViewed ? (
              <ViewAssets
                assets={assets}
                project={selectedProject}
                onRefetch={onRefetch}
                isLoading={loadingProject}
              />
            ) : (
              isNotFound && !matchHomePath && <NotFound />
            )}
            {!selectedProject?.id && !!recentAsset?.length && (
              <Accordion
                key="recent"
                assets={recentAsset}
                title="Recent files"
              />
            )}
            {!selectedProject?.id && !!favoriteAssets?.length && (
              <Accordion
                key="favorite"
                assets={favoriteAssets}
                title="Favourite files"
              />
            )}
          </>
        ) : (
          <ViewSearchAssets
            projects={projects}
            assets={assets}
            isLoading={isSearchLoading}
          />
        )}
        <AddProjectModal companyId={companyId} refetch={refetchProjectsList} />
        <RequestNewProjectModal />
        <CalendlyModal />
        <RequestNewProjectFinalModal refetch={refetchProjectsList} />
        <RequestNewLocalizationModal />
        <RequestNewLocalizationFinalModal />
        {matchHomePath && <RemovePopup />}
      </MainStyled>
    </>
  );
}

export default HomePage;
