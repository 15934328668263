import styled from 'styled-components';

import SelectCreatable from '../CreatableSelect/CreatableSelect';

const SearchInput = styled(SelectCreatable)`
  .react-select {
    &__value-container {
      padding-top: 2px;
      padding-bottom: 2px;
    }
  }
`;

export default SearchInput;
