import styled, { css, DefaultTheme } from 'styled-components';

import type {
  ITextProps,
  ITextStyle,
  IFontSize,
  IFontWeight,
} from 'components/atoms/Text/Text.interfaces';

const getSize = (
  theme: DefaultTheme,
  { xs, sm, md, lg, xl, doubleXl }: IFontSize,
  textFieldType: 'fontSize' | 'lineHeight',
  type?: 'text' | 'header'
) => {
  const sizeArray = theme.typography[textFieldType][type || 'text'];
  const size = { xs, sm, md, lg, xl, doubleXl };
  // @ts-ignore
  const index = Object.keys(size).findIndex((key) => !!size[key]);
  return sizeArray[index] || sizeArray[2];
};

const getFontWeight = (
  theme: DefaultTheme,
  { bold, semibold, medium, regular = true }: IFontWeight
) => {
  const {
    typography: { weight },
  } = theme;

  return (
    (bold && weight.bold) ||
    (semibold && weight.semibold) ||
    (medium && weight.medium) ||
    (regular && weight.regular)
  );
};

const textStyle = css<ITextStyle>`
  margin-block-start: unset;
  margin-block-end: unset;
  font-family: 'Inter';
  font-style: normal;
  font-weight: ${({ theme, bold, semibold, medium, regular = true }) =>
    getFontWeight(theme, {
      bold,
      semibold,
      medium,
      regular,
    })};
  font-size: ${({ theme, xs, sm, md, lg, xl }) =>
    getSize(theme, { xs, sm, md, lg, xl }, 'fontSize')}px;
  line-height: ${({ theme, xs, sm, md, lg, xl }) =>
    getSize(theme, { xs, sm, md, lg, xl }, 'lineHeight')}px;
  color: ${({ color }) => color};
  opacity: ${({ opacity }) => opacity};
  ${({ ellipsis }) => (ellipsis ? ellipsisStyle : '')};
  word-break: ${({ wordBreak }) => wordBreak};
  text-align: ${({ textAlign }) => textAlign};
  user-select: ${({ userSelect }) => userSelect};
`;

export const ellipsisStyle = css`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: inherit;
`;

const TextPStyles = styled.p<ITextStyle>`
  ${textStyle}
`;

const TextSpamStyles = styled.span<ITextStyle>`
  ${textStyle}
`;

function Text({ children, as = 'p', ...args }: ITextProps) {
  const TextStyles = as === 'p' ? TextPStyles : TextSpamStyles;
  return <TextStyles {...args}>{children}</TextStyles>;
}
export default Text;
export { getSize, getFontWeight, textStyle };
