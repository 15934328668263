import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import styled, { useTheme } from 'styled-components';

import Box, { boxStyle, IBoxProps } from 'components/atoms/Box/Box';
import Flex from 'components/atoms/Flex/Flex';

import { Roles } from 'graph/generated.graphql';
import { useAppSelector } from 'redux/hooks';
import { selectViewer } from 'redux/viewer/viewerSlice';
import { useOnRightClick } from 'utils/hooks/useOnRightClick/useOnRightClick';

import { IAssetsUpload } from './AssetsUpload.interface';

import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import Text from '../Text/Text';

const DropZone = styled(Flex)<{ error?: boolean }>`
  background-color: ${({ theme }) => theme.colors.base};
  position: relative;
  min-height: calc(100vh - 300px);
`;

const DropZoneInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
`;
const Overlay = styled(Box)<{ show: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${({ theme }) => theme.colors.base};
  opacity: 0.7;
  pointer-events: none;

  ${({ show }) =>
    !show
      ? `
  display: none;
  z-index: -1;
  `
      : null}
`;

const ContextMenu = styled.ul<IBoxProps>`
  position: fixed;
  background-color: ${({ theme }) => theme.colors.base};
  border: 1px solid ${({ theme }) => theme.colors.grey200};
  box-shadow: ${({ theme }) => theme.shadow.lgShadow};
  border-radius: 8px;
  z-index: 30 !important;
  overflow: hidden;
  ${boxStyle}
`;
const ListItem = styled.li<IBoxProps>`
  ${boxStyle}
  &:hover:first-child {
    background-color: ${({ theme }) => theme.colors.base};
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.grey100};
  }
`;
const DropdownDivider = styled(Dropdown.Divider)`
  margin: 0;
  border-top: 1px solid ${({ theme }) => theme.colors.grey200};
`;

export const AssetsUpload = React.memo(
  ({
    error: propError,
    projectName,
    onDrop,
    children,
    className,
  }: IAssetsUpload) => {
    const theme = useTheme();
    const viewer = useAppSelector(selectViewer);
    const [error, setError] = useState(false);
    const [show, setShow] = useState(false);
    const [showContextMenu, setShowContextMenu] = useState(false);
    const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });

    const ref = useRef<HTMLElement>(null);
    const { getRootProps, getInputProps, open } = useDropzone({
      multiple: true,
      noClick: true,
      accept: {},
      onError() {
        setError(true);
      },
      onDrop,
      onDropAccepted: useCallback(() => {
        setShow(false);
      }, []),
      onDropRejected: useCallback(() => {
        setShow(false);
      }, []),
      onDragLeave: useCallback(() => {
        setShow(false);
      }, []),
      onDragOver: useCallback(() => {
        setShow(true);
      }, []),
    });
    useEffect(() => {
      setError(propError || false);
    }, [propError]);
    const handleContextMenu = useCallback(
      (event: MouseEvent) => {
        event.preventDefault();
        if (
          (event.target as HTMLElement).id === 'listOfAssets' ||
          (event.target as HTMLElement).id === 'no-assets'
        ) {
          setAnchorPoint({ x: event.pageX, y: event.pageY });
          setShowContextMenu(true);
        }
      },
      [setAnchorPoint]
    );

    const handleClick = useCallback(
      () => (showContextMenu ? setShowContextMenu(false) : null),
      [showContextMenu]
    );
    useOnRightClick(ref, handleClick, handleContextMenu);
    useEffect(() => {
      handleClick();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectName]);

    return (
      <Box {...getRootProps()} ref={ref} fullWidth className={className}>
        {showContextMenu && viewer?.roles === Roles.VidicoAdmin ? (
          <ContextMenu
            style={{
              top: anchorPoint.y,
              left: anchorPoint.x,
            }}
          >
            <ListItem px={4} py={2}>
              <Text sm bold>
                {projectName}
              </Text>
            </ListItem>
            <DropdownDivider />
            <ListItem px={4} py={2}>
              <Button noStyle onMouseUp={open}>
                <Flex alignItems="center">
                  <Icon
                    mr={3}
                    size={16}
                    name="share01"
                    color={theme.colors.vidicoBlack}
                  />
                  <Text as="span" sm medium color={theme.colors.vidicoBlack}>
                    Upload media
                  </Text>
                </Flex>
              </Button>
            </ListItem>
          </ContextMenu>
        ) : null}

        <DropZone error={error} justifyContent="center" fullWidth>
          <Flex fullWidth flexDirection="column" alignItems="center">
            <DropZoneInput type="file" {...getInputProps()} />
            {children}
          </Flex>
          {viewer?.roles === Roles.VidicoAdmin && (
            <Overlay show={show}>
              <Flex
                h="100%"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <Icon
                  name="download01"
                  size={32}
                  color={theme.colors.primary600}
                />
                <Text md semibold color={theme.colors.vidicoBlack}>
                  Drop file any where to begin.
                </Text>
              </Flex>
            </Overlay>
          )}
        </DropZone>
      </Box>
    );
  }
);
