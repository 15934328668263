import { useApolloClient } from '@apollo/client';
import axios from 'axios';
import { useState, createContext, useCallback, useRef, useMemo } from 'react';

import {
  AssetFragment,
  FileType,
  Localizations,
  UploadAssetInput,
  useAssetsUploadMutation,
} from 'graph/generated.graphql';
import { ASSET_QUERY } from 'graph/workspaces/queries';
import { makeId } from 'utils/helpers/makeRandomId';
import { getExifData, getImageWidthHeight } from 'utils/helpers/metadata';

const defaultContextValue = {};

export type ProcessState = {
  errorMessage?: string;
  progress?: number;
  uploaded?: boolean;
  asset?: Partial<AssetFragment>;
};

const BackgroundUploadContext = createContext<{
  state: Record<string, Record<string, ProcessState>>;
  handleUpload: Function;
  setNewStateUploaded: (
    projectId: string,
    fileId: string,
    uploaded: boolean
  ) => void;
  setNewState: (projectId: string, fileId: string, state: ProcessState) => void;
  removeAssetFromState: (projectId: string, fileId: string) => void;
  removeAssetsByTypeFromState: (
    projectId: string,
    type: FileType,
    localization: Localizations
  ) => void;
}>({
  state: defaultContextValue,
  handleUpload: () => null,
  setNewStateUploaded: () => null,
  setNewState: () => null,
  removeAssetFromState: () => null,
  removeAssetsByTypeFromState: () => null,
});

function BackgroundUploadProvider({ children }: { children: JSX.Element }) {
  const [uploadState, setUploadState] = useState<
    Record<string, Record<string, ProcessState>>
  >({});
  const fileMapRef = useRef<Record<string, File>>({});
  const client = useApolloClient();
  const setNewState = useCallback(
    (projectId: string, fileId: string, state: ProcessState) => {
      setUploadState((oldState) => ({
        ...oldState,
        [projectId]: {
          ...(oldState[projectId] || {}),
          [fileId]: state,
        },
      }));
    },
    [setUploadState]
  );
  const removeAssetFromState = useCallback(
    (projectId: string, fileId: string) => {
      setUploadState((oldState) => {
        const copy = { ...oldState };
        if (copy[projectId]) {
          delete copy[projectId][fileId];
        }
        return { ...copy };
      });
    },
    [setUploadState]
  );
  const removeAssetsByTypeFromState = useCallback(
    (projectId: string, type: FileType, localization: Localizations) => {
      setUploadState((oldState) => {
        const copy = { ...oldState };
        if (copy[projectId]) {
          for (const [key, value] of Object.entries(copy[projectId])) {
            if (
              value.asset?.type === type &&
              value.asset.localization === localization
            ) {
              delete copy[projectId][key];
            }
          }
        }
        return { ...copy };
      });
    },
    [setUploadState]
  );
  const setNewStateUploaded = useCallback(
    (projectId: string, fileId: string, uploaded: boolean) => {
      setUploadState((oldState) => ({
        ...oldState,
        [projectId]: {
          ...(oldState[projectId] || {}),
          [fileId]: {
            ...(oldState[projectId][fileId] || {}),
            uploaded,
          },
        },
      }));
    },
    [setUploadState]
  );

  const [getUploadLinks] = useAssetsUploadMutation();

  const handleUpload = useCallback(
    async ({
      files,
      projectId,
      localization,
    }: {
      files: Array<File>;
      projectId: string;
      localization: Localizations;
      refetch: () => void;
    }) => {
      const toUpload = await Promise.all(
        files.map(async (file) => {
          fileMapRef.current[`${projectId}-${localization}-${file.name}`] =
            file;
          let uploadFileObj: UploadAssetInput = {
            fullName: file.name,
            size: file.size,
            originalUpdatedAt: new Date(file.lastModified),
          };
          let additionData: Partial<UploadAssetInput> = {};

          if (!file.type.includes('video')) {
            const res = await getExifData(file);
            if (res) {
              additionData = {
                ...additionData,
                maxWidth: res?.ImageWidth,
                maxHeight: res?.ImageHeight,
                dotPerInch: res?.XResolution,
              };
            }
            const imageResult = await getImageWidthHeight(file);
            if (imageResult) {
              const { maxHeight, maxWidth } = imageResult;
              additionData = { ...additionData, maxHeight, maxWidth };
            }
          }

          uploadFileObj = { ...uploadFileObj, ...additionData };
          return uploadFileObj;
        })
      );

      getUploadLinks({
        variables: {
          files: toUpload,
          projectId,
          localization,
        },
      })
        .then(({ data }) => {
          if (!data) return;
          const { s3Urls } = data.assetUpload || {};
          s3Urls.forEach(({ url, fileName, oldFileName, assetId, type }) => {
            const fileId = `${projectId}-${localization}-${oldFileName}`;
            const file = fileMapRef.current[fileId] || {};
            axios
              .put(url, file, {
                headers: {
                  'Content-Type': file.type,
                },
                onUploadProgress: (progressEvent) => {
                  setNewState(projectId, assetId, {
                    errorMessage: '',
                    progress: progressEvent.progress || 0,
                    uploaded: false,
                    asset: {
                      name: fileName,
                      localization,
                      type,
                    },
                  });
                },
              })
              .then(() => {
                setTimeout(() => {
                  client
                    .query({ query: ASSET_QUERY, variables: { assetId } })
                    .then(({ data: dataS3 }) => {
                      const assetData = dataS3?.asset.asset;
                      setNewState(projectId, assetId, {
                        errorMessage: '',
                        progress: 1,
                        uploaded: true,
                        asset: assetData,
                      });
                    });
                }, 7000);
              })
              .catch((error) => {
                setNewState(projectId, assetId, {
                  errorMessage: error.message,
                  progress: 0,
                  uploaded: false,
                  asset: {
                    name: fileName,
                    localization,
                    type,
                  },
                });
              });
          });
        })
        .catch((error) => {
          toUpload.forEach((item) =>
            setNewState(projectId, makeId(12), {
              errorMessage: error.message,
              progress: 0,
              uploaded: false,
              asset: {
                name: item.fullName,
                localization,
                type: FileType.Picture,
              },
            })
          );
        });
    },
    [client, getUploadLinks, setNewState]
  );
  const value = useMemo(
    () => ({
      state: uploadState,
      handleUpload,
      setNewStateUploaded,
      setNewState,
      removeAssetFromState,
      removeAssetsByTypeFromState,
    }),
    [
      handleUpload,
      removeAssetFromState,
      removeAssetsByTypeFromState,
      setNewStateUploaded,
      setNewState,
      uploadState,
    ]
  );

  return (
    <BackgroundUploadContext.Provider value={value}>
      {children}
    </BackgroundUploadContext.Provider>
  );
}

export { BackgroundUploadProvider, BackgroundUploadContext };
