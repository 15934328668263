import React from 'react';
import styled, { useTheme } from 'styled-components';

import Box from 'components/atoms/Box/Box';
import Flex from 'components/atoms/Flex/Flex';
import Icon from 'components/atoms/Icon/Icon';
import Text from 'components/atoms/Text/Text';
import ActionModal, {
  ButtonsAlignmentName,
} from 'components/molecules/ActionModal/ActionModal';
import { Modal } from 'components/molecules/Modal/Modal';

import { useChangeRoleMutation } from 'graph/generated.graphql';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  selectChangeAccessLevelInfo,
  selectChangeAccessLevelModal,
  setModal,
} from 'redux/modal/modalSlice';

const IconWrapper = styled(Flex)`
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.error100};
`;
function ChangeAccessLevelModal() {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const show = useAppSelector(selectChangeAccessLevelModal);
  const info = useAppSelector(selectChangeAccessLevelInfo);
  const onModalClose = () => {
    dispatch(setModal({ showChangeAccessLevelModal: false }));
  };
  const [changeRole, { loading: isLoading }] = useChangeRoleMutation({
    onCompleted() {
      onModalClose();
    },
  });

  return (
    <Modal show={show} onHide={onModalClose} width={450}>
      <Flex fullWidth justifyContent="left" alignItems="center" mb={3} gap={8}>
        <IconWrapper w={32} h={32} justifyContent="center" alignItems="center">
          <Icon color={theme.colors.error600} size={16} name="alertCircle" />
        </IconWrapper>
        <Text lg bold>
          Change Access
        </Text>
      </Flex>
      <Box mb={5}>
        <Text sm medium color={theme.colors.grey600}>
          Changing your access to a lower level will revoke your own access. To
          reinstate this access you will need to contact a Workspace Owner or
          Admin.
        </Text>
      </Box>
      <ActionModal
        buttonsAlignment={ButtonsAlignmentName.RIGHT}
        secondaryButtonProps={{
          text: 'Cancel',
          onClick: onModalClose,
        }}
        primaryButtonProps={{
          text: 'Change Access',
          type: 'button',
          isLoading,
          onClick: () => changeRole({ variables: info }),
        }}
      />
    </Modal>
  );
}

export default ChangeAccessLevelModal;
