import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  Long: any;
  UUID: any;
  Upload: any;
}

export interface AccountChangePasswordCommandInput {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
}

export interface AccountCreateCommandInput {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  googleCode?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
}

export interface AccountDeleteCommandInput {
  improvement?: InputMaybe<Scalars['String']>;
  reason?: InputMaybe<DeleteReasons>;
}

export interface AccountEditCommandInput {
  country?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  file?: InputMaybe<Scalars['Upload']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
}

export interface AccountEmailConfirmationCommandInput {
  key: Scalars['String'];
}

export interface AccountInviteCommandInput {
  companyId: Scalars['UUID'];
  invites: Array<InviteRecordInput>;
}

export interface AccountInvitePayload {
  __typename?: 'AccountInvitePayload';
  errors?: Maybe<Array<Error>>;
  inviteResponses: Array<InviteResponse>;
  isUnSuccess: Scalars['Boolean'];
}

export interface AccountLoginCommandInput {
  code?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  longSession?: Scalars['Boolean'];
  password?: InputMaybe<Scalars['String']>;
}

export interface AccountRequestEmailVerifyCommandInput {
  email: Scalars['String'];
}

export interface AccountResendInviteCommandInput {
  userId: Scalars['UUID'];
}

export interface AccountResetPasswordCommandInput {
  email: Scalars['String'];
}

export interface AccountSetPasswordCommandInput {
  firstName?: InputMaybe<Scalars['String']>;
  key: Scalars['String'];
  lastName?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  resetPassword?: Scalars['Boolean'];
}

export interface AccountTokenPayload {
  __typename?: 'AccountTokenPayload';
  email?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  role?: Maybe<Roles>;
  subdomainName?: Maybe<Scalars['String']>;
}

export interface ActionPayload {
  __typename?: 'ActionPayload';
  errors?: Maybe<Array<Error>>;
  isSuccess: Scalars['Boolean'];
}

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
  Validation = 'VALIDATION',
}

export interface Asset {
  __typename?: 'Asset';
  aspectRatio: Scalars['String'];
  audio: Scalars['String'];
  audioChannels?: Maybe<Scalars['Int']>;
  bitRate?: Maybe<Scalars['Int']>;
  codec?: Maybe<Scalars['String']>;
  colorSpace?: Maybe<Scalars['String']>;
  companyId: Scalars['UUID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy: Scalars['UUID'];
  dealeted: Scalars['Boolean'];
  dealetedAt?: Maybe<Scalars['Date']>;
  dealetedBy?: Maybe<Scalars['UUID']>;
  dotPerInch?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['Float']>;
  expireStreamDate?: Maybe<Scalars['Date']>;
  extension: Scalars['String'];
  favoriteFor: Array<Favorite>;
  frameRate?: Maybe<Scalars['Float']>;
  hasThumbnail: Scalars['Boolean'];
  id: Scalars['UUID'];
  isNew: Scalars['Boolean'];
  isPicture: Scalars['Boolean'];
  isVideo: Scalars['Boolean'];
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['UUID']>;
  localization: Localizations;
  maxHeight?: Maybe<Scalars['Int']>;
  maxResolution: Scalars['String'];
  maxWidth?: Maybe<Scalars['Int']>;
  mp4Support: Mp4Support;
  muxDownloadUrl: Scalars['String'];
  muxId: Scalars['String'];
  muxStreamUrl: Scalars['String'];
  muxUrlCreatedAt?: Maybe<Scalars['Date']>;
  name: Scalars['String'];
  normalizedName: Scalars['String'];
  originalCreatedAt?: Maybe<Scalars['Date']>;
  originalUpdatedAt?: Maybe<Scalars['Date']>;
  path: Scalars['String'];
  playbackId: Scalars['String'];
  project: Project;
  projectId: Scalars['UUID'];
  projectName: Scalars['String'];
  s3DownloadUrl: Scalars['String'];
  s3UrlCreatedAt?: Maybe<Scalars['Date']>;
  sharedLinks: Array<SharedLink>;
  size: Scalars['Float'];
  status: AssetStatus;
  thumbnail?: Maybe<Scalars['String']>;
  type: FileType;
  uploadId: Scalars['String'];
  uploaded: Scalars['Boolean'];
  viewedBy: Array<User>;
}

export interface AssetViewedByArgs {
  where?: InputMaybe<UserFilterInput>;
}

export interface AssetEntityPayload {
  __typename?: 'AssetEntityPayload';
  asset: Asset;
  errors?: Maybe<Array<Error>>;
}

export interface AssetFilterInput {
  and?: InputMaybe<Array<AssetFilterInput>>;
  aspectRatio?: InputMaybe<StringOperationFilterInput>;
  audio?: InputMaybe<StringOperationFilterInput>;
  audioChannels?: InputMaybe<IntOperationFilterInput>;
  bitRate?: InputMaybe<IntOperationFilterInput>;
  codec?: InputMaybe<StringOperationFilterInput>;
  colorSpace?: InputMaybe<StringOperationFilterInput>;
  companyId?: InputMaybe<UuidOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  dealeted?: InputMaybe<BooleanOperationFilterInput>;
  dealetedAt?: InputMaybe<DateTimeOperationFilterInput>;
  dealetedBy?: InputMaybe<UuidOperationFilterInput>;
  dotPerInch?: InputMaybe<IntOperationFilterInput>;
  duration?: InputMaybe<FloatOperationFilterInput>;
  expireStreamDate?: InputMaybe<DateTimeOperationFilterInput>;
  extension?: InputMaybe<StringOperationFilterInput>;
  favoriteFor?: InputMaybe<ListFilterInputTypeOfFavoriteFilterInput>;
  frameRate?: InputMaybe<FloatOperationFilterInput>;
  hasThumbnail?: InputMaybe<BooleanOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  isPicture?: InputMaybe<BooleanOperationFilterInput>;
  isVideo?: InputMaybe<BooleanOperationFilterInput>;
  lastModifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: InputMaybe<UuidOperationFilterInput>;
  localization?: InputMaybe<LocalizationsOperationFilterInput>;
  maxHeight?: InputMaybe<IntOperationFilterInput>;
  maxResolution?: InputMaybe<StringOperationFilterInput>;
  maxWidth?: InputMaybe<IntOperationFilterInput>;
  mp4Support?: InputMaybe<Mp4SupportOperationFilterInput>;
  muxDownloadUrl?: InputMaybe<StringOperationFilterInput>;
  muxId?: InputMaybe<StringOperationFilterInput>;
  muxStreamUrl?: InputMaybe<StringOperationFilterInput>;
  muxUrlCreatedAt?: InputMaybe<DateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  normalizedName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<AssetFilterInput>>;
  originalCreatedAt?: InputMaybe<DateTimeOperationFilterInput>;
  originalUpdatedAt?: InputMaybe<DateTimeOperationFilterInput>;
  path?: InputMaybe<StringOperationFilterInput>;
  playbackId?: InputMaybe<StringOperationFilterInput>;
  project?: InputMaybe<ProjectFilterInput>;
  projectId?: InputMaybe<UuidOperationFilterInput>;
  s3DownloadUrl?: InputMaybe<StringOperationFilterInput>;
  s3UrlCreatedAt?: InputMaybe<DateTimeOperationFilterInput>;
  sharedLinks?: InputMaybe<ListFilterInputTypeOfSharedLinkFilterInput>;
  size?: InputMaybe<FloatOperationFilterInput>;
  status?: InputMaybe<AssetStatusOperationFilterInput>;
  thumbnail?: InputMaybe<StringOperationFilterInput>;
  type?: InputMaybe<FileTypeOperationFilterInput>;
  uploadId?: InputMaybe<StringOperationFilterInput>;
  uploaded?: InputMaybe<BooleanOperationFilterInput>;
  viewedBy?: InputMaybe<ListFilterInputTypeOfUserFilterInput>;
}

export interface AssetPayloadBase {
  __typename?: 'AssetPayloadBase';
  asset?: Maybe<Asset>;
  errors?: Maybe<Array<Error>>;
}

export interface AssetQueryInput {
  assetId: Scalars['UUID'];
  key?: InputMaybe<Scalars['String']>;
}

export interface AssetRemoveCommandInput {
  assetsId: Array<Scalars['UUID']>;
}

export interface AssetRenameCommandInput {
  assetId: Scalars['UUID'];
  assetName: Scalars['String'];
}

export interface AssetSortInput {
  aspectRatio?: InputMaybe<SortEnumType>;
  audio?: InputMaybe<SortEnumType>;
  audioChannels?: InputMaybe<SortEnumType>;
  bitRate?: InputMaybe<SortEnumType>;
  codec?: InputMaybe<SortEnumType>;
  colorSpace?: InputMaybe<SortEnumType>;
  companyId?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  dealeted?: InputMaybe<SortEnumType>;
  dealetedAt?: InputMaybe<SortEnumType>;
  dealetedBy?: InputMaybe<SortEnumType>;
  dotPerInch?: InputMaybe<SortEnumType>;
  duration?: InputMaybe<SortEnumType>;
  expireStreamDate?: InputMaybe<SortEnumType>;
  extension?: InputMaybe<SortEnumType>;
  frameRate?: InputMaybe<SortEnumType>;
  hasThumbnail?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isPicture?: InputMaybe<SortEnumType>;
  isVideo?: InputMaybe<SortEnumType>;
  lastModifiedAt?: InputMaybe<SortEnumType>;
  lastModifiedBy?: InputMaybe<SortEnumType>;
  localization?: InputMaybe<SortEnumType>;
  maxHeight?: InputMaybe<SortEnumType>;
  maxResolution?: InputMaybe<SortEnumType>;
  maxWidth?: InputMaybe<SortEnumType>;
  mp4Support?: InputMaybe<SortEnumType>;
  muxDownloadUrl?: InputMaybe<SortEnumType>;
  muxId?: InputMaybe<SortEnumType>;
  muxStreamUrl?: InputMaybe<SortEnumType>;
  muxUrlCreatedAt?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  normalizedName?: InputMaybe<SortEnumType>;
  originalCreatedAt?: InputMaybe<SortEnumType>;
  originalUpdatedAt?: InputMaybe<SortEnumType>;
  path?: InputMaybe<SortEnumType>;
  playbackId?: InputMaybe<SortEnumType>;
  project?: InputMaybe<ProjectSortInput>;
  projectId?: InputMaybe<SortEnumType>;
  s3DownloadUrl?: InputMaybe<SortEnumType>;
  s3UrlCreatedAt?: InputMaybe<SortEnumType>;
  size?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
  thumbnail?: InputMaybe<SortEnumType>;
  type?: InputMaybe<SortEnumType>;
  uploadId?: InputMaybe<SortEnumType>;
  uploaded?: InputMaybe<SortEnumType>;
}

export enum AssetStatus {
  BitRateAdded = 'BIT_RATE_ADDED',
  Mp4StatuCreated = 'MP4_STATU_CREATED',
  MuxDownloadUrlCreated = 'MUX_DOWNLOAD_URL_CREATED',
  NotUploaded = 'NOT_UPLOADED',
  S3Uploaded = 'S3_UPLOADED',
  Uploaded = 'UPLOADED',
}

export interface AssetStatusOperationFilterInput {
  eq?: InputMaybe<AssetStatus>;
  in?: InputMaybe<Array<AssetStatus>>;
  neq?: InputMaybe<AssetStatus>;
  nin?: InputMaybe<Array<AssetStatus>>;
}

export interface AssetSub {
  __typename?: 'AssetSub';
  companyId: Scalars['UUID'];
  hasThumbnail: Scalars['Boolean'];
  id: Scalars['UUID'];
  localization: Localizations;
  muxDownloadUrl: Scalars['String'];
  name: Scalars['String'];
  path: Scalars['String'];
  projectId: Scalars['UUID'];
  thumbnail?: Maybe<Scalars['String']>;
  type: FileType;
}

export interface AssetSubscription {
  __typename?: 'AssetSubscription';
  subscribeAsset: Asset;
}

export interface AssetSubscriptionSubscribeAssetArgs {
  localization: Localizations;
  projectId: Scalars['UUID'];
}

export interface AssetUploadCommandInput {
  files: Array<UploadAssetInput>;
  localization: Localizations;
  projectId: Scalars['UUID'];
}

export interface AssetUploadUpdateCommandInput {
  assetId: Scalars['UUID'];
}

export interface AssetUrlPayloads {
  __typename?: 'AssetUrlPayloads';
  errors?: Maybe<Array<Error>>;
  s3Urls: Array<S3UrlResponse>;
}

export interface AssetsDownloadCommandInput {
  assetsId: Array<Scalars['UUID']>;
  sharedLinkKey?: InputMaybe<Scalars['String']>;
}

export interface AssetsDownloadPayload {
  __typename?: 'AssetsDownloadPayload';
  assets?: Maybe<Array<Asset>>;
  errors?: Maybe<Array<Error>>;
}

/** A segment of a collection. */
export interface AssetsListCollectionSegment {
  __typename?: 'AssetsListCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Asset>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
}

export interface AssetsStarredCommandInput {
  assetsId: Array<Scalars['UUID']>;
}

export interface AssetsVievedByCommandInput {
  assetsId: Array<Scalars['UUID']>;
}

export interface BooleanOperationFilterInput {
  eq?: InputMaybe<Scalars['Boolean']>;
  neq?: InputMaybe<Scalars['Boolean']>;
}

export enum Budget {
  Large = 'LARGE',
  Maximum = 'MAXIMUM',
  Medium = 'MEDIUM',
  Small = 'SMALL',
}

/** Information about the offset pagination. */
export interface CollectionSegmentInfo {
  __typename?: 'CollectionSegmentInfo';
  /** Indicates whether more items exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean'];
  /** Indicates whether more items exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean'];
}

/** A segment of a collection. */
export interface CompaniesListCollectionSegment {
  __typename?: 'CompaniesListCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Company>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
}

export interface Company {
  __typename?: 'Company';
  createdAt: Scalars['Date'];
  createdBy: Scalars['UUID'];
  currency: CurrencyType;
  domainNames: Array<Scalars['String']>;
  id: Scalars['UUID'];
  lastActiveData: Scalars['Date'];
  lastModifiedAt?: Maybe<Scalars['Date']>;
  lastModifiedBy?: Maybe<Scalars['UUID']>;
  lastSessionAt?: Maybe<Scalars['Date']>;
  logo?: Maybe<Scalars['String']>;
  managerId: Scalars['UUID'];
  name: Scalars['String'];
  normalizedName: Scalars['String'];
  pathToLogo: Scalars['String'];
  pathToLogoThumbnail: Scalars['String'];
  projects: Array<Project>;
  projectsCount: Scalars['Int'];
  subdomainName: Scalars['String'];
  teamMembers: Array<User>;
  teamMembersCount: Scalars['Int'];
  totalAssetsSize: Scalars['Float'];
}

export interface CompanyCreateCommandInput {
  company: Scalars['String'];
  currency?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  file: Scalars['Upload'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  managerId: Scalars['UUID'];
}

export interface CompanyDeleteCommandInput {
  companyId: Scalars['UUID'];
}

export interface CompanyEditCommandInput {
  companyId: Scalars['UUID'];
  companyName?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  domainNames?: InputMaybe<Array<Scalars['String']>>;
  file?: InputMaybe<Scalars['Upload']>;
  managerId?: InputMaybe<Scalars['UUID']>;
}

export interface CompanyFilterInput {
  and?: InputMaybe<Array<CompanyFilterInput>>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  currency?: InputMaybe<CurrencyTypeOperationFilterInput>;
  domainNames?: InputMaybe<ListStringOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  lastActiveData?: InputMaybe<DateTimeOperationFilterInput>;
  lastModifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: InputMaybe<UuidOperationFilterInput>;
  lastSessionAt?: InputMaybe<DateTimeOperationFilterInput>;
  logo?: InputMaybe<StringOperationFilterInput>;
  managerId?: InputMaybe<UuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  normalizedName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<CompanyFilterInput>>;
  pathToLogo?: InputMaybe<StringOperationFilterInput>;
  pathToLogoThumbnail?: InputMaybe<StringOperationFilterInput>;
  projects?: InputMaybe<ListFilterInputTypeOfProjectFilterInput>;
  projectsCount?: InputMaybe<IntOperationFilterInput>;
  subdomainName?: InputMaybe<StringOperationFilterInput>;
  teamMembers?: InputMaybe<ListFilterInputTypeOfUserFilterInput>;
  teamMembersCount?: InputMaybe<IntOperationFilterInput>;
  totalAssetsSize?: InputMaybe<FloatOperationFilterInput>;
}

export interface CompanyPayloadBase {
  __typename?: 'CompanyPayloadBase';
  company?: Maybe<Company>;
  errors?: Maybe<Array<Error>>;
}

export interface CompanyQueryInput {
  companyId?: InputMaybe<Scalars['UUID']>;
}

export interface CompanySortInput {
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  currency?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastActiveData?: InputMaybe<SortEnumType>;
  lastModifiedAt?: InputMaybe<SortEnumType>;
  lastModifiedBy?: InputMaybe<SortEnumType>;
  lastSessionAt?: InputMaybe<SortEnumType>;
  logo?: InputMaybe<SortEnumType>;
  managerId?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  normalizedName?: InputMaybe<SortEnumType>;
  pathToLogo?: InputMaybe<SortEnumType>;
  pathToLogoThumbnail?: InputMaybe<SortEnumType>;
  projectsCount?: InputMaybe<SortEnumType>;
  subdomainName?: InputMaybe<SortEnumType>;
  teamMembersCount?: InputMaybe<SortEnumType>;
  totalAssetsSize?: InputMaybe<SortEnumType>;
}

export enum CurrencyType {
  Aud = 'AUD',
  Usd = 'USD',
}

export interface CurrencyTypeOperationFilterInput {
  eq?: InputMaybe<CurrencyType>;
  in?: InputMaybe<Array<CurrencyType>>;
  neq?: InputMaybe<CurrencyType>;
  nin?: InputMaybe<Array<CurrencyType>>;
}

export interface DateTimeOperationFilterInput {
  eq?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  neq?: InputMaybe<Scalars['DateTime']>;
  ngt?: InputMaybe<Scalars['DateTime']>;
  ngte?: InputMaybe<Scalars['DateTime']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  nlt?: InputMaybe<Scalars['DateTime']>;
  nlte?: InputMaybe<Scalars['DateTime']>;
}

export enum DeleteReasons {
  DontFind = 'DONT_FIND',
  DontNeed = 'DONT_NEED',
  Other = 'OTHER',
}

export interface Error {
  __typename?: 'Error';
  code: Scalars['Int'];
  message: Scalars['String'];
}

export interface Favorite {
  __typename?: 'Favorite';
  asset?: Maybe<Asset>;
  assetId: Scalars['UUID'];
  createdAt: Scalars['Date'];
  user: User;
  userId: Scalars['UUID'];
}

export interface FavoriteFilterInput {
  and?: InputMaybe<Array<FavoriteFilterInput>>;
  asset?: InputMaybe<AssetFilterInput>;
  assetId?: InputMaybe<UuidOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<FavoriteFilterInput>>;
  user?: InputMaybe<UserFilterInput>;
  userId?: InputMaybe<UuidOperationFilterInput>;
}

export enum FileType {
  Caption = 'CAPTION',
  Cutdown = 'CUTDOWN',
  Editing = 'EDITING',
  Picture = 'PICTURE',
  Video = 'VIDEO',
}

export interface FileTypeOperationFilterInput {
  eq?: InputMaybe<FileType>;
  in?: InputMaybe<Array<FileType>>;
  neq?: InputMaybe<FileType>;
  nin?: InputMaybe<Array<FileType>>;
}

export interface FloatOperationFilterInput {
  eq?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  neq?: InputMaybe<Scalars['Float']>;
  ngt?: InputMaybe<Scalars['Float']>;
  ngte?: InputMaybe<Scalars['Float']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  nlt?: InputMaybe<Scalars['Float']>;
  nlte?: InputMaybe<Scalars['Float']>;
}

export interface GenerateMuxDownloadUrlCommandInput {
  assetsId?: InputMaybe<Array<Scalars['UUID']>>;
  localization?: InputMaybe<Localizations>;
  projectId?: InputMaybe<Scalars['UUID']>;
}

export interface IntOperationFilterInput {
  eq?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  neq?: InputMaybe<Scalars['Int']>;
  ngt?: InputMaybe<Scalars['Int']>;
  ngte?: InputMaybe<Scalars['Int']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  nlt?: InputMaybe<Scalars['Int']>;
  nlte?: InputMaybe<Scalars['Int']>;
}

export interface InviteRecordInput {
  email: Scalars['String'];
  role: Roles;
  send: Scalars['Boolean'];
}

export interface InviteResponse {
  __typename?: 'InviteResponse';
  email: Scalars['String'];
  isSuccess: Scalars['Boolean'];
}

export interface ListFilterInputTypeOfAssetFilterInput {
  all?: InputMaybe<AssetFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AssetFilterInput>;
  some?: InputMaybe<AssetFilterInput>;
}

export interface ListFilterInputTypeOfFavoriteFilterInput {
  all?: InputMaybe<FavoriteFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<FavoriteFilterInput>;
  some?: InputMaybe<FavoriteFilterInput>;
}

export interface ListFilterInputTypeOfProjectFilterInput {
  all?: InputMaybe<ProjectFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ProjectFilterInput>;
  some?: InputMaybe<ProjectFilterInput>;
}

export interface ListFilterInputTypeOfSharedLinkFilterInput {
  all?: InputMaybe<SharedLinkFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<SharedLinkFilterInput>;
  some?: InputMaybe<SharedLinkFilterInput>;
}

export interface ListFilterInputTypeOfUserFilterInput {
  all?: InputMaybe<UserFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<UserFilterInput>;
  some?: InputMaybe<UserFilterInput>;
}

export interface ListLocalizationsOperationFilterInput {
  all?: InputMaybe<LocalizationsOperationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<LocalizationsOperationFilterInput>;
  some?: InputMaybe<LocalizationsOperationFilterInput>;
}

export interface ListPowerUpsOperationFilterInput {
  all?: InputMaybe<PowerUpsOperationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<PowerUpsOperationFilterInput>;
  some?: InputMaybe<PowerUpsOperationFilterInput>;
}

export interface ListStringOperationFilterInput {
  all?: InputMaybe<StringOperationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<StringOperationFilterInput>;
  some?: InputMaybe<StringOperationFilterInput>;
}

export enum Localizations {
  AuEnglish = 'AU_ENGLISH',
  BeDutch = 'BE_DUTCH',
  BrPortuguese = 'BR_PORTUGUESE',
  CaEnglish = 'CA_ENGLISH',
  ChMandarin = 'CH_MANDARIN',
  DeGerman = 'DE_GERMAN',
  EuSpanish = 'EU_SPANISH',
  FrFrench = 'FR_FRENCH',
  HkChinese = 'HK_CHINESE',
  IrEnglish = 'IR_ENGLISH',
  ItItalian = 'IT_ITALIAN',
  JpJapanese = 'JP_JAPANESE',
  KrKorean = 'KR_KOREAN',
  NlDutch = 'NL_DUTCH',
  NzEnglish = 'NZ_ENGLISH',
  PoPortuguese = 'PO_PORTUGUESE',
  UkEnglish = 'UK_ENGLISH',
  UsEnglish = 'US_ENGLISH',
  UsSpanish = 'US_SPANISH',
  ZaSouthAfrica = 'ZA_SOUTH_AFRICA',
}

export interface LocalizationsOperationFilterInput {
  eq?: InputMaybe<Localizations>;
  in?: InputMaybe<Array<Localizations>>;
  neq?: InputMaybe<Localizations>;
  nin?: InputMaybe<Array<Localizations>>;
}

export interface LongOperationFilterInput {
  eq?: InputMaybe<Scalars['Long']>;
  gt?: InputMaybe<Scalars['Long']>;
  gte?: InputMaybe<Scalars['Long']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Long']>>>;
  lt?: InputMaybe<Scalars['Long']>;
  lte?: InputMaybe<Scalars['Long']>;
  neq?: InputMaybe<Scalars['Long']>;
  ngt?: InputMaybe<Scalars['Long']>;
  ngte?: InputMaybe<Scalars['Long']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Long']>>>;
  nlt?: InputMaybe<Scalars['Long']>;
  nlte?: InputMaybe<Scalars['Long']>;
}

export enum Mp4Support {
  None = 'NONE',
  Standard = 'STANDARD',
}

export interface Mp4SupportOperationFilterInput {
  eq?: InputMaybe<Mp4Support>;
  in?: InputMaybe<Array<Mp4Support>>;
  neq?: InputMaybe<Mp4Support>;
  nin?: InputMaybe<Array<Mp4Support>>;
}

export interface Mutation {
  __typename?: 'Mutation';
  /** The user will delete himself */
  accountDelete: ActionPayload;
  accountEdit: AccountTokenPayload;
  assetDownload: AssetsDownloadPayload;
  assetRemove: ActionPayload;
  assetRename: AssetEntityPayload;
  assetUpload: AssetUrlPayloads;
  assetUploadUpdate: ProjectPayloadBase;
  assetsStarred: UserPayload;
  assetsVievedBy: Array<Asset>;
  /** The user will delete himself */
  changePassword: ActionPayload;
  changeRole: UserEntityPayload;
  companyCreate: CompanyPayloadBase;
  companyDelete: ActionPayload;
  companyEdit: CompanyPayloadBase;
  createSharedLink: SharedLinkPayload;
  deleteProject: ActionPayload;
  deleteSharedLink: ActionPayload;
  editProject: ProjectPayloadBase;
  /** Confirm email after registration and set status Active. Need key from message */
  emailConfirmation: ActionPayload;
  generateMuxDownloadUrl: ActionPayload;
  /** Add member to workspace */
  inviteNewUser: AccountInvitePayload;
  logOut: ActionPayload;
  login: AccountTokenPayload;
  projectCreate: ProjectPayloadBase;
  registerUser: AccountTokenPayload;
  /** Resends mail to confirm email */
  requestEmailVerify: ActionPayload;
  /** Send email for reset password. Need email */
  requestResetPassword: ActionPayload;
  /** Resend invite link to a user */
  resendInviteToUser: ActionPayload;
  /** Set password after invitation or reset password */
  setPassword: AccountTokenPayload;
  sharedLink: SharedAssetsPayload;
  /** Delete user by VidicoAdmin ore Owner */
  userDelete: ActionPayload;
}

export interface MutationAccountDeleteArgs {
  input: AccountDeleteCommandInput;
}

export interface MutationAccountEditArgs {
  input: AccountEditCommandInput;
}

export interface MutationAssetDownloadArgs {
  input: AssetsDownloadCommandInput;
}

export interface MutationAssetRemoveArgs {
  input: AssetRemoveCommandInput;
}

export interface MutationAssetRenameArgs {
  input: AssetRenameCommandInput;
}

export interface MutationAssetUploadArgs {
  input: AssetUploadCommandInput;
}

export interface MutationAssetUploadUpdateArgs {
  input: AssetUploadUpdateCommandInput;
}

export interface MutationAssetsStarredArgs {
  input: AssetsStarredCommandInput;
}

export interface MutationAssetsVievedByArgs {
  input: AssetsVievedByCommandInput;
}

export interface MutationChangePasswordArgs {
  input: AccountChangePasswordCommandInput;
}

export interface MutationChangeRoleArgs {
  input: UserEditAccessCommandInput;
}

export interface MutationCompanyCreateArgs {
  input: CompanyCreateCommandInput;
}

export interface MutationCompanyDeleteArgs {
  input: CompanyDeleteCommandInput;
}

export interface MutationCompanyEditArgs {
  input: CompanyEditCommandInput;
}

export interface MutationCreateSharedLinkArgs {
  input: SharedLinkCreateCommandInput;
}

export interface MutationDeleteProjectArgs {
  input: ProjectDeleteCommandInput;
}

export interface MutationDeleteSharedLinkArgs {
  input: SharedLinkDeleteCommandInput;
}

export interface MutationEditProjectArgs {
  input: ProjectEditCommandInput;
}

export interface MutationEmailConfirmationArgs {
  input: AccountEmailConfirmationCommandInput;
}

export interface MutationGenerateMuxDownloadUrlArgs {
  input: GenerateMuxDownloadUrlCommandInput;
}

export interface MutationInviteNewUserArgs {
  input: AccountInviteCommandInput;
}

export interface MutationLoginArgs {
  input: AccountLoginCommandInput;
}

export interface MutationProjectCreateArgs {
  input: ProjectCreateCommandInput;
}

export interface MutationRegisterUserArgs {
  input: AccountCreateCommandInput;
}

export interface MutationRequestEmailVerifyArgs {
  input: AccountRequestEmailVerifyCommandInput;
}

export interface MutationRequestResetPasswordArgs {
  input: AccountResetPasswordCommandInput;
}

export interface MutationResendInviteToUserArgs {
  input: AccountResendInviteCommandInput;
}

export interface MutationSetPasswordArgs {
  input: AccountSetPasswordCommandInput;
}

export interface MutationSharedLinkArgs {
  input: SharedLinkCommandInput;
}

export interface MutationUserDeleteArgs {
  input: UserDeleteCommandInput;
}

export interface NewAssetsInfo {
  __typename?: 'NewAssetsInfo';
  all: Scalars['Int'];
  captions: Scalars['Int'];
  cutdowns: Scalars['Int'];
  editingFiles: Scalars['Int'];
  images: Scalars['Int'];
  videos: Scalars['Int'];
}

export interface NewAssetsInfoPayload {
  __typename?: 'NewAssetsInfoPayload';
  AU_ENGLISH?: Maybe<NewAssetsInfo>;
  BE_DUTCH?: Maybe<NewAssetsInfo>;
  BR_PORTUGUESE?: Maybe<NewAssetsInfo>;
  CA_ENGLISH?: Maybe<NewAssetsInfo>;
  CH_MANDARIN?: Maybe<NewAssetsInfo>;
  DE_GERMAN?: Maybe<NewAssetsInfo>;
  EU_SPANISH?: Maybe<NewAssetsInfo>;
  FR_FRENCH?: Maybe<NewAssetsInfo>;
  HK_CHINESE?: Maybe<NewAssetsInfo>;
  IR_ENGLISH?: Maybe<NewAssetsInfo>;
  IT_ITALIAN?: Maybe<NewAssetsInfo>;
  JP_JAPANESE?: Maybe<NewAssetsInfo>;
  KR_KOREAN?: Maybe<NewAssetsInfo>;
  NL_DUTCH?: Maybe<NewAssetsInfo>;
  NZ_ENGLISH?: Maybe<NewAssetsInfo>;
  PO_PORTUGUESE?: Maybe<NewAssetsInfo>;
  UK_ENGLISH?: Maybe<NewAssetsInfo>;
  US_ENGLISH?: Maybe<NewAssetsInfo>;
  US_SPANISH?: Maybe<NewAssetsInfo>;
  ZA_SOUTH_AFRICA?: Maybe<NewAssetsInfo>;
  errors?: Maybe<Array<Error>>;
}

export interface NewAssetsInfoQueryInput {
  projectId: Scalars['UUID'];
}

export interface NullableOfBudgetOperationFilterInput {
  eq?: InputMaybe<Budget>;
  in?: InputMaybe<Array<InputMaybe<Budget>>>;
  neq?: InputMaybe<Budget>;
  nin?: InputMaybe<Array<InputMaybe<Budget>>>;
}

export enum PowerUps {
  Captions = 'CAPTIONS',
  Cutdowns = 'CUTDOWNS',
  Ratios = 'RATIOS',
}

export interface PowerUpsOperationFilterInput {
  eq?: InputMaybe<PowerUps>;
  in?: InputMaybe<Array<PowerUps>>;
  neq?: InputMaybe<PowerUps>;
  nin?: InputMaybe<Array<PowerUps>>;
}

export interface Project {
  __typename?: 'Project';
  assets: Array<Asset>;
  assetsCount: Scalars['Int'];
  assetsSize: Scalars['Float'];
  budget?: Maybe<Budget>;
  company: Company;
  companyId: Scalars['UUID'];
  createdAt: Scalars['Date'];
  createdBy: Scalars['UUID'];
  dealeted: Scalars['Boolean'];
  dealetedAt: Scalars['Date'];
  dealetedBy?: Maybe<Scalars['UUID']>;
  description: Scalars['String'];
  existingLocalizations: Array<Localizations>;
  id: Scalars['UUID'];
  lastModifiedAt?: Maybe<Scalars['Date']>;
  lastModifiedBy?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  normalizedName: Scalars['String'];
  pendingLocalizations: Array<Localizations>;
  powerUps: Array<PowerUps>;
  sharedLinks: Array<SharedLink>;
  status: ProjectStatus;
  thumbnail: Scalars['String'];
  thumbnailId?: Maybe<Scalars['UUID']>;
  updatedThumbnail: Scalars['Boolean'];
}

export interface ProjectCreateCommandInput {
  briefDetails?: InputMaybe<Scalars['String']>;
  budget?: InputMaybe<Budget>;
  companyId: Scalars['UUID'];
  localizations?: InputMaybe<Array<Localizations>>;
  powerUps?: InputMaybe<Array<PowerUps>>;
  projectName: Scalars['String'];
}

export interface ProjectDeleteCommandInput {
  projectId: Scalars['UUID'];
}

export interface ProjectEditCommandInput {
  assetId?: InputMaybe<Scalars['UUID']>;
  localizations?: InputMaybe<Array<Localizations>>;
  projectId: Scalars['UUID'];
  projectName?: InputMaybe<Scalars['String']>;
  seconds?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<ProjectStatus>;
}

export interface ProjectFilterInput {
  and?: InputMaybe<Array<ProjectFilterInput>>;
  assets?: InputMaybe<ListFilterInputTypeOfAssetFilterInput>;
  assetsCount?: InputMaybe<IntOperationFilterInput>;
  assetsSize?: InputMaybe<FloatOperationFilterInput>;
  budget?: InputMaybe<NullableOfBudgetOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<UuidOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  dealeted?: InputMaybe<BooleanOperationFilterInput>;
  dealetedAt?: InputMaybe<DateTimeOperationFilterInput>;
  dealetedBy?: InputMaybe<UuidOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  existingLocalizations?: InputMaybe<ListLocalizationsOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  lastModifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: InputMaybe<UuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  normalizedName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ProjectFilterInput>>;
  pendingLocalizations?: InputMaybe<ListLocalizationsOperationFilterInput>;
  powerUps?: InputMaybe<ListPowerUpsOperationFilterInput>;
  sharedLinks?: InputMaybe<ListFilterInputTypeOfSharedLinkFilterInput>;
  status?: InputMaybe<ProjectStatusOperationFilterInput>;
  thumbnail?: InputMaybe<StringOperationFilterInput>;
  thumbnailId?: InputMaybe<UuidOperationFilterInput>;
  updatedThumbnail?: InputMaybe<BooleanOperationFilterInput>;
}

export interface ProjectPayloadBase {
  __typename?: 'ProjectPayloadBase';
  errors?: Maybe<Array<Error>>;
  project?: Maybe<Project>;
}

export interface ProjectQueryInput {
  projectId: Scalars['UUID'];
}

export interface ProjectSortInput {
  assetsCount?: InputMaybe<SortEnumType>;
  assetsSize?: InputMaybe<SortEnumType>;
  budget?: InputMaybe<SortEnumType>;
  company?: InputMaybe<CompanySortInput>;
  companyId?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  dealeted?: InputMaybe<SortEnumType>;
  dealetedAt?: InputMaybe<SortEnumType>;
  dealetedBy?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastModifiedAt?: InputMaybe<SortEnumType>;
  lastModifiedBy?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  normalizedName?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
  thumbnail?: InputMaybe<SortEnumType>;
  thumbnailId?: InputMaybe<SortEnumType>;
  updatedThumbnail?: InputMaybe<SortEnumType>;
}

export enum ProjectStatus {
  Approved = 'APPROVED',
  Locked = 'LOCKED',
  Pending = 'PENDING',
}

export interface ProjectStatusOperationFilterInput {
  eq?: InputMaybe<ProjectStatus>;
  in?: InputMaybe<Array<ProjectStatus>>;
  neq?: InputMaybe<ProjectStatus>;
  nin?: InputMaybe<Array<ProjectStatus>>;
}

/** A segment of a collection. */
export interface ProjectsListCollectionSegment {
  __typename?: 'ProjectsListCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Project>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
}

export interface ProjectsListQueryInput {
  companyId?: InputMaybe<Scalars['UUID']>;
}

export interface Query {
  __typename?: 'Query';
  asset: AssetPayloadBase;
  assetsList?: Maybe<AssetsListCollectionSegment>;
  companiesList?: Maybe<CompaniesListCollectionSegment>;
  newAssetsInfo: NewAssetsInfoPayload;
  projectsList?: Maybe<ProjectsListCollectionSegment>;
  userById: UserPayload;
  usersList?: Maybe<Array<User>>;
  validateKey: ActionPayload;
  vidicoAdmin: VidicoAdminsListPayload;
  viewCompany: CompanyPayloadBase;
  viewProject: ProjectPayloadBase;
}

export interface QueryAssetArgs {
  input: AssetQueryInput;
}

export interface QueryAssetsListArgs {
  order?: InputMaybe<Array<AssetSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssetFilterInput>;
}

export interface QueryCompaniesListArgs {
  order?: InputMaybe<Array<CompanySortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CompanyFilterInput>;
}

export interface QueryNewAssetsInfoArgs {
  input: NewAssetsInfoQueryInput;
}

export interface QueryProjectsListArgs {
  input: ProjectsListQueryInput;
  order?: InputMaybe<Array<ProjectSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProjectFilterInput>;
}

export interface QueryUserByIdArgs {
  input: UserQueryInput;
}

export interface QueryUsersListArgs {
  input: UsersListQueryInput;
  order?: InputMaybe<Array<UserSortInput>>;
  where?: InputMaybe<UserFilterInput>;
}

export interface QueryValidateKeyArgs {
  input: ValidateKeyQueryInput;
}

export interface QueryViewCompanyArgs {
  input: CompanyQueryInput;
}

export interface QueryViewProjectArgs {
  input: ProjectQueryInput;
}

export enum Roles {
  Admin = 'ADMIN',
  Member = 'MEMBER',
  Owner = 'OWNER',
  VidicoAdmin = 'VIDICO_ADMIN',
}

export interface RolesOperationFilterInput {
  eq?: InputMaybe<Roles>;
  in?: InputMaybe<Array<Roles>>;
  neq?: InputMaybe<Roles>;
  nin?: InputMaybe<Array<Roles>>;
}

export interface S3UrlResponse {
  __typename?: 'S3UrlResponse';
  assetId: Scalars['UUID'];
  fileName: Scalars['String'];
  oldFileName: Scalars['String'];
  type: FileType;
  url: Scalars['String'];
}

export interface SharedAssetsPayload {
  __typename?: 'SharedAssetsPayload';
  assets?: Maybe<Array<Asset>>;
  errors?: Maybe<Array<Error>>;
}

export interface SharedLink {
  __typename?: 'SharedLink';
  assets: Array<Asset>;
  createdAt: Scalars['Date'];
  createdBy: Scalars['UUID'];
  id: Scalars['UUID'];
  isProject: Scalars['Boolean'];
  key: Scalars['String'];
  link: Scalars['String'];
  projectId: Scalars['UUID'];
  status: SharedLinkStatus;
}

export interface SharedLinkCommandInput {
  key: Scalars['String'];
}

export interface SharedLinkCreateCommandInput {
  assetIds: Array<Scalars['UUID']>;
  isProject: Scalars['Boolean'];
  projectId: Scalars['UUID'];
}

export interface SharedLinkDeleteCommandInput {
  linkId: Scalars['UUID'];
}

export interface SharedLinkFilterInput {
  and?: InputMaybe<Array<SharedLinkFilterInput>>;
  assets?: InputMaybe<ListFilterInputTypeOfAssetFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  isProject?: InputMaybe<BooleanOperationFilterInput>;
  key?: InputMaybe<StringOperationFilterInput>;
  link?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<SharedLinkFilterInput>>;
  projectId?: InputMaybe<UuidOperationFilterInput>;
  status?: InputMaybe<SharedLinkStatusOperationFilterInput>;
}

export interface SharedLinkPayload {
  __typename?: 'SharedLinkPayload';
  errors?: Maybe<Array<Error>>;
  sharedLink: SharedLink;
}

export enum SharedLinkStatus {
  New = 'NEW',
  Used = 'USED',
}

export interface SharedLinkStatusOperationFilterInput {
  eq?: InputMaybe<SharedLinkStatus>;
  in?: InputMaybe<Array<SharedLinkStatus>>;
  neq?: InputMaybe<SharedLinkStatus>;
  nin?: InputMaybe<Array<SharedLinkStatus>>;
}

export enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC',
}

export interface StringOperationFilterInput {
  and?: InputMaybe<Array<StringOperationFilterInput>>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  eq?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ncontains?: InputMaybe<Scalars['String']>;
  nendsWith?: InputMaybe<Scalars['String']>;
  neq?: InputMaybe<Scalars['String']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  nstartsWith?: InputMaybe<Scalars['String']>;
  or?: InputMaybe<Array<StringOperationFilterInput>>;
  startsWith?: InputMaybe<Scalars['String']>;
}

export interface UploadAssetInput {
  bitRate?: InputMaybe<Scalars['Int']>;
  codec?: InputMaybe<Scalars['String']>;
  colorSpace?: InputMaybe<Scalars['String']>;
  dotPerInch?: InputMaybe<Scalars['Int']>;
  fullName: Scalars['String'];
  maxHeight?: InputMaybe<Scalars['Int']>;
  maxWidth?: InputMaybe<Scalars['Int']>;
  originalCreatedAt?: InputMaybe<Scalars['Date']>;
  originalUpdatedAt?: InputMaybe<Scalars['Date']>;
  size: Scalars['Float'];
}

export interface User {
  __typename?: 'User';
  assets: Array<Asset>;
  avatar?: Maybe<Scalars['String']>;
  calendlyUrl: Scalars['String'];
  company: Company;
  companyId: Scalars['UUID'];
  country: Scalars['String'];
  createdAt: Scalars['Date'];
  createdBy: Scalars['String'];
  deletedReason?: Maybe<Scalars['UUID']>;
  email: Scalars['String'];
  favorites: Array<Favorite>;
  firstName: Scalars['String'];
  id: Scalars['UUID'];
  lastActive?: Maybe<Scalars['Date']>;
  lastModifiedAt: Scalars['Date'];
  lastModifiedBy: Scalars['String'];
  lastName: Scalars['String'];
  pathToAvatar: Scalars['String'];
  pathToAvatarThumbnail: Scalars['String'];
  roles: Roles;
  status: UserStatus;
  subdomain: Scalars['String'];
  timeZoneId: Scalars['String'];
  userName: Scalars['String'];
}

export interface UserDeleteCommandInput {
  userId: Scalars['UUID'];
}

export interface UserEditAccessCommandInput {
  role: Roles;
  userId: Scalars['UUID'];
}

export interface UserEntityPayload {
  __typename?: 'UserEntityPayload';
  user?: Maybe<User>;
}

export interface UserFilterInput {
  activationKey?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<UserFilterInput>>;
  assets?: InputMaybe<ListFilterInputTypeOfAssetFilterInput>;
  avatar?: InputMaybe<StringOperationFilterInput>;
  calendlyUrl?: InputMaybe<StringOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<UuidOperationFilterInput>;
  country?: InputMaybe<StringOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  createdBy?: InputMaybe<StringOperationFilterInput>;
  deletedReason?: InputMaybe<UuidOperationFilterInput>;
  email?: InputMaybe<StringOperationFilterInput>;
  emailConfirmed?: InputMaybe<BooleanOperationFilterInput>;
  favorites?: InputMaybe<ListFilterInputTypeOfFavoriteFilterInput>;
  firstName?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  lastActive?: InputMaybe<DateTimeOperationFilterInput>;
  lastModifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: InputMaybe<StringOperationFilterInput>;
  lastName?: InputMaybe<StringOperationFilterInput>;
  normalizedEmail?: InputMaybe<StringOperationFilterInput>;
  normalizedUserName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<UserFilterInput>>;
  passwordHash?: InputMaybe<StringOperationFilterInput>;
  pathToAvatar?: InputMaybe<StringOperationFilterInput>;
  pathToAvatarThumbnail?: InputMaybe<StringOperationFilterInput>;
  refreshToken?: InputMaybe<StringOperationFilterInput>;
  refreshTokenExpiry?: InputMaybe<DateTimeOperationFilterInput>;
  resetDate?: InputMaybe<DateTimeOperationFilterInput>;
  resetKey?: InputMaybe<StringOperationFilterInput>;
  role?: InputMaybe<RolesOperationFilterInput>;
  status?: InputMaybe<UserStatusOperationFilterInput>;
  timeZoneId?: InputMaybe<StringOperationFilterInput>;
  userName?: InputMaybe<StringOperationFilterInput>;
  verifyKey?: InputMaybe<StringOperationFilterInput>;
  verifyKeyExpiry?: InputMaybe<LongOperationFilterInput>;
}

export interface UserPayload {
  __typename?: 'UserPayload';
  errors?: Maybe<Array<Error>>;
  user?: Maybe<User>;
}

export interface UserQueryInput {
  userId: Scalars['UUID'];
}

export interface UserSortInput {
  activationKey?: InputMaybe<SortEnumType>;
  avatar?: InputMaybe<SortEnumType>;
  calendlyUrl?: InputMaybe<SortEnumType>;
  company?: InputMaybe<CompanySortInput>;
  companyId?: InputMaybe<SortEnumType>;
  country?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  deletedReason?: InputMaybe<SortEnumType>;
  email?: InputMaybe<SortEnumType>;
  emailConfirmed?: InputMaybe<SortEnumType>;
  firstName?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastActive?: InputMaybe<SortEnumType>;
  lastModifiedAt?: InputMaybe<SortEnumType>;
  lastModifiedBy?: InputMaybe<SortEnumType>;
  lastName?: InputMaybe<SortEnumType>;
  normalizedEmail?: InputMaybe<SortEnumType>;
  normalizedUserName?: InputMaybe<SortEnumType>;
  passwordHash?: InputMaybe<SortEnumType>;
  pathToAvatar?: InputMaybe<SortEnumType>;
  pathToAvatarThumbnail?: InputMaybe<SortEnumType>;
  refreshToken?: InputMaybe<SortEnumType>;
  refreshTokenExpiry?: InputMaybe<SortEnumType>;
  resetDate?: InputMaybe<SortEnumType>;
  resetKey?: InputMaybe<SortEnumType>;
  role?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
  timeZoneId?: InputMaybe<SortEnumType>;
  userName?: InputMaybe<SortEnumType>;
  verifyKey?: InputMaybe<SortEnumType>;
  verifyKeyExpiry?: InputMaybe<SortEnumType>;
}

export enum UserStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  Pending = 'PENDING',
}

export interface UserStatusOperationFilterInput {
  eq?: InputMaybe<UserStatus>;
  in?: InputMaybe<Array<UserStatus>>;
  neq?: InputMaybe<UserStatus>;
  nin?: InputMaybe<Array<UserStatus>>;
}

export interface UsersListQueryInput {
  companyId?: InputMaybe<Scalars['UUID']>;
}

export interface UuidOperationFilterInput {
  eq?: InputMaybe<Scalars['UUID']>;
  gt?: InputMaybe<Scalars['UUID']>;
  gte?: InputMaybe<Scalars['UUID']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  lt?: InputMaybe<Scalars['UUID']>;
  lte?: InputMaybe<Scalars['UUID']>;
  neq?: InputMaybe<Scalars['UUID']>;
  ngt?: InputMaybe<Scalars['UUID']>;
  ngte?: InputMaybe<Scalars['UUID']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  nlt?: InputMaybe<Scalars['UUID']>;
  nlte?: InputMaybe<Scalars['UUID']>;
}

export interface ValidateKeyQueryInput {
  key: Scalars['String'];
}

export interface VidicoAdminsListPayload {
  __typename?: 'VidicoAdminsListPayload';
  errors?: Maybe<Array<Error>>;
  vidicoAdmins?: Maybe<Array<User>>;
}

export type ErrorFragment = {
  __typename?: 'Error';
  message: string;
  code: number;
};

export type CollectionSegmentInfoFragment = {
  __typename?: 'CollectionSegmentInfo';
  hasNextPage: boolean;
  hasPreviousPage: boolean;
};

export type FavoriteFragment = {
  __typename?: 'Favorite';
  userId: any;
  assetId: any;
  createdAt: any;
  user: { __typename?: 'User'; id: any };
  asset?: {
    __typename?: 'Asset';
    id: any;
    thumbnail?: string | null;
    name: string;
    projectName: string;
    projectId: any;
    dealeted: boolean;
  } | null;
};

export type UserBaseFragment = {
  __typename?: 'User';
  id: any;
  companyId: any;
  subdomain: string;
  avatar?: string | null;
  roles: Roles;
  email: string;
  userName: string;
  firstName: string;
  lastName: string;
  createdBy: string;
  createdAt: any;
  lastModifiedBy: string;
  lastModifiedAt: any;
  lastActive?: any | null;
  status: UserStatus;
  country: string;
  timeZoneId: string;
  calendlyUrl: string;
  deletedReason?: any | null;
  pathToAvatar: string;
  pathToAvatarThumbnail: string;
};

export type UserFragment = {
  __typename?: 'User';
  id: any;
  companyId: any;
  subdomain: string;
  avatar?: string | null;
  roles: Roles;
  email: string;
  userName: string;
  firstName: string;
  lastName: string;
  createdBy: string;
  createdAt: any;
  lastModifiedBy: string;
  lastModifiedAt: any;
  lastActive?: any | null;
  status: UserStatus;
  country: string;
  timeZoneId: string;
  calendlyUrl: string;
  deletedReason?: any | null;
  pathToAvatar: string;
  pathToAvatarThumbnail: string;
  favorites: Array<{
    __typename?: 'Favorite';
    userId: any;
    assetId: any;
    createdAt: any;
    user: { __typename?: 'User'; id: any };
    asset?: {
      __typename?: 'Asset';
      id: any;
      thumbnail?: string | null;
      name: string;
      projectName: string;
      projectId: any;
      dealeted: boolean;
    } | null;
  }>;
  company: {
    __typename?: 'Company';
    id: any;
    logo?: string | null;
    name: string;
    managerId: any;
    domainNames: Array<string>;
  };
};

export type AccountTokenPayloadFragment = {
  __typename?: 'AccountTokenPayload';
  email?: string | null;
  role?: Roles | null;
  subdomainName?: string | null;
  errors?: Array<{
    __typename?: 'Error';
    message: string;
    code: number;
  }> | null;
};

export type ActionPayloadFragment = {
  __typename?: 'ActionPayload';
  isSuccess: boolean;
  errors?: Array<{
    __typename?: 'Error';
    message: string;
    code: number;
  }> | null;
};

export type AccountInvitePayloadFragment = {
  __typename?: 'AccountInvitePayload';
  isUnSuccess: boolean;
  inviteResponses: Array<{
    __typename?: 'InviteResponse';
    email: string;
    isSuccess: boolean;
  }>;
  errors?: Array<{
    __typename?: 'Error';
    message: string;
    code: number;
  }> | null;
};

export type LoginMutationVariables = Exact<{
  input: AccountLoginCommandInput;
}>;

export type LoginMutation = {
  __typename?: 'Mutation';
  login: {
    __typename?: 'AccountTokenPayload';
    email?: string | null;
    role?: Roles | null;
    subdomainName?: string | null;
    errors?: Array<{
      __typename?: 'Error';
      message: string;
      code: number;
    }> | null;
  };
};

export type LoginWithGoogleMutationVariables = Exact<{
  code: Scalars['String'];
}>;

export type LoginWithGoogleMutation = {
  __typename?: 'Mutation';
  login: {
    __typename?: 'AccountTokenPayload';
    email?: string | null;
    role?: Roles | null;
    subdomainName?: string | null;
    errors?: Array<{
      __typename?: 'Error';
      message: string;
      code: number;
    }> | null;
  };
};

export type LogOutMutationVariables = Exact<{ [key: string]: never }>;

export type LogOutMutation = {
  __typename?: 'Mutation';
  logOut: {
    __typename?: 'ActionPayload';
    isSuccess: boolean;
    errors?: Array<{
      __typename?: 'Error';
      message: string;
      code: number;
    }> | null;
  };
};

export type RegisterUserMutationVariables = Exact<{
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
}>;

export type RegisterUserMutation = {
  __typename?: 'Mutation';
  registerUser: {
    __typename?: 'AccountTokenPayload';
    email?: string | null;
    role?: Roles | null;
    subdomainName?: string | null;
    errors?: Array<{
      __typename?: 'Error';
      message: string;
      code: number;
    }> | null;
  };
};

export type SignUpWithGoogleMutationVariables = Exact<{
  access_token?: InputMaybe<Scalars['String']>;
}>;

export type SignUpWithGoogleMutation = {
  __typename?: 'Mutation';
  registerUser: {
    __typename?: 'AccountTokenPayload';
    email?: string | null;
    role?: Roles | null;
    subdomainName?: string | null;
    errors?: Array<{
      __typename?: 'Error';
      message: string;
      code: number;
    }> | null;
  };
};

export type SetNewPasswordMutationVariables = Exact<{
  key: Scalars['String'];
  password: Scalars['String'];
  resetPassword?: Scalars['Boolean'];
}>;

export type SetNewPasswordMutation = {
  __typename?: 'Mutation';
  setPassword: {
    __typename?: 'AccountTokenPayload';
    email?: string | null;
    role?: Roles | null;
    subdomainName?: string | null;
    errors?: Array<{
      __typename?: 'Error';
      message: string;
      code: number;
    }> | null;
  };
};

export type CompleatSignUpMutationVariables = Exact<{
  key: Scalars['String'];
  password: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
}>;

export type CompleatSignUpMutation = {
  __typename?: 'Mutation';
  setPassword: {
    __typename?: 'AccountTokenPayload';
    email?: string | null;
    role?: Roles | null;
    subdomainName?: string | null;
    errors?: Array<{
      __typename?: 'Error';
      message: string;
      code: number;
    }> | null;
  };
};

export type RequestEmailVerifyMutationVariables = Exact<{
  email: Scalars['String'];
}>;

export type RequestEmailVerifyMutation = {
  __typename?: 'Mutation';
  requestEmailVerify: {
    __typename?: 'ActionPayload';
    isSuccess: boolean;
    errors?: Array<{
      __typename?: 'Error';
      message: string;
      code: number;
    }> | null;
  };
};

export type EmailConfirmationMutationVariables = Exact<{
  key: Scalars['String'];
}>;

export type EmailConfirmationMutation = {
  __typename?: 'Mutation';
  emailConfirmation: {
    __typename?: 'ActionPayload';
    isSuccess: boolean;
    errors?: Array<{
      __typename?: 'Error';
      message: string;
      code: number;
    }> | null;
  };
};

export type ChangeRoleMutationVariables = Exact<{
  role: Roles;
  userId: Scalars['UUID'];
}>;

export type ChangeRoleMutation = {
  __typename?: 'Mutation';
  changeRole: {
    __typename?: 'UserEntityPayload';
    user?: {
      __typename?: 'User';
      id: any;
      companyId: any;
      subdomain: string;
      avatar?: string | null;
      roles: Roles;
      email: string;
      userName: string;
      firstName: string;
      lastName: string;
      createdBy: string;
      createdAt: any;
      lastModifiedBy: string;
      lastModifiedAt: any;
      lastActive?: any | null;
      status: UserStatus;
      country: string;
      timeZoneId: string;
      calendlyUrl: string;
      deletedReason?: any | null;
      pathToAvatar: string;
      pathToAvatarThumbnail: string;
      favorites: Array<{
        __typename?: 'Favorite';
        userId: any;
        assetId: any;
        createdAt: any;
        user: { __typename?: 'User'; id: any };
        asset?: {
          __typename?: 'Asset';
          id: any;
          thumbnail?: string | null;
          name: string;
          projectName: string;
          projectId: any;
          dealeted: boolean;
        } | null;
      }>;
      company: {
        __typename?: 'Company';
        id: any;
        logo?: string | null;
        name: string;
        managerId: any;
        domainNames: Array<string>;
      };
    } | null;
  };
};

export type InviteNewUserMutationVariables = Exact<{
  invites: Array<InviteRecordInput> | InviteRecordInput;
  companyId: Scalars['UUID'];
}>;

export type InviteNewUserMutation = {
  __typename?: 'Mutation';
  inviteNewUser: {
    __typename?: 'AccountInvitePayload';
    isUnSuccess: boolean;
    inviteResponses: Array<{
      __typename?: 'InviteResponse';
      email: string;
      isSuccess: boolean;
    }>;
    errors?: Array<{
      __typename?: 'Error';
      message: string;
      code: number;
    }> | null;
  };
};

export type UserDeleteMutationVariables = Exact<{
  userId: Scalars['UUID'];
}>;

export type UserDeleteMutation = {
  __typename?: 'Mutation';
  userDelete: {
    __typename?: 'ActionPayload';
    isSuccess: boolean;
    errors?: Array<{
      __typename?: 'Error';
      message: string;
      code: number;
    }> | null;
  };
};

export type AccountDeleteMutationVariables = Exact<{
  reason?: InputMaybe<DeleteReasons>;
  improvement?: InputMaybe<Scalars['String']>;
}>;

export type AccountDeleteMutation = {
  __typename?: 'Mutation';
  accountDelete: {
    __typename?: 'ActionPayload';
    isSuccess: boolean;
    errors?: Array<{
      __typename?: 'Error';
      message: string;
      code: number;
    }> | null;
  };
};

export type ChangePasswordMutationVariables = Exact<{
  oldPassword: Scalars['String'];
  newPassword: Scalars['String'];
}>;

export type ChangePasswordMutation = {
  __typename?: 'Mutation';
  changePassword: {
    __typename?: 'ActionPayload';
    isSuccess: boolean;
    errors?: Array<{
      __typename?: 'Error';
      message: string;
      code: number;
    }> | null;
  };
};

export type RequestResetPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;

export type RequestResetPasswordMutation = {
  __typename?: 'Mutation';
  requestResetPassword: {
    __typename?: 'ActionPayload';
    isSuccess: boolean;
    errors?: Array<{
      __typename?: 'Error';
      message: string;
      code: number;
    }> | null;
  };
};

export type EditProfileMutationVariables = Exact<{
  file?: InputMaybe<Scalars['Upload']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
}>;

export type EditProfileMutation = {
  __typename?: 'Mutation';
  accountEdit: {
    __typename?: 'AccountTokenPayload';
    email?: string | null;
    role?: Roles | null;
    subdomainName?: string | null;
    errors?: Array<{
      __typename?: 'Error';
      message: string;
      code: number;
    }> | null;
  };
};

export type ResendInviteToUserMutationVariables = Exact<{
  userId: Scalars['UUID'];
}>;

export type ResendInviteToUserMutation = {
  __typename?: 'Mutation';
  resendInviteToUser: {
    __typename?: 'ActionPayload';
    isSuccess: boolean;
    errors?: Array<{
      __typename?: 'Error';
      message: string;
      code: number;
    }> | null;
  };
};

export type UserByIdQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;

export type UserByIdQuery = {
  __typename?: 'Query';
  userById: {
    __typename?: 'UserPayload';
    user?: {
      __typename?: 'User';
      id: any;
      companyId: any;
      subdomain: string;
      avatar?: string | null;
      roles: Roles;
      email: string;
      userName: string;
      firstName: string;
      lastName: string;
      createdBy: string;
      createdAt: any;
      lastModifiedBy: string;
      lastModifiedAt: any;
      lastActive?: any | null;
      status: UserStatus;
      country: string;
      timeZoneId: string;
      calendlyUrl: string;
      deletedReason?: any | null;
      pathToAvatar: string;
      pathToAvatarThumbnail: string;
      favorites: Array<{
        __typename?: 'Favorite';
        userId: any;
        assetId: any;
        createdAt: any;
        user: { __typename?: 'User'; id: any };
        asset?: {
          __typename?: 'Asset';
          id: any;
          thumbnail?: string | null;
          name: string;
          projectName: string;
          projectId: any;
          dealeted: boolean;
        } | null;
      }>;
      company: {
        __typename?: 'Company';
        id: any;
        logo?: string | null;
        name: string;
        managerId: any;
        domainNames: Array<string>;
      };
    } | null;
    errors?: Array<{
      __typename?: 'Error';
      message: string;
      code: number;
    }> | null;
  };
};

export type GetCompanyByIdQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['UUID']>;
}>;

export type GetCompanyByIdQuery = {
  __typename?: 'Query';
  viewCompany: {
    __typename?: 'CompanyPayloadBase';
    company?: {
      __typename?: 'Company';
      id: any;
      logo?: string | null;
      name: string;
      teamMembersCount: number;
      subdomainName: string;
      domainNames: Array<string>;
      projectsCount: number;
      createdAt: any;
      lastModifiedAt?: any | null;
      lastSessionAt?: any | null;
      totalAssetsSize: number;
      managerId: any;
      currency: CurrencyType;
      projects: Array<{
        __typename?: 'Project';
        id: any;
        name: string;
        companyId: any;
        createdAt: any;
        createdBy: any;
        lastModifiedAt?: any | null;
        lastModifiedBy?: any | null;
        assetsCount: number;
        assetsSize: number;
        thumbnail: string;
        existingLocalizations: Array<Localizations>;
        pendingLocalizations: Array<Localizations>;
        status: ProjectStatus;
        assets: Array<{
          __typename?: 'Asset';
          localization: Localizations;
          companyId: any;
          projectId: any;
          name: string;
          projectName: string;
          thumbnail?: string | null;
          path: string;
          id: any;
          muxId: string;
          uploadId: string;
          playbackId: string;
          createdBy: any;
          createdAt?: any | null;
          lastModifiedBy?: any | null;
          lastModifiedAt?: any | null;
          size: number;
          type: FileType;
          extension: string;
          dealeted: boolean;
          dealetedBy?: any | null;
          dealetedAt?: any | null;
          duration?: number | null;
          maxResolution: string;
          aspectRatio: string;
          frameRate?: number | null;
          colorSpace?: string | null;
          audio: string;
          audioChannels?: number | null;
          codec?: string | null;
          maxHeight?: number | null;
          maxWidth?: number | null;
          dotPerInch?: number | null;
          bitRate?: number | null;
          muxStreamUrl: string;
          expireStreamDate?: any | null;
          muxDownloadUrl: string;
          muxUrlCreatedAt?: any | null;
          mp4Support: Mp4Support;
          s3DownloadUrl: string;
          s3UrlCreatedAt?: any | null;
          status: AssetStatus;
          originalCreatedAt?: any | null;
          originalUpdatedAt?: any | null;
          isNew: boolean;
          project: { __typename?: 'Project'; id: any; name: string };
        }>;
      }>;
      teamMembers: Array<{
        __typename?: 'User';
        id: any;
        companyId: any;
        subdomain: string;
        avatar?: string | null;
        roles: Roles;
        email: string;
        userName: string;
        firstName: string;
        lastName: string;
        createdBy: string;
        createdAt: any;
        lastModifiedBy: string;
        lastModifiedAt: any;
        lastActive?: any | null;
        status: UserStatus;
        country: string;
        timeZoneId: string;
        calendlyUrl: string;
        deletedReason?: any | null;
        pathToAvatar: string;
        pathToAvatarThumbnail: string;
        favorites: Array<{
          __typename?: 'Favorite';
          userId: any;
          assetId: any;
          createdAt: any;
          user: { __typename?: 'User'; id: any };
          asset?: {
            __typename?: 'Asset';
            id: any;
            thumbnail?: string | null;
            name: string;
            projectName: string;
            projectId: any;
            dealeted: boolean;
          } | null;
        }>;
        company: {
          __typename?: 'Company';
          id: any;
          logo?: string | null;
          name: string;
          managerId: any;
          domainNames: Array<string>;
        };
      }>;
    } | null;
  };
};

export type GetCompanyByIdBaseInfoQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['UUID']>;
}>;

export type GetCompanyByIdBaseInfoQuery = {
  __typename?: 'Query';
  viewCompany: {
    __typename?: 'CompanyPayloadBase';
    company?: {
      __typename?: 'Company';
      id: any;
      logo?: string | null;
      name: string;
      teamMembersCount: number;
      subdomainName: string;
      domainNames: Array<string>;
      projectsCount: number;
      createdAt: any;
      lastModifiedAt?: any | null;
      lastSessionAt?: any | null;
      totalAssetsSize: number;
      managerId: any;
      currency: CurrencyType;
    } | null;
  };
};

export type ValidateTokenQueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type ValidateTokenQuery = {
  __typename?: 'Query';
  validateKey: {
    __typename?: 'ActionPayload';
    isSuccess: boolean;
    errors?: Array<{
      __typename?: 'Error';
      message: string;
      code: number;
    }> | null;
  };
};

export type AssetFragment = {
  __typename?: 'Asset';
  localization: Localizations;
  companyId: any;
  projectId: any;
  name: string;
  projectName: string;
  thumbnail?: string | null;
  path: string;
  id: any;
  muxId: string;
  uploadId: string;
  playbackId: string;
  createdBy: any;
  createdAt?: any | null;
  lastModifiedBy?: any | null;
  lastModifiedAt?: any | null;
  size: number;
  type: FileType;
  extension: string;
  dealeted: boolean;
  dealetedBy?: any | null;
  dealetedAt?: any | null;
  duration?: number | null;
  maxResolution: string;
  aspectRatio: string;
  frameRate?: number | null;
  colorSpace?: string | null;
  audio: string;
  audioChannels?: number | null;
  codec?: string | null;
  maxHeight?: number | null;
  maxWidth?: number | null;
  dotPerInch?: number | null;
  bitRate?: number | null;
  muxStreamUrl: string;
  expireStreamDate?: any | null;
  muxDownloadUrl: string;
  muxUrlCreatedAt?: any | null;
  mp4Support: Mp4Support;
  s3DownloadUrl: string;
  s3UrlCreatedAt?: any | null;
  status: AssetStatus;
  originalCreatedAt?: any | null;
  originalUpdatedAt?: any | null;
  isNew: boolean;
  project: { __typename?: 'Project'; id: any; name: string };
};

export type ProjectBaseFragment = {
  __typename?: 'Project';
  id: any;
  name: string;
  companyId: any;
  createdAt: any;
  createdBy: any;
  lastModifiedAt?: any | null;
  lastModifiedBy?: any | null;
  assetsCount: number;
  assetsSize: number;
  thumbnail: string;
  existingLocalizations: Array<Localizations>;
  pendingLocalizations: Array<Localizations>;
  status: ProjectStatus;
};

export type ProjectFragment = {
  __typename?: 'Project';
  id: any;
  name: string;
  companyId: any;
  createdAt: any;
  createdBy: any;
  lastModifiedAt?: any | null;
  lastModifiedBy?: any | null;
  assetsCount: number;
  assetsSize: number;
  thumbnail: string;
  existingLocalizations: Array<Localizations>;
  pendingLocalizations: Array<Localizations>;
  status: ProjectStatus;
  assets: Array<{
    __typename?: 'Asset';
    localization: Localizations;
    companyId: any;
    projectId: any;
    name: string;
    projectName: string;
    thumbnail?: string | null;
    path: string;
    id: any;
    muxId: string;
    uploadId: string;
    playbackId: string;
    createdBy: any;
    createdAt?: any | null;
    lastModifiedBy?: any | null;
    lastModifiedAt?: any | null;
    size: number;
    type: FileType;
    extension: string;
    dealeted: boolean;
    dealetedBy?: any | null;
    dealetedAt?: any | null;
    duration?: number | null;
    maxResolution: string;
    aspectRatio: string;
    frameRate?: number | null;
    colorSpace?: string | null;
    audio: string;
    audioChannels?: number | null;
    codec?: string | null;
    maxHeight?: number | null;
    maxWidth?: number | null;
    dotPerInch?: number | null;
    bitRate?: number | null;
    muxStreamUrl: string;
    expireStreamDate?: any | null;
    muxDownloadUrl: string;
    muxUrlCreatedAt?: any | null;
    mp4Support: Mp4Support;
    s3DownloadUrl: string;
    s3UrlCreatedAt?: any | null;
    status: AssetStatus;
    originalCreatedAt?: any | null;
    originalUpdatedAt?: any | null;
    isNew: boolean;
    project: { __typename?: 'Project'; id: any; name: string };
  }>;
};

export type CompanyFragment = {
  __typename?: 'Company';
  id: any;
  logo?: string | null;
  name: string;
  teamMembersCount: number;
  subdomainName: string;
  domainNames: Array<string>;
  projectsCount: number;
  createdAt: any;
  lastModifiedAt?: any | null;
  lastSessionAt?: any | null;
  totalAssetsSize: number;
  managerId: any;
  currency: CurrencyType;
  projects: Array<{
    __typename?: 'Project';
    id: any;
    name: string;
    companyId: any;
    createdAt: any;
    createdBy: any;
    lastModifiedAt?: any | null;
    lastModifiedBy?: any | null;
    assetsCount: number;
    assetsSize: number;
    thumbnail: string;
    existingLocalizations: Array<Localizations>;
    pendingLocalizations: Array<Localizations>;
    status: ProjectStatus;
    assets: Array<{
      __typename?: 'Asset';
      localization: Localizations;
      companyId: any;
      projectId: any;
      name: string;
      projectName: string;
      thumbnail?: string | null;
      path: string;
      id: any;
      muxId: string;
      uploadId: string;
      playbackId: string;
      createdBy: any;
      createdAt?: any | null;
      lastModifiedBy?: any | null;
      lastModifiedAt?: any | null;
      size: number;
      type: FileType;
      extension: string;
      dealeted: boolean;
      dealetedBy?: any | null;
      dealetedAt?: any | null;
      duration?: number | null;
      maxResolution: string;
      aspectRatio: string;
      frameRate?: number | null;
      colorSpace?: string | null;
      audio: string;
      audioChannels?: number | null;
      codec?: string | null;
      maxHeight?: number | null;
      maxWidth?: number | null;
      dotPerInch?: number | null;
      bitRate?: number | null;
      muxStreamUrl: string;
      expireStreamDate?: any | null;
      muxDownloadUrl: string;
      muxUrlCreatedAt?: any | null;
      mp4Support: Mp4Support;
      s3DownloadUrl: string;
      s3UrlCreatedAt?: any | null;
      status: AssetStatus;
      originalCreatedAt?: any | null;
      originalUpdatedAt?: any | null;
      isNew: boolean;
      project: { __typename?: 'Project'; id: any; name: string };
    }>;
  }>;
  teamMembers: Array<{
    __typename?: 'User';
    id: any;
    companyId: any;
    subdomain: string;
    avatar?: string | null;
    roles: Roles;
    email: string;
    userName: string;
    firstName: string;
    lastName: string;
    createdBy: string;
    createdAt: any;
    lastModifiedBy: string;
    lastModifiedAt: any;
    lastActive?: any | null;
    status: UserStatus;
    country: string;
    timeZoneId: string;
    calendlyUrl: string;
    deletedReason?: any | null;
    pathToAvatar: string;
    pathToAvatarThumbnail: string;
    favorites: Array<{
      __typename?: 'Favorite';
      userId: any;
      assetId: any;
      createdAt: any;
      user: { __typename?: 'User'; id: any };
      asset?: {
        __typename?: 'Asset';
        id: any;
        thumbnail?: string | null;
        name: string;
        projectName: string;
        projectId: any;
        dealeted: boolean;
      } | null;
    }>;
    company: {
      __typename?: 'Company';
      id: any;
      logo?: string | null;
      name: string;
      managerId: any;
      domainNames: Array<string>;
    };
  }>;
};

export type CompanyBaseFragment = {
  __typename?: 'Company';
  id: any;
  logo?: string | null;
  name: string;
  teamMembersCount: number;
  subdomainName: string;
  domainNames: Array<string>;
  projectsCount: number;
  createdAt: any;
  lastModifiedAt?: any | null;
  lastSessionAt?: any | null;
  totalAssetsSize: number;
  managerId: any;
  currency: CurrencyType;
};

export type CompanyPayloadBaseFragment = {
  __typename?: 'CompanyPayloadBase';
  company?: {
    __typename?: 'Company';
    id: any;
    logo?: string | null;
    name: string;
    teamMembersCount: number;
    subdomainName: string;
    domainNames: Array<string>;
    projectsCount: number;
    createdAt: any;
    lastModifiedAt?: any | null;
    lastSessionAt?: any | null;
    totalAssetsSize: number;
    managerId: any;
    currency: CurrencyType;
  } | null;
  errors?: Array<{
    __typename?: 'Error';
    message: string;
    code: number;
  }> | null;
};

export type S3UrlResponseFragment = {
  __typename?: 'S3UrlResponse';
  url: string;
  fileName: string;
  oldFileName: string;
  assetId: any;
  type: FileType;
};

export type CompanyCreateMutationVariables = Exact<{
  file: Scalars['Upload'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  company: Scalars['String'];
  managerId: Scalars['UUID'];
  currency?: InputMaybe<Scalars['String']>;
}>;

export type CompanyCreateMutation = {
  __typename?: 'Mutation';
  companyCreate: {
    __typename?: 'CompanyPayloadBase';
    company?: { __typename?: 'Company'; id: any } | null;
    errors?: Array<{
      __typename?: 'Error';
      message: string;
      code: number;
    }> | null;
  };
};

export type CompanyEditMutationVariables = Exact<{
  file?: InputMaybe<Scalars['Upload']>;
  companyName?: InputMaybe<Scalars['String']>;
  managerId?: InputMaybe<Scalars['UUID']>;
  domainNames?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  companyId: Scalars['UUID'];
  currency?: InputMaybe<Scalars['String']>;
}>;

export type CompanyEditMutation = {
  __typename?: 'Mutation';
  companyEdit: {
    __typename?: 'CompanyPayloadBase';
    company?: {
      __typename?: 'Company';
      id: any;
      logo?: string | null;
      name: string;
      teamMembersCount: number;
      subdomainName: string;
      domainNames: Array<string>;
      projectsCount: number;
      createdAt: any;
      lastModifiedAt?: any | null;
      lastSessionAt?: any | null;
      totalAssetsSize: number;
      managerId: any;
      currency: CurrencyType;
    } | null;
    errors?: Array<{
      __typename?: 'Error';
      message: string;
      code: number;
    }> | null;
  };
};

export type ProjectCreateMutationVariables = Exact<{
  projectName: Scalars['String'];
  briefDetails?: InputMaybe<Scalars['String']>;
  powerUps?: InputMaybe<Array<PowerUps> | PowerUps>;
  budget?: InputMaybe<Budget>;
  companyId: Scalars['UUID'];
  localizations?: InputMaybe<Array<Localizations> | Localizations>;
}>;

export type ProjectCreateMutation = {
  __typename?: 'Mutation';
  projectCreate: {
    __typename?: 'ProjectPayloadBase';
    project?: {
      __typename?: 'Project';
      id: any;
      name: string;
      companyId: any;
      createdAt: any;
      createdBy: any;
      lastModifiedAt?: any | null;
      lastModifiedBy?: any | null;
      assetsCount: number;
      assetsSize: number;
      thumbnail: string;
      existingLocalizations: Array<Localizations>;
      pendingLocalizations: Array<Localizations>;
      status: ProjectStatus;
    } | null;
    errors?: Array<{
      __typename?: 'Error';
      message: string;
      code: number;
    }> | null;
  };
};

export type EditProjectMutationVariables = Exact<{
  projectId: Scalars['UUID'];
  projectName?: InputMaybe<Scalars['String']>;
  localizations?: InputMaybe<Array<Localizations> | Localizations>;
  assetId?: InputMaybe<Scalars['UUID']>;
  seconds?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<ProjectStatus>;
}>;

export type EditProjectMutation = {
  __typename?: 'Mutation';
  editProject: {
    __typename?: 'ProjectPayloadBase';
    project?: {
      __typename?: 'Project';
      id: any;
      name: string;
      companyId: any;
      createdAt: any;
      createdBy: any;
      lastModifiedAt?: any | null;
      lastModifiedBy?: any | null;
      assetsCount: number;
      assetsSize: number;
      thumbnail: string;
      existingLocalizations: Array<Localizations>;
      pendingLocalizations: Array<Localizations>;
      status: ProjectStatus;
    } | null;
    errors?: Array<{
      __typename?: 'Error';
      message: string;
      code: number;
    }> | null;
  };
};

export type AssetsUploadMutationVariables = Exact<{
  files: Array<UploadAssetInput> | UploadAssetInput;
  projectId: Scalars['UUID'];
  localization: Localizations;
}>;

export type AssetsUploadMutation = {
  __typename?: 'Mutation';
  assetUpload: {
    __typename?: 'AssetUrlPayloads';
    s3Urls: Array<{
      __typename?: 'S3UrlResponse';
      url: string;
      fileName: string;
      oldFileName: string;
      assetId: any;
      type: FileType;
    }>;
    errors?: Array<{
      __typename?: 'Error';
      message: string;
      code: number;
    }> | null;
  };
};

export type CreateSharedLinkMutationVariables = Exact<{
  projectId: Scalars['UUID'];
  assetIds: Array<Scalars['UUID']> | Scalars['UUID'];
  isProject: Scalars['Boolean'];
}>;

export type CreateSharedLinkMutation = {
  __typename?: 'Mutation';
  createSharedLink: {
    __typename?: 'SharedLinkPayload';
    sharedLink: {
      __typename?: 'SharedLink';
      id: any;
      key: string;
      projectId: any;
      isProject: boolean;
      link: string;
      status: SharedLinkStatus;
      assets: Array<{
        __typename?: 'Asset';
        localization: Localizations;
        companyId: any;
        projectId: any;
        name: string;
        projectName: string;
        thumbnail?: string | null;
        path: string;
        id: any;
        muxId: string;
        uploadId: string;
        playbackId: string;
        createdBy: any;
        createdAt?: any | null;
        lastModifiedBy?: any | null;
        lastModifiedAt?: any | null;
        size: number;
        type: FileType;
        extension: string;
        dealeted: boolean;
        dealetedBy?: any | null;
        dealetedAt?: any | null;
        duration?: number | null;
        maxResolution: string;
        aspectRatio: string;
        frameRate?: number | null;
        colorSpace?: string | null;
        audio: string;
        audioChannels?: number | null;
        codec?: string | null;
        maxHeight?: number | null;
        maxWidth?: number | null;
        dotPerInch?: number | null;
        bitRate?: number | null;
        muxStreamUrl: string;
        expireStreamDate?: any | null;
        muxDownloadUrl: string;
        muxUrlCreatedAt?: any | null;
        mp4Support: Mp4Support;
        s3DownloadUrl: string;
        s3UrlCreatedAt?: any | null;
        status: AssetStatus;
        originalCreatedAt?: any | null;
        originalUpdatedAt?: any | null;
        isNew: boolean;
        project: { __typename?: 'Project'; id: any; name: string };
      }>;
    };
    errors?: Array<{ __typename?: 'Error'; message: string }> | null;
  };
};

export type DeleteSharedLinkMutationVariables = Exact<{
  linkId: Scalars['UUID'];
}>;

export type DeleteSharedLinkMutation = {
  __typename?: 'Mutation';
  deleteSharedLink: {
    __typename?: 'ActionPayload';
    isSuccess: boolean;
    errors?: Array<{
      __typename?: 'Error';
      message: string;
      code: number;
    }> | null;
  };
};

export type GenerateNewSharedLinkMutationVariables = Exact<{
  linkId: Scalars['UUID'];
  projectId: Scalars['UUID'];
  assetIds: Array<Scalars['UUID']> | Scalars['UUID'];
  isProject: Scalars['Boolean'];
}>;

export type GenerateNewSharedLinkMutation = {
  __typename?: 'Mutation';
  deleteSharedLink: {
    __typename?: 'ActionPayload';
    isSuccess: boolean;
    errors?: Array<{
      __typename?: 'Error';
      message: string;
      code: number;
    }> | null;
  };
  createSharedLink: {
    __typename?: 'SharedLinkPayload';
    sharedLink: {
      __typename?: 'SharedLink';
      id: any;
      key: string;
      projectId: any;
      isProject: boolean;
      link: string;
      status: SharedLinkStatus;
      assets: Array<{
        __typename?: 'Asset';
        localization: Localizations;
        companyId: any;
        projectId: any;
        name: string;
        projectName: string;
        thumbnail?: string | null;
        path: string;
        id: any;
        muxId: string;
        uploadId: string;
        playbackId: string;
        createdBy: any;
        createdAt?: any | null;
        lastModifiedBy?: any | null;
        lastModifiedAt?: any | null;
        size: number;
        type: FileType;
        extension: string;
        dealeted: boolean;
        dealetedBy?: any | null;
        dealetedAt?: any | null;
        duration?: number | null;
        maxResolution: string;
        aspectRatio: string;
        frameRate?: number | null;
        colorSpace?: string | null;
        audio: string;
        audioChannels?: number | null;
        codec?: string | null;
        maxHeight?: number | null;
        maxWidth?: number | null;
        dotPerInch?: number | null;
        bitRate?: number | null;
        muxStreamUrl: string;
        expireStreamDate?: any | null;
        muxDownloadUrl: string;
        muxUrlCreatedAt?: any | null;
        mp4Support: Mp4Support;
        s3DownloadUrl: string;
        s3UrlCreatedAt?: any | null;
        status: AssetStatus;
        originalCreatedAt?: any | null;
        originalUpdatedAt?: any | null;
        isNew: boolean;
        project: { __typename?: 'Project'; id: any; name: string };
      }>;
    };
    errors?: Array<{ __typename?: 'Error'; message: string }> | null;
  };
};

export type RemoveAssetsMutationVariables = Exact<{
  assetsId: Array<Scalars['UUID']> | Scalars['UUID'];
}>;

export type RemoveAssetsMutation = {
  __typename?: 'Mutation';
  assetRemove: {
    __typename?: 'ActionPayload';
    isSuccess: boolean;
    errors?: Array<{
      __typename?: 'Error';
      message: string;
      code: number;
    }> | null;
  };
};

export type AssetDownloadMutationVariables = Exact<{
  assetsId: Array<Scalars['UUID']> | Scalars['UUID'];
  sharedLinkKey?: InputMaybe<Scalars['String']>;
}>;

export type AssetDownloadMutation = {
  __typename?: 'Mutation';
  assetDownload: {
    __typename?: 'AssetsDownloadPayload';
    assets?: Array<{
      __typename?: 'Asset';
      localization: Localizations;
      companyId: any;
      projectId: any;
      name: string;
      projectName: string;
      thumbnail?: string | null;
      path: string;
      id: any;
      muxId: string;
      uploadId: string;
      playbackId: string;
      createdBy: any;
      createdAt?: any | null;
      lastModifiedBy?: any | null;
      lastModifiedAt?: any | null;
      size: number;
      type: FileType;
      extension: string;
      dealeted: boolean;
      dealetedBy?: any | null;
      dealetedAt?: any | null;
      duration?: number | null;
      maxResolution: string;
      aspectRatio: string;
      frameRate?: number | null;
      colorSpace?: string | null;
      audio: string;
      audioChannels?: number | null;
      codec?: string | null;
      maxHeight?: number | null;
      maxWidth?: number | null;
      dotPerInch?: number | null;
      bitRate?: number | null;
      muxStreamUrl: string;
      expireStreamDate?: any | null;
      muxDownloadUrl: string;
      muxUrlCreatedAt?: any | null;
      mp4Support: Mp4Support;
      s3DownloadUrl: string;
      s3UrlCreatedAt?: any | null;
      status: AssetStatus;
      originalCreatedAt?: any | null;
      originalUpdatedAt?: any | null;
      isNew: boolean;
      project: { __typename?: 'Project'; id: any; name: string };
    }> | null;
  };
};

export type DeleteProjectMutationVariables = Exact<{
  projectId: Scalars['UUID'];
}>;

export type DeleteProjectMutation = {
  __typename?: 'Mutation';
  deleteProject: {
    __typename?: 'ActionPayload';
    isSuccess: boolean;
    errors?: Array<{
      __typename?: 'Error';
      message: string;
      code: number;
    }> | null;
  };
};

export type MarkAssetsAsFavoriteMutationVariables = Exact<{
  assetsId: Array<Scalars['UUID']> | Scalars['UUID'];
}>;

export type MarkAssetsAsFavoriteMutation = {
  __typename?: 'Mutation';
  assetsStarred: {
    __typename?: 'UserPayload';
    user?: {
      __typename?: 'User';
      id: any;
      companyId: any;
      subdomain: string;
      avatar?: string | null;
      roles: Roles;
      email: string;
      userName: string;
      firstName: string;
      lastName: string;
      createdBy: string;
      createdAt: any;
      lastModifiedBy: string;
      lastModifiedAt: any;
      lastActive?: any | null;
      status: UserStatus;
      country: string;
      timeZoneId: string;
      calendlyUrl: string;
      deletedReason?: any | null;
      pathToAvatar: string;
      pathToAvatarThumbnail: string;
      favorites: Array<{
        __typename?: 'Favorite';
        userId: any;
        assetId: any;
        createdAt: any;
        user: { __typename?: 'User'; id: any };
        asset?: {
          __typename?: 'Asset';
          id: any;
          thumbnail?: string | null;
          name: string;
          projectName: string;
          projectId: any;
          dealeted: boolean;
        } | null;
      }>;
      company: {
        __typename?: 'Company';
        id: any;
        logo?: string | null;
        name: string;
        managerId: any;
        domainNames: Array<string>;
      };
    } | null;
    errors?: Array<{
      __typename?: 'Error';
      message: string;
      code: number;
    }> | null;
  };
};

export type RenameAssetMutationVariables = Exact<{
  assetId: Scalars['UUID'];
  assetName: Scalars['String'];
}>;

export type RenameAssetMutation = {
  __typename?: 'Mutation';
  assetRename: {
    __typename?: 'AssetEntityPayload';
    asset: { __typename?: 'Asset'; id: any; name: string };
    errors?: Array<{
      __typename?: 'Error';
      message: string;
      code: number;
    }> | null;
  };
};

export type AssetsViewedByMutationVariables = Exact<{
  assetsId: Array<Scalars['UUID']> | Scalars['UUID'];
}>;

export type AssetsViewedByMutation = {
  __typename?: 'Mutation';
  assetsVievedBy: Array<{
    __typename?: 'Asset';
    localization: Localizations;
    companyId: any;
    projectId: any;
    name: string;
    projectName: string;
    thumbnail?: string | null;
    path: string;
    id: any;
    muxId: string;
    uploadId: string;
    playbackId: string;
    createdBy: any;
    createdAt?: any | null;
    lastModifiedBy?: any | null;
    lastModifiedAt?: any | null;
    size: number;
    type: FileType;
    extension: string;
    dealeted: boolean;
    dealetedBy?: any | null;
    dealetedAt?: any | null;
    duration?: number | null;
    maxResolution: string;
    aspectRatio: string;
    frameRate?: number | null;
    colorSpace?: string | null;
    audio: string;
    audioChannels?: number | null;
    codec?: string | null;
    maxHeight?: number | null;
    maxWidth?: number | null;
    dotPerInch?: number | null;
    bitRate?: number | null;
    muxStreamUrl: string;
    expireStreamDate?: any | null;
    muxDownloadUrl: string;
    muxUrlCreatedAt?: any | null;
    mp4Support: Mp4Support;
    s3DownloadUrl: string;
    s3UrlCreatedAt?: any | null;
    status: AssetStatus;
    originalCreatedAt?: any | null;
    originalUpdatedAt?: any | null;
    isNew: boolean;
    project: { __typename?: 'Project'; id: any; name: string };
  }>;
};

export type PrepareForDownloadMutationVariables = Exact<{
  assetsId?: InputMaybe<Array<Scalars['UUID']> | Scalars['UUID']>;
  projectId?: InputMaybe<Scalars['UUID']>;
  localization?: InputMaybe<Localizations>;
}>;

export type PrepareForDownloadMutation = {
  __typename?: 'Mutation';
  generateMuxDownloadUrl: {
    __typename?: 'ActionPayload';
    isSuccess: boolean;
    errors?: Array<{
      __typename?: 'Error';
      message: string;
      code: number;
    }> | null;
  };
};

export type CompaniesListQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<CompanySortInput> | CompanySortInput>;
  where?: InputMaybe<CompanyFilterInput>;
}>;

export type CompaniesListQuery = {
  __typename?: 'Query';
  companiesList?: {
    __typename?: 'CompaniesListCollectionSegment';
    totalCount: number;
    pageInfo: {
      __typename?: 'CollectionSegmentInfo';
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
    items?: Array<{
      __typename?: 'Company';
      id: any;
      logo?: string | null;
      name: string;
      teamMembersCount: number;
      subdomainName: string;
      domainNames: Array<string>;
      projectsCount: number;
      createdAt: any;
      lastModifiedAt?: any | null;
      lastSessionAt?: any | null;
      totalAssetsSize: number;
      managerId: any;
      currency: CurrencyType;
    }> | null;
  } | null;
};

export type ProjectsListQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ProjectSortInput> | ProjectSortInput>;
  where?: InputMaybe<ProjectFilterInput>;
  companyId?: InputMaybe<Scalars['UUID']>;
}>;

export type ProjectsListQuery = {
  __typename?: 'Query';
  projectsList?: {
    __typename?: 'ProjectsListCollectionSegment';
    totalCount: number;
    pageInfo: {
      __typename?: 'CollectionSegmentInfo';
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
    items?: Array<{
      __typename?: 'Project';
      id: any;
      name: string;
      companyId: any;
      createdAt: any;
      createdBy: any;
      lastModifiedAt?: any | null;
      lastModifiedBy?: any | null;
      assetsCount: number;
      assetsSize: number;
      thumbnail: string;
      existingLocalizations: Array<Localizations>;
      pendingLocalizations: Array<Localizations>;
      status: ProjectStatus;
    }> | null;
  } | null;
};

export type GetAccountManagersQueryVariables = Exact<{ [key: string]: never }>;

export type GetAccountManagersQuery = {
  __typename?: 'Query';
  vidicoAdmin: {
    __typename?: 'VidicoAdminsListPayload';
    vidicoAdmins?: Array<{
      __typename?: 'User';
      id: any;
      companyId: any;
      subdomain: string;
      avatar?: string | null;
      roles: Roles;
      email: string;
      userName: string;
      firstName: string;
      lastName: string;
      createdBy: string;
      createdAt: any;
      lastModifiedBy: string;
      lastModifiedAt: any;
      lastActive?: any | null;
      status: UserStatus;
      country: string;
      timeZoneId: string;
      calendlyUrl: string;
      deletedReason?: any | null;
      pathToAvatar: string;
      pathToAvatarThumbnail: string;
    }> | null;
    errors?: Array<{
      __typename?: 'Error';
      message: string;
      code: number;
    }> | null;
  };
};

export type GetAssetByIdQueryVariables = Exact<{
  assetId: Scalars['UUID'];
  key?: InputMaybe<Scalars['String']>;
}>;

export type GetAssetByIdQuery = {
  __typename?: 'Query';
  asset: {
    __typename?: 'AssetPayloadBase';
    asset?: {
      __typename?: 'Asset';
      localization: Localizations;
      companyId: any;
      projectId: any;
      name: string;
      projectName: string;
      thumbnail?: string | null;
      path: string;
      id: any;
      muxId: string;
      uploadId: string;
      playbackId: string;
      createdBy: any;
      createdAt?: any | null;
      lastModifiedBy?: any | null;
      lastModifiedAt?: any | null;
      size: number;
      type: FileType;
      extension: string;
      dealeted: boolean;
      dealetedBy?: any | null;
      dealetedAt?: any | null;
      duration?: number | null;
      maxResolution: string;
      aspectRatio: string;
      frameRate?: number | null;
      colorSpace?: string | null;
      audio: string;
      audioChannels?: number | null;
      codec?: string | null;
      maxHeight?: number | null;
      maxWidth?: number | null;
      dotPerInch?: number | null;
      bitRate?: number | null;
      muxStreamUrl: string;
      expireStreamDate?: any | null;
      muxDownloadUrl: string;
      muxUrlCreatedAt?: any | null;
      mp4Support: Mp4Support;
      s3DownloadUrl: string;
      s3UrlCreatedAt?: any | null;
      status: AssetStatus;
      originalCreatedAt?: any | null;
      originalUpdatedAt?: any | null;
      isNew: boolean;
      project: { __typename?: 'Project'; id: any; name: string };
    } | null;
    errors?: Array<{
      __typename?: 'Error';
      message: string;
      code: number;
    }> | null;
  };
};

export type GetProjectByIdQueryVariables = Exact<{
  projectId: Scalars['UUID'];
}>;

export type GetProjectByIdQuery = {
  __typename?: 'Query';
  viewProject: {
    __typename?: 'ProjectPayloadBase';
    project?: {
      __typename?: 'Project';
      id: any;
      name: string;
      companyId: any;
      createdAt: any;
      createdBy: any;
      lastModifiedAt?: any | null;
      lastModifiedBy?: any | null;
      assetsCount: number;
      assetsSize: number;
      thumbnail: string;
      existingLocalizations: Array<Localizations>;
      pendingLocalizations: Array<Localizations>;
      status: ProjectStatus;
      assets: Array<{
        __typename?: 'Asset';
        localization: Localizations;
        companyId: any;
        projectId: any;
        name: string;
        projectName: string;
        thumbnail?: string | null;
        path: string;
        id: any;
        muxId: string;
        uploadId: string;
        playbackId: string;
        createdBy: any;
        createdAt?: any | null;
        lastModifiedBy?: any | null;
        lastModifiedAt?: any | null;
        size: number;
        type: FileType;
        extension: string;
        dealeted: boolean;
        dealetedBy?: any | null;
        dealetedAt?: any | null;
        duration?: number | null;
        maxResolution: string;
        aspectRatio: string;
        frameRate?: number | null;
        colorSpace?: string | null;
        audio: string;
        audioChannels?: number | null;
        codec?: string | null;
        maxHeight?: number | null;
        maxWidth?: number | null;
        dotPerInch?: number | null;
        bitRate?: number | null;
        muxStreamUrl: string;
        expireStreamDate?: any | null;
        muxDownloadUrl: string;
        muxUrlCreatedAt?: any | null;
        mp4Support: Mp4Support;
        s3DownloadUrl: string;
        s3UrlCreatedAt?: any | null;
        status: AssetStatus;
        originalCreatedAt?: any | null;
        originalUpdatedAt?: any | null;
        isNew: boolean;
        project: { __typename?: 'Project'; id: any; name: string };
      }>;
    } | null;
  };
};

export type GetUsersByCompanyIdQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['UUID']>;
  order?: InputMaybe<Array<UserSortInput> | UserSortInput>;
  where?: InputMaybe<UserFilterInput>;
}>;

export type GetUsersByCompanyIdQuery = {
  __typename?: 'Query';
  usersList?: Array<{
    __typename?: 'User';
    id: any;
    companyId: any;
    subdomain: string;
    avatar?: string | null;
    roles: Roles;
    email: string;
    userName: string;
    firstName: string;
    lastName: string;
    createdBy: string;
    createdAt: any;
    lastModifiedBy: string;
    lastModifiedAt: any;
    lastActive?: any | null;
    status: UserStatus;
    country: string;
    timeZoneId: string;
    calendlyUrl: string;
    deletedReason?: any | null;
    pathToAvatar: string;
    pathToAvatarThumbnail: string;
  }> | null;
};

export type GetAssetsQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssetFilterInput>;
  order?: InputMaybe<Array<AssetSortInput> | AssetSortInput>;
  userId?: InputMaybe<Scalars['UUID']>;
}>;

export type GetAssetsQuery = {
  __typename?: 'Query';
  assetsList?: {
    __typename?: 'AssetsListCollectionSegment';
    totalCount: number;
    items?: Array<{
      __typename?: 'Asset';
      localization: Localizations;
      companyId: any;
      projectId: any;
      name: string;
      projectName: string;
      thumbnail?: string | null;
      path: string;
      id: any;
      muxId: string;
      uploadId: string;
      playbackId: string;
      createdBy: any;
      createdAt?: any | null;
      lastModifiedBy?: any | null;
      lastModifiedAt?: any | null;
      size: number;
      type: FileType;
      extension: string;
      dealeted: boolean;
      dealetedBy?: any | null;
      dealetedAt?: any | null;
      duration?: number | null;
      maxResolution: string;
      aspectRatio: string;
      frameRate?: number | null;
      colorSpace?: string | null;
      audio: string;
      audioChannels?: number | null;
      codec?: string | null;
      maxHeight?: number | null;
      maxWidth?: number | null;
      dotPerInch?: number | null;
      bitRate?: number | null;
      muxStreamUrl: string;
      expireStreamDate?: any | null;
      muxDownloadUrl: string;
      muxUrlCreatedAt?: any | null;
      mp4Support: Mp4Support;
      s3DownloadUrl: string;
      s3UrlCreatedAt?: any | null;
      status: AssetStatus;
      originalCreatedAt?: any | null;
      originalUpdatedAt?: any | null;
      isNew: boolean;
      viewedBy: Array<{ __typename?: 'User'; id: any }>;
      project: { __typename?: 'Project'; id: any; name: string };
    }> | null;
    pageInfo: {
      __typename?: 'CollectionSegmentInfo';
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  } | null;
};

export type GetSharedAssetsListMutationVariables = Exact<{
  key: Scalars['String'];
}>;

export type GetSharedAssetsListMutation = {
  __typename?: 'Mutation';
  sharedLink: {
    __typename?: 'SharedAssetsPayload';
    assets?: Array<{
      __typename?: 'Asset';
      localization: Localizations;
      companyId: any;
      projectId: any;
      name: string;
      projectName: string;
      thumbnail?: string | null;
      path: string;
      id: any;
      muxId: string;
      uploadId: string;
      playbackId: string;
      createdBy: any;
      createdAt?: any | null;
      lastModifiedBy?: any | null;
      lastModifiedAt?: any | null;
      size: number;
      type: FileType;
      extension: string;
      dealeted: boolean;
      dealetedBy?: any | null;
      dealetedAt?: any | null;
      duration?: number | null;
      maxResolution: string;
      aspectRatio: string;
      frameRate?: number | null;
      colorSpace?: string | null;
      audio: string;
      audioChannels?: number | null;
      codec?: string | null;
      maxHeight?: number | null;
      maxWidth?: number | null;
      dotPerInch?: number | null;
      bitRate?: number | null;
      muxStreamUrl: string;
      expireStreamDate?: any | null;
      muxDownloadUrl: string;
      muxUrlCreatedAt?: any | null;
      mp4Support: Mp4Support;
      s3DownloadUrl: string;
      s3UrlCreatedAt?: any | null;
      status: AssetStatus;
      originalCreatedAt?: any | null;
      originalUpdatedAt?: any | null;
      isNew: boolean;
      project: { __typename?: 'Project'; id: any; name: string };
    }> | null;
    errors?: Array<{
      __typename?: 'Error';
      message: string;
      code: number;
    }> | null;
  };
};

export type NewAssetsInfoQueryVariables = Exact<{
  projectId: Scalars['UUID'];
}>;

export type NewAssetsInfoQuery = {
  __typename?: 'Query';
  newAssetsInfo: {
    __typename?: 'NewAssetsInfoPayload';
    US_ENGLISH?: {
      __typename?: 'NewAssetsInfo';
      all: number;
      videos: number;
      cutdowns: number;
      images: number;
      captions: number;
      editingFiles: number;
    } | null;
    AU_ENGLISH?: {
      __typename?: 'NewAssetsInfo';
      all: number;
      videos: number;
      cutdowns: number;
      images: number;
      captions: number;
      editingFiles: number;
    } | null;
    UK_ENGLISH?: {
      __typename?: 'NewAssetsInfo';
      all: number;
      videos: number;
      cutdowns: number;
      images: number;
      captions: number;
      editingFiles: number;
    } | null;
    CA_ENGLISH?: {
      __typename?: 'NewAssetsInfo';
      all: number;
      videos: number;
      cutdowns: number;
      images: number;
      captions: number;
      editingFiles: number;
    } | null;
    IR_ENGLISH?: {
      __typename?: 'NewAssetsInfo';
      all: number;
      videos: number;
      cutdowns: number;
      images: number;
      captions: number;
      editingFiles: number;
    } | null;
    EU_SPANISH?: {
      __typename?: 'NewAssetsInfo';
      all: number;
      videos: number;
      cutdowns: number;
      images: number;
      captions: number;
      editingFiles: number;
    } | null;
    US_SPANISH?: {
      __typename?: 'NewAssetsInfo';
      all: number;
      videos: number;
      cutdowns: number;
      images: number;
      captions: number;
      editingFiles: number;
    } | null;
    IT_ITALIAN?: {
      __typename?: 'NewAssetsInfo';
      all: number;
      videos: number;
      cutdowns: number;
      images: number;
      captions: number;
      editingFiles: number;
    } | null;
    JP_JAPANESE?: {
      __typename?: 'NewAssetsInfo';
      all: number;
      videos: number;
      cutdowns: number;
      images: number;
      captions: number;
      editingFiles: number;
    } | null;
    BR_PORTUGUESE?: {
      __typename?: 'NewAssetsInfo';
      all: number;
      videos: number;
      cutdowns: number;
      images: number;
      captions: number;
      editingFiles: number;
    } | null;
    PO_PORTUGUESE?: {
      __typename?: 'NewAssetsInfo';
      all: number;
      videos: number;
      cutdowns: number;
      images: number;
      captions: number;
      editingFiles: number;
    } | null;
    HK_CHINESE?: {
      __typename?: 'NewAssetsInfo';
      all: number;
      videos: number;
      cutdowns: number;
      images: number;
      captions: number;
      editingFiles: number;
    } | null;
    CH_MANDARIN?: {
      __typename?: 'NewAssetsInfo';
      all: number;
      videos: number;
      cutdowns: number;
      images: number;
      captions: number;
      editingFiles: number;
    } | null;
  };
};

export type OnAssetsChangeSubscriptionVariables = Exact<{
  projectId: Scalars['UUID'];
  localization: Localizations;
}>;

export type OnAssetsChangeSubscription = {
  __typename?: 'AssetSubscription';
  subscribeAsset: {
    __typename?: 'Asset';
    thumbnail?: string | null;
    path: string;
    id: any;
    projectId: any;
    localization: Localizations;
    companyId: any;
    name: string;
    type: FileType;
    hasThumbnail: boolean;
    muxDownloadUrl: string;
  };
};

export const CollectionSegmentInfoFragmentDoc = gql`
  fragment CollectionSegmentInfo on CollectionSegmentInfo {
    hasNextPage
    hasPreviousPage
  }
`;
export const UserBaseFragmentDoc = gql`
  fragment UserBase on User {
    id
    companyId
    subdomain
    avatar
    roles
    email
    userName
    firstName
    lastName
    createdBy
    createdAt
    lastModifiedBy
    lastModifiedAt
    lastActive
    status
    country
    timeZoneId
    calendlyUrl
    deletedReason
    pathToAvatar
    pathToAvatarThumbnail
  }
`;
export const ErrorFragmentDoc = gql`
  fragment Error on Error {
    message
    code
  }
`;
export const AccountTokenPayloadFragmentDoc = gql`
  fragment AccountTokenPayload on AccountTokenPayload {
    email
    role
    subdomainName
    errors {
      ...Error
    }
  }
  ${ErrorFragmentDoc}
`;
export const ActionPayloadFragmentDoc = gql`
  fragment ActionPayload on ActionPayload {
    isSuccess
    errors {
      ...Error
    }
  }
  ${ErrorFragmentDoc}
`;
export const AccountInvitePayloadFragmentDoc = gql`
  fragment AccountInvitePayload on AccountInvitePayload {
    isUnSuccess
    inviteResponses {
      email
      isSuccess
    }
    errors {
      ...Error
    }
  }
  ${ErrorFragmentDoc}
`;
export const ProjectBaseFragmentDoc = gql`
  fragment ProjectBase on Project {
    id
    name
    companyId
    createdAt
    createdBy
    lastModifiedAt
    lastModifiedBy
    assetsCount
    assetsSize
    thumbnail
    existingLocalizations
    pendingLocalizations
    status
  }
`;
export const AssetFragmentDoc = gql`
  fragment Asset on Asset {
    localization
    companyId
    projectId
    name
    projectName
    thumbnail
    path
    id
    muxId
    uploadId
    playbackId
    createdBy
    createdAt
    lastModifiedBy
    lastModifiedAt
    size
    type
    extension
    dealeted
    dealetedBy
    dealetedAt
    duration
    maxResolution
    aspectRatio
    frameRate
    colorSpace
    audio
    audioChannels
    codec
    maxHeight
    maxWidth
    dotPerInch
    bitRate
    muxStreamUrl
    expireStreamDate
    muxDownloadUrl
    muxUrlCreatedAt
    project {
      id
      name
    }
    mp4Support
    s3DownloadUrl
    s3UrlCreatedAt
    status
    originalCreatedAt
    originalUpdatedAt
    isNew
  }
`;
export const ProjectFragmentDoc = gql`
  fragment Project on Project {
    id
    name
    companyId
    createdAt
    createdBy
    lastModifiedAt
    lastModifiedBy
    assetsCount
    assetsSize
    thumbnail
    existingLocalizations
    pendingLocalizations
    status
    assets {
      ...Asset
    }
  }
  ${AssetFragmentDoc}
`;
export const FavoriteFragmentDoc = gql`
  fragment Favorite on Favorite {
    userId
    user {
      id
    }
    assetId
    createdAt
    asset {
      id
      thumbnail
      name
      projectName
      projectId
      dealeted
    }
  }
`;
export const UserFragmentDoc = gql`
  fragment User on User {
    id
    companyId
    subdomain
    avatar
    roles
    email
    userName
    firstName
    lastName
    createdBy
    createdAt
    lastModifiedBy
    lastModifiedAt
    lastActive
    status
    country
    timeZoneId
    calendlyUrl
    deletedReason
    pathToAvatar
    pathToAvatarThumbnail
    favorites {
      ...Favorite
    }
    company {
      id
      logo
      name
      managerId
      domainNames
    }
  }
  ${FavoriteFragmentDoc}
`;
export const CompanyFragmentDoc = gql`
  fragment Company on Company {
    id
    logo
    name
    teamMembersCount
    subdomainName
    domainNames
    projectsCount
    createdAt
    lastModifiedAt
    lastSessionAt
    projects {
      ...Project
    }
    teamMembers {
      ...User
    }
    totalAssetsSize
    managerId
    currency
  }
  ${ProjectFragmentDoc}
  ${UserFragmentDoc}
`;
export const CompanyBaseFragmentDoc = gql`
  fragment CompanyBase on Company {
    id
    logo
    name
    teamMembersCount
    subdomainName
    domainNames
    projectsCount
    createdAt
    lastModifiedAt
    lastSessionAt
    totalAssetsSize
    managerId
    currency
  }
`;
export const CompanyPayloadBaseFragmentDoc = gql`
  fragment CompanyPayloadBase on CompanyPayloadBase {
    company {
      ...CompanyBase
    }
    errors {
      ...Error
    }
  }
  ${CompanyBaseFragmentDoc}
  ${ErrorFragmentDoc}
`;
export const S3UrlResponseFragmentDoc = gql`
  fragment S3UrlResponse on S3UrlResponse {
    url
    fileName
    oldFileName
    assetId
    type
  }
`;
export const LoginDocument = gql`
  mutation login($input: AccountLoginCommandInput!) {
    login(input: $input) {
      ...AccountTokenPayload
    }
  }
  ${AccountTokenPayloadFragmentDoc}
`;
export type LoginMutationFn = Apollo.MutationFunction<
  LoginMutation,
  LoginMutationVariables
>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginMutation,
    LoginMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginMutation, LoginMutationVariables>(
    LoginDocument,
    options
  );
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<
  LoginMutation,
  LoginMutationVariables
>;
export const LoginWithGoogleDocument = gql`
  mutation loginWithGoogle($code: String!) {
    login(input: { code: $code }) {
      ...AccountTokenPayload
    }
  }
  ${AccountTokenPayloadFragmentDoc}
`;
export type LoginWithGoogleMutationFn = Apollo.MutationFunction<
  LoginWithGoogleMutation,
  LoginWithGoogleMutationVariables
>;

/**
 * __useLoginWithGoogleMutation__
 *
 * To run a mutation, you first call `useLoginWithGoogleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginWithGoogleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginWithGoogleMutation, { data, loading, error }] = useLoginWithGoogleMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useLoginWithGoogleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginWithGoogleMutation,
    LoginWithGoogleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    LoginWithGoogleMutation,
    LoginWithGoogleMutationVariables
  >(LoginWithGoogleDocument, options);
}
export type LoginWithGoogleMutationHookResult = ReturnType<
  typeof useLoginWithGoogleMutation
>;
export type LoginWithGoogleMutationResult =
  Apollo.MutationResult<LoginWithGoogleMutation>;
export type LoginWithGoogleMutationOptions = Apollo.BaseMutationOptions<
  LoginWithGoogleMutation,
  LoginWithGoogleMutationVariables
>;
export const LogOutDocument = gql`
  mutation logOut {
    logOut {
      ...ActionPayload
    }
  }
  ${ActionPayloadFragmentDoc}
`;
export type LogOutMutationFn = Apollo.MutationFunction<
  LogOutMutation,
  LogOutMutationVariables
>;

/**
 * __useLogOutMutation__
 *
 * To run a mutation, you first call `useLogOutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogOutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logOutMutation, { data, loading, error }] = useLogOutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogOutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LogOutMutation,
    LogOutMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LogOutMutation, LogOutMutationVariables>(
    LogOutDocument,
    options
  );
}
export type LogOutMutationHookResult = ReturnType<typeof useLogOutMutation>;
export type LogOutMutationResult = Apollo.MutationResult<LogOutMutation>;
export type LogOutMutationOptions = Apollo.BaseMutationOptions<
  LogOutMutation,
  LogOutMutationVariables
>;
export const RegisterUserDocument = gql`
  mutation registerUser(
    $firstName: String
    $lastName: String
    $email: String
    $password: String
  ) {
    registerUser(
      input: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        password: $password
      }
    ) {
      ...AccountTokenPayload
    }
  }
  ${AccountTokenPayloadFragmentDoc}
`;
export type RegisterUserMutationFn = Apollo.MutationFunction<
  RegisterUserMutation,
  RegisterUserMutationVariables
>;

/**
 * __useRegisterUserMutation__
 *
 * To run a mutation, you first call `useRegisterUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerUserMutation, { data, loading, error }] = useRegisterUserMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useRegisterUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterUserMutation,
    RegisterUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegisterUserMutation,
    RegisterUserMutationVariables
  >(RegisterUserDocument, options);
}
export type RegisterUserMutationHookResult = ReturnType<
  typeof useRegisterUserMutation
>;
export type RegisterUserMutationResult =
  Apollo.MutationResult<RegisterUserMutation>;
export type RegisterUserMutationOptions = Apollo.BaseMutationOptions<
  RegisterUserMutation,
  RegisterUserMutationVariables
>;
export const SignUpWithGoogleDocument = gql`
  mutation signUpWithGoogle($access_token: String) {
    registerUser(input: { googleCode: $access_token }) {
      ...AccountTokenPayload
    }
  }
  ${AccountTokenPayloadFragmentDoc}
`;
export type SignUpWithGoogleMutationFn = Apollo.MutationFunction<
  SignUpWithGoogleMutation,
  SignUpWithGoogleMutationVariables
>;

/**
 * __useSignUpWithGoogleMutation__
 *
 * To run a mutation, you first call `useSignUpWithGoogleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpWithGoogleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpWithGoogleMutation, { data, loading, error }] = useSignUpWithGoogleMutation({
 *   variables: {
 *      access_token: // value for 'access_token'
 *   },
 * });
 */
export function useSignUpWithGoogleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignUpWithGoogleMutation,
    SignUpWithGoogleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SignUpWithGoogleMutation,
    SignUpWithGoogleMutationVariables
  >(SignUpWithGoogleDocument, options);
}
export type SignUpWithGoogleMutationHookResult = ReturnType<
  typeof useSignUpWithGoogleMutation
>;
export type SignUpWithGoogleMutationResult =
  Apollo.MutationResult<SignUpWithGoogleMutation>;
export type SignUpWithGoogleMutationOptions = Apollo.BaseMutationOptions<
  SignUpWithGoogleMutation,
  SignUpWithGoogleMutationVariables
>;
export const SetNewPasswordDocument = gql`
  mutation setNewPassword(
    $key: String!
    $password: String!
    $resetPassword: Boolean! = false
  ) {
    setPassword(
      input: { key: $key, password: $password, resetPassword: $resetPassword }
    ) {
      ...AccountTokenPayload
    }
  }
  ${AccountTokenPayloadFragmentDoc}
`;
export type SetNewPasswordMutationFn = Apollo.MutationFunction<
  SetNewPasswordMutation,
  SetNewPasswordMutationVariables
>;

/**
 * __useSetNewPasswordMutation__
 *
 * To run a mutation, you first call `useSetNewPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetNewPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setNewPasswordMutation, { data, loading, error }] = useSetNewPasswordMutation({
 *   variables: {
 *      key: // value for 'key'
 *      password: // value for 'password'
 *      resetPassword: // value for 'resetPassword'
 *   },
 * });
 */
export function useSetNewPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetNewPasswordMutation,
    SetNewPasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetNewPasswordMutation,
    SetNewPasswordMutationVariables
  >(SetNewPasswordDocument, options);
}
export type SetNewPasswordMutationHookResult = ReturnType<
  typeof useSetNewPasswordMutation
>;
export type SetNewPasswordMutationResult =
  Apollo.MutationResult<SetNewPasswordMutation>;
export type SetNewPasswordMutationOptions = Apollo.BaseMutationOptions<
  SetNewPasswordMutation,
  SetNewPasswordMutationVariables
>;
export const CompleatSignUpDocument = gql`
  mutation compleatSignUp(
    $key: String!
    $password: String!
    $firstName: String!
    $lastName: String!
  ) {
    setPassword(
      input: {
        key: $key
        password: $password
        firstName: $firstName
        lastName: $lastName
      }
    ) {
      ...AccountTokenPayload
    }
  }
  ${AccountTokenPayloadFragmentDoc}
`;
export type CompleatSignUpMutationFn = Apollo.MutationFunction<
  CompleatSignUpMutation,
  CompleatSignUpMutationVariables
>;

/**
 * __useCompleatSignUpMutation__
 *
 * To run a mutation, you first call `useCompleatSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleatSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [compleatSignUpMutation, { data, loading, error }] = useCompleatSignUpMutation({
 *   variables: {
 *      key: // value for 'key'
 *      password: // value for 'password'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *   },
 * });
 */
export function useCompleatSignUpMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompleatSignUpMutation,
    CompleatSignUpMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompleatSignUpMutation,
    CompleatSignUpMutationVariables
  >(CompleatSignUpDocument, options);
}
export type CompleatSignUpMutationHookResult = ReturnType<
  typeof useCompleatSignUpMutation
>;
export type CompleatSignUpMutationResult =
  Apollo.MutationResult<CompleatSignUpMutation>;
export type CompleatSignUpMutationOptions = Apollo.BaseMutationOptions<
  CompleatSignUpMutation,
  CompleatSignUpMutationVariables
>;
export const RequestEmailVerifyDocument = gql`
  mutation requestEmailVerify($email: String!) {
    requestEmailVerify(input: { email: $email }) {
      isSuccess
      errors {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type RequestEmailVerifyMutationFn = Apollo.MutationFunction<
  RequestEmailVerifyMutation,
  RequestEmailVerifyMutationVariables
>;

/**
 * __useRequestEmailVerifyMutation__
 *
 * To run a mutation, you first call `useRequestEmailVerifyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestEmailVerifyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestEmailVerifyMutation, { data, loading, error }] = useRequestEmailVerifyMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRequestEmailVerifyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestEmailVerifyMutation,
    RequestEmailVerifyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestEmailVerifyMutation,
    RequestEmailVerifyMutationVariables
  >(RequestEmailVerifyDocument, options);
}
export type RequestEmailVerifyMutationHookResult = ReturnType<
  typeof useRequestEmailVerifyMutation
>;
export type RequestEmailVerifyMutationResult =
  Apollo.MutationResult<RequestEmailVerifyMutation>;
export type RequestEmailVerifyMutationOptions = Apollo.BaseMutationOptions<
  RequestEmailVerifyMutation,
  RequestEmailVerifyMutationVariables
>;
export const EmailConfirmationDocument = gql`
  mutation emailConfirmation($key: String!) {
    emailConfirmation(input: { key: $key }) {
      isSuccess
      errors {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type EmailConfirmationMutationFn = Apollo.MutationFunction<
  EmailConfirmationMutation,
  EmailConfirmationMutationVariables
>;

/**
 * __useEmailConfirmationMutation__
 *
 * To run a mutation, you first call `useEmailConfirmationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmailConfirmationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emailConfirmationMutation, { data, loading, error }] = useEmailConfirmationMutation({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useEmailConfirmationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EmailConfirmationMutation,
    EmailConfirmationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EmailConfirmationMutation,
    EmailConfirmationMutationVariables
  >(EmailConfirmationDocument, options);
}
export type EmailConfirmationMutationHookResult = ReturnType<
  typeof useEmailConfirmationMutation
>;
export type EmailConfirmationMutationResult =
  Apollo.MutationResult<EmailConfirmationMutation>;
export type EmailConfirmationMutationOptions = Apollo.BaseMutationOptions<
  EmailConfirmationMutation,
  EmailConfirmationMutationVariables
>;
export const ChangeRoleDocument = gql`
  mutation changeRole($role: Roles!, $userId: UUID!) {
    changeRole(input: { role: $role, userId: $userId }) {
      user {
        ...User
      }
    }
  }
  ${UserFragmentDoc}
`;
export type ChangeRoleMutationFn = Apollo.MutationFunction<
  ChangeRoleMutation,
  ChangeRoleMutationVariables
>;

/**
 * __useChangeRoleMutation__
 *
 * To run a mutation, you first call `useChangeRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeRoleMutation, { data, loading, error }] = useChangeRoleMutation({
 *   variables: {
 *      role: // value for 'role'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useChangeRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeRoleMutation,
    ChangeRoleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ChangeRoleMutation, ChangeRoleMutationVariables>(
    ChangeRoleDocument,
    options
  );
}
export type ChangeRoleMutationHookResult = ReturnType<
  typeof useChangeRoleMutation
>;
export type ChangeRoleMutationResult =
  Apollo.MutationResult<ChangeRoleMutation>;
export type ChangeRoleMutationOptions = Apollo.BaseMutationOptions<
  ChangeRoleMutation,
  ChangeRoleMutationVariables
>;
export const InviteNewUserDocument = gql`
  mutation inviteNewUser($invites: [InviteRecordInput!]!, $companyId: UUID!) {
    inviteNewUser(input: { invites: $invites, companyId: $companyId }) {
      ...AccountInvitePayload
    }
  }
  ${AccountInvitePayloadFragmentDoc}
`;
export type InviteNewUserMutationFn = Apollo.MutationFunction<
  InviteNewUserMutation,
  InviteNewUserMutationVariables
>;

/**
 * __useInviteNewUserMutation__
 *
 * To run a mutation, you first call `useInviteNewUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteNewUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteNewUserMutation, { data, loading, error }] = useInviteNewUserMutation({
 *   variables: {
 *      invites: // value for 'invites'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useInviteNewUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InviteNewUserMutation,
    InviteNewUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InviteNewUserMutation,
    InviteNewUserMutationVariables
  >(InviteNewUserDocument, options);
}
export type InviteNewUserMutationHookResult = ReturnType<
  typeof useInviteNewUserMutation
>;
export type InviteNewUserMutationResult =
  Apollo.MutationResult<InviteNewUserMutation>;
export type InviteNewUserMutationOptions = Apollo.BaseMutationOptions<
  InviteNewUserMutation,
  InviteNewUserMutationVariables
>;
export const UserDeleteDocument = gql`
  mutation userDelete($userId: UUID!) {
    userDelete(input: { userId: $userId }) {
      ...ActionPayload
    }
  }
  ${ActionPayloadFragmentDoc}
`;
export type UserDeleteMutationFn = Apollo.MutationFunction<
  UserDeleteMutation,
  UserDeleteMutationVariables
>;

/**
 * __useUserDeleteMutation__
 *
 * To run a mutation, you first call `useUserDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userDeleteMutation, { data, loading, error }] = useUserDeleteMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserDeleteMutation,
    UserDeleteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UserDeleteMutation, UserDeleteMutationVariables>(
    UserDeleteDocument,
    options
  );
}
export type UserDeleteMutationHookResult = ReturnType<
  typeof useUserDeleteMutation
>;
export type UserDeleteMutationResult =
  Apollo.MutationResult<UserDeleteMutation>;
export type UserDeleteMutationOptions = Apollo.BaseMutationOptions<
  UserDeleteMutation,
  UserDeleteMutationVariables
>;
export const AccountDeleteDocument = gql`
  mutation accountDelete($reason: DeleteReasons, $improvement: String) {
    accountDelete(input: { reason: $reason, improvement: $improvement }) {
      ...ActionPayload
    }
  }
  ${ActionPayloadFragmentDoc}
`;
export type AccountDeleteMutationFn = Apollo.MutationFunction<
  AccountDeleteMutation,
  AccountDeleteMutationVariables
>;

/**
 * __useAccountDeleteMutation__
 *
 * To run a mutation, you first call `useAccountDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAccountDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [accountDeleteMutation, { data, loading, error }] = useAccountDeleteMutation({
 *   variables: {
 *      reason: // value for 'reason'
 *      improvement: // value for 'improvement'
 *   },
 * });
 */
export function useAccountDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AccountDeleteMutation,
    AccountDeleteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AccountDeleteMutation,
    AccountDeleteMutationVariables
  >(AccountDeleteDocument, options);
}
export type AccountDeleteMutationHookResult = ReturnType<
  typeof useAccountDeleteMutation
>;
export type AccountDeleteMutationResult =
  Apollo.MutationResult<AccountDeleteMutation>;
export type AccountDeleteMutationOptions = Apollo.BaseMutationOptions<
  AccountDeleteMutation,
  AccountDeleteMutationVariables
>;
export const ChangePasswordDocument = gql`
  mutation changePassword($oldPassword: String!, $newPassword: String!) {
    changePassword(
      input: { oldPassword: $oldPassword, newPassword: $newPassword }
    ) {
      ...ActionPayload
    }
  }
  ${ActionPayloadFragmentDoc}
`;
export type ChangePasswordMutationFn = Apollo.MutationFunction<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      oldPassword: // value for 'oldPassword'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
  >(ChangePasswordDocument, options);
}
export type ChangePasswordMutationHookResult = ReturnType<
  typeof useChangePasswordMutation
>;
export type ChangePasswordMutationResult =
  Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>;
export const RequestResetPasswordDocument = gql`
  mutation requestResetPassword($email: String!) {
    requestResetPassword(input: { email: $email }) {
      ...ActionPayload
    }
  }
  ${ActionPayloadFragmentDoc}
`;
export type RequestResetPasswordMutationFn = Apollo.MutationFunction<
  RequestResetPasswordMutation,
  RequestResetPasswordMutationVariables
>;

/**
 * __useRequestResetPasswordMutation__
 *
 * To run a mutation, you first call `useRequestResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestResetPasswordMutation, { data, loading, error }] = useRequestResetPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRequestResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestResetPasswordMutation,
    RequestResetPasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestResetPasswordMutation,
    RequestResetPasswordMutationVariables
  >(RequestResetPasswordDocument, options);
}
export type RequestResetPasswordMutationHookResult = ReturnType<
  typeof useRequestResetPasswordMutation
>;
export type RequestResetPasswordMutationResult =
  Apollo.MutationResult<RequestResetPasswordMutation>;
export type RequestResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  RequestResetPasswordMutation,
  RequestResetPasswordMutationVariables
>;
export const EditProfileDocument = gql`
  mutation editProfile(
    $file: Upload
    $firstName: String
    $lastName: String
    $email: String
    $country: String
    $timezone: String
  ) {
    accountEdit(
      input: {
        file: $file
        firstName: $firstName
        lastName: $lastName
        email: $email
        country: $country
        timezone: $timezone
      }
    ) {
      ...AccountTokenPayload
    }
  }
  ${AccountTokenPayloadFragmentDoc}
`;
export type EditProfileMutationFn = Apollo.MutationFunction<
  EditProfileMutation,
  EditProfileMutationVariables
>;

/**
 * __useEditProfileMutation__
 *
 * To run a mutation, you first call `useEditProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editProfileMutation, { data, loading, error }] = useEditProfileMutation({
 *   variables: {
 *      file: // value for 'file'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      country: // value for 'country'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useEditProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditProfileMutation,
    EditProfileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditProfileMutation, EditProfileMutationVariables>(
    EditProfileDocument,
    options
  );
}
export type EditProfileMutationHookResult = ReturnType<
  typeof useEditProfileMutation
>;
export type EditProfileMutationResult =
  Apollo.MutationResult<EditProfileMutation>;
export type EditProfileMutationOptions = Apollo.BaseMutationOptions<
  EditProfileMutation,
  EditProfileMutationVariables
>;
export const ResendInviteToUserDocument = gql`
  mutation resendInviteToUser($userId: UUID!) {
    resendInviteToUser(input: { userId: $userId }) {
      ...ActionPayload
    }
  }
  ${ActionPayloadFragmentDoc}
`;
export type ResendInviteToUserMutationFn = Apollo.MutationFunction<
  ResendInviteToUserMutation,
  ResendInviteToUserMutationVariables
>;

/**
 * __useResendInviteToUserMutation__
 *
 * To run a mutation, you first call `useResendInviteToUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendInviteToUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendInviteToUserMutation, { data, loading, error }] = useResendInviteToUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useResendInviteToUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResendInviteToUserMutation,
    ResendInviteToUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResendInviteToUserMutation,
    ResendInviteToUserMutationVariables
  >(ResendInviteToUserDocument, options);
}
export type ResendInviteToUserMutationHookResult = ReturnType<
  typeof useResendInviteToUserMutation
>;
export type ResendInviteToUserMutationResult =
  Apollo.MutationResult<ResendInviteToUserMutation>;
export type ResendInviteToUserMutationOptions = Apollo.BaseMutationOptions<
  ResendInviteToUserMutation,
  ResendInviteToUserMutationVariables
>;
export const UserByIdDocument = gql`
  query userById($id: UUID!) {
    userById(input: { userId: $id }) {
      user {
        ...User
      }
      errors {
        ...Error
      }
    }
  }
  ${UserFragmentDoc}
  ${ErrorFragmentDoc}
`;

/**
 * __useUserByIdQuery__
 *
 * To run a query within a React component, call `useUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserByIdQuery(
  baseOptions: Apollo.QueryHookOptions<UserByIdQuery, UserByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserByIdQuery, UserByIdQueryVariables>(
    UserByIdDocument,
    options
  );
}
export function useUserByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserByIdQuery,
    UserByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserByIdQuery, UserByIdQueryVariables>(
    UserByIdDocument,
    options
  );
}
export type UserByIdQueryHookResult = ReturnType<typeof useUserByIdQuery>;
export type UserByIdLazyQueryHookResult = ReturnType<
  typeof useUserByIdLazyQuery
>;
export type UserByIdQueryResult = Apollo.QueryResult<
  UserByIdQuery,
  UserByIdQueryVariables
>;
export const GetCompanyByIdDocument = gql`
  query getCompanyById($companyId: UUID) {
    viewCompany(input: { companyId: $companyId }) {
      company {
        ...Company
      }
    }
  }
  ${CompanyFragmentDoc}
`;

/**
 * __useGetCompanyByIdQuery__
 *
 * To run a query within a React component, call `useGetCompanyByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyByIdQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetCompanyByIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCompanyByIdQuery,
    GetCompanyByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCompanyByIdQuery, GetCompanyByIdQueryVariables>(
    GetCompanyByIdDocument,
    options
  );
}
export function useGetCompanyByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyByIdQuery,
    GetCompanyByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCompanyByIdQuery, GetCompanyByIdQueryVariables>(
    GetCompanyByIdDocument,
    options
  );
}
export type GetCompanyByIdQueryHookResult = ReturnType<
  typeof useGetCompanyByIdQuery
>;
export type GetCompanyByIdLazyQueryHookResult = ReturnType<
  typeof useGetCompanyByIdLazyQuery
>;
export type GetCompanyByIdQueryResult = Apollo.QueryResult<
  GetCompanyByIdQuery,
  GetCompanyByIdQueryVariables
>;
export const GetCompanyByIdBaseInfoDocument = gql`
  query getCompanyByIdBaseInfo($companyId: UUID) {
    viewCompany(input: { companyId: $companyId }) {
      company {
        ...CompanyBase
      }
    }
  }
  ${CompanyBaseFragmentDoc}
`;

/**
 * __useGetCompanyByIdBaseInfoQuery__
 *
 * To run a query within a React component, call `useGetCompanyByIdBaseInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyByIdBaseInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyByIdBaseInfoQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetCompanyByIdBaseInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCompanyByIdBaseInfoQuery,
    GetCompanyByIdBaseInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyByIdBaseInfoQuery,
    GetCompanyByIdBaseInfoQueryVariables
  >(GetCompanyByIdBaseInfoDocument, options);
}
export function useGetCompanyByIdBaseInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyByIdBaseInfoQuery,
    GetCompanyByIdBaseInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyByIdBaseInfoQuery,
    GetCompanyByIdBaseInfoQueryVariables
  >(GetCompanyByIdBaseInfoDocument, options);
}
export type GetCompanyByIdBaseInfoQueryHookResult = ReturnType<
  typeof useGetCompanyByIdBaseInfoQuery
>;
export type GetCompanyByIdBaseInfoLazyQueryHookResult = ReturnType<
  typeof useGetCompanyByIdBaseInfoLazyQuery
>;
export type GetCompanyByIdBaseInfoQueryResult = Apollo.QueryResult<
  GetCompanyByIdBaseInfoQuery,
  GetCompanyByIdBaseInfoQueryVariables
>;
export const ValidateTokenDocument = gql`
  query validateToken($token: String!) {
    validateKey(input: { key: $token }) {
      ...ActionPayload
    }
  }
  ${ActionPayloadFragmentDoc}
`;

/**
 * __useValidateTokenQuery__
 *
 * To run a query within a React component, call `useValidateTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateTokenQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useValidateTokenQuery(
  baseOptions: Apollo.QueryHookOptions<
    ValidateTokenQuery,
    ValidateTokenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ValidateTokenQuery, ValidateTokenQueryVariables>(
    ValidateTokenDocument,
    options
  );
}
export function useValidateTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ValidateTokenQuery,
    ValidateTokenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ValidateTokenQuery, ValidateTokenQueryVariables>(
    ValidateTokenDocument,
    options
  );
}
export type ValidateTokenQueryHookResult = ReturnType<
  typeof useValidateTokenQuery
>;
export type ValidateTokenLazyQueryHookResult = ReturnType<
  typeof useValidateTokenLazyQuery
>;
export type ValidateTokenQueryResult = Apollo.QueryResult<
  ValidateTokenQuery,
  ValidateTokenQueryVariables
>;
export const CompanyCreateDocument = gql`
  mutation companyCreate(
    $file: Upload!
    $firstName: String!
    $lastName: String!
    $email: String!
    $company: String!
    $managerId: UUID!
    $currency: String
  ) {
    companyCreate(
      input: {
        file: $file
        firstName: $firstName
        lastName: $lastName
        email: $email
        company: $company
        managerId: $managerId
        currency: $currency
      }
    ) {
      company {
        id
      }
      errors {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type CompanyCreateMutationFn = Apollo.MutationFunction<
  CompanyCreateMutation,
  CompanyCreateMutationVariables
>;

/**
 * __useCompanyCreateMutation__
 *
 * To run a mutation, you first call `useCompanyCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyCreateMutation, { data, loading, error }] = useCompanyCreateMutation({
 *   variables: {
 *      file: // value for 'file'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      company: // value for 'company'
 *      managerId: // value for 'managerId'
 *      currency: // value for 'currency'
 *   },
 * });
 */
export function useCompanyCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompanyCreateMutation,
    CompanyCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompanyCreateMutation,
    CompanyCreateMutationVariables
  >(CompanyCreateDocument, options);
}
export type CompanyCreateMutationHookResult = ReturnType<
  typeof useCompanyCreateMutation
>;
export type CompanyCreateMutationResult =
  Apollo.MutationResult<CompanyCreateMutation>;
export type CompanyCreateMutationOptions = Apollo.BaseMutationOptions<
  CompanyCreateMutation,
  CompanyCreateMutationVariables
>;
export const CompanyEditDocument = gql`
  mutation companyEdit(
    $file: Upload
    $companyName: String
    $managerId: UUID
    $domainNames: [String!]
    $companyId: UUID!
    $currency: String
  ) {
    companyEdit(
      input: {
        file: $file
        companyName: $companyName
        managerId: $managerId
        domainNames: $domainNames
        companyId: $companyId
        currency: $currency
      }
    ) {
      ...CompanyPayloadBase
    }
  }
  ${CompanyPayloadBaseFragmentDoc}
`;
export type CompanyEditMutationFn = Apollo.MutationFunction<
  CompanyEditMutation,
  CompanyEditMutationVariables
>;

/**
 * __useCompanyEditMutation__
 *
 * To run a mutation, you first call `useCompanyEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyEditMutation, { data, loading, error }] = useCompanyEditMutation({
 *   variables: {
 *      file: // value for 'file'
 *      companyName: // value for 'companyName'
 *      managerId: // value for 'managerId'
 *      domainNames: // value for 'domainNames'
 *      companyId: // value for 'companyId'
 *      currency: // value for 'currency'
 *   },
 * });
 */
export function useCompanyEditMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompanyEditMutation,
    CompanyEditMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CompanyEditMutation, CompanyEditMutationVariables>(
    CompanyEditDocument,
    options
  );
}
export type CompanyEditMutationHookResult = ReturnType<
  typeof useCompanyEditMutation
>;
export type CompanyEditMutationResult =
  Apollo.MutationResult<CompanyEditMutation>;
export type CompanyEditMutationOptions = Apollo.BaseMutationOptions<
  CompanyEditMutation,
  CompanyEditMutationVariables
>;
export const ProjectCreateDocument = gql`
  mutation projectCreate(
    $projectName: String!
    $briefDetails: String
    $powerUps: [PowerUps!]
    $budget: Budget
    $companyId: UUID!
    $localizations: [Localizations!]
  ) {
    projectCreate(
      input: {
        projectName: $projectName
        briefDetails: $briefDetails
        powerUps: $powerUps
        budget: $budget
        companyId: $companyId
        localizations: $localizations
      }
    ) {
      project {
        ...ProjectBase
      }
      errors {
        ...Error
      }
    }
  }
  ${ProjectBaseFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type ProjectCreateMutationFn = Apollo.MutationFunction<
  ProjectCreateMutation,
  ProjectCreateMutationVariables
>;

/**
 * __useProjectCreateMutation__
 *
 * To run a mutation, you first call `useProjectCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectCreateMutation, { data, loading, error }] = useProjectCreateMutation({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      briefDetails: // value for 'briefDetails'
 *      powerUps: // value for 'powerUps'
 *      budget: // value for 'budget'
 *      companyId: // value for 'companyId'
 *      localizations: // value for 'localizations'
 *   },
 * });
 */
export function useProjectCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ProjectCreateMutation,
    ProjectCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ProjectCreateMutation,
    ProjectCreateMutationVariables
  >(ProjectCreateDocument, options);
}
export type ProjectCreateMutationHookResult = ReturnType<
  typeof useProjectCreateMutation
>;
export type ProjectCreateMutationResult =
  Apollo.MutationResult<ProjectCreateMutation>;
export type ProjectCreateMutationOptions = Apollo.BaseMutationOptions<
  ProjectCreateMutation,
  ProjectCreateMutationVariables
>;
export const EditProjectDocument = gql`
  mutation editProject(
    $projectId: UUID!
    $projectName: String
    $localizations: [Localizations!]
    $assetId: UUID
    $seconds: Int
    $status: ProjectStatus
  ) {
    editProject(
      input: {
        projectId: $projectId
        projectName: $projectName
        localizations: $localizations
        assetId: $assetId
        seconds: $seconds
        status: $status
      }
    ) {
      project {
        ...ProjectBase
      }
      errors {
        ...Error
      }
    }
  }
  ${ProjectBaseFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type EditProjectMutationFn = Apollo.MutationFunction<
  EditProjectMutation,
  EditProjectMutationVariables
>;

/**
 * __useEditProjectMutation__
 *
 * To run a mutation, you first call `useEditProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editProjectMutation, { data, loading, error }] = useEditProjectMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      projectName: // value for 'projectName'
 *      localizations: // value for 'localizations'
 *      assetId: // value for 'assetId'
 *      seconds: // value for 'seconds'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useEditProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditProjectMutation,
    EditProjectMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditProjectMutation, EditProjectMutationVariables>(
    EditProjectDocument,
    options
  );
}
export type EditProjectMutationHookResult = ReturnType<
  typeof useEditProjectMutation
>;
export type EditProjectMutationResult =
  Apollo.MutationResult<EditProjectMutation>;
export type EditProjectMutationOptions = Apollo.BaseMutationOptions<
  EditProjectMutation,
  EditProjectMutationVariables
>;
export const AssetsUploadDocument = gql`
  mutation assetsUpload(
    $files: [UploadAssetInput!]!
    $projectId: UUID!
    $localization: Localizations!
  ) {
    assetUpload(
      input: {
        files: $files
        projectId: $projectId
        localization: $localization
      }
    ) {
      s3Urls {
        ...S3UrlResponse
      }
      errors {
        ...Error
      }
    }
  }
  ${S3UrlResponseFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type AssetsUploadMutationFn = Apollo.MutationFunction<
  AssetsUploadMutation,
  AssetsUploadMutationVariables
>;

/**
 * __useAssetsUploadMutation__
 *
 * To run a mutation, you first call `useAssetsUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssetsUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assetsUploadMutation, { data, loading, error }] = useAssetsUploadMutation({
 *   variables: {
 *      files: // value for 'files'
 *      projectId: // value for 'projectId'
 *      localization: // value for 'localization'
 *   },
 * });
 */
export function useAssetsUploadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssetsUploadMutation,
    AssetsUploadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssetsUploadMutation,
    AssetsUploadMutationVariables
  >(AssetsUploadDocument, options);
}
export type AssetsUploadMutationHookResult = ReturnType<
  typeof useAssetsUploadMutation
>;
export type AssetsUploadMutationResult =
  Apollo.MutationResult<AssetsUploadMutation>;
export type AssetsUploadMutationOptions = Apollo.BaseMutationOptions<
  AssetsUploadMutation,
  AssetsUploadMutationVariables
>;
export const CreateSharedLinkDocument = gql`
  mutation createSharedLink(
    $projectId: UUID!
    $assetIds: [UUID!]!
    $isProject: Boolean!
  ) {
    createSharedLink(
      input: {
        projectId: $projectId
        assetIds: $assetIds
        isProject: $isProject
      }
    ) {
      sharedLink {
        id
        assets {
          ...Asset
        }
        key
        projectId
        isProject
        link
        status
      }
      errors {
        message
      }
    }
  }
  ${AssetFragmentDoc}
`;
export type CreateSharedLinkMutationFn = Apollo.MutationFunction<
  CreateSharedLinkMutation,
  CreateSharedLinkMutationVariables
>;

/**
 * __useCreateSharedLinkMutation__
 *
 * To run a mutation, you first call `useCreateSharedLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSharedLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSharedLinkMutation, { data, loading, error }] = useCreateSharedLinkMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      assetIds: // value for 'assetIds'
 *      isProject: // value for 'isProject'
 *   },
 * });
 */
export function useCreateSharedLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSharedLinkMutation,
    CreateSharedLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateSharedLinkMutation,
    CreateSharedLinkMutationVariables
  >(CreateSharedLinkDocument, options);
}
export type CreateSharedLinkMutationHookResult = ReturnType<
  typeof useCreateSharedLinkMutation
>;
export type CreateSharedLinkMutationResult =
  Apollo.MutationResult<CreateSharedLinkMutation>;
export type CreateSharedLinkMutationOptions = Apollo.BaseMutationOptions<
  CreateSharedLinkMutation,
  CreateSharedLinkMutationVariables
>;
export const DeleteSharedLinkDocument = gql`
  mutation deleteSharedLink($linkId: UUID!) {
    deleteSharedLink(input: { linkId: $linkId }) {
      ...ActionPayload
    }
  }
  ${ActionPayloadFragmentDoc}
`;
export type DeleteSharedLinkMutationFn = Apollo.MutationFunction<
  DeleteSharedLinkMutation,
  DeleteSharedLinkMutationVariables
>;

/**
 * __useDeleteSharedLinkMutation__
 *
 * To run a mutation, you first call `useDeleteSharedLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSharedLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSharedLinkMutation, { data, loading, error }] = useDeleteSharedLinkMutation({
 *   variables: {
 *      linkId: // value for 'linkId'
 *   },
 * });
 */
export function useDeleteSharedLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteSharedLinkMutation,
    DeleteSharedLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteSharedLinkMutation,
    DeleteSharedLinkMutationVariables
  >(DeleteSharedLinkDocument, options);
}
export type DeleteSharedLinkMutationHookResult = ReturnType<
  typeof useDeleteSharedLinkMutation
>;
export type DeleteSharedLinkMutationResult =
  Apollo.MutationResult<DeleteSharedLinkMutation>;
export type DeleteSharedLinkMutationOptions = Apollo.BaseMutationOptions<
  DeleteSharedLinkMutation,
  DeleteSharedLinkMutationVariables
>;
export const GenerateNewSharedLinkDocument = gql`
  mutation generateNewSharedLink(
    $linkId: UUID!
    $projectId: UUID!
    $assetIds: [UUID!]!
    $isProject: Boolean!
  ) {
    deleteSharedLink(input: { linkId: $linkId }) {
      ...ActionPayload
    }
    createSharedLink(
      input: {
        projectId: $projectId
        assetIds: $assetIds
        isProject: $isProject
      }
    ) {
      sharedLink {
        id
        assets {
          ...Asset
        }
        key
        projectId
        isProject
        link
        status
      }
      errors {
        message
      }
    }
  }
  ${ActionPayloadFragmentDoc}
  ${AssetFragmentDoc}
`;
export type GenerateNewSharedLinkMutationFn = Apollo.MutationFunction<
  GenerateNewSharedLinkMutation,
  GenerateNewSharedLinkMutationVariables
>;

/**
 * __useGenerateNewSharedLinkMutation__
 *
 * To run a mutation, you first call `useGenerateNewSharedLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateNewSharedLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateNewSharedLinkMutation, { data, loading, error }] = useGenerateNewSharedLinkMutation({
 *   variables: {
 *      linkId: // value for 'linkId'
 *      projectId: // value for 'projectId'
 *      assetIds: // value for 'assetIds'
 *      isProject: // value for 'isProject'
 *   },
 * });
 */
export function useGenerateNewSharedLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateNewSharedLinkMutation,
    GenerateNewSharedLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GenerateNewSharedLinkMutation,
    GenerateNewSharedLinkMutationVariables
  >(GenerateNewSharedLinkDocument, options);
}
export type GenerateNewSharedLinkMutationHookResult = ReturnType<
  typeof useGenerateNewSharedLinkMutation
>;
export type GenerateNewSharedLinkMutationResult =
  Apollo.MutationResult<GenerateNewSharedLinkMutation>;
export type GenerateNewSharedLinkMutationOptions = Apollo.BaseMutationOptions<
  GenerateNewSharedLinkMutation,
  GenerateNewSharedLinkMutationVariables
>;
export const RemoveAssetsDocument = gql`
  mutation removeAssets($assetsId: [UUID!]!) {
    assetRemove(input: { assetsId: $assetsId }) {
      ...ActionPayload
    }
  }
  ${ActionPayloadFragmentDoc}
`;
export type RemoveAssetsMutationFn = Apollo.MutationFunction<
  RemoveAssetsMutation,
  RemoveAssetsMutationVariables
>;

/**
 * __useRemoveAssetsMutation__
 *
 * To run a mutation, you first call `useRemoveAssetsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAssetsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAssetsMutation, { data, loading, error }] = useRemoveAssetsMutation({
 *   variables: {
 *      assetsId: // value for 'assetsId'
 *   },
 * });
 */
export function useRemoveAssetsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveAssetsMutation,
    RemoveAssetsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveAssetsMutation,
    RemoveAssetsMutationVariables
  >(RemoveAssetsDocument, options);
}
export type RemoveAssetsMutationHookResult = ReturnType<
  typeof useRemoveAssetsMutation
>;
export type RemoveAssetsMutationResult =
  Apollo.MutationResult<RemoveAssetsMutation>;
export type RemoveAssetsMutationOptions = Apollo.BaseMutationOptions<
  RemoveAssetsMutation,
  RemoveAssetsMutationVariables
>;
export const AssetDownloadDocument = gql`
  mutation assetDownload($assetsId: [UUID!]!, $sharedLinkKey: String) {
    assetDownload(
      input: { assetsId: $assetsId, sharedLinkKey: $sharedLinkKey }
    ) {
      assets {
        ...Asset
      }
    }
  }
  ${AssetFragmentDoc}
`;
export type AssetDownloadMutationFn = Apollo.MutationFunction<
  AssetDownloadMutation,
  AssetDownloadMutationVariables
>;

/**
 * __useAssetDownloadMutation__
 *
 * To run a mutation, you first call `useAssetDownloadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssetDownloadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assetDownloadMutation, { data, loading, error }] = useAssetDownloadMutation({
 *   variables: {
 *      assetsId: // value for 'assetsId'
 *      sharedLinkKey: // value for 'sharedLinkKey'
 *   },
 * });
 */
export function useAssetDownloadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssetDownloadMutation,
    AssetDownloadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssetDownloadMutation,
    AssetDownloadMutationVariables
  >(AssetDownloadDocument, options);
}
export type AssetDownloadMutationHookResult = ReturnType<
  typeof useAssetDownloadMutation
>;
export type AssetDownloadMutationResult =
  Apollo.MutationResult<AssetDownloadMutation>;
export type AssetDownloadMutationOptions = Apollo.BaseMutationOptions<
  AssetDownloadMutation,
  AssetDownloadMutationVariables
>;
export const DeleteProjectDocument = gql`
  mutation deleteProject($projectId: UUID!) {
    deleteProject(input: { projectId: $projectId }) {
      ...ActionPayload
    }
  }
  ${ActionPayloadFragmentDoc}
`;
export type DeleteProjectMutationFn = Apollo.MutationFunction<
  DeleteProjectMutation,
  DeleteProjectMutationVariables
>;

/**
 * __useDeleteProjectMutation__
 *
 * To run a mutation, you first call `useDeleteProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectMutation, { data, loading, error }] = useDeleteProjectMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useDeleteProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteProjectMutation,
    DeleteProjectMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteProjectMutation,
    DeleteProjectMutationVariables
  >(DeleteProjectDocument, options);
}
export type DeleteProjectMutationHookResult = ReturnType<
  typeof useDeleteProjectMutation
>;
export type DeleteProjectMutationResult =
  Apollo.MutationResult<DeleteProjectMutation>;
export type DeleteProjectMutationOptions = Apollo.BaseMutationOptions<
  DeleteProjectMutation,
  DeleteProjectMutationVariables
>;
export const MarkAssetsAsFavoriteDocument = gql`
  mutation markAssetsAsFavorite($assetsId: [UUID!]!) {
    assetsStarred(input: { assetsId: $assetsId }) {
      user {
        ...User
      }
      errors {
        ...Error
      }
    }
  }
  ${UserFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type MarkAssetsAsFavoriteMutationFn = Apollo.MutationFunction<
  MarkAssetsAsFavoriteMutation,
  MarkAssetsAsFavoriteMutationVariables
>;

/**
 * __useMarkAssetsAsFavoriteMutation__
 *
 * To run a mutation, you first call `useMarkAssetsAsFavoriteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAssetsAsFavoriteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAssetsAsFavoriteMutation, { data, loading, error }] = useMarkAssetsAsFavoriteMutation({
 *   variables: {
 *      assetsId: // value for 'assetsId'
 *   },
 * });
 */
export function useMarkAssetsAsFavoriteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkAssetsAsFavoriteMutation,
    MarkAssetsAsFavoriteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MarkAssetsAsFavoriteMutation,
    MarkAssetsAsFavoriteMutationVariables
  >(MarkAssetsAsFavoriteDocument, options);
}
export type MarkAssetsAsFavoriteMutationHookResult = ReturnType<
  typeof useMarkAssetsAsFavoriteMutation
>;
export type MarkAssetsAsFavoriteMutationResult =
  Apollo.MutationResult<MarkAssetsAsFavoriteMutation>;
export type MarkAssetsAsFavoriteMutationOptions = Apollo.BaseMutationOptions<
  MarkAssetsAsFavoriteMutation,
  MarkAssetsAsFavoriteMutationVariables
>;
export const RenameAssetDocument = gql`
  mutation renameAsset($assetId: UUID!, $assetName: String!) {
    assetRename(input: { assetId: $assetId, assetName: $assetName }) {
      asset {
        id
        name
      }
      errors {
        message
        code
      }
    }
  }
`;
export type RenameAssetMutationFn = Apollo.MutationFunction<
  RenameAssetMutation,
  RenameAssetMutationVariables
>;

/**
 * __useRenameAssetMutation__
 *
 * To run a mutation, you first call `useRenameAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenameAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renameAssetMutation, { data, loading, error }] = useRenameAssetMutation({
 *   variables: {
 *      assetId: // value for 'assetId'
 *      assetName: // value for 'assetName'
 *   },
 * });
 */
export function useRenameAssetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RenameAssetMutation,
    RenameAssetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RenameAssetMutation, RenameAssetMutationVariables>(
    RenameAssetDocument,
    options
  );
}
export type RenameAssetMutationHookResult = ReturnType<
  typeof useRenameAssetMutation
>;
export type RenameAssetMutationResult =
  Apollo.MutationResult<RenameAssetMutation>;
export type RenameAssetMutationOptions = Apollo.BaseMutationOptions<
  RenameAssetMutation,
  RenameAssetMutationVariables
>;
export const AssetsViewedByDocument = gql`
  mutation assetsViewedBy($assetsId: [UUID!]!) {
    assetsVievedBy(input: { assetsId: $assetsId }) {
      ...Asset
    }
  }
  ${AssetFragmentDoc}
`;
export type AssetsViewedByMutationFn = Apollo.MutationFunction<
  AssetsViewedByMutation,
  AssetsViewedByMutationVariables
>;

/**
 * __useAssetsViewedByMutation__
 *
 * To run a mutation, you first call `useAssetsViewedByMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssetsViewedByMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assetsViewedByMutation, { data, loading, error }] = useAssetsViewedByMutation({
 *   variables: {
 *      assetsId: // value for 'assetsId'
 *   },
 * });
 */
export function useAssetsViewedByMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssetsViewedByMutation,
    AssetsViewedByMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssetsViewedByMutation,
    AssetsViewedByMutationVariables
  >(AssetsViewedByDocument, options);
}
export type AssetsViewedByMutationHookResult = ReturnType<
  typeof useAssetsViewedByMutation
>;
export type AssetsViewedByMutationResult =
  Apollo.MutationResult<AssetsViewedByMutation>;
export type AssetsViewedByMutationOptions = Apollo.BaseMutationOptions<
  AssetsViewedByMutation,
  AssetsViewedByMutationVariables
>;
export const PrepareForDownloadDocument = gql`
  mutation prepareForDownload(
    $assetsId: [UUID!]
    $projectId: UUID
    $localization: Localizations
  ) {
    generateMuxDownloadUrl(
      input: {
        assetsId: $assetsId
        projectId: $projectId
        localization: $localization
      }
    ) {
      ...ActionPayload
    }
  }
  ${ActionPayloadFragmentDoc}
`;
export type PrepareForDownloadMutationFn = Apollo.MutationFunction<
  PrepareForDownloadMutation,
  PrepareForDownloadMutationVariables
>;

/**
 * __usePrepareForDownloadMutation__
 *
 * To run a mutation, you first call `usePrepareForDownloadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePrepareForDownloadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [prepareForDownloadMutation, { data, loading, error }] = usePrepareForDownloadMutation({
 *   variables: {
 *      assetsId: // value for 'assetsId'
 *      projectId: // value for 'projectId'
 *      localization: // value for 'localization'
 *   },
 * });
 */
export function usePrepareForDownloadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PrepareForDownloadMutation,
    PrepareForDownloadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PrepareForDownloadMutation,
    PrepareForDownloadMutationVariables
  >(PrepareForDownloadDocument, options);
}
export type PrepareForDownloadMutationHookResult = ReturnType<
  typeof usePrepareForDownloadMutation
>;
export type PrepareForDownloadMutationResult =
  Apollo.MutationResult<PrepareForDownloadMutation>;
export type PrepareForDownloadMutationOptions = Apollo.BaseMutationOptions<
  PrepareForDownloadMutation,
  PrepareForDownloadMutationVariables
>;
export const CompaniesListDocument = gql`
  query companiesList(
    $skip: Int
    $take: Int
    $order: [CompanySortInput!]
    $where: CompanyFilterInput
  ) {
    companiesList(skip: $skip, take: $take, order: $order, where: $where) {
      pageInfo {
        ...CollectionSegmentInfo
      }
      items {
        ...CompanyBase
      }
      totalCount
    }
  }
  ${CollectionSegmentInfoFragmentDoc}
  ${CompanyBaseFragmentDoc}
`;

/**
 * __useCompaniesListQuery__
 *
 * To run a query within a React component, call `useCompaniesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompaniesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompaniesListQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCompaniesListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CompaniesListQuery,
    CompaniesListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CompaniesListQuery, CompaniesListQueryVariables>(
    CompaniesListDocument,
    options
  );
}
export function useCompaniesListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompaniesListQuery,
    CompaniesListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CompaniesListQuery, CompaniesListQueryVariables>(
    CompaniesListDocument,
    options
  );
}
export type CompaniesListQueryHookResult = ReturnType<
  typeof useCompaniesListQuery
>;
export type CompaniesListLazyQueryHookResult = ReturnType<
  typeof useCompaniesListLazyQuery
>;
export type CompaniesListQueryResult = Apollo.QueryResult<
  CompaniesListQuery,
  CompaniesListQueryVariables
>;
export const ProjectsListDocument = gql`
  query projectsList(
    $skip: Int
    $take: Int
    $order: [ProjectSortInput!]
    $where: ProjectFilterInput
    $companyId: UUID
  ) {
    projectsList(
      skip: $skip
      take: $take
      order: $order
      where: $where
      input: { companyId: $companyId }
    ) {
      pageInfo {
        ...CollectionSegmentInfo
      }
      items {
        ...ProjectBase
      }
      totalCount
    }
  }
  ${CollectionSegmentInfoFragmentDoc}
  ${ProjectBaseFragmentDoc}
`;

/**
 * __useProjectsListQuery__
 *
 * To run a query within a React component, call `useProjectsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsListQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useProjectsListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ProjectsListQuery,
    ProjectsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectsListQuery, ProjectsListQueryVariables>(
    ProjectsListDocument,
    options
  );
}
export function useProjectsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectsListQuery,
    ProjectsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProjectsListQuery, ProjectsListQueryVariables>(
    ProjectsListDocument,
    options
  );
}
export type ProjectsListQueryHookResult = ReturnType<
  typeof useProjectsListQuery
>;
export type ProjectsListLazyQueryHookResult = ReturnType<
  typeof useProjectsListLazyQuery
>;
export type ProjectsListQueryResult = Apollo.QueryResult<
  ProjectsListQuery,
  ProjectsListQueryVariables
>;
export const GetAccountManagersDocument = gql`
  query getAccountManagers {
    vidicoAdmin {
      vidicoAdmins {
        ...UserBase
      }
      errors {
        ...Error
      }
    }
  }
  ${UserBaseFragmentDoc}
  ${ErrorFragmentDoc}
`;

/**
 * __useGetAccountManagersQuery__
 *
 * To run a query within a React component, call `useGetAccountManagersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountManagersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountManagersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAccountManagersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAccountManagersQuery,
    GetAccountManagersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAccountManagersQuery,
    GetAccountManagersQueryVariables
  >(GetAccountManagersDocument, options);
}
export function useGetAccountManagersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAccountManagersQuery,
    GetAccountManagersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAccountManagersQuery,
    GetAccountManagersQueryVariables
  >(GetAccountManagersDocument, options);
}
export type GetAccountManagersQueryHookResult = ReturnType<
  typeof useGetAccountManagersQuery
>;
export type GetAccountManagersLazyQueryHookResult = ReturnType<
  typeof useGetAccountManagersLazyQuery
>;
export type GetAccountManagersQueryResult = Apollo.QueryResult<
  GetAccountManagersQuery,
  GetAccountManagersQueryVariables
>;
export const GetAssetByIdDocument = gql`
  query getAssetById($assetId: UUID!, $key: String) {
    asset(input: { assetId: $assetId, key: $key }) {
      asset {
        ...Asset
      }
      errors {
        ...Error
      }
    }
  }
  ${AssetFragmentDoc}
  ${ErrorFragmentDoc}
`;

/**
 * __useGetAssetByIdQuery__
 *
 * To run a query within a React component, call `useGetAssetByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetByIdQuery({
 *   variables: {
 *      assetId: // value for 'assetId'
 *      key: // value for 'key'
 *   },
 * });
 */
export function useGetAssetByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAssetByIdQuery,
    GetAssetByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAssetByIdQuery, GetAssetByIdQueryVariables>(
    GetAssetByIdDocument,
    options
  );
}
export function useGetAssetByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAssetByIdQuery,
    GetAssetByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAssetByIdQuery, GetAssetByIdQueryVariables>(
    GetAssetByIdDocument,
    options
  );
}
export type GetAssetByIdQueryHookResult = ReturnType<
  typeof useGetAssetByIdQuery
>;
export type GetAssetByIdLazyQueryHookResult = ReturnType<
  typeof useGetAssetByIdLazyQuery
>;
export type GetAssetByIdQueryResult = Apollo.QueryResult<
  GetAssetByIdQuery,
  GetAssetByIdQueryVariables
>;
export const GetProjectByIdDocument = gql`
  query getProjectById($projectId: UUID!) {
    viewProject(input: { projectId: $projectId }) {
      project {
        ...Project
      }
    }
  }
  ${ProjectFragmentDoc}
`;

/**
 * __useGetProjectByIdQuery__
 *
 * To run a query within a React component, call `useGetProjectByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectByIdQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetProjectByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectByIdQuery,
    GetProjectByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProjectByIdQuery, GetProjectByIdQueryVariables>(
    GetProjectByIdDocument,
    options
  );
}
export function useGetProjectByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectByIdQuery,
    GetProjectByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProjectByIdQuery, GetProjectByIdQueryVariables>(
    GetProjectByIdDocument,
    options
  );
}
export type GetProjectByIdQueryHookResult = ReturnType<
  typeof useGetProjectByIdQuery
>;
export type GetProjectByIdLazyQueryHookResult = ReturnType<
  typeof useGetProjectByIdLazyQuery
>;
export type GetProjectByIdQueryResult = Apollo.QueryResult<
  GetProjectByIdQuery,
  GetProjectByIdQueryVariables
>;
export const GetUsersByCompanyIdDocument = gql`
  query getUsersByCompanyId(
    $companyId: UUID
    $order: [UserSortInput!]
    $where: UserFilterInput
  ) {
    usersList(input: { companyId: $companyId }, order: $order, where: $where) {
      ...UserBase
    }
  }
  ${UserBaseFragmentDoc}
`;

/**
 * __useGetUsersByCompanyIdQuery__
 *
 * To run a query within a React component, call `useGetUsersByCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersByCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersByCompanyIdQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetUsersByCompanyIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUsersByCompanyIdQuery,
    GetUsersByCompanyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUsersByCompanyIdQuery,
    GetUsersByCompanyIdQueryVariables
  >(GetUsersByCompanyIdDocument, options);
}
export function useGetUsersByCompanyIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUsersByCompanyIdQuery,
    GetUsersByCompanyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUsersByCompanyIdQuery,
    GetUsersByCompanyIdQueryVariables
  >(GetUsersByCompanyIdDocument, options);
}
export type GetUsersByCompanyIdQueryHookResult = ReturnType<
  typeof useGetUsersByCompanyIdQuery
>;
export type GetUsersByCompanyIdLazyQueryHookResult = ReturnType<
  typeof useGetUsersByCompanyIdLazyQuery
>;
export type GetUsersByCompanyIdQueryResult = Apollo.QueryResult<
  GetUsersByCompanyIdQuery,
  GetUsersByCompanyIdQueryVariables
>;
export const GetAssetsDocument = gql`
  query getAssets(
    $skip: Int
    $take: Int
    $where: AssetFilterInput
    $order: [AssetSortInput!]
    $userId: UUID
  ) {
    assetsList(skip: $skip, take: $take, where: $where, order: $order) {
      items {
        ...Asset
        viewedBy(where: { id: { eq: $userId } }) {
          id
        }
      }
      pageInfo {
        ...CollectionSegmentInfo
      }
      totalCount
    }
  }
  ${AssetFragmentDoc}
  ${CollectionSegmentInfoFragmentDoc}
`;

/**
 * __useGetAssetsQuery__
 *
 * To run a query within a React component, call `useGetAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      order: // value for 'order'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetAssetsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAssetsQuery, GetAssetsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAssetsQuery, GetAssetsQueryVariables>(
    GetAssetsDocument,
    options
  );
}
export function useGetAssetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAssetsQuery,
    GetAssetsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAssetsQuery, GetAssetsQueryVariables>(
    GetAssetsDocument,
    options
  );
}
export type GetAssetsQueryHookResult = ReturnType<typeof useGetAssetsQuery>;
export type GetAssetsLazyQueryHookResult = ReturnType<
  typeof useGetAssetsLazyQuery
>;
export type GetAssetsQueryResult = Apollo.QueryResult<
  GetAssetsQuery,
  GetAssetsQueryVariables
>;
export const GetSharedAssetsListDocument = gql`
  mutation getSharedAssetsList($key: String!) {
    sharedLink(input: { key: $key }) {
      assets {
        ...Asset
      }
      errors {
        ...Error
      }
    }
  }
  ${AssetFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type GetSharedAssetsListMutationFn = Apollo.MutationFunction<
  GetSharedAssetsListMutation,
  GetSharedAssetsListMutationVariables
>;

/**
 * __useGetSharedAssetsListMutation__
 *
 * To run a mutation, you first call `useGetSharedAssetsListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetSharedAssetsListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getSharedAssetsListMutation, { data, loading, error }] = useGetSharedAssetsListMutation({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useGetSharedAssetsListMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GetSharedAssetsListMutation,
    GetSharedAssetsListMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GetSharedAssetsListMutation,
    GetSharedAssetsListMutationVariables
  >(GetSharedAssetsListDocument, options);
}
export type GetSharedAssetsListMutationHookResult = ReturnType<
  typeof useGetSharedAssetsListMutation
>;
export type GetSharedAssetsListMutationResult =
  Apollo.MutationResult<GetSharedAssetsListMutation>;
export type GetSharedAssetsListMutationOptions = Apollo.BaseMutationOptions<
  GetSharedAssetsListMutation,
  GetSharedAssetsListMutationVariables
>;
export const NewAssetsInfoDocument = gql`
  query newAssetsInfo($projectId: UUID!) {
    newAssetsInfo(input: { projectId: $projectId }) {
      US_ENGLISH {
        all
        videos
        cutdowns
        images
        captions
        editingFiles
      }
      US_ENGLISH {
        all
        videos
        cutdowns
        images
        captions
        editingFiles
      }
      AU_ENGLISH {
        all
        videos
        cutdowns
        images
        captions
        editingFiles
      }
      UK_ENGLISH {
        all
        videos
        cutdowns
        images
        captions
        editingFiles
      }
      CA_ENGLISH {
        all
        videos
        cutdowns
        images
        captions
        editingFiles
      }
      IR_ENGLISH {
        all
        videos
        cutdowns
        images
        captions
        editingFiles
      }
      EU_SPANISH {
        all
        videos
        cutdowns
        images
        captions
        editingFiles
      }
      US_SPANISH {
        all
        videos
        cutdowns
        images
        captions
        editingFiles
      }
      IT_ITALIAN {
        all
        videos
        cutdowns
        images
        captions
        editingFiles
      }
      JP_JAPANESE {
        all
        videos
        cutdowns
        images
        captions
        editingFiles
      }
      BR_PORTUGUESE {
        all
        videos
        cutdowns
        images
        captions
        editingFiles
      }
      PO_PORTUGUESE {
        all
        videos
        cutdowns
        images
        captions
        editingFiles
      }
      HK_CHINESE {
        all
        videos
        cutdowns
        images
        captions
        editingFiles
      }
      CH_MANDARIN {
        all
        videos
        cutdowns
        images
        captions
        editingFiles
      }
    }
  }
`;

/**
 * __useNewAssetsInfoQuery__
 *
 * To run a query within a React component, call `useNewAssetsInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewAssetsInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewAssetsInfoQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useNewAssetsInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    NewAssetsInfoQuery,
    NewAssetsInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NewAssetsInfoQuery, NewAssetsInfoQueryVariables>(
    NewAssetsInfoDocument,
    options
  );
}
export function useNewAssetsInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NewAssetsInfoQuery,
    NewAssetsInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NewAssetsInfoQuery, NewAssetsInfoQueryVariables>(
    NewAssetsInfoDocument,
    options
  );
}
export type NewAssetsInfoQueryHookResult = ReturnType<
  typeof useNewAssetsInfoQuery
>;
export type NewAssetsInfoLazyQueryHookResult = ReturnType<
  typeof useNewAssetsInfoLazyQuery
>;
export type NewAssetsInfoQueryResult = Apollo.QueryResult<
  NewAssetsInfoQuery,
  NewAssetsInfoQueryVariables
>;
export const OnAssetsChangeDocument = gql`
  subscription OnAssetsChange(
    $projectId: UUID!
    $localization: Localizations!
  ) {
    subscribeAsset(projectId: $projectId, localization: $localization) {
      thumbnail
      path
      id
      projectId
      localization
      companyId
      name
      type
      hasThumbnail
      muxDownloadUrl
    }
  }
`;

/**
 * __useOnAssetsChangeSubscription__
 *
 * To run a query within a React component, call `useOnAssetsChangeSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnAssetsChangeSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnAssetsChangeSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      localization: // value for 'localization'
 *   },
 * });
 */
export function useOnAssetsChangeSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnAssetsChangeSubscription,
    OnAssetsChangeSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OnAssetsChangeSubscription,
    OnAssetsChangeSubscriptionVariables
  >(OnAssetsChangeDocument, options);
}
export type OnAssetsChangeSubscriptionHookResult = ReturnType<
  typeof useOnAssetsChangeSubscription
>;
export type OnAssetsChangeSubscriptionResult =
  Apollo.SubscriptionResult<OnAssetsChangeSubscription>;
