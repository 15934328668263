import React, { FormEvent } from 'react';
import { Col, Form, Row } from 'react-bootstrap';

import FormControl from 'components/atoms/FormControl/FormControl';
import FormFeetback from 'components/atoms/FormFeetback/FormFeetback';
import ActionModal, {
  ButtonsAlignmentName,
} from 'components/molecules/ActionModal/ActionModal';

import { useEditProjectMutation } from 'graph/generated.graphql';
import { useAppSelector } from 'redux/hooks';
import { selectRenameProjectInfo } from 'redux/modal/modalSlice';
import { FormErrorMessages, FormErrorTypes } from 'utils/constants/forms';
import { isProjectNameExistError } from 'utils/errors/isGqlError';
import {
  useFormState,
  useFormValidation,
} from 'utils/hooks/useFormValidation/useFormValidation';

import { IRenameProjectFormProps } from './RenameProjectForm.interface';

enum FormFields {
  ProjectName = 'projectName',
}

const FormFieldsList = [FormFields.ProjectName];

function RenameProjectForm({ onModalClose }: IRenameProjectFormProps) {
  const project = useAppSelector(selectRenameProjectInfo);
  const initialFormValue = {
    [FormFields.ProjectName]: project?.name || '',
  };
  const [formErrors, isFormValid, [setProjectNameError]] =
    useFormValidation(FormFieldsList);

  const [
    formState,
    [setProjectName],
    [formOnSubmit, formOnError, formOnSuccess],
  ] = useFormState(FormFieldsList, initialFormValue);

  const { isLoading, errorSlug } = formState;

  const [editMutation, { loading: isProjectCreateLoading }] =
    useEditProjectMutation({
      onCompleted() {
        formOnSuccess();
        onModalClose();
      },
      onError(error) {
        error.graphQLErrors.forEach((gqlError) => {
          if (isProjectNameExistError(gqlError)) {
            formOnError();
            return setProjectNameError({
              type: FormErrorTypes.Invalid,
              message: FormErrorMessages.PROJECT_NAME_NOT_UNIQUE,
            });
          }
          return formOnError(gqlError.message);
        });
      },
    });

  const validProjectName = (value: string) => {
    if (!value) {
      setProjectNameError({
        type: FormErrorTypes.Invalid,
        message: FormErrorMessages.PROJECT_NAME_BLANK,
      });
      return false;
    }
    if (value.length > 50) {
      setProjectNameError({
        type: FormErrorTypes.Invalid,
        message: FormErrorMessages.MAX_50_CHARACTERS,
      });
      return false;
    }
    setProjectNameError(null);
    return true;
  };

  const onProjectNameTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProjectName(e.target.value);
    setProjectNameError(null);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const { projectName } = formState;
    if (!validProjectName(projectName)) {
      return null;
    }

    formOnSubmit();
    editMutation({
      variables: { projectId: project?.id, projectName },
    });
    return null;
  };

  return (
    <Form
      className="w-100"
      noValidate
      validated={isFormValid}
      onSubmit={handleSubmit}
    >
      <Row className="gx-3">
        <Col>
          <Form.Group>
            <FormControl
              placeholder="Enter new project name"
              required
              value={formState.projectName}
              $errorType={formErrors[FormFields.ProjectName]?.type}
              onChange={onProjectNameTextChange}
            />
            <FormFeetback type={formErrors[FormFields.ProjectName]?.type}>
              {formErrors[FormFields.ProjectName]?.message}
            </FormFeetback>
          </Form.Group>
        </Col>
      </Row>
      <ActionModal
        primaryButtonProps={{
          text: 'Rename',
          isLoading: isLoading || isProjectCreateLoading,
          type: 'submit',
          disabled: !isFormValid,
        }}
        secondaryButtonProps={{ text: 'Cancel', onClick: onModalClose }}
        buttonsAlignment={ButtonsAlignmentName.RIGHT}
        error={errorSlug}
      />
    </Form>
  );
}

export default RenameProjectForm;
