import { Image } from 'react-bootstrap';
import { components, MultiValueGenericProps } from 'react-select';
import styled from 'styled-components';

import Flex from '../../atoms/Flex/Flex';
import { IOption } from '../../atoms/Select/Select';
import { ISelectProps } from '../../atoms/Select/Select.interface';
import Text from '../../atoms/Text/Text';

export const SelectCountriesWrapper = styled.div<ISelectProps>`
  .countries-select {
    &__group {
      padding: 0;
    }
    &__group-heading {
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      color: ${({ theme }) => theme.colors.grey800};
      text-transform: none;
      padding: 8px 24px;
    }
    &-container {
    }
    &__placeholder {
      font-weight: ${({ theme }) => theme.typography.weight.regular};
      color: ${({ theme }) => theme.colors.grey400};
    }
    &__control {
      transition: all 0ms;
      box-shadow: none;
      ${({ backgroundColor }) =>
        backgroundColor ? `background-color: ${backgroundColor};` : ''};
      ${({ theme, noBorder }) =>
        noBorder
          ? 'border: none;'
          : `border: 1px solid ${theme.colors.grey900};`};
      outline: none !important;
      ${({ round }) =>
        round ? 'border-radius: 100px;' : 'border-radius: 8px;'}
      &:hover {
        ${({ theme, noBorder }) =>
          noBorder
            ? 'border: none;'
            : `border: 1px solid ${theme.colors.grey900};`};
      }
      &--is-focused,
      &--is-focused:hover {
        ${({ theme, noBorder }) =>
          noBorder
            ? 'border: none;'
            : `border: 1px solid ${theme.colors.grey900};
            margin: -1px;`};
      }
    }
    &__value-container {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    &__indicator-separator {
      display: none;
    }
    &__option {
      cursor: pointer;
      &:active {
        background-color: ${({ theme }) => theme.colors.base};
      }
      &--is-selected,
      &--is-focused {
        background-color: ${({ theme }) => theme.colors.base};
        color: ${({ theme }) => theme.colors.grey900};
      }
      &--is-disabled {
        color: inherit;
      }
    }
    &__multi-value {
      background: ${({ theme }) => theme.colors.base};
      border-radius: 6px;
      border: 1px solid ${({ theme }) => theme.colors.grey300};
      margin: 2px 8px 2px 2px;
      &:hover {
        background: ${({ theme }) => theme.colors.base};
        border: 1px solid ${({ theme }) => theme.colors.grey300};
      }
      &__remove:hover {
        background-color: transparent;
        color: ${({ theme }) => theme.colors.grey900};
      }
      &__remove svg:hover {
        background-color: ${({ theme }) => theme.colors.grey100};
        border-radius: 3px;
      }
      &__remove svg {
        color: ${({ theme }) => theme.colors.grey400};
      }
      &__label {
        padding: 0;
        padding-left: 10px;
      }
    }
  }
`;

function MultiCountriesValueLabel({
  children,
  ...props
}: MultiValueGenericProps<IOption>) {
  const { data } = props;
  return (
    <components.MultiValueLabel {...props}>
      <Flex justifyContent="center" alignItems="center" pt={0.5} pb={0.5}>
        {data.icon && (
          <Flex mr={1.25} ml={-0.8}>
            <Image height={16} src={data.icon} />
          </Flex>
        )}
        <Text medium sm>
          {children}
        </Text>
      </Flex>
    </components.MultiValueLabel>
  );
}

export default MultiCountriesValueLabel;
