import React from 'react';
import { useTheme } from 'styled-components';

import Box from 'components/atoms/Box/Box';
import Flex from 'components/atoms/Flex/Flex';
import Icon from 'components/atoms/Icon/Icon';
import Text from 'components/atoms/Text/Text';
import { Modal } from 'components/molecules/Modal/Modal';

import { CompanyFragment } from 'graph/generated.graphql';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  selectAddProjectModal,
  selectIsAddLocalizationMode,
  selectIsEditProjectMode,
  selectProjectToEdit,
  setModal,
} from 'redux/modal/modalSlice';

import AddProjectForm from './components/AddProjectForm/AddProjectForm';
import EditProjectForm from './components/EditProjectForm/EditProjectForm';

type AddProjectModalProps = {
  refetch: () => void;
  companyId: CompanyFragment['id'];
};

function AddProjectModal({ refetch, companyId }: AddProjectModalProps) {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const show = useAppSelector(selectAddProjectModal);
  const isEditMode = useAppSelector(selectIsEditProjectMode);
  const projectToEdit = useAppSelector(selectProjectToEdit);
  const isAddLocalizationMode = useAppSelector(selectIsAddLocalizationMode);
  const onCloseModal = () =>
    dispatch(
      setModal({
        showAddProjectModal: false,
        projectToEdit: undefined,
        isEditProjectMode: false,
        localizationToAdd: undefined,
        isAddLocalizationMode: false,
      })
    );

  return (
    <Modal show={show} onHide={onCloseModal} width={480}>
      <Flex fullWidth justifyContent="left" mb={4}>
        <Box mr={2}>
          <Icon color={theme.colors.primary600} size={28} name="folderIcons" />
        </Box>
        <Text lg bold>
          {isAddLocalizationMode ? 'Add Localisation' : 'Add Project'}
        </Text>
      </Flex>
      {isEditMode ? (
        <EditProjectForm
          isAddLocalizationMode={isAddLocalizationMode}
          companyId={companyId}
          refetch={refetch}
          onModalClose={onCloseModal}
          projectToEdit={projectToEdit}
        />
      ) : (
        <AddProjectForm
          companyId={companyId}
          refetch={refetch}
          onModalClose={onCloseModal}
        />
      )}
    </Modal>
  );
}

export default AddProjectModal;
