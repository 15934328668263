import React, { useState } from 'react';
import { Image, OverlayTrigger, Tooltip } from 'react-bootstrap';
import styled, { useTheme } from 'styled-components';

import { secondsToTime } from 'utils/transformers/momentFormats';
import {
  getExtension,
  getFileNameWithoutExtension,
} from 'utils/transformers/text';

import { IAssetCardProps } from './AssetCard.interface';

import { getAssetPreviewImage } from '../../../utils/assets/getAssetPreviewImage';
import Box from '../Box/Box';
import Flex from '../Flex/Flex';
import Icon from '../Icon/Icon';
import IconButton from '../IconButton/IconButton';
import ProgressBar from '../ProgressBar/ProgressBar';
import Text, { ellipsisStyle } from '../Text/Text';

const Wrapper = styled(Box)``;

const FileNameText = styled(Text)`
  ${ellipsisStyle}
  user-select: none;
`;

const AssetCardWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.grey100};
  position: relative;
`;
const ProgressBarWrapper = styled(Box)`
  position: absolute;
  bottom: -8px;
  left: 0;
  right: 0;
`;
const AspectRatioBox = styled(Box)`
  line-height: 12px;
  font-size: 10px;
  border: 1px solid ${({ theme }) => theme.colors.grey400};
`;
const SpanStyled = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
const Dot = styled(Box)`
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.success500};
  flex-shrink: 0;
`;
const ImageStyled = styled(Image)`
  height: 168px;
  width: 100%;
  object-fit: contain;
  user-select: none;
`;
function AssetCard({
  asset,
  progress,
  isResentUpload,
  errorMessage,
  textIcon,
  thumbnailIcon,
  onMouseEnter,
  onStarButtonClick,
  isStarred,
  onDoubleClick,
}: IAssetCardProps) {
  const theme = useTheme();
  const [isImageError, setIsImageError] = useState(false);
  const fileName = getFileNameWithoutExtension(asset.name || '');
  const formattedDuration = asset.duration
    ? ` · ${secondsToTime(asset.duration)} · `
    : '';
  const fileExtension = getExtension(asset.name || '');
  const assetPath = getAssetPreviewImage(asset);
  return (
    <Wrapper onMouseEnter={onMouseEnter} onDoubleClick={onDoubleClick}>
      <AssetCardWrapper
        h={168}
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        {assetPath && !isImageError ? (
          <Box h={168}>
            <ImageStyled
              src={assetPath}
              onError={() => setIsImageError(true)}
            />
          </Box>
        ) : (
          <Icon size={48} name={thumbnailIcon} color={theme.colors.grey300} />
        )}
        {progress && progress < 1 ? (
          <ProgressBarWrapper>
            <ProgressBar fullWidth progress={progress} />
          </ProgressBarWrapper>
        ) : null}
      </AssetCardWrapper>
      <Box px={3} py={2}>
        <FileNameText as="span" sm medium color={theme.colors.grey800}>
          <Flex alignItems="center">
            {isResentUpload && <Dot mr={1} w={6} h={6} />}
            <OverlayTrigger
              key={fileName}
              overlay={<Tooltip>{fileName}</Tooltip>}
            >
              <SpanStyled>{fileName}</SpanStyled>
            </OverlayTrigger>
          </Flex>
        </FileNameText>
        {errorMessage ? (
          <Text xs medium color={theme.colors.error500} userSelect="none">
            {errorMessage}
          </Text>
        ) : (
          <Text
            as="span"
            xs
            medium
            color={theme.colors.grey400}
            opacity={0.9}
            userSelect="none"
          >
            {progress && progress < 1 ? (
              <>
                <Icon mr={2} name="autorenew" />
                Uploading...
              </>
            ) : (
              <Flex justifyContent="space-between">
                <Flex alignItems="center">
                  {textIcon && <Icon size={16} mr={2} name={textIcon} />}
                  {fileExtension}
                  {formattedDuration}
                  {asset.aspectRatio ? (
                    <AspectRatioBox ml={1} p={0.25}>
                      {asset.aspectRatio}
                    </AspectRatioBox>
                  ) : (
                    ''
                  )}
                </Flex>
                {onStarButtonClick && (
                  <IconButton
                    iconColor={
                      isStarred ? theme.colors.grey400 : theme.colors.base
                    }
                    iconSize={24}
                    buttonSize={24}
                    iconName={isStarred ? 'starFilled' : 'star'}
                    hoverIconName="starFilled"
                    onMouseDownCapture={(e) => e.stopPropagation()}
                    onClick={onStarButtonClick}
                  />
                )}
              </Flex>
            )}
          </Text>
        )}
      </Box>
    </Wrapper>
  );
}

export default AssetCard;
