import axios, { AxiosResponse, CancelTokenSource } from 'axios';

import { IconKeys } from 'components/atoms/Icon/Icon.interfaces.generated';

import { FileType } from 'graph/generated.graphql';

export const getIconNameByFileType = (
  assetType: FileType | undefined | null
): IconKeys => {
  switch (assetType) {
    case FileType.Video:
      return 'videoFileFilled';
    case FileType.Cutdown:
      return 'cutdownsFilesFilled';
    case FileType.Picture:
      return 'imageFile';
    case FileType.Caption:
      return 'captionsFileFilled';
    case FileType.Editing:
      return 'editingFileFilled';
    default:
      return 'imageFile';
  }
};
export const getThumbnailIconByFileType = (
  assetType: FileType | undefined | null
): IconKeys => {
  switch (assetType) {
    case FileType.Video:
    case FileType.Cutdown:
      return 'videosMode';
    case FileType.Picture:
      return 'imagesmode';
    case FileType.Caption:
      return 'captionsMode';
    case FileType.Editing:
      return 'othersFile';
    default:
      return 'imagesmode';
  }
};

export function download(blob: Blob | MediaSource, name: string) {
  if (!blob) return;
  const a = document.createElement('a');
  const objectURL = URL.createObjectURL(blob);
  a.href = objectURL;
  a.download = name;
  document.body.appendChild(a);
  a.click();
  URL.revokeObjectURL(objectURL);
  document.body.removeChild(a);
}

export async function downloadFileConcurrently(
  url: string,
  setProgress: (index: number, progress: number) => void,
  indexProp: number,
  cancelToken: CancelTokenSource,
  chunkSizeMb = 5
): Promise<Blob> {
  let response: AxiosResponse;
  try {
    //  fetching 'content-length' without body to calculate number of chunks
    //  if HEAD fail (MUX can block it) download will compleat without dividing to chunks
    response = await axios.head(url);
  } catch {
    // backup download method
    const res = await downloadWithProgress(
      url,
      setProgress,
      indexProp,
      cancelToken
    );
    return res;
  }
  const contentLength: number = Number(response.headers['content-length']);
  let chunkSize: number = 1024 * 1024 * chunkSizeMb;
  let numChunks: number = Math.ceil(contentLength / chunkSize);
  while (numChunks > 25) {
    chunkSize *= 2;
    numChunks = Math.ceil(contentLength / chunkSize)
  }
  
  let progress = 0;

  const chunkRequests: Promise<Blob>[] = Array.from({ length: numChunks }).map(
    (_, index) => {
      const startByte = index * chunkSize;
      const endByte = Math.min(startByte + chunkSize - 1, contentLength - 1);
      return axios
        .get(url, {
          headers: {
            Range: `bytes=${startByte}-${endByte}`,
          },
          responseType: 'blob',
          cancelToken: cancelToken.token,
        })
        .then((responseData: AxiosResponse<Blob>) => {
          progress += 1;
          setProgress(indexProp, (progress / numChunks) * 100);
          return responseData.data;
        });
    }
  );

  const downloadedChunks: Blob[] = await Promise.all(chunkRequests);
  const concatenatedBlob: Blob = new Blob(downloadedChunks);

  return concatenatedBlob;
}

export async function downloadWithProgress(
  url: string,
  setProgress: (index: number, progress: number) => void,
  index: number,
  cancelToken: CancelTokenSource
): Promise<Blob> {
  const { data } = await axios({
    url,
    method: 'GET',
    responseType: 'blob',
    onDownloadProgress: (progressEvent) => {
      if (progressEvent.total) {
        const percentage = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setProgress(index, percentage);
      }
    },
    cancelToken: cancelToken.token,
  });
  return data;
}
