import { Slider, Input, SliderCol, ToggleLabel } from './Toggler-style';

import Icon from '../Icon/Icon';
import { IconKeys } from '../Icon/Icon.interfaces.generated';


interface TogglerModel {
  leftIconSize?: number,
  leftIcon?: IconKeys,
  leftText?: string,
  rightIconSize?: number,
  rightIcon?: IconKeys,
  rightText?: string
  sliderWidth: number
  sliderColumnWidth: number,
  checked: boolean,
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

function Toggler({
                   leftIconSize,
                   leftIcon,
                   leftText,
                   rightIconSize,
                   rightIcon,
                   rightText,
                   sliderWidth,
                   sliderColumnWidth,
                   checked,
                   onChange
                 }: TogglerModel) {
  return (
    <fieldset>
      <ToggleLabel as='label' htmlFor='toggle' className='slider'>
        <Input id='toggle' checked={checked} onChange={onChange} />
        <Slider w={sliderWidth}>
          <SliderCol w={sliderColumnWidth}>
            {leftIcon && <Icon w={leftIconSize} name={leftIcon} />}
            {leftText}
          </SliderCol>
          <SliderCol w={sliderColumnWidth}>
            {rightIcon && <Icon w={rightIconSize} name={rightIcon} />}
            {rightText}
          </SliderCol>
        </Slider>
      </ToggleLabel>
    </fieldset>
  );
}

export default Toggler;


