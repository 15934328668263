import React from 'react';
import { Dropdown } from 'react-bootstrap';
import styled from 'styled-components';

import Flex from 'components/atoms/Flex/Flex';
import Icon from 'components/atoms/Icon/Icon';
import IconButton from 'components/atoms/IconButton/IconButton';
import Text from 'components/atoms/Text/Text';

import { CustomToggleProps } from 'containers/Layout/components/Header/components/ProfileMenu/ProfileMenu';

import { IDotsDropDownMenuProps } from './DotsDropDownMenu.interface';

const IconButtonStyled = styled(IconButton)`
  border-radius: 6px;
  &:hover {
    background-color: ${({ theme }) => theme.colors.grey100};
  }
`;

const IconButtonToggle = React.forwardRef(
  (props: CustomToggleProps, ref: React.Ref<HTMLButtonElement>) => (
    <IconButtonStyled
      ref={ref}
      iconSize={20}
      iconName="dotsHorizontal"
      buttonSize={24}
      onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        props.onClick(e);
      }}
    />
  )
);
export function DotsDropDownMenu({ menuItems }: IDotsDropDownMenuProps) {
  return (
    <Dropdown className="position-static">
      <Dropdown.Toggle as={IconButtonToggle} />
      <Dropdown.Menu>
        {menuItems?.map(({ icon, label, onClick, key, href }) => (
          <Dropdown.Item href={href} key={key} eventKey={key} onClick={onClick}>
            <Flex alignItems="center" gap={12}>
              {icon && <Icon size={24} name={icon} />}
              <Text sm medium>
                {label}
              </Text>
            </Flex>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}
