import React from 'react';
import styled, { useTheme } from 'styled-components';

import { INoSuchAssetTypeCardProps } from './NoSuchAssetTypeCard.interface';

import Box from '../Box/Box';
import Flex from '../Flex/Flex';
import Icon from '../Icon/Icon';
import Text from '../Text/Text';

const Wrapper = styled(Flex)`
  background-color: rgba(255, 194, 123, 0.12);
  min-width: 300px;
  max-width: 710px;
`;
const TextStyled = styled(Text)`
  line-height: 20px;
`;
function NoSuchAssetTypeCard({
  icon,
  title,
  text,
  secondText,
}: INoSuchAssetTypeCardProps) {
  const theme = useTheme();
  return (
    <Wrapper alignItems="center" flexDirection="column" px={16} py={6} m={8}>
      <Icon mb={5} name={icon} size={48} color={theme.colors.primary600} />
      <Box mb={5}>
        <TextStyled textAlign="center" xl bold>
          {title}
        </TextStyled>
      </Box>
      <Box mb={5}>
        <TextStyled textAlign="center" md regular>
          {text}
        </TextStyled>
      </Box>
      {secondText && (
        <TextStyled textAlign="center" md regular>
          {secondText}
        </TextStyled>
      )}
    </Wrapper>
  );
}

export default NoSuchAssetTypeCard;
