import styled from 'styled-components';

import { boxStyle } from 'components/atoms/Box/Box';
import icons from 'components/atoms/Icon/Icon.generated';
import type {
  IIconProps,
  ISvgSpecType,
} from 'components/atoms/Icon/Icon.interfaces';

const Svg = styled.svg<{
  rotateOnHover?: boolean;
}>`
  ${boxStyle}
  ${({ rotateOnHover }) =>
    rotateOnHover
      ? `&:hover {
    transform: rotate(-180deg);
  }`
      : null}
`;

function Icon({ name, size = 14, color, ...args }: IIconProps) {
  const icon: ISvgSpecType = icons[name];
  return icon ? (
    <Svg
      {...args}
      color={color}
      viewBox={icon.viewBox}
      width={size}
      height={size}
      fill="none"
    >
      <path d={icon.path} {...icon.properties} />
    </Svg>
  ) : null;
}
export default Icon;
