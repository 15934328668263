import React, { FormEvent } from 'react';
import { Form } from 'react-bootstrap';
import { useTheme } from 'styled-components';

import Box from 'components/atoms/Box/Box';
import Button from 'components/atoms/Button/Button';
import FormFeetback from 'components/atoms/FormFeetback/FormFeetback';
import InputPassword from 'components/atoms/InputPassword/InputPassword';
import Text from 'components/atoms/Text/Text';
import PasswordStrengthMeter from 'components/molecules/PasswordStrengthMeter/PasswordStrengthMeter';

import { ISetNewPasswordFormProps } from 'containers/Authentification/ResetPassword/components/SetNewPasswordForm/SetNewPasswordForm.interface';
import {
  AccountTokenPayloadFragment,
  useSetNewPasswordMutation,
} from 'graph/generated.graphql';
import { getRedirectUrlV3 } from 'utils/authentication/getRedirectUrl/getRedirectUrl';
import { FormErrorMessages, FormErrorTypes } from 'utils/constants/forms';
import {
  useFormState,
  useFormValidation,
} from 'utils/hooks/useFormValidation/useFormValidation';
import {
  checkPasswordStrongness,
  checkPasswordToWhiteSpace,
} from 'utils/validators/validators';

enum FormFields {
  Password = 'password',
}

const FormFieldsList = [FormFields.Password];
const passwordMessageMap = {
  [FormErrorTypes.Blank]: FormErrorMessages.PASSWORD_BLANK,
  [FormErrorTypes.Warning]: FormErrorMessages.PASSWORD_WARNING,
  [FormErrorTypes.Invalid]: FormErrorMessages.PASSWORD_INVALID,
  [FormErrorTypes.Valid]: FormErrorMessages.PASSWORD_VALID,
};
function SetNewPasswordForm({ token, isDisable }: ISetNewPasswordFormProps) {
  const theme = useTheme();

  const [setNewPassword, { loading: isCompleatRegisterLoading }] =
    useSetNewPasswordMutation({
      onError(error) {
        formOnError(error.message);
      },
      onCompleted({ setPassword: setPasswordData }) {
        onSuccess(setPasswordData);
      },
    });

  const [formErrors, isFormValid, [setPasswordError]] =
    useFormValidation(FormFieldsList);

  const [formState, [setPassword], [formOnSubmit, formOnError, formOnSuccess]] =
    useFormState(FormFieldsList);

  const { isLoading, errorSlug } = formState;

  const validPassword = (value: string) => {
    if (checkPasswordToWhiteSpace(value)) {
      setPasswordError({
        type: FormErrorTypes.Invalid,
        message: FormErrorMessages.SPACES_IN_PASSWORD,
      });
      return false;
    }
    const type = checkPasswordStrongness(value);
    setPasswordError({
      type,
      message: passwordMessageMap[type],
    });
    return type === FormErrorTypes.Valid || type === FormErrorTypes.Warning;
  };

  const onPasswordTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    validPassword(e.target.value);
  };

  const onSuccess = (data: AccountTokenPayloadFragment) => {
    const { role, subdomainName } = data;

    formOnSuccess();
    const domainWithSubDomain = getRedirectUrlV3(role, subdomainName);
    window.location.assign(domainWithSubDomain);
  };

  const { password } = formState;
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    if (!validPassword(password)) {
      return null;
    }
    formOnSubmit();

    setNewPassword({
      variables: {
        key: token,
        password,
        resetPassword: true,
      },
    });
    return null;
  };
  return (
    <Form
      className="w-100"
      noValidate
      validated={isFormValid}
      onSubmit={handleSubmit}
    >
      <Form.Group className="mb-1" controlId="formBasicPassword">
        <Form.Label>
          <Text color={theme.colors.grey900} sm semibold>
            New Password *
          </Text>
        </Form.Label>
        <InputPassword
          $errorType={formErrors[FormFields.Password]?.type}
          placeholder="Enter password"
          onChange={onPasswordTextChange}
          disabled={isDisable}
        />
        <FormFeetback type={formErrors[FormFields.Password]?.type}>
          {formErrors[FormFields.Password]?.message}
        </FormFeetback>
      </Form.Group>
      <PasswordStrengthMeter password={password} mb={7} />
      <Box mb={4}>
        <Button
          fullWidth
          round
          lg
          semibold
          py={4}
          type="submit"
          disabled={!isFormValid || isDisable}
          isLoading={isLoading || isCompleatRegisterLoading}
        >
          Change password
        </Button>
      </Box>
      <Form.Group>
        <FormFeetback type={FormErrorTypes.Invalid}>{errorSlug}</FormFeetback>
      </Form.Group>
    </Form>
  );
}

export default SetNewPasswordForm;
