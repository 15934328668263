import React from 'react';
import styled from 'styled-components';

import Flex from 'components/atoms/Flex/Flex';

import ResetPassword from 'containers/Authentification/ResetPassword/ResetPassword';
import Footer from 'containers/Layout/components/Footer/Footer';

const MainStyled = styled.main`
  min-height: calc(100vh - 84px);
`;

function ResetPasswordPage() {
  return (
    <>
      <MainStyled>
        <Flex
          h="calc(100vh - 84px)"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <ResetPassword />
        </Flex>
      </MainStyled>
      <Footer />
    </>
  );
}

export default ResetPasswordPage;
