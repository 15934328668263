import React from 'react';
import { useTheme } from 'styled-components';

import Flex from 'components/atoms/Flex/Flex';
import Icon from 'components/atoms/Icon/Icon';
import Text from 'components/atoms/Text/Text';
import { Modal } from 'components/molecules/Modal/Modal';

import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectRenameProjectModal, setModal } from 'redux/modal/modalSlice';

import RenameProjectForm from './components/RenameProjectForm';

function RenameProjectModal() {
  const theme = useTheme();
  const show = useAppSelector(selectRenameProjectModal);
  const dispatch = useAppDispatch();
  const onCloseModal = () => {
    dispatch(
      setModal({
        showRenameProjectModal: false,
        renameProjectInfo: undefined,
      })
    );
  };

  return (
    <Modal show={show} onHide={onCloseModal} width={400}>
      <Flex fullWidth justifyContent="left" mb={5}>
        <Icon
          color={theme.colors.primary600}
          size={28}
          name="driveFileRenameOutline"
          mr={2}
        />

        <Text lg bold>
          Rename Project
        </Text>
      </Flex>
      <RenameProjectForm onModalClose={onCloseModal} />
    </Modal>
  );
}

export default RenameProjectModal;
