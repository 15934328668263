import styled from 'styled-components';

 export const ToggleLabel = styled.label`
  position: relative;
  display: inline-block;
  height: 20px;

  &.slider {
    display: flex;
    width: auto;
    height: 44px;
  }
`;

export const Slider = styled.div<{w: number}>`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.grey100};
  transition: .15s;
  border-radius: 12px;

  &::before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: ${({ theme }) => theme.colors.base};
    box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1), 0 1px 2px rgba(16, 24, 40, 0.06);
    transition: .15s;
    border-radius: 50%;
  }

  ${ToggleLabel}.slider & {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;

    &::before {
      width: ${props => `${props.w}px` || 'auto'};
      height: 36px;
      left: 4px;
      bottom: 4px;
      border-radius: 8px;
    }
  }
`;

export const SliderCol = styled.div<{w: number}>`
  z-index: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${props => `${props.w}px` || 'auto'};
  height: 36px;
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.grey900};
  user-select: none;
  transition: .15s;

  svg {
    margin-left: -2px;
    margin-right: 4px;
  }

  &:last-child {
    color: ${({ theme }) => theme.colors.grey400};
    svg {
      path {
        stroke: ${({ theme }) => theme.colors.grey400}
      }
    }
  }

  ${ToggleLabel}.slider &.active {
    color: ${({ theme }) => theme.colors.grey900};
  }
`;

export const Input = styled.input.attrs({ type: 'checkbox' })`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + ${Slider} {
    background-color: ${({ theme }) => theme.colors.primary700};

    &::before {
      transform: translateX(16px);
    }
  }

  ${ToggleLabel}.slider & {
    &:checked + ${Slider} {
      background-color: ${({ theme }) => theme.colors.grey100};

      &::before {
        transform: translateX(100%);
      }
    }

    &:checked + ${Slider} ${SliderCol} {
      color: ${({ theme }) => theme.colors.grey400};
      svg {
        path {
          stroke: ${({ theme }) => theme.colors.grey400}
        }
      }
    }

    &:checked + ${Slider} ${SliderCol}:last-child {
      color: ${({ theme }) => theme.colors.grey900};
      svg {
        path {
          stroke: ${({ theme }) => theme.colors.grey900}
        }
      }
    }
  }
`;

