import React, { useState } from 'react';
import { useTheme } from 'styled-components';

import Box from 'components/atoms/Box/Box';
import Flex from 'components/atoms/Flex/Flex';
import Text from 'components/atoms/Text/Text';
import ActionModal, {
  ButtonsAlignmentName,
} from 'components/molecules/ActionModal/ActionModal';
import { Modal } from 'components/molecules/Modal/Modal';

import { useUserDeleteMutation } from 'graph/generated.graphql';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  selectDeleteMemberInfo,
  selectDeleteMemberModal,
  setModal,
} from 'redux/modal/modalSlice';
import { FormErrorMessages } from 'utils/constants/forms';
import { isOwnerTryDeleteHisAccountError } from 'utils/errors/isGqlError';

function DeleteMemberModal() {
  const [errorMessage, setErrorMessage] = useState('');
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const show = useAppSelector(selectDeleteMemberModal);
  const info = useAppSelector(selectDeleteMemberInfo);
  const onCloseModal = () => {
    dispatch(setModal({ showDeleteMemberModal: false }));
    setErrorMessage('');
  };

  const [deleteMember, { loading: isDeleteMemberLoading }] =
    useUserDeleteMutation({
      onCompleted() {
        onCloseModal();
      },
      onError(error) {
        error.graphQLErrors.forEach((gqlError) => {
          if (isOwnerTryDeleteHisAccountError(gqlError)) {
            return setErrorMessage(
              FormErrorMessages.OWNER_TRY_DELETE_HIS_ACCOUNT
            );
          }
          return setErrorMessage(gqlError.message);
        });
      },
    });
  const handleButtonClick = () => {
    deleteMember({
      variables: info?.info,
      refetchQueries: ['getUsersByCompanyId'],
    });
  };
  return (
    <Modal show={show} onHide={onCloseModal} width={480}>
      <Flex fullWidth justifyContent="left" flexDirection="column" mb={5}>
        <Box mb={5}>
          <Text lg bold>
            Delete {info?.userName}
          </Text>
        </Box>
        <Text sm medium color={theme.colors.grey600}>
          <b>Caution: </b>This will revoke the user’s access to the workspace.
        </Text>
      </Flex>

      <ActionModal
        primaryButtonProps={{
          text: 'Delete User',
          type: 'button',
          isLoading: isDeleteMemberLoading,
          onClick: handleButtonClick,
        }}
        secondaryButtonProps={{ text: 'Cancel', onClick: onCloseModal }}
        buttonsAlignment={ButtonsAlignmentName.RIGHT}
        error={errorMessage}
      />
    </Modal>
  );
}

export default DeleteMemberModal;
