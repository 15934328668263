import React from 'react';
import { useTheme } from 'styled-components';

import Box from 'components/atoms/Box/Box';
import Text from 'components/atoms/Text/Text';

import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  selectShowDownloadCancelModal,
  setModal,
} from 'redux/modal/modalSlice';

import { IDownloadModalProps } from './DownloadModal.interface';

import ActionModal, { ButtonsAlignmentName } from '../ActionModal/ActionModal';
import { Modal } from '../Modal/Modal';

function DownloadModal({ cancelDownload }: IDownloadModalProps) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const show = useAppSelector(selectShowDownloadCancelModal);
  const onCloseModal = () =>
    dispatch(
      setModal({
        showDownloadCancelModal: false,
      })
    );

  return (
    <Modal show={show} onHide={onCloseModal} width={446}>
      <Box fullWidth mb={4}>
        <Text lg bold color={theme.colors.grey900}>
          Cancel Download?
        </Text>
      </Box>
      <Box fullWidth mb={4}>
        <Text sm medium color={theme.colors.grey600}>
          Your download is not complete. Would you like to cancel the download?
        </Text>
      </Box>
      <ActionModal
        primaryButtonProps={{
          text: 'Continue Download',
          type: 'button',
          onClick: onCloseModal,
        }}
        secondaryButtonProps={{
          text: 'Cancel Download',
          onClick: cancelDownload,
        }}
        buttonsAlignment={ButtonsAlignmentName.CENTER}
      />
    </Modal>
  );
}

export default DownloadModal;
