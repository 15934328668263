import styled, { css } from 'styled-components';

import type { IHeaderProps } from 'components/atoms/H/H.interfaces';
import { getFontWeight, getSize } from 'components/atoms/Text/Text';
import type { ITextStyle } from 'components/atoms/Text/Text.interfaces';

const textStyle = css<ITextStyle>`
  font-family: 'Inter';
  font-style: normal;
  margin-block-start: unset;
  margin-block-end: unset;
  font-weight: ${({ theme, bold, semibold, medium, regular = true }) =>
    getFontWeight(theme, {
      bold,
      semibold,
      medium,
      regular,
    })};
  font-size: ${({ theme, xs, sm, md, lg, xl, doubleXl }) =>
    getSize(theme, { xs, sm, md, lg, xl, doubleXl }, 'fontSize', 'header')}px;
  line-height: ${({ theme, xs, sm, md, lg, xl, doubleXl }) =>
    getSize(theme, { xs, sm, md, lg, xl, doubleXl }, 'lineHeight', 'header')}px;
`;

const h1 = styled.h1`
  ${textStyle}
`;
const h2 = styled.h2`
  ${textStyle}
`;
const h3 = styled.h3`
  ${textStyle}
`;
const h4 = styled.h4`
  ${textStyle}
`;
const h5 = styled.h5`
  ${textStyle}
`;
const h6 = styled.h6`
  ${textStyle}
`;

function H({ children, as = 1, doubleXl, ...args }: IHeaderProps) {
  const HComponent = [h1, h2, h3, h4, h5, h6][as - 1] || h1;
  return (
    <HComponent doubleXl={doubleXl} {...args}>
      {children}
    </HComponent>
  );
}
export default H;
