import { gql } from '@apollo/client';

import { ERROR_FRAGMENT } from 'graph/common/fragments';
import { USER_FRAGMENT } from 'graph/users/fragments';

const ASSET_FRAGMENT = gql`
  fragment Asset on Asset {
    localization
    companyId
    projectId
    name
    projectName
    thumbnail
    path
    id
    muxId
    uploadId
    playbackId
    createdBy
    createdAt
    lastModifiedBy
    lastModifiedAt
    size
    type
    extension
    dealeted
    dealetedBy
    dealetedAt
    duration
    maxResolution
    aspectRatio
    frameRate
    colorSpace
    audio
    audioChannels
    codec
    maxHeight
    maxWidth
    dotPerInch
    bitRate
    muxStreamUrl
    expireStreamDate
    muxDownloadUrl    
    muxUrlCreatedAt
    project {
      id
      name
    }
    mp4Support
    s3DownloadUrl
    s3UrlCreatedAt
    status
    originalCreatedAt
    originalUpdatedAt
    isNew
  }
`;

const PROJECT_BASE_FRAGMENT = gql`
  fragment ProjectBase on Project {
    id
    name
    companyId
    createdAt
    createdBy
    lastModifiedAt
    lastModifiedBy
    assetsCount
    assetsSize
    thumbnail
    existingLocalizations
    pendingLocalizations
    status
  }
`;
const PROJECT_FRAGMENT = gql`
  fragment Project on Project {
    id
    name
    companyId
    createdAt
    createdBy
    lastModifiedAt
    lastModifiedBy
    assetsCount
    assetsSize
    thumbnail
    existingLocalizations
    pendingLocalizations
    status
    assets {
      ...Asset
    }
  }
  ${ASSET_FRAGMENT}
`;

const COMPANY_FRAGMENT = gql`
  fragment Company on Company {
    id
    logo
    name
    teamMembersCount
    subdomainName
    domainNames
    projectsCount
    createdAt
    lastModifiedAt
    lastSessionAt
    projects {
      ...Project
    }
    teamMembers {
      ...User
    }
    totalAssetsSize
    managerId,
    currency
  }
  ${PROJECT_FRAGMENT}
  ${USER_FRAGMENT}
`;
const COMPANY_BASE_FRAGMENT = gql`
  fragment CompanyBase on Company {
    id
    logo
    name
    teamMembersCount
    subdomainName
    domainNames
    projectsCount
    createdAt
    lastModifiedAt
    lastSessionAt
    totalAssetsSize
    managerId
    currency
  }
`;

const COMPANY_BASE_PAYLOAD_FRAGMENT = gql`
  fragment CompanyPayloadBase on CompanyPayloadBase {
    company {
      ...CompanyBase
    }
    errors {
      ...Error
    }
  }
  ${COMPANY_BASE_FRAGMENT}
  ${ERROR_FRAGMENT}
`;
const S3_URLS_FRAGMENT = gql`
  fragment S3UrlResponse on S3UrlResponse {
    url
    fileName
    oldFileName
    assetId
    type
  }
`;

export {
  COMPANY_BASE_PAYLOAD_FRAGMENT,
  COMPANY_FRAGMENT,
  COMPANY_BASE_FRAGMENT,
  S3_URLS_FRAGMENT,
  PROJECT_BASE_FRAGMENT,
  PROJECT_FRAGMENT,
  ASSET_FRAGMENT,
};
