import React, { useCallback, useMemo, useState } from 'react';
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { Column } from 'react-table';
import styled, { useTheme } from 'styled-components';

import Avatar from 'components/atoms/Avatar/Avatar';
import Box from 'components/atoms/Box/Box';
import Button from 'components/atoms/Button/Button';
import Flex from 'components/atoms/Flex/Flex';
import Icon from 'components/atoms/Icon/Icon';
import IconButton from 'components/atoms/IconButton/IconButton';
import Text from 'components/atoms/Text/Text';
import PermissionSelection from 'components/molecules/PermissionSelection/PermissionSelection';
import Table, {
  OnTableStateChangedType,
} from 'components/molecules/Table/Table';

import {
  GetUsersByCompanyIdQueryVariables,
  Roles,
  SortEnumType,
  useChangeRoleMutation,
  useGetUsersByCompanyIdQuery,
  UserStatus,
} from 'graph/generated.graphql';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { setModal } from 'redux/modal/modalSlice';
import { selectViewer } from 'redux/viewer/viewerSlice';
import { defaultDateFormat } from 'utils/transformers/momentFormats';
import { capitalized, getUserRolesName } from 'utils/transformers/text';

import InvitationSender from '../../../../components/molecules/InvitationSender/InvitationSender';
import { mapUsersInviteState } from '../../../../components/molecules/InvitationSender/InvitationSender.helper';
import { UserInvitationStatus } from '../../../../components/molecules/InvitationSender/InvitationSender.interface';
import { PENDING_STATUS } from '../../../../utils/constants/common';

const TableWrapper = styled(Box)`
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.colors.grey200};
  box-shadow: ${({ theme }) => theme.shadow.smShadow};
  & tbody {
    display: block;
    height: 358px;
    overflow: auto;
    width: 100%;
  }

  & thead,
  tr {
    display: table;
    table-layout: fixed;
    width: 100%;
  }
  & table {
    width: 100%;
  }
  & th:first-child,
  td:first-child {
    width: 300px;
  }
  & th:nth-child(5),
  td:nth-child(5) {
    width: 105px;
  }
`;

function TeamManagementList() {
  const theme = useTheme();
  const [errorMessage, setErrorMessage] = useState('');
  const user = useAppSelector(selectViewer);
  const companyId = user?.companyId;
  const dispatch = useAppDispatch();
  const isWorkspaceOwnerOrAdmin = user?.roles === Roles.Owner || user?.roles === Roles.Admin;

  const {
    data: teamData,
    loading: isUserLoading,
    previousData: prevUserListData,
    refetch: usersListRefetch,
  } = useGetUsersByCompanyIdQuery({
    variables: {
      companyId,
      where: {
        status: { neq: UserStatus.Deleted },
        companyId: { eq: companyId },
      },
    },
    fetchPolicy: 'no-cache'
  });
  const onTableStateChanged: OnTableStateChangedType = useCallback(
    ({ sortBy }) => {
      const variables: GetUsersByCompanyIdQueryVariables = {
        companyId,
        order: sortBy.map((item) => ({
          [item.id === 'roles' ? 'role' : item.id]: item.desc
            ? SortEnumType.Desc
            : SortEnumType.Asc,
        })),
        where: {
          status: { neq: UserStatus.Deleted },
          companyId: { eq: companyId },
        },
      };
      usersListRefetch(variables);
    },
    // eslint-disable-next-line
    [usersListRefetch]
  );
  const team = teamData?.usersList || [];

  const previousUserList = prevUserListData?.usersList || [];

  const currentUserList = isUserLoading ? previousUserList : team;

  const [changeRole] = useChangeRoleMutation({
    onError(error) {
      setErrorMessage(error.message);
    },
    onCompleted() {
      setErrorMessage('');
    },
  });

  const [invitationStatus, setInvitationStatus] = useState(mapUsersInviteState(currentUserList));

  const columns = useMemo(
    (): Array<Column & { sort?: boolean }> => [
      {
        Header: 'Name',
        accessor: 'firstName',
        maxWidth: 400,

        Cell: ({ row }: any) => (
          <Flex alignItems="center">
            <Box mr={3}>
              <Avatar
                md
                src={row.original.avatar || ''}
                text={`${row.original.firstName} ${row.original.lastName}`}
              />
            </Box>
            <Flex flexDirection="column">
              <OverlayTrigger
                key={row.original.firstName}
                overlay={
                  <Tooltip
                    id={row.original.firstName}
                  >{`${row.original.firstName} ${row.original.lastName}`}</Tooltip>
                }
              >
                <Box w={205}>
                  <Text ellipsis sm medium color={theme.colors.grey700}>
                    {`${row.original.firstName} ${row.original.lastName}`}
                  </Text>
                </Box>
              </OverlayTrigger>
              <OverlayTrigger
                key={`-${row.original.email}`}
                overlay={
                  <Tooltip id={`-${row.original.email}`}>
                    {row.original.email}
                  </Tooltip>
                }
              >
                <Box>
                  <Text ellipsis sm regular color={theme.colors.grey500}>
                    {row.original.status === UserStatus.Pending
                      ? capitalized(row.original.status)
                      : row.original.email}
                  </Text>
                </Box>
              </OverlayTrigger>
            </Flex>
          </Flex>
        ),
      },
      {
        Header: 'Access',
        accessor: 'roles',
        Cell: ({ row }: any) => (
          <Box>{getUserRolesName(row.original.roles)}</Box>
        ),
      },
      {
        Header: 'Last active',
        accessor: 'lastActive',
        Cell: ({ cell: { value } }: any) => (
          <span>{value ? defaultDateFormat(value) : ''}</span>
        ),
      },
      {
        Header: 'Join date',
        accessor: 'createdAt',
        Cell: ({ cell: { value } }: any) => (
          <span>{value ? defaultDateFormat(value) : ''}</span>
        ),
      },
      {
        accessor: 'id',
        sort: false,
        Cell: ({ row }: any) => (
          <Box key={row.original.id} mx={-4}>
            <Row className="gx-1 flex-nowrap">
              <Col md={4 as any}>
                <PermissionSelection
                  id={row.original.id}
                  selectedItem={row.original.roles}
                  userId={row.original.id}
                  onChange={changeRole}
                  disabled={
                    row.original.roles === user?.roles ||
                      user?.roles === Roles.Admin
                      ? row.original.roles === Roles.Owner
                      : false
                  }
                />
              </Col>
              {
                row.original.status === PENDING_STATUS && <Col md={4 as any}>
                  <InvitationSender
                    userId={row.original.id}
                    invitationStatus={invitationStatus}
                    setInvitationStatus={setInvitationStatus}
                    disabled={UserInvitationStatus.Sent === invitationStatus[row.original.id]} />
                </Col>
              }
              <Col md={4 as any}>
                <OverlayTrigger
                  key={row.original.id}
                  overlay={<Tooltip id={row.original.id}>Delete user</Tooltip>}
                >
                  <IconButton
                    type="button"
                    iconName="trash01"
                    iconSize={20}
                    iconColor={theme.colors.grey500}
                    disabled={
                      user?.roles === Roles.Admin
                        ? row.original.roles === Roles.Admin ||
                        row.original.roles === Roles.Owner
                        : false
                    }
                    onClick={() => {
                      dispatch(
                        setModal({
                          showDeleteMemberModal: true,
                          deleteMemberInfo: {
                            userName: `${row.original.firstName} ${row.original.lastName}`,
                            info: { userId: row.original.id },
                          },
                        })
                      );
                    }}
                  />
                </OverlayTrigger>
              </Col>
            </Row>
          </Box>
        ),
      },
    ],
    [changeRole, dispatch, theme, user?.roles]
  );
  return (
    <Box>
      <Box mb={8}>
        <Button
          px={4}
          py={2}
          onClick={() => dispatch(setModal({ showAddMemberModal: true }))}
        >
          <Icon size={20} name="plus" mr={2} color={theme.colors.base} />
          Add team member
        </Button>
      </Box>
      <Box mb={8}>
        <Text sm semibold color={theme.colors.grey700}>
          Team members
        </Text>
        <Text sm regular color={theme.colors.grey500}>
          Manage your existing team and change roles/permissions.
        </Text>
      </Box>
      <TableWrapper h={399}>
        <Table
          py={2}
          columns={columns}
          initialState={
            isWorkspaceOwnerOrAdmin ? undefined : { hiddenColumns: ['id'] }
          }
          data={currentUserList}
        onTableStateChanged={onTableStateChanged}      />
      </TableWrapper>
      <Box py={3}>
        <Text sm regular color={theme.colors.error600}>
          {errorMessage}
        </Text>
      </Box>
    </Box>
  );
}

export default TeamManagementList;
