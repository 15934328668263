import React, { useContext, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import AssetCard from 'components/atoms/AssetCard/AssetCard';

import {
  AssetFragment,
  ProjectBaseFragment,
  Roles,
  useMarkAssetsAsFavoriteMutation,
} from 'graph/generated.graphql';
import { useAppSelector } from 'redux/hooks';
import { selectViewer } from 'redux/viewer/viewerSlice';
import { BackgroundUploadContext } from 'utils/context/backgroundUploadContext/backgroundUploadContext';
import { DragSelectContext } from 'utils/context/dragSelectContext/dragSelectContext';
import {
  getIconNameByFileType,
  getThumbnailIconByFileType,
} from 'utils/helpers/workspace';

const ListItemStyle = styled.li`
  border-radius: 6px;
  height: max-content;
  user-select: none;
  overflow: hidden;
  border: 2px solid transparent;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.grey100};
    border: 2px solid ${({ theme }) => theme.colors.grey100};
    box-shadow: 0px 0px 0px 4px ${({ theme }) => theme.colors.grey100};
    & svg {
      color: ${({ theme }) => theme.colors.grey400};
    }
  }
  &.ds-selected {
    border: 2px solid ${({ theme }) => theme.colors.primary600};
    background-color: ${({ theme }) => theme.colors.grey100};
    & svg {
      color: ${({ theme }) => theme.colors.grey400};
    }
  }
`;
function ListItem({
  asset,
  project,
  onItemClick,
}: {
  asset: AssetFragment;
  project: ProjectBaseFragment;
  onItemClick: Function;
}) {
  const { state } = useContext(BackgroundUploadContext);
  const inputRef = useRef(null);
  const { ds } = useContext(DragSelectContext);

  // adding a selectable element
  useEffect(() => {
    const element = inputRef.current as unknown as HTMLElement;
    if (!element || !ds) return;
    ds.addSelectables(element);
  }, [ds, inputRef]);

  const projectId = project.id;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const viewer = useAppSelector(selectViewer);
  const uploadingAssets = Object.keys(state[projectId] || {});
  const [markAsFavorite] = useMarkAssetsAsFavoriteMutation();
  const isVidicoAdmin = viewer?.roles === Roles.VidicoAdmin;

  const onDoubleClickHandler = () => {
    navigate(`${pathname}/${asset.id}`);
  };
  return (
    <ListItemStyle
      key={asset.id}
      id={asset.id}
      ref={inputRef}
      aria-labelledby="Selectable"
      className="ds-selectable"
      onClick={onItemClick(inputRef.current)}
    >
      <AssetCard
        asset={asset}
        textIcon={getIconNameByFileType(asset.type)}
        thumbnailIcon={getThumbnailIconByFileType(asset.type)}
        onDoubleClick={onDoubleClickHandler}
        isResentUpload={
          isVidicoAdmin ? uploadingAssets.includes(asset.id) : asset.isNew
        }
        onStarButtonClick={
          !isVidicoAdmin
            ? () => markAsFavorite({ variables: { assetsId: asset.id } })
            : undefined
        }
        isStarred={!!viewer?.favorites?.find((a) => a.assetId === asset.id)}
      />
    </ListItemStyle>
  );
}

export default ListItem;
