import React from 'react';
import { Toast } from 'react-bootstrap';
import styled, { useTheme } from 'styled-components';

import { INotificationProps } from './Notification.interface';

import Text from '../Text/Text';

const ToastStyled = styled(Toast)`
  .toast-header {
    border-bottom: none;
    justify-content: space-between;
    padding-bottom: 4px;
    background-color: ${({ theme }) => theme.colors.base};

    & .btn-close {
      width: 12px;
      height: 12px;
      opacity: 1;
      box-sizing: border-box;
      margin-right: 1px;
      &:focus {
        box-shadow: none;
      }
    }
  }
  .toast-body {
    background-color: ${({ theme }) => theme.colors.base};
    padding-top: 4px;
    border-radius: 8px;
  }
  box-shadow: ${({ theme }) => theme.shadow.lgShadow};
`;
function Notification({ title, children, ...args }: INotificationProps) {
  const theme = useTheme();
  return (
    <ToastStyled {...args}>
      <Toast.Header>
        <Text sm medium color={theme.colors.grey900}>
          {title}
        </Text>
      </Toast.Header>
      <Toast.Body>{children}</Toast.Body>
    </ToastStyled>
  );
}

export default Notification;
