import { useState } from 'react';
import styled, { useTheme } from 'styled-components';

import Box from 'components/atoms/Box/Box';
import Button from 'components/atoms/Button/Button';
import Flex from 'components/atoms/Flex/Flex';
import H from 'components/atoms/H/H';
import Logo from 'components/atoms/Logo/Logo';
import Text from 'components/atoms/Text/Text';

import Footer from 'containers/Layout/components/Footer/Footer';
import { useRequestEmailVerifyMutation } from 'graph/generated.graphql';
import { device } from 'utils/styles/device';

const SignUpWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.base};
  margin-right: auto;
  max-width: 836px;
  min-height: 100vh;
  max-height: 1024px;
  @media ${device.laptop} {
    margin: 0;
  }
`;
const FlexStyled = styled(Flex)`
  @media ${device.laptopL} {
    margin: 0;
  }
`;
const LogoWrapper = styled.div`
  margin-bottom: ${(props) => props.theme.size(12)};
`;

const TextStyled = styled(Text)`
  text-align: center;
`;
const AStyled = styled.a`
  text-decoration: underline;
`;

function ResendVerificationEmailContainer({
  email,
  isErrorProp,
}: {
  email: string;
  isErrorProp?: boolean;
}) {
  const theme = useTheme();
  const [isError, setIsError] = useState(isErrorProp);
  const [requestEmailVerify, { loading: isLoading }] =
    useRequestEmailVerifyMutation({
      onCompleted() {
        setIsError(false);
      },
      onError() {
        setIsError(true);
      },
    });
  return (
    <SignUpWrapper flexDirection="column" justifyContent="space-between">
      <FlexStyled
        flex="inherit"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        p={19}
      >
        <LogoWrapper>
          <Logo />
        </LogoWrapper>
        <Flex flexDirection="column" alignItems="center" mb={8}>
          <Box mb={3}>
            <H as={2} sm bold color={theme.colors.black}>
              {isError ? 'Something went wrong' : 'Almost done'}
            </H>
          </Box>
          {isErrorProp ? (
            <TextStyled md regular color={theme.colors.grey600}>
              The activation link did not properly activate your
              <br />
              account. Try resending the email.
            </TextStyled>
          ) : (
            <TextStyled md regular color={theme.colors.grey600}>
              We’ve sent an email to <b>{email}</b>
              <br />
              Open it up to activate your account.
            </TextStyled>
          )}
        </Flex>

        <Flex w={488} mb={6}>
          <Button
            fullWidth
            py={4}
            isLoading={isLoading}
            onClick={() =>
              requestEmailVerify({
                variables: {
                  email,
                },
              })
            }
          >
            Resend email
          </Button>
        </Flex>

        <Flex justifyContent="center" w={488}>
          <TextStyled sm color={theme.colors.grey700}>
            Signing up for Vidico’s Video Store you agree to the{' '}
            <AStyled href="https://vidico.com/privacy-policy/" target="_blank">
              Privacy Policy
            </AStyled>{' '}
            and{' '}
            <AStyled
              href="https://vidico.com/terms-and-conditions/"
              target="_blank"
            >
              Terms of Service
            </AStyled>
            .
          </TextStyled>
        </Flex>
      </FlexStyled>
      <Footer />
    </SignUpWrapper>
  );
}

export default ResendVerificationEmailContainer;
