import { DefaultTheme } from 'styled-components';

const mainTheme: DefaultTheme = {
  space: 4,
  colors: {
    base: '#FFFFFF',
    bgGrey: '#F8F8F8',
    beige: '#F1EDE9',
    black: '#000000',
    grey50: '#FAFAFA',
    grey100: '#F5F5F5',
    grey200: '#E5E5E5',
    grey300: '#D6D6D6',
    grey400: '#A3A3A3',
    grey500: '#737373',
    grey600: '#525252',
    grey700: '#424242',
    grey800: '#292929',
    grey900: '#141414',
    greyWarm200: '#E7E5E4',
    primary25: '#FFF9F0',
    primary50: '#FEF3E2',
    primary100: '#FEE0B8',
    primary200: '#FDCC8A',
    primary300: '#FDB85E',
    primary500: '#FB9A31',
    primary600: '#F68D2E',
    primary700: '#EF802B',
    success25: '#F6FEF9',
    success100: '#D1FADF',
    success300: '#6CE9A6',
    success500: '#12B76A',
    success600: '#039855',
    success700: '#027A48',
    vidicoBlack: '#0D0D0D',
    vidicoGrey: '#BCBCBE',
    vidicoAccent: '#FFC27B',
    warning500: '#EAAA08',
    error25: '#FFFBFA',
    error100: '#FEE4E2',
    error300: '#FDA29B',
    error500: '#F04438',
    error600: '#D92D20',
    error700: '#B42318',
    hoverColor: 'rgba(255, 255, 255, 0.3)',
    inherit: 'inherit',
  },
  backgrounds: {
    gradientPrimary600: 'linear-gradient(90deg, #F68D2E 0%, #FB9A31 100%);',
  },
  shadow: {
    xsShadow: '0px 1px 2px rgba(16, 24, 40, 0.05);',
    smShadow:
      '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);',
    mdShadow:
      '0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);',
    lgShadow:
      '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);',
    xlShadow:
      '0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);',
  },
  size(number) {
    return `${number * this.space}px`;
  },
  avatar: {
    //     xs  sm  md  lg  xl doubleXl
    size: [24, 32, 40, 48, 56, 64],
    //            xs  sm  md  lg  xl doubleXl
    placeholder: [16, 20, 24, 28, 32, 32],
    //         xs  sm md  lg  xl doubleXl
    indicator: [6, 8, 10, 12, 14, 16],
  },
  typography: {
    weight: {
      bold: 700,
      semibold: 600,
      medium: 500,
      regular: 400,
    },
    fontSize: {
      //     xs  sm  md  lg  xl  doubleXl
      text: [12, 14, 16, 18, 20, 24],
      header: [24, 30, 36, 48, 60],
    },
    lineHeight: {
      text: [18, 20, 24, 28, 30],
      header: [32, 38, 44, 60, 72],
    },
  },
};

export { mainTheme };
