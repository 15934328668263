import { useContext } from "react";
import { AuthContext, IAuthContext } from "react-oauth2-code-pkce";

import { getRedirectUrlV3 } from "../utils/authentication/getRedirectUrl/getRedirectUrl";
import { setRedirectInProgress } from "../utils/localStorage/viewer";

function SubdomainProvider({
  children,
}: {
  children: JSX.Element;
}): JSX.Element | null {

  const { tokenData } = useContext<IAuthContext>(AuthContext);

  if (tokenData) {
    const url = getRedirectUrlV3(tokenData?.role, tokenData?.subdomain);
    if (!url.includes(window.location.origin)) {
      setRedirectInProgress('true');
      window.location.assign(url);
    }
  }

  return children;
}

export default SubdomainProvider;