import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';

interface ReferralRewardState {
  isFirstEnter: boolean;
}

const initialState: ReferralRewardState = {
  isFirstEnter: true,
};

const referralRewardSlice = createSlice({
  name: 'referralReward',
  initialState,
  reducers: {
    setFirstEnter: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isFirstEnter: action.payload,
    }),
  },
});

export const { setFirstEnter } = referralRewardSlice.actions;

export const selectIsFirstEnter = (state: RootState) =>
  state.referralReward.isFirstEnter;

export default referralRewardSlice.reducer;
