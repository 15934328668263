import React, { FormEvent } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { FileRejection } from 'react-dropzone';
import styled, { useTheme } from 'styled-components';

import Avatar from 'components/atoms/Avatar/Avatar';
import Box from 'components/atoms/Box/Box';
import Button from 'components/atoms/Button/Button';
import Divider from 'components/atoms/Divider/Divider';
import { FileUpload } from 'components/atoms/FileUpload/FileUpload';
import Flex from 'components/atoms/Flex/Flex';
import FormControl from 'components/atoms/FormControl/FormControl';
import FormFeetback from 'components/atoms/FormFeetback/FormFeetback';
import Text from 'components/atoms/Text/Text';
import ActionModal, {
  ButtonsAlignmentName,
} from 'components/molecules/ActionModal/ActionModal';

import {
  CompanyEditMutationVariables,
  EditProfileMutationVariables,
  Roles,
  useCompanyEditMutation,
  useEditProfileMutation,
} from 'graph/generated.graphql';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { setModal } from 'redux/modal/modalSlice';
import { selectViewer } from 'redux/viewer/viewerSlice';
import { DEFAULT_COMPANY_AVATAR } from 'utils/constants/common';
import { FormErrorMessages, FormErrorTypes } from 'utils/constants/forms';
import {
  isEmailValidError,
  isFileRequiresError,
} from 'utils/errors/isGqlError';
import {
  useFormState,
  useFormValidation,
} from 'utils/hooks/useFormValidation/useFormValidation';
import { isEmail } from 'utils/validators/validators';

enum FormFields {
  FirstName = 'firstName',
  LastName = 'lastName',
  Email = 'email',
  ProfilePhoto = 'profilePhoto',
  CompanyPhoto = 'companyPhoto',
}

const FormFieldsList = [
  FormFields.FirstName,
  FormFields.LastName,
  FormFields.Email,
  FormFields.ProfilePhoto,
  FormFields.CompanyPhoto,
];
const ControlWrapper = styled(Box)`
  flex: 0;
  flex-basis: 512px;
`;

function MyProfileForm() {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const user = useAppSelector(selectViewer);

  const initialFormValue = {
    [FormFields.FirstName]: user?.firstName || '',
    [FormFields.LastName]: user?.lastName || '',
    [FormFields.Email]: user?.email || '',
  };

  const [
    formErrors,
    isFormValid,
    [
      setFirstNameError,
      setLastNameError,
      setEmailError,
      setProfilePhotoError,
      setCompanyPhotoError,
      ,
    ],
  ] = useFormValidation(FormFieldsList);

  const [
    formState,
    [setFirstName, setLastName, setEmail, setProfilePhoto, setCompanyPhoto],
    [formOnSubmit, formOnError, formOnSuccess],
  ] = useFormState(FormFieldsList, initialFormValue);

  const { isLoading, errorSlug } = formState;

  const [editProfile, { loading: isEditProfileLoading }] =
    useEditProfileMutation({
      onCompleted() {
        formOnSuccess();
      },
      onError(error) {
        error.graphQLErrors.forEach((gqlError) => {
          if (isFileRequiresError(gqlError)) {
            formOnError();
            return setProfilePhotoError({
              type: FormErrorTypes.Invalid,
              message: FormErrorMessages.FILE_REQUIRED,
            });
          }
          if (isEmailValidError(gqlError)) {
            formOnError();
            return setEmailError({
              type: FormErrorTypes.Invalid,
              message: FormErrorMessages.EMAIL_INVALID,
            });
          }
          return formOnError(gqlError.message);
        });
      },
    });
  const [companyEdit, { loading: isCompanyEditLoading }] =
    useCompanyEditMutation({
      onCompleted() {
        formOnSuccess();
      },
      onError(error) {
        error.graphQLErrors.forEach((gqlError) => {
          if (isFileRequiresError(gqlError)) {
            formOnError();
            return setCompanyPhotoError({
              type: FormErrorTypes.Invalid,
              message: FormErrorMessages.FILE_REQUIRED,
            });
          }
          return formOnError(gqlError.message);
        });
      },
    });

  const validName = (type: 'first' | 'last', value: string) => {
    const nameValidator =
      type === 'first' ? setFirstNameError : setLastNameError;
    if (!value) {
      nameValidator({
        type: FormErrorTypes.Invalid,
        message:
          type === 'first'
            ? FormErrorMessages.FIRST_NAME_BLANK
            : FormErrorMessages.LAST_NAME_BLANK,
      });
      return false;
    }
    if (value.length > 40) {
      nameValidator({
        type: FormErrorTypes.Invalid,
        message: FormErrorMessages.MAX_40_CHARACTERS,
      });
      return false;
    }
    nameValidator(null);
    return true;
  };

  const validEmail = (value: string) => {
    if (!value) {
      setEmailError({
        type: FormErrorTypes.Invalid,
        message: FormErrorMessages.EMAIL_BLANK,
      });
      return false;
    }
    if (!isEmail(value)) {
      setEmailError({
        type: FormErrorTypes.Invalid,
        message: FormErrorMessages.EMAIL_INVALID,
      });
      return false;
    }
    setEmailError(null);
    return true;
  };

  const validPhoto = (
    type: FormFields.ProfilePhoto | FormFields.CompanyPhoto,
    file: File
  ) => {
    const photoValidator =
      type === FormFields.ProfilePhoto
        ? setProfilePhotoError
        : setCompanyPhotoError;
    if (file && file.size > 5242880) {
      photoValidator({
        type: FormErrorTypes.Invalid,
        message: FormErrorMessages.FILE_SIZE_ERROR,
      });
      return false;
    }
    photoValidator(null);
    return true;
  };

  const onFirstNameTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(e.target.value);
    setFirstNameError(null);
  };

  const onLastNameTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(e.target.value);
    setLastNameError(null);
  };

  const onEmailTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setEmailError(null);
  };

  const onProfilePhotoDrop = <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[]
  ) => {
    setProfilePhoto(acceptedFiles[0]);
    validPhoto(FormFields.ProfilePhoto, acceptedFiles[0]);
    if (fileRejections.length) {
      setProfilePhotoError({
        type: FormErrorTypes.Invalid,
        message: FormErrorMessages.FILE_SIZE_ERROR,
      });
    }
  };

  const onCompanyPhotoDrop = <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[]
  ) => {
    setCompanyPhoto(acceptedFiles[0]);
    validPhoto(FormFields.CompanyPhoto, acceptedFiles[0]);
    if (fileRejections.length) {
      setCompanyPhotoError({
        type: FormErrorTypes.Invalid,
        message: FormErrorMessages.FILE_SIZE_ERROR,
      });
    }
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const { firstName, lastName, email, profilePhoto, companyPhoto } =
      formState;

    if (
      [
        validName('first', firstName),
        validName('last', lastName),
        validEmail(email),
        validPhoto(FormFields.ProfilePhoto, profilePhoto),
        validPhoto(FormFields.CompanyPhoto, companyPhoto),
      ].filter((v) => !v).length
    ) {
      return null;
    }

    if (
      !!profilePhoto ||
      initialFormValue.firstName !== firstName ||
      initialFormValue.lastName !== lastName ||
      initialFormValue.email !== email
    ) {
      const variables: EditProfileMutationVariables = {
        ...(initialFormValue.firstName !== firstName ? { firstName } : {}),
        ...(initialFormValue.lastName !== lastName ? { lastName } : {}),
        ...(profilePhoto ? { file: profilePhoto } : {}),
        ...(initialFormValue.email !== email ? { email } : {}),
      };
      formOnSubmit();
      editProfile({
        variables,
      });
    }

    if (companyPhoto && user?.roles !== Roles.Member) {
      const variables: CompanyEditMutationVariables = {
        companyId: user?.companyId,
        ...(companyPhoto ? { file: companyPhoto } : {}),
      };
      formOnSubmit();
      return companyEdit({
        variables,
      });
    }
    return null;
  };
  const resetForm = () => {
    setFirstName(initialFormValue.firstName);
    setLastName(initialFormValue.lastName);
    setEmail(initialFormValue.email);
    setCompanyPhoto(null);
    setProfilePhoto(null);
    setFirstNameError(null);
    setLastNameError(null);
    setEmailError(null);
    setProfilePhotoError(null);
    setCompanyPhotoError(null);
  };
  const { firstName, lastName, email, profilePhoto, companyPhoto } = formState;
  return (
    <Form
      className="w-100"
      noValidate
      validated={isFormValid}
      onSubmit={handleSubmit}
    >
      <Form.Group controlId="validationCustom01">
        <Flex>
          <Box w={280} mr={8}>
            <Form.Label>
              <Text sm medium color={theme.colors.grey700}>
                Name
              </Text>
            </Form.Label>
          </Box>
          <ControlWrapper>
            <Row className="gx-3">
              <Col>
                <FormControl
                  placeholder="First name"
                  value={firstName}
                  required
                  onChange={onFirstNameTextChange}
                />
                <FormFeetback type={formErrors[FormFields.FirstName]?.type}>
                  {formErrors[FormFields.FirstName]?.message}
                </FormFeetback>
              </Col>
              <Col>
                <FormControl
                  placeholder="Last name"
                  value={lastName}
                  onChange={onLastNameTextChange}
                />
                <FormFeetback type={formErrors[FormFields.LastName]?.type}>
                  {formErrors[FormFields.LastName]?.message}
                </FormFeetback>
              </Col>
            </Row>
          </ControlWrapper>
        </Flex>
      </Form.Group>
      <Divider mb={5} />
      <Row className="gx-3 ">
        <Col>
          <Form.Group controlId="edit-profile-email">
            <Flex>
              <Box w={280} mr={8}>
                <Form.Label>
                  <Text sm medium color={theme.colors.grey700}>
                    Email address
                  </Text>
                </Form.Label>
              </Box>
              <ControlWrapper>
                <FormControl
                  type="email"
                  preIcon="mail01"
                  required
                  value={email}
                  onChange={onEmailTextChange}
                  placeholder="Enter work email"
                />
                <FormFeetback type={formErrors[FormFields.Email]?.type}>
                  {formErrors[FormFields.Email]?.message}
                </FormFeetback>
              </ControlWrapper>
            </Flex>
          </Form.Group>
        </Col>
      </Row>
      <Divider mb={5} />
      {user?.roles !== Roles.Member && (
        <>
          <Row className="gx-3 ">
            <Col>
              <Form.Group controlId="companyPhotos">
                <Flex>
                  <Box w={280} mr={8}>
                    <Form.Label>
                      <Text sm medium color={theme.colors.grey700}>
                        Company photo
                      </Text>
                      <Text sm regular color={theme.colors.grey600}>
                        This will be displayed on the company profile. Only
                        admins can update this.
                      </Text>
                    </Form.Label>
                  </Box>
                  <ControlWrapper>
                    <Flex alignItems="center" justifyContent="center">
                      <Box mr={5}>
                        <Avatar
                          src={
                            companyPhoto
                              ? URL.createObjectURL(companyPhoto)
                              : user?.company?.logo || DEFAULT_COMPANY_AVATAR
                          }
                          xl
                        />
                      </Box>
                      <FileUpload onDrop={onCompanyPhotoDrop} />
                    </Flex>
                    <FormFeetback
                      type={formErrors[FormFields.CompanyPhoto]?.type}
                    >
                      {formErrors[FormFields.CompanyPhoto]?.message}
                    </FormFeetback>
                  </ControlWrapper>
                </Flex>
              </Form.Group>
            </Col>
          </Row>
          <Divider mb={5} />
        </>
      )}
      <Row className="gx-3 ">
        <Col>
          <Form.Group controlId="profilePhotos">
            <Flex>
              <Box w={280} mr={8}>
                <Form.Label>
                  <Text sm medium color={theme.colors.grey700}>
                    Profile photo
                  </Text>
                  <Text sm regular color={theme.colors.grey600}>
                    This will be displayed as your profile.
                  </Text>
                </Form.Label>
              </Box>
              <ControlWrapper>
                <Flex alignItems="center" justifyContent="center">
                  <Box mr={5}>
                    <Avatar
                      src={
                        profilePhoto
                          ? URL.createObjectURL(profilePhoto)
                          : user?.avatar || ''
                      }
                      text={`${firstName} ${lastName}`}
                      xl
                    />
                  </Box>
                  <FileUpload onDrop={onProfilePhotoDrop} />
                </Flex>
                <FormFeetback type={formErrors[FormFields.ProfilePhoto]?.type}>
                  {formErrors[FormFields.ProfilePhoto]?.message}
                </FormFeetback>
              </ControlWrapper>
            </Flex>
          </Form.Group>
        </Col>
      </Row>
      <Divider mb={5} />
      <Row className="gx-3 ">
        <Col>
          <Form.Group controlId="profilePhotos">
            <Flex>
              <Box w={280} mr={8}>
                <Form.Label>
                  <Text sm medium color={theme.colors.grey700}>
                    Delete account
                  </Text>
                  <Text sm regular color={theme.colors.grey600}>
                    Find out how you can delete your account.
                  </Text>
                </Form.Label>
              </Box>
              <ControlWrapper>
                <Button
                  px={4}
                  py={2.5}
                  sm
                  medium
                  onClick={() =>
                    dispatch(setModal({ showDeleteAccountModal: true }))
                  }
                >
                  Delete account
                </Button>
              </ControlWrapper>
            </Flex>
          </Form.Group>
        </Col>
      </Row>
      <Divider mb={5} />
      <ActionModal
        primaryButtonProps={{
          text: 'Save',
          disabled: !isFormValid,
          isLoading: isLoading || isEditProfileLoading || isCompanyEditLoading,
          type: 'submit',
        }}
        secondaryButtonProps={{
          text: 'Cancel',
          onClick: () => resetForm(),
        }}
        buttonsAlignment={ButtonsAlignmentName.RIGHT}
        error={errorSlug}
      />
    </Form>
  );
}

export default MyProfileForm;
