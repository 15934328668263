import React, { FormEvent, useCallback, useMemo, useState } from 'react';
import { Col, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { FileRejection } from 'react-dropzone';
import styled, { useTheme } from 'styled-components';

import Avatar from 'components/atoms/Avatar/Avatar';
import Box from 'components/atoms/Box/Box';
import { FileUpload } from 'components/atoms/FileUpload/FileUpload';
import Flex from 'components/atoms/Flex/Flex';
import FormControl from 'components/atoms/FormControl/FormControl';
import FormFeetback from 'components/atoms/FormFeetback/FormFeetback';
import IconButton from 'components/atoms/IconButton/IconButton';
import { IOption, Select } from 'components/atoms/Select/Select';
import Text from 'components/atoms/Text/Text';
import ActionModal, { ButtonsAlignmentName } from 'components/molecules/ActionModal/ActionModal';
import PermissionSelection from 'components/molecules/PermissionSelection/PermissionSelection';
import Table, { OnTableStateChangedType } from 'components/molecules/Table/Table';

import {
  CompanyFragment,
  CurrencyType,
  GetUsersByCompanyIdQueryVariables,
  Roles,
  SortEnumType,
  useChangeRoleMutation,
  useCompanyEditMutation,
  useGetAccountManagersQuery,
  useGetUsersByCompanyIdQuery,
  UserStatus
} from 'graph/generated.graphql';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { setModal } from 'redux/modal/modalSlice';
import { selectViewer } from 'redux/viewer/viewerSlice';
import { DEFAULT_COMPANY_AVATAR, imagesTypeForAvatar, PENDING_STATUS } from 'utils/constants/common';
import { FormErrorMessages, FormErrorTypes } from 'utils/constants/forms';
import { isCompanyNameExistError, isFileRequiresError } from 'utils/errors/isGqlError';
import { useFormState, useFormValidation } from 'utils/hooks/useFormValidation/useFormValidation';
import { mapUserToSelectOptions } from 'utils/transformers/mappers';
import { capitalized, getExtension, getUserRolesName } from 'utils/transformers/text';

import Toggler from '../../../../components/atoms/Toggler/Toggler';
import InvitationSender from '../../../../components/molecules/InvitationSender/InvitationSender';
import { mapUsersInviteState } from '../../../../components/molecules/InvitationSender/InvitationSender.helper';
import {

  UserInvitationStatus
} from '../../../../components/molecules/InvitationSender/InvitationSender.interface';

type AddWorkspaceFormProps = {
  refetch: () => void;
  onModalClose: () => void;
  company: Partial<CompanyFragment>;
  showModal?: boolean;
};

enum FormFields {
  CompanyName = 'companyName',
  File = 'file',
  AccountManagerId = 'accountManagerId',
  Currency = 'currency'
}

const TableWrapper = styled(Box)<{ fixHeight?: boolean }>`
  border-radius: 8px;
  overflow: hidden;

  & tbody {
    display: block;
    ${({ fixHeight }) => (fixHeight ? 'height: 235px;' : null)}
    overflow: auto;
  }

  & thead {
    display: table;
    table-layout: fixed;
    width: 100%;
  }

  & table {
    width: 100%;
  }

  & th:first-child {
    width: 240px;
  }
`;
const FormFieldsList = [
  FormFields.CompanyName,
  FormFields.File,
  FormFields.AccountManagerId,
  FormFields.Currency
];

function EditWorkspaceForm({
                             onModalClose,
                             refetch,
                             company,
                             showModal
                           }: AddWorkspaceFormProps) {
  const theme = useTheme();
  const viewer = useAppSelector(selectViewer);

  const dispatch = useAppDispatch();
  const { loading, data: accountManagersData } = useGetAccountManagersQuery({
    skip: !showModal
  });
  const isVidicoAdmin = viewer?.roles === Roles.VidicoAdmin;

  const [errorMessage, setErrorMessage] = useState('');
  const {
    data: teamData,
    loading: isUserLoading,
    previousData: prevUserListData,
    refetch: usersListRefetch
  } = useGetUsersByCompanyIdQuery({
    variables: {
      companyId: company.id,
      where: {
        status: { neq: UserStatus.Deleted },
        companyId: { eq: company.id }
      }
    },
    skip: !showModal || !company.id
  });

  const [currency, setCurrency] = useState(company.currency !== CurrencyType.Aud)
  const onCurrencyChanged = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrencyError(null)
    setCurrency(e.target.checked);
  }, [])

  const onTableStateChanged: OnTableStateChangedType = useCallback(
    ({ sortBy }) => {
      const variables: GetUsersByCompanyIdQueryVariables = {
        companyId: company.id,
        order: sortBy.map((item) => ({
          [item.id === 'roles' ? 'role' : item.id]: item.desc
            ? SortEnumType.Desc
            : SortEnumType.Asc
        })),
        where: {
          status: { neq: UserStatus.Deleted },
          companyId: { eq: company.id }
        }
      };
      usersListRefetch(variables);
    },
    // eslint-disable-next-line
    [usersListRefetch]
  );

  const team = teamData?.usersList || [];

  const initialFormValue = {
    [FormFields.CompanyName]: company?.name || '',
    [FormFields.AccountManagerId]: company.managerId
  };
  const [
    formErrors,
    isFormValid,
    [setCompanyNameError, setFileError, setAccountManagerError, setCurrencyError]
  ] = useFormValidation(FormFieldsList);

  const [
    formState,
    [setCompanyName, setFile, setAccountManagerId],
    [formOnSubmit, formOnError, formOnSuccess]
  ] = useFormState(FormFieldsList, initialFormValue);


  const listOfAdmins =
    accountManagersData?.vidicoAdmin?.vidicoAdmins?.filter(
      (user) => user.calendlyUrl
    ) || [];

  const options = listOfAdmins.map(mapUserToSelectOptions);
  const { errorSlug, accountManagerId } = formState;

  const getValue = (): IOption | undefined =>
    accountManagerId
      ? options.find((item: IOption) => item.value === accountManagerId)
      : undefined;

  const previousUserList = prevUserListData?.usersList || [];

  const currentUserList = isUserLoading ? previousUserList : team;

  const [changeRole] = useChangeRoleMutation({
    onError(error) {
      setErrorMessage(error.message);
    },
    onCompleted() {
      setErrorMessage('');
    },
    refetchQueries: ['getUsersByCompanyId']
  });

  const [companyEdit, { loading: isCompanyEditLoading }] =
    useCompanyEditMutation({
      onCompleted() {
        formOnSuccess();
        onModalClose();
        refetch();
      },
      onError(error) {
        error.graphQLErrors.forEach((gqlError) => {
          if (isCompanyNameExistError(gqlError)) {
            formOnError();
            return setCompanyNameError({
              type: FormErrorTypes.Invalid,
              message: FormErrorMessages.COMPANY_NAME_NOT_UNIQUE
            });
          }
          if (isFileRequiresError(gqlError)) {
            formOnError();
            return setFileError({
              type: FormErrorTypes.Invalid,
              message: FormErrorMessages.FILE_REQUIRED
            });
          }
          return formOnError(gqlError.message);
        });
      }
    });

  const [invitationStatus, setInvitationStatus] = useState(mapUsersInviteState(currentUserList));

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'firstName',
        Cell: ({ row }: any) => (
          <Flex ml={-6} alignItems='center'>
            <Box mr={3}>
              <Avatar
                md
                src={row.original.avatar}
                text={`${row.original.firstName} ${row.original.lastName}`}
              />
            </Box>
            <Flex flexDirection='column'>
              <OverlayTrigger
                key={row.original.firstName}
                overlay={
                  <Tooltip
                    id={row.original.firstName}
                  >{`${row.original.firstName} ${row.original.lastName}`}</Tooltip>
                }
              >
                <Box w={130}>
                  <Text ellipsis sm medium color={theme.colors.grey700}>
                    {`${row.original.firstName} ${row.original.lastName}`}
                  </Text>
                </Box>
              </OverlayTrigger>
              <OverlayTrigger
                key={`-${row.original.email}`}
                overlay={
                  <Tooltip id={`-${row.original.email}`}>
                    {row.original.email}
                  </Tooltip>
                }
              >
                <Box w={130}>
                  <Text ellipsis sm regular color={theme.colors.grey500}>
                    {row.original.status === UserStatus.Pending
                      ? capitalized(row.original.status)
                      : row.original.email}
                  </Text>
                </Box>
              </OverlayTrigger>
            </Flex>
          </Flex>
        )
      },
      {
        Header: 'Access',
        accessor: 'roles',
        Cell: ({ row }: any) => (
          <Box>{getUserRolesName(row.original.roles)}</Box>
        )
      },
      {
        accessor: 'id',
        sort: false,
        Cell: ({ row }: any) => (
          <Box mx={-5}>
            <Row className='gx-0 flex-nowrap'>
              <Col md={4 as any}>
                <PermissionSelection
                  id={row.original.id}
                  selectedItem={row.original.roles}
                  userId={row.original.id}
                  onChange={changeRole}
                  disabled={row.original.roles === Roles.Owner && isVidicoAdmin}
                />
              </Col>
              {
                row.original.status === PENDING_STATUS && <Col md={4 as any}>
                   <InvitationSender
                     userId={row.original.id}
                     invitationStatus={invitationStatus}
                     setInvitationStatus={setInvitationStatus}
                     disabled={UserInvitationStatus.Sent === invitationStatus[row.original.id]}/>
                </Col>
              }
              <Col md={4 as any}>
                <OverlayTrigger
                  key={row.original.id}
                  overlay={<Tooltip id={row.original.id}>Delete user</Tooltip>}
                >
                  <IconButton
                    type='button'
                    iconName='trash01'
                    iconSize={20}
                    iconColor={theme.colors.grey500}
                    onClick={() => {
                      dispatch(
                        setModal({
                          showDeleteMemberModal: true,
                          deleteMemberInfo: {
                            userName: `${row.original.firstName} ${row.original.lastName}`,
                            info: { userId: row.original.id }
                          }
                        })
                      );
                    }}
                  />
                </OverlayTrigger>
              </Col>
            </Row>
          </Box>
        )
      }
    ],
    [theme, changeRole, isVidicoAdmin, dispatch, invitationStatus]
  );
  const validAccountManager = (value: IOption) => {
    if (!value) {
      setAccountManagerError({
        type: FormErrorTypes.Invalid,
        message: FormErrorMessages.ACCOUNT_MANAGER_NOT_SET
      });
      return false;
    }
    setAccountManagerError(null);
    return true;
  };
  const validCompany = (value: string) => {
    if (!value) {
      setCompanyNameError({
        type: FormErrorTypes.Invalid,
        message: FormErrorMessages.COMPANY_BLANK
      });
      return false;
    }
    if (value.length > 50) {
      setCompanyNameError({
        type: FormErrorTypes.Invalid,
        message: FormErrorMessages.MAX_50_CHARACTERS
      });
      return false;
    }
    setCompanyNameError(null);
    return true;
  };

  const validFile = (file: File) => {
    if (!file) {
      return true;
    }
    if (file.size > 5242880) {
      setFileError({
        type: FormErrorTypes.Invalid,
        message: FormErrorMessages.FILE_SIZE_ERROR
      });
      return false;
    }
    if (!imagesTypeForAvatar.includes(getExtension(file?.name) || '')) {
      setFileError({
        type: FormErrorTypes.Invalid,
        message: FormErrorMessages.FILE_SIZE_ERROR
      });
      return false;
    }
    setFileError(null);
    return true;
  };

  const onCompanyTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyName(e.target.value);
    setCompanyNameError(null);
  };

  const onFileDrop = <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[]
  ) => {
    setFile(acceptedFiles[0]);
    validFile(acceptedFiles[0]);
    if (fileRejections.length) {
      setFileError({
        type: FormErrorTypes.Invalid,
        message: FormErrorMessages.FILE_SIZE_ERROR
      });
    }
  };

  const onAccountManagerSelectChange = (newValue: any) => {
    setAccountManagerId(newValue.value);
    validAccountManager(newValue.value);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const { companyName, file } = formState;
    const currencyValue = currency ? CurrencyType.Usd : CurrencyType.Aud;

    if ([validCompany(companyName), validFile(file)].filter((v) => !v).length) {
      return null;
    }

    formOnSubmit();
    if (
      !!file ||
      company.name !== companyName ||
      company.managerId !== accountManagerId || currencyValue !== company.currency
    ) {
      const variables = {
        companyId: company.id,
        managerId: accountManagerId,
        currency: currencyValue,
        ...(file ? { file } : {}),
        ...(company.name !== companyName ? { companyName } : {})
      };

      return companyEdit({
        variables
      });
    }
    onModalClose();
    return null;
  };
  const { file } = formState;
  const moreThenFour = currentUserList.length > 4;
  return (
    <Form
      className='w-100'
      noValidate
      validated={isFormValid}
      onSubmit={handleSubmit}
    >
      <Row className='gx-3'>
        <Col>
          <Form.Group controlId='validationCustom01'>
            <Form.Label>
              <Text sm semibold color={theme.colors.grey700}>
                Company name
              </Text>
            </Form.Label>
            <FormControl
              defaultValue={company.name}
              onChange={onCompanyTextChange}
              placeholder='Workspace Owner’s company'
              required
            />
            <FormFeetback type={formErrors[FormFields.CompanyName]?.type}>
              {formErrors[FormFields.CompanyName]?.message}
            </FormFeetback>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId='validationSelect'>
            <Form.Label>
              <Text sm semibold color={theme.colors.grey700}>
                Account manager
              </Text>
            </Form.Label>
            <Select
              isSearchable={false}
              placeholder='Select account manager'
              value={getValue()}
              isLoading={loading}
              options={options}
              minMenuWidth={320}
              onChange={onAccountManagerSelectChange}
            />
            <FormFeetback type={formErrors[FormFields.AccountManagerId]?.type}>
              {formErrors[FormFields.AccountManagerId]?.message}
            </FormFeetback>
          </Form.Group>
        </Col>
      </Row>
      <Row className='gx-3 pb-2'>
        <Col px={0}>
          <Flex alignItems='center' justifyContent='space-between'>
            <Box pr={1}>
              <Text sm semibold color={theme.colors.grey700}>
                Currency
              </Text>
              <Text sm regular color={theme.colors.grey500}>
                This is the currency the company pays.
              </Text>
            </Box>
            <Box w='158px'>
              <Toggler leftText='AUD' rightText='USD' leftIconSize={16} leftIcon='currency' rightIconSize={16}
                       rightIcon='currency' sliderWidth={75} sliderColumnWidth={83} onChange={onCurrencyChanged} checked={currency}/>
            </Box>
          </Flex>
        </Col>
      </Row>
      <Row className='gx-3 pt-1'>
        <Col>
          <Form.Group controlId='validationCustom02'>
            <Box mb={5}>
              <Form.Label>
                <Text sm semibold color={theme.colors.grey700}>
                  Company photo
                </Text>
                <Text sm regular color={theme.colors.grey500}>
                  This will be displayed on your profile.
                </Text>
              </Form.Label>
            </Box>
            <Form.Group controlId='file'>
              <Flex alignItems='center' justifyContent='center'>
                <Box mr={5}>
                  <Avatar
                    src={
                      file
                        ? URL.createObjectURL(file)
                        : company.logo || DEFAULT_COMPANY_AVATAR
                    }
                    xl
                  />
                </Box>
                <FileUpload onDrop={onFileDrop} />
              </Flex>
              <FormFeetback type={formErrors[FormFields.File]?.type}>
                {formErrors[FormFields.File]?.message}
              </FormFeetback>
            </Form.Group>
          </Form.Group>
        </Col>
      </Row>
      <Row className='gx-3 '>
        <Col>
          <Form.Group controlId='validationCustom02'>
            <Box mb={5}>
              <Form.Label>
                <Text sm semibold color={theme.colors.grey700}>
                  Team Management
                </Text>
              </Form.Label>
              <TableWrapper
                h={moreThenFour ? 280 : undefined}
                fixHeight={moreThenFour}
              >
                <Table
                  py={2}
                  noBorders
                  noBackgrounds
                  columns={columns}
                  data={currentUserList}
                  initialState={{ sortBy: [{ id: 'roles', desc: false }] }}
                  onTableStateChanged={onTableStateChanged}
                />
              </TableWrapper>
            </Box>
          </Form.Group>
        </Col>
      </Row>
      <ActionModal
        primaryButtonProps={{
          text: 'Save',
          isLoading: isCompanyEditLoading,
          disabled: !isFormValid,
          type: 'submit',
          fullWidth: true
        }}
        secondaryButtonProps={{
          text: 'Close',
          onClick: onModalClose,
          fullWidth: true
        }}
        buttonsAlignment={ButtonsAlignmentName.CENTER}
        error={errorSlug || errorMessage}
      />
    </Form>
  );
}

export default EditWorkspaceForm;
