import {
  Roles,
} from 'graph/generated.graphql';
import {
  HTTP_PREFIX,
  ENVIRONMENT_TYPE,
  SUB_DOMAINS,
} from 'utils/constants/common';
import { RoutesPath } from 'utils/constants/routes';

export const getRedirectUrlV3 = (
  role?: string | Roles | null,
  subdomain?: string | null,
  route?: RoutesPath
) => {
  const prefix = HTTP_PREFIX.Https;
  const REACT_APP_DOMAIN = process.env.REACT_APP_DOMAIN as string;

  const subdomainPrefix = getSubdomainPrefix();
  const appSubdomain = getSubdomain();

  if (!role || !subdomain) {
    return `${prefix}${subdomainPrefix}${appSubdomain}${REACT_APP_DOMAIN}${route}`;
  }

  if (correctRole(role) === correctRole(Roles.VidicoAdmin)) {
    return `${prefix}${subdomainPrefix}${SUB_DOMAINS.Admin}.${REACT_APP_DOMAIN}/workspaces`;
  }
  return `${prefix}${subdomainPrefix}${subdomain}.${REACT_APP_DOMAIN}`;
};

export const getSignUpUrl = () => {
  const prefix = HTTP_PREFIX.Https;
  const REACT_APP_DOMAIN = process.env.REACT_APP_DOMAIN as string;

  const subdomainPrefix = getSubdomainPrefix();
  const appSubdomain = getSubdomain();

  return `${prefix}${subdomainPrefix}${appSubdomain}${REACT_APP_DOMAIN}${RoutesPath.SignIn}`;
};

const getSubdomainPrefix = () => {
  let subdomainPrefix = '';
  const REACT_APP_ENV = process.env.REACT_APP_ENV as string;
  if (REACT_APP_ENV === ENVIRONMENT_TYPE.Staging) {
    subdomainPrefix = 'stag-';
  }
  return subdomainPrefix;
};

const getSubdomain = () => {
  const REACT_APP_ENV = process.env.REACT_APP_ENV as string;

  let appSubdomain = `${SUB_DOMAINS.App}.`;
  if (REACT_APP_ENV === ENVIRONMENT_TYPE.Dev) {
    appSubdomain = '';
  }
  return appSubdomain;
}

const correctRole = (role?: string | Roles | null) => role?.toString()?.replace('_', '')?.toLowerCase()
