import React, { useEffect } from 'react';
import { useTheme } from 'styled-components';

import Box from 'components/atoms/Box/Box';
import Flex from 'components/atoms/Flex/Flex';
import Icon from 'components/atoms/Icon/Icon';
import Text from 'components/atoms/Text/Text';
import { Modal } from 'components/molecules/Modal/Modal';

import { Roles, useUserByIdQuery } from 'graph/generated.graphql';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectRequestNewProjectModal, setModal } from 'redux/modal/modalSlice';
import { selectViewer } from 'redux/viewer/viewerSlice';

import RequestNewProjectForm from './components/RequestNewProjectForm';

function RequestNewProjectModal() {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const show = useAppSelector(selectRequestNewProjectModal);
  const user = useAppSelector(selectViewer);
  const onModalClose = () =>
    dispatch(setModal({ showRequestNewProjectModal: false }));
  const { data: accountManagerData } = useUserByIdQuery({
    variables: { id: user?.company?.managerId },
    skip: user?.roles === Roles.VidicoAdmin || !show,
  });

  useEffect(() => {
    if (accountManagerData?.userById.user) {
      dispatch(
        setModal({ calendlyModalInfo: accountManagerData?.userById.user })
      );
    }
  }, [accountManagerData, dispatch]);

  return (
    <Modal show={show} onHide={onModalClose} width={490}>
      <Flex fullWidth justifyContent="left" alignItems="center" mb={3} gap={8}>
        <Icon
          color={theme.colors.primary600}
          size={32}
          name="createNewFolder"
        />

        <Text lg bold>
          New Project
        </Text>
      </Flex>
      <Box mb={5}>
        <Text sm medium color={theme.colors.grey600}>
          Ready to start a new project with us? Enter the project details below
          and let’s get started.
        </Text>
      </Box>
      <RequestNewProjectForm onModalClose={onModalClose} />
    </Modal>
  );
}

export default RequestNewProjectModal;
