import { gql } from '@apollo/client';

import { ERROR_FRAGMENT } from 'graph/common/fragments';

const FAVORITE_FRAGMENT = gql`
  fragment Favorite on Favorite {
    userId
    user {
      id
    }
    assetId
    createdAt
    asset {
      id
      thumbnail
      name
      projectName
      projectId
      dealeted
    }
  }
`;
const USER_BASE_FRAGMENT = gql`
  fragment UserBase on User {
    id
    companyId
    subdomain
    avatar
    roles
    email
    userName
    firstName
    lastName
    createdBy
    createdAt
    lastModifiedBy
    lastModifiedAt
    lastActive
    status
    country
    timeZoneId
    calendlyUrl
    deletedReason
    pathToAvatar
    pathToAvatarThumbnail
  }
`;
const USER_FRAGMENT = gql`
  fragment User on User {
    id
    companyId
    subdomain
    avatar
    roles
    email
    userName
    firstName
    lastName
    createdBy
    createdAt
    lastModifiedBy
    lastModifiedAt
    lastActive
    status
    country
    timeZoneId
    calendlyUrl
    deletedReason
    pathToAvatar
    pathToAvatarThumbnail
    favorites {
      ...Favorite
    }
    company {
      id
      logo
      name
      managerId
      domainNames
    }
  }
  ${FAVORITE_FRAGMENT}
`;

const ACCOUNT_TOKEN_PAYLOAD = gql`
  fragment AccountTokenPayload on AccountTokenPayload {
    email
    role
    subdomainName
    errors {
      ...Error
    }
  }
  ${ERROR_FRAGMENT}
`;
const ACTION_PAYLOAD = gql`
  fragment ActionPayload on ActionPayload {
    isSuccess
    errors {
      ...Error
    }
  }
  ${ERROR_FRAGMENT}
`;
const ACTION_INVITE_PAYLOAD = gql`
  fragment AccountInvitePayload on AccountInvitePayload {
    isUnSuccess
    inviteResponses {
      email
      isSuccess
    }
    errors {
      ...Error
    }
  }
  ${ERROR_FRAGMENT}
`;
export {
  USER_FRAGMENT,
  USER_BASE_FRAGMENT,
  ACCOUNT_TOKEN_PAYLOAD,
  ACTION_PAYLOAD,
  FAVORITE_FRAGMENT,
  ACTION_INVITE_PAYLOAD,
};
