import React, { Ref, useState } from 'react';
import styled, { useTheme } from 'styled-components';

import Flex from 'components/atoms/Flex/Flex';
import Icon from 'components/atoms/Icon/Icon';

import { IIconButtonProps } from './IconButton.interface';

const ButtonStyled = styled.button<{ hoverColor?: string }>`
  border: none;
  background-color: transparent;
  border-radius: 6px;
  cursor: pointer;
  padding: 0;
  ${({ hoverColor }) =>
    hoverColor
      ? `&:hover {
    background-color: ${hoverColor};}`
      : ''};
`;

const IconButton = React.forwardRef(
  (
    {
      iconName,
      iconSize = 15,
      iconColor,
      buttonSize = 40,
      hoverColor,
      hoverIconName,
      disabled,
      ...args
    }: IIconButtonProps,
    ref?: Ref<HTMLButtonElement>
  ) => {
    const theme = useTheme();
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);
    return (
      <ButtonStyled
        hoverColor={hoverColor}
        disabled={disabled}
        ref={ref}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        {...args}
      >
        <Flex
          w={buttonSize}
          h={buttonSize}
          justifyContent="center"
          alignItems="center"
        >
          <Icon
            size={iconSize}
            color={disabled ? theme.colors.grey300 : iconColor}
            name={isHovered ? hoverIconName || iconName : iconName}
          />
        </Flex>
      </ButtonStyled>
    );
  }
);

export default IconButton;
