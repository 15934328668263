import React from 'react';
import styled from 'styled-components';

import Box from 'components/atoms/Box/Box';

import ChangePasswordForm from './components/ChangePasswordForm';

const ChangePasswordWrapper = styled(Box)`
  max-width: 705px;
  flex-basis: 705px;
`;
function Password() {
  return (
    <ChangePasswordWrapper>
      <ChangePasswordForm />
    </ChangePasswordWrapper>
  );
}

export default Password;
