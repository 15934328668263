import React from 'react';
import { Dropdown } from 'react-bootstrap';
import styled from 'styled-components';

import Box, { boxStyle, IBoxProps } from 'components/atoms/Box/Box';
import Flex from 'components/atoms/Flex/Flex';
import Icon from 'components/atoms/Icon/Icon';
import Text from 'components/atoms/Text/Text';

import { IContextMenuProp } from './ContextMenu.interface';

const ContextMenuWrapper = styled.ul<IBoxProps>`
  position: fixed;
  z-index: 30 !important;
  ${boxStyle}
`;

const DropdownDivider = styled(Dropdown.Divider)`
  margin: 0;
  border-top: 1px solid ${({ theme }) => theme.colors.grey200};
`;

function ContextMenu({ anchorPoint, menuItems, menuTitle }: IContextMenuProp) {
  return (
    <ContextMenuWrapper
      style={{
        top: anchorPoint.y,
        left: anchorPoint.x,
      }}
    >
      <Dropdown.Menu show>
        <Box px={4} py={2}>
          <Text sm bold>
            {menuTitle}
          </Text>
        </Box>
        <DropdownDivider />
        {menuItems?.map(({ icon, label, onClick, key, href, hide }) =>
          !hide ? (
            <Dropdown.Item
              href={href}
              key={key}
              eventKey={key}
              onClick={onClick}
            >
              <Flex alignItems="center" gap={12}>
                {icon && <Icon size={24} name={icon} />}
                <Text sm medium>
                  {label}
                </Text>
              </Flex>
            </Dropdown.Item>
          ) : null
        )}
      </Dropdown.Menu>
    </ContextMenuWrapper>
  );
}

export default ContextMenu;
