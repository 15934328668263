import { useContext, createContext, useRef, useMemo } from "react";
import { AuthContext, IAuthContext } from "react-oauth2-code-pkce";

import {
  getSessionState
} from '../utils/localStorage/viewer';

interface useSessionCheckIframeProps {
}
const SessionCheckIframeContext = createContext<useSessionCheckIframeProps>({});

const useSessionCheckIframeContext = () => useContext(SessionCheckIframeContext);

function SessionCheckIframeProvider({ children }: { children: JSX.Element }) {

  const contentRef = useRef<HTMLIFrameElement | null>(null);

  const unchangedState = "unchanged";
  const clientId = `${process.env.REACT_APP_CLIENT_ID}`;
  const iframeId = `check-session-iframe`;
  const identityServerUrl = `${process.env.REACT_APP_IDENTITY_URL}`;
  const identityServerCheckSession = `${identityServerUrl}/connect/checksession`;
  let timerID: any = null;

  function init() {
    setTimer();
    window.addEventListener("message", receiveMessage, false);
  }

  function setTimer() {
    checkSession();
    timerID = setInterval(checkSession, 3 * 1000);
  }

  function stopTimer() {
    clearInterval(timerID);
    timerID = null
  }

  function checkSession() {
    const sessionState = getSessionState();
     if (!sessionState) {
      stopTimer();
     }
    const win = (window.parent?.document.getElementById(iframeId) as HTMLIFrameElement)?.contentWindow;
    if (win) {
      const message = `${clientId} ${sessionState}`;
      win?.postMessage(message, identityServerCheckSession);
    }
  }

  function receiveMessage(e: MessageEvent) {
    if (e.origin !== identityServerUrl) {
      return;
    }
   console.log("receiveMessage", e.data);
    if (e.data !== unchangedState) {
      login();
    }
  }

  const { login } = useContext<IAuthContext>(AuthContext);
  const value = useMemo(() => ({

  }), []);

  return (
    <SessionCheckIframeContext.Provider value={value}>
      {children}
      <iframe ref={contentRef} title='session-iframe' onLoad={init} src={identityServerCheckSession} id={iframeId} hidden />
    </SessionCheckIframeContext.Provider>
  );
}

export { SessionCheckIframeProvider, SessionCheckIframeContext, useSessionCheckIframeContext };