import React from 'react';
import { useTheme } from 'styled-components';

import Avatar from 'components/atoms/Avatar/Avatar';
import Box from 'components/atoms/Box/Box';
import Spinner from 'components/atoms/Spinner/Spinner';
import Text from 'components/atoms/Text/Text';
import { Modal } from 'components/molecules/Modal/Modal';

import {
  CompanyFragment,
  useGetCompanyByIdBaseInfoQuery,
} from 'graph/generated.graphql';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectEditWorkspaceModal, setModal } from 'redux/modal/modalSlice';

import EditWorkspaceForm from './components/EditWorkspaceForm';

type AddWorkspaceModalProps = {
  refetch: () => void;
  editedWorkspaceId: CompanyFragment['id'];
};
function EditWorkspaceModal({
  refetch,
  editedWorkspaceId,
}: AddWorkspaceModalProps) {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const show = useAppSelector(selectEditWorkspaceModal);
  const onCloseModal = () =>
    dispatch(setModal({ showEditWorkspaceModal: false }));

  const { data: companyData, loading: isCompanyLoading } =
    useGetCompanyByIdBaseInfoQuery({
      variables: {
        companyId: editedWorkspaceId,
      },
      skip: !editedWorkspaceId,
    });

  const company = companyData?.viewCompany.company;
  return (
    <Modal show={show} onHide={onCloseModal} width={490}>
      {isCompanyLoading ? (
        <Spinner />
      ) : (
        <>
          <Box mb={3}>
            <Avatar xl src={company?.logo || undefined} text={company?.name} />
          </Box>
          <Box mb={2}>
            <Text
              wordBreak="break-word"
              textAlign="center"
              lg
              medium
              color={theme.colors.grey900}
            >
              {company?.name}
            </Text>
          </Box>
          <Box mb={2}>
            <Text sm medium color={theme.colors.grey600}>
              Edit the company name, profile image and team members here.
            </Text>
          </Box>
          <EditWorkspaceForm
            company={company || {}}
            refetch={refetch}
            onModalClose={onCloseModal}
            showModal={show}
          />
        </>
      )}
    </Modal>
  );
}

export default EditWorkspaceModal;
