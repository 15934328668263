import { gql } from '@apollo/client';

const ERROR_FRAGMENT = gql`
  fragment Error on Error {
    message
    code
  }
`;
const PAGE_INFO = gql`
  fragment CollectionSegmentInfo on CollectionSegmentInfo {
    hasNextPage
    hasPreviousPage
  }
`;
export { ERROR_FRAGMENT, PAGE_INFO };
