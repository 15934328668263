import { Roles } from 'graph/generated.graphql';

export const getInitialFromText = (text: string) =>
  text
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase())
    .slice(0, 2)
    .join('');

export const convertBytesToMb = (bytes: number): string => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes <= 0) return '0 MB';
  let i = Math.floor(Math.log(bytes) / Math.log(1000));
  if (i === 1) {
    i += 1;
  }
  const convertedValue = parseFloat((bytes / 1000 ** i).toFixed(2));
  return `${convertedValue} ${sizes[i]}`;
};

export const convertBytes = (bytes: number): string => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes <= 0) return '0';
  const i = Math.floor(Math.log(bytes) / Math.log(1000));
  const convertedValue = parseFloat((bytes / 1000 ** i).toFixed(2));
  return `${convertedValue} ${sizes[i]}`;
};
export const convertBitesRate = (bitesPerSec: number): string => {
  const sizes = ['Bytes/s', 'KBit/s', 'MBit/s', 'GBit/s', 'TBit/s'];
  if (bitesPerSec === 0) return '0 Bit/s';
  const i = Math.floor(Math.log(bitesPerSec) / Math.log(1024));
  const convertedValue = parseFloat((bitesPerSec / 1024 ** i).toFixed(2));
  return `${convertedValue} ${sizes[i]}`;
};

export const getExtension = (filename: string) =>
  filename.split('.').pop()?.toUpperCase();

export const getFileNameWithoutExtension = (filename: string) => {
  const array = filename.split('.');
  return array.length > 1 ? array.slice(0, -1).join('.') : filename;
};
export const getUserRolesName = (role: Roles) => {
  let result = '';
  if (role === Roles.Admin) {
    result = 'Admin';
  }
  if (role === Roles.Member) {
    result = 'Member';
  }
  if (role === Roles.VidicoAdmin) {
    result = 'Vidico admin';
  }
  if (role === Roles.Owner) {
    result = 'Owner';
  }
  return result;
};
export const capitalized = (word: string) => {
  const normalizeWord = word.toLowerCase();
  return normalizeWord.charAt(0).toUpperCase() + normalizeWord.slice(1);
};
