import React, { useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import AssetCard from 'components/atoms/AssetCard/AssetCard';

import {
  AssetFragment,
  Roles,
  useMarkAssetsAsFavoriteMutation,
} from 'graph/generated.graphql';
import { useAppSelector } from 'redux/hooks';
import { selectViewer } from 'redux/viewer/viewerSlice';
import { RoutesPath } from 'utils/constants/routes';
import { DragSelectContext } from 'utils/context/dragSelectContext/dragSelectContext';
import { buildLink } from 'utils/helpers/route';
import {
  getIconNameByFileType,
  getThumbnailIconByFileType,
} from 'utils/helpers/workspace';

const ListItemStyle = styled.li`
  border-radius: 6px;
  overflow: hidden;
  height: max-content;
  border: 2px solid transparent;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.grey100};
    border: 2px solid ${({ theme }) => theme.colors.grey100};
    box-shadow: 0px 0px 0px 4px ${({ theme }) => theme.colors.grey100};
    & svg {
      color: ${({ theme }) => theme.colors.grey400};
    }
  }
  &.ds-selected {
    border: 2px solid ${({ theme }) => theme.colors.primary600};
    background-color: ${({ theme }) => theme.colors.grey100};
    & svg {
      color: ${({ theme }) => theme.colors.grey400};
    }
  }
`;
function ListItemForSearch({
  asset,
  onItemClick,
}: {
  asset: AssetFragment;
  onItemClick: Function;
}) {
  const inputRef = useRef(null);
  const { ds } = useContext(DragSelectContext);
  const navigate = useNavigate();
  const viewer = useAppSelector(selectViewer);
  const [markAsFavorite] = useMarkAssetsAsFavoriteMutation();
  const isVidicoAdmin = viewer?.roles === Roles.VidicoAdmin;
  const assetLink = ({ companyId, projectId, id }: AssetFragment) =>
    isVidicoAdmin
      ? buildLink(RoutesPath.ViewAssetById, {
          companyId,
          projectId,
          assetId: id,
        })
      : buildLink(RoutesPath.HomeViewAssetById, { projectId, assetId: id });

  // adding a selectable element
  useEffect(() => {
    const element = inputRef.current as unknown as HTMLElement;
    if (!element || !ds) return;
    ds.addSelectables(element);
  }, [ds, inputRef]);
  return (
    <ListItemStyle
      key={asset.id}
      id={asset.id}
      ref={inputRef}
      aria-labelledby="Selectable"
      className="ds-selectable"
      onClick={onItemClick(inputRef.current)}
    >
      <AssetCard
        asset={asset}
        textIcon={getIconNameByFileType(asset.type)}
        thumbnailIcon={getThumbnailIconByFileType(asset.type)}
        onDoubleClick={() => navigate(assetLink(asset))}
        onStarButtonClick={
          !isVidicoAdmin
            ? () => markAsFavorite({ variables: { assetsId: asset.id } })
            : undefined
        }
        isStarred={!!viewer?.favorites?.find((a) => a.assetId === asset.id)}
      />
    </ListItemStyle>
  );
}

export default ListItemForSearch;
