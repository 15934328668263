import React from 'react';
import styled from 'styled-components';

import Box from 'components/atoms/Box/Box';
import Flex from 'components/atoms/Flex/Flex';
import H from 'components/atoms/H/H';
import Text from 'components/atoms/Text/Text';

import SignUpContainer from 'containers/Authentification/SignUp/SignUp';
import { device } from 'utils/styles/device';

const SignUpRightSide = styled(Flex)`
  background-image: ${({ theme }) => theme.backgrounds.gradientPrimary600};
  color: ${({ theme }) => theme.colors.base};
  overflow: hidden;
  min-height: 1024px;
  max-height: 100vh;

  @media ${device.laptopL} {
    padding-left: ${({ theme }) => theme.size(10)};
  }
`;
const BoxStyled = styled(Box)`
  display: inline-table;
  border-radius: 12px;
  height: 636px;
  border: 5px solid ${({ theme }) => theme.colors.vidicoBlack};
  overflow: hidden;
`;

function SignUp() {
  return (
    <main>
      <Flex alignItems="stretch">
        <SignUpContainer />
        <SignUpRightSide flexDirection="column" flex={1} pl={20} pt={38}>
          <Box mb={6} w={528}>
            <H lg bold>
              Start turning your ideas into reality.
            </H>
          </Box>
          <Box mb={26} w={528}>
            <Text lg medium>
              Create a free account and get full access to all features for
              30-days. No credit card needed. Get started in 2 minutes.
            </Text>
          </Box>
          <BoxStyled>
            <img
              width={1024}
              src="/assets/images/signUpImage.jpg"
              alt="SignUp web page"
            />
          </BoxStyled>
        </SignUpRightSide>
      </Flex>
    </main>
  );
}

export default SignUp;
