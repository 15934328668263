import React from 'react';
import { useTheme } from 'styled-components';

import Box from 'components/atoms/Box/Box';
import Text from 'components/atoms/Text/Text';

import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  selectVideoTypeFileToDownload,
  selectVideoTypeSelectionModal,
  setModal,
} from 'redux/modal/modalSlice';

import { VideoSizeType } from '../../../utils/constants/video-size-type';
import Flex from '../../atoms/Flex/Flex';
import Icon from '../../atoms/Icon/Icon';
import ActionModal, { ButtonsAlignmentName } from '../ActionModal/ActionModal';
import { Modal } from '../Modal/Modal';

function DownloadVideoModal() {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const show = useAppSelector(selectVideoTypeSelectionModal);
  const videoTypeFileToDownload = useAppSelector(selectVideoTypeFileToDownload);
  const onCloseModal = () =>
    dispatch(
      setModal({
        showVideoTypeSelectionModal: false,
      })
    );

  const onOriginalVideSelection = () => dispatchModal('original');
  const onCompressedVideSelection = () => dispatchModal('compressed');

  const dispatchModal = (videoTypeSize: VideoSizeType) =>
    dispatch(
      setModal({
        videoTypeSize,
        showVideoTypeSelectionModal: false,
        videoTypeFileToDownload: undefined,
        fileToDownload: videoTypeFileToDownload,
        popupMessageInfo: { text: 'Download started' },
      })
    );

  return (
    <Modal show={show} onHide={onCloseModal} width={550}>
      <Flex fullWidth justifyContent="left" mb={4}>
        <Text lg bold color={theme.colors.primary600}>
          <Icon size={28} mr={2} name="download01" />
        </Text>
        <Text lg bold>
          Download as...
        </Text>
      </Flex>
      <Box fullWidth mb={8}>
        <Text medium color={theme.colors.grey500}>
          Choose your video quality:
          <Box ml={2}>
            <Text medium>
              &#8226; Optimized: Created for web streaming, faster download.
            </Text>
            <Text medium>
              &#8226; Uncompressed: Maximum quality available, slower download.
            </Text>
          </Box>
        </Text>
      </Box>
      <ActionModal
        primaryButtonProps={{
          text: 'Optimized videos',
          type: 'button',
          onClick: onCompressedVideSelection,
        }}
        secondaryButtonProps={{
          text: 'Uncompressed videos',
          onClick: onOriginalVideSelection,
        }}
        buttonsAlignment={ButtonsAlignmentName.RIGHT}
      />
    </Modal>
  );
}

export default DownloadVideoModal;
