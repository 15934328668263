import { useCallback, useMemo, useState } from 'react';
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

import Avatar from 'components/atoms/Avatar/Avatar';
import Box from 'components/atoms/Box/Box';
import Flex from 'components/atoms/Flex/Flex';
import IconButton from 'components/atoms/IconButton/IconButton';
import Text from 'components/atoms/Text/Text';
import Table, {
  OnTableStateChangedType,
} from 'components/molecules/Table/Table';

import AddWorkspaceModal from 'containers/Workspace/AddWorkspaceModal/AddWorkspaceModal';
import EditWorkspaceModal from 'containers/Workspace/EditWorkspaceModal/EditWorkspaceModal';
import {
  CompanyFragment,
  QueryCompaniesListArgs,
  SortEnumType,
  useCompaniesListQuery,
} from 'graph/generated.graphql';
import { useAppDispatch } from 'redux/hooks';
import { setModal } from 'redux/modal/modalSlice';
import { RoutesPath } from 'utils/constants/routes';
import { defaultDateFormat } from 'utils/transformers/momentFormats';
import { convertBytes } from 'utils/transformers/text';

const StyledFlex = styled(Flex)`
  cursor: pointer;
`;
const StyledText = styled(Text)`
  word-break: break-word;
`;
function WorkspaceListTable() {
  const [editedWorkspaceId, setEditedWorkspaceId] = useState<
    CompanyFragment['id'] | null
  >(null);
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const initialPageSize = 50;
  const {
    data: companiesListData,
    previousData: prevCompaniesListData,
    loading: companiesListLoading,
    refetch: companiesListRefetch,
  } = useCompaniesListQuery({
    variables: {
      take: initialPageSize,
      order: [{ name: SortEnumType.Asc }],
    },
  });
  const { companiesList } = companiesListData || {};
  const { totalCount, items } = companiesList || {};
  const previousCompaniesList =
    prevCompaniesListData?.companiesList?.items || [];
  const currentCompaniesList = companiesListLoading
    ? previousCompaniesList
    : items || [];

  const onTableStateChanged: OnTableStateChangedType = useCallback(
    ({ pageIndex, pageSize, sortBy }) => {
      const variables: QueryCompaniesListArgs = {
        skip: pageIndex * pageSize,
        take: pageSize,
        order: sortBy.map((item) => ({
          [item.id]: item.desc ? SortEnumType.Desc : SortEnumType.Asc,
        })),
      };
      companiesListRefetch(variables);
    },
    [companiesListRefetch]
  );
  const onVideoPlannerView = useCallback(
    (id: string) => {
      navigate(`${RoutesPath.VideoPlanner}/${id}`);
    },
    [navigate]
  );
  const onWorkspaceView = useCallback(
    (id: string) => {
      navigate(`${RoutesPath.Workspaces}/${id}/projects`);
    },
    [navigate]
  );

  const onWorkspaceEdit = useCallback(
    (id: string) => {
      setEditedWorkspaceId(id);
      dispatch(setModal({ showEditWorkspaceModal: true }));
    },
    [dispatch]
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Company',
        accessor: 'name',
        Cell: ({ row }: any) => (
          <StyledFlex
            w={300}
            alignItems="center"
            onClick={() => onWorkspaceView(row.original.id)}
          >
            <Box mr={3}>
              <Avatar md src={row.original.logo} text={row.original.name} />
            </Box>
            <StyledText w={300} sm medium color={theme.colors.grey900}>
              {row.original.name}
            </StyledText>
          </StyledFlex>
        ),
      },
      {
        Header: 'Team size',
        accessor: 'teamMembersCount',
      },
      {
        Header: 'Date created',
        accessor: 'createdAt',
        Cell: ({ cell: { value } }: any) => (
          <span>{value ? defaultDateFormat(value) : ''}</span>
        ),
      },
      {
        Header: 'Last updated',
        accessor: 'lastModifiedAt',
        Cell: ({ cell: { value } }: any) => (
          <span>{value ? defaultDateFormat(value) : ''}</span>
        ),
      },
      {
        Header: 'Last session',
        accessor: 'lastSessionAt',
        Cell: ({ cell: { value } }: any) => (
          <span>{value ? defaultDateFormat(value) : ''}</span>
        ),
      },
      {
        Header: 'No. of projects',
        accessor: 'projectsCount',
      },
      {
        Header: 'Total size of assets',
        accessor: 'totalAssetsSize',
        Cell: ({ cell: { value } }: any) => <span>{convertBytes(value)}</span>,
      },
      {
        accessor: 'id',
        sort: false,
        Cell: ({ row }: any) => (
          <Box w={120}>
            <Row className="gx-1 flex-nowrap">
              <Col>
                <OverlayTrigger
                  key={row.original.name}
                  overlay={
                    <Tooltip id={row.original.name}>Open video planner</Tooltip>
                  }
                >
                  <IconButton
                    iconColor={theme.colors.grey500}
                    iconName="logIn03"
                    onClick={() => onVideoPlannerView(row.original.id)}
                  />
                </OverlayTrigger>
              </Col>
              <Col>
                <OverlayTrigger
                  key={`${row.original.name}${row.original.id}`}
                  overlay={
                    <Tooltip id={`${row.original.name}${row.original.id}`}>
                      Edit company
                    </Tooltip>
                  }
                >
                  <IconButton
                    iconColor={theme.colors.grey500}
                    iconName="edit05"
                    onClick={() => onWorkspaceEdit(row.original.id)}
                  />
                </OverlayTrigger>
              </Col>
              <Col>
                {' '}
                <OverlayTrigger
                  key={`${row.original.id}${row.original.name}`}
                  overlay={
                    <Tooltip id={`${row.original.id}${row.original.name}`}>
                      Delete company
                    </Tooltip>
                  }
                >
                  <IconButton
                    iconColor={theme.colors.grey500}
                    iconName="trash01"
                  />
                </OverlayTrigger>
              </Col>
            </Row>
          </Box>
        ),
      },
    ],
    [onWorkspaceEdit, onWorkspaceView, onVideoPlannerView, theme]
  );
  return (
    <>
      <Table
        columns={columns}
        data={currentCompaniesList}
        pagination={{
          total: totalCount || 0,
          pageSize: initialPageSize,
          pageSizeOptions: ['25', '50', '100'],
        }}
        initialState={{ sortBy: [{ id: 'name', desc: false }] }}
        onTableStateChanged={onTableStateChanged}
      />
      <AddWorkspaceModal refetch={companiesListRefetch} />
      <EditWorkspaceModal
        editedWorkspaceId={editedWorkspaceId}
        refetch={companiesListRefetch}
      />
    </>
  );
}

export default WorkspaceListTable;
