import React, { useContext, useEffect } from 'react';

import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  selectFileToDownload,
  selectVideoTypeSize,
  setModal,
} from 'redux/modal/modalSlice';
import { DragSelectContext } from 'utils/context/dragSelectContext/dragSelectContext';
import useFileDownloader from 'utils/hooks/useFileDownloader/useFileDownloader';

import DownloadModal from '../DownloadModal/DownloadModal';
import DownloadNotification from '../DownloadNotification/DownloadNotification';

function DownloadProgress() {
  const dispatch = useAppDispatch();
  const { ds } = useContext(DragSelectContext);

  const fileToDownload = useAppSelector(selectFileToDownload);
  const videoType = useAppSelector(selectVideoTypeSize);
  const {
    downloadFiles,
    totalProgress,
    cancelDownload,
    isDownloading,
    errorState,
  } = useFileDownloader();
  const handleCancelDownload = () => {
    cancelDownload();
    dispatch(
      setModal({
        showDownloadCancelModal: false,
      })
    );
  };
  useEffect(() => {
    if (fileToDownload) {
      downloadFiles(fileToDownload, videoType);
      dispatch(
        setModal({
          fileToDownload: undefined,
          videoTypeSize: undefined,
        })
      );
      ds?.clearSelection(true);
    }
  }, [dispatch, downloadFiles, ds, fileToDownload, videoType]);

  useEffect(() => {
    if (errorState)
      dispatch(
        setModal({
          showPopupMessage: true,
          popupMessageInfo: { text: 'Download failed' },
        })
      );
  }, [dispatch, errorState]);

  window.onbeforeunload = (event) => {
    if (isDownloading) {
      const confirmationMessage = 'Are you sure you want to leave this page?';

      event.preventDefault();
      // eslint-disable-next-line no-param-reassign
      event.returnValue = confirmationMessage;
      return confirmationMessage;
    }

    // Default return value when the condition is not met
    return undefined;
  };
  return (
    <>
      {isDownloading && (
        <DownloadNotification
          totalProgress={totalProgress}
          show={isDownloading}
        />
      )}
      <DownloadModal cancelDownload={handleCancelDownload} />
    </>
  );
}

export default DownloadProgress;
