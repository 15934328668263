import React, { FormEvent, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useTheme } from 'styled-components';

import { Alert } from 'components/atoms/Alert/Alert';
import Box from 'components/atoms/Box/Box';
import Button from 'components/atoms/Button/Button';
import Flex from 'components/atoms/Flex/Flex';
import FormControl from 'components/atoms/FormControl/FormControl';
import FormFeetback from 'components/atoms/FormFeetback/FormFeetback';
import InputPassword from 'components/atoms/InputPassword/InputPassword';
import Text from 'components/atoms/Text/Text';
import PasswordStrengthMeter from 'components/molecules/PasswordStrengthMeter/PasswordStrengthMeter';

import { ICompleatSignUpFormProps } from 'containers/Authentification/CompleatSignUp/components/CompleatSignUpForm/CompleatSignUpForm.interfaces';
import { ISignInProps } from 'containers/Authentification/SignIn/SignIn.interface';
import {
  AccountTokenPayloadFragment,
  useCompleatSignUpMutation,
} from 'graph/generated.graphql';
import { getRedirectUrlV3 } from 'utils/authentication/getRedirectUrl/getRedirectUrl';
import { ErrorsMessage } from 'utils/constants/errors';
import { FormErrorMessages, FormErrorTypes } from 'utils/constants/forms';
import { isLinkAlreadyUsedError } from 'utils/errors/isGqlError';
import {
  useFormState,
  useFormValidation,
} from 'utils/hooks/useFormValidation/useFormValidation';
import {
  checkPasswordStrongness,
  checkPasswordToWhiteSpace,
} from 'utils/validators/validators';

enum FormFields {
  FirstName = 'firstName',
  LastName = 'lastName',
  Password = 'password',
}

const FormFieldsList = [
  FormFields.FirstName,
  FormFields.LastName,
  FormFields.Password,
];
const passwordMessageMap = {
  [FormErrorTypes.Blank]: FormErrorMessages.PASSWORD_BLANK,
  [FormErrorTypes.Warning]: FormErrorMessages.PASSWORD_WARNING,
  [FormErrorTypes.Invalid]: FormErrorMessages.PASSWORD_INVALID,
  [FormErrorTypes.Valid]: FormErrorMessages.PASSWORD_VALID,
};
function CompleatSignUpForm({
  token,
  firstName: firstNameProp,
  lastName: lastNameProp,
}: ICompleatSignUpFormProps) {
  const initialFormValue = {
    [FormFields.FirstName]: firstNameProp || '',
    [FormFields.LastName]: lastNameProp || '',
  };
  const theme = useTheme();
  const [message, setMessage] = useState<ISignInProps['message']>({
    type: 'warning',
    show: false,
    message: '',
  });

  const [compleatRegister, { loading: isCompleatRegisterLoading }] =
    useCompleatSignUpMutation({
      onError(error) {
        error.graphQLErrors.forEach((gqlError) => {
          if (isLinkAlreadyUsedError(gqlError)) {
            formOnError();
            return setMessage({
              type: 'warning',
              show: true,
              message: ErrorsMessage.tokenExpired,
            });
          }
          return formOnError(gqlError.message);
        });
      },
      onCompleted({ setPassword: setPasswordData }) {
        onSuccess(setPasswordData);
      },
    });

  const [
    formErrors,
    isFormValid,
    [setFirstNameError, setLatNameError, setPasswordError],
  ] = useFormValidation(FormFieldsList);

  const [
    formState,
    [setFirstName, setLastName, setPassword],
    [formOnSubmit, formOnError, formOnSuccess],
  ] = useFormState(FormFieldsList, initialFormValue);

  const { isLoading, errorSlug } = formState;

  const validName = (type: 'first' | 'last', value: string) => {
    const nameValidator =
      type === 'first' ? setFirstNameError : setLatNameError;
    if (!value) {
      nameValidator({
        type: FormErrorTypes.Invalid,
        message:
          type === 'first'
            ? FormErrorMessages.FIRST_NAME_BLANK
            : FormErrorMessages.LAST_NAME_BLANK,
      });
      return false;
    }
    if (value.length > 40) {
      nameValidator({
        type: FormErrorTypes.Invalid,
        message: FormErrorMessages.MAX_40_CHARACTERS,
      });
      return false;
    }
    nameValidator(null);
    return true;
  };

  const validPassword = (value: string) => {
    if (checkPasswordToWhiteSpace(value)) {
      setPasswordError({
        type: FormErrorTypes.Invalid,
        message: FormErrorMessages.SPACES_IN_PASSWORD,
      });
      return false;
    }
    const type = checkPasswordStrongness(value);
    setPasswordError({
      type,
      message: passwordMessageMap[type],
    });
    return type === FormErrorTypes.Valid || type === FormErrorTypes.Warning;
  };

  const onFirstNameTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(e.target.value);
    setFirstNameError(null);
  };

  const onLastNameTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(e.target.value);
    setLatNameError(null);
  };

  const onPasswordTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    validPassword(e.target.value);
  };

  const onSuccess = (data: AccountTokenPayloadFragment) => {
    const { role, subdomainName } = data;

    formOnSuccess();
    const domainWithSubDomain = getRedirectUrlV3(role, subdomainName);
    window.location.assign(domainWithSubDomain);
  };
  const { firstName, lastName, password } = formState;

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    if (!validName('first', firstName) || !validName('last', lastName)) {
      return null;
    }
    if (!validPassword(password)) {
      return null;
    }
    formOnSubmit();

    compleatRegister({
      variables: {
        key: token,
        firstName,
        lastName,
        password,
      },
    });
    return null;
  };
  return (
    <Form
      className="w-100"
      noValidate
      validated={isFormValid}
      onSubmit={handleSubmit}
    >
      <Flex justifyContent="center">
        <Alert
          variant={message.type}
          transition={false}
          iconName="infoCircle"
          show={message.show}
        >
          {message.message}
        </Alert>
      </Flex>
      <Row>
        <Col>
          <Form.Group controlId="validationCustom01">
            <Form.Label>
              <Text color={theme.colors.grey900} sm semibold>
                First name
              </Text>
            </Form.Label>
            <FormControl
              maxLength={40}
              placeholder="Steve"
              defaultValue={firstNameProp}
              required
              $errorType={formErrors[FormFields.FirstName]?.type}
              onChange={onFirstNameTextChange}
            />
            <FormFeetback type={formErrors[FormFields.FirstName]?.type}>
              {formErrors[FormFields.FirstName]?.message}
            </FormFeetback>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="validationCustom02">
            <Form.Label>
              <Text color={theme.colors.grey900} sm semibold>
                Last name
              </Text>
            </Form.Label>
            <FormControl
              maxLength={40}
              defaultValue={lastNameProp}
              $errorType={formErrors[FormFields.LastName]?.type}
              placeholder="Spielberg"
              onChange={onLastNameTextChange}
              autoComplete="off"
            />
            <FormFeetback type={formErrors[FormFields.LastName]?.type}>
              {formErrors[FormFields.LastName]?.message}
            </FormFeetback>
          </Form.Group>
        </Col>
      </Row>
      <Form.Group className="mb-1" controlId="formBasicPassword">
        <Form.Label>
          <Text color={theme.colors.grey900} sm semibold>
            Create Password *
          </Text>
        </Form.Label>
        <InputPassword
          autoComplete="new-password"
          $errorType={formErrors[FormFields.Password]?.type}
          placeholder="Enter password"
          onChange={onPasswordTextChange}
        />
        <FormFeetback type={formErrors[FormFields.Password]?.type}>
          {formErrors[FormFields.Password]?.message}
        </FormFeetback>
      </Form.Group>
      <PasswordStrengthMeter password={password} mb={7} />
      <Box mb={4}>
        <Button
          fullWidth
          round
          lg
          semibold
          py={4}
          type="submit"
          disabled={!isFormValid}
          isLoading={isLoading || isCompleatRegisterLoading}
        >
          <Box>Log in</Box>
        </Button>
      </Box>
      <Form.Group>
        <FormFeetback type={FormErrorTypes.Invalid}>{errorSlug}</FormFeetback>
      </Form.Group>
    </Form>
  );
}

export default CompleatSignUpForm;
