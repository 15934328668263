import { ENVIRONMENT_TYPE, SUBDOMAIN } from 'utils/constants/common';

export const getSubdomainByEnv = (subdomain: string) => {
  let subdomainPrefix = '';
  let sub = subdomain;
  const REACT_APP_ENV = process.env.REACT_APP_ENV as string;
  if (REACT_APP_ENV === ENVIRONMENT_TYPE.Dev) {
    if (subdomain === SUBDOMAIN.App) {
      sub = '';
    }
    subdomainPrefix = '';
  }
  if (REACT_APP_ENV === ENVIRONMENT_TYPE.Staging) {
    subdomainPrefix = 'stag-';
  }
  return `${subdomainPrefix}${sub}`;
};
