import {
  TOKEN,
  REDIRECT_IN_PROGRESS,
  SESSION_STATE
} from '../constants/localStorage';

export const setSessionState = (value: string) => localStorage.setItem(SESSION_STATE, value);
export const removeSessionState = () => localStorage.removeItem(SESSION_STATE);
export const getSessionState = () => localStorage.getItem(SESSION_STATE);

export const setRedirectInProgress = (value: string) => localStorage.setItem(REDIRECT_IN_PROGRESS, value);
export const getRedirectInProgress = () => localStorage.getItem(REDIRECT_IN_PROGRESS);

export const removeToken = () => localStorage.removeItem(TOKEN);
