import React, { useState } from 'react';
import { InputGroup } from 'react-bootstrap';
import styled from 'styled-components';

import { IInputPasswordProps } from './InputPassword.interface';

import { Control, getErrorTypeColor } from '../FormControl/FormControl';
import Icon from '../Icon/Icon';

const InputGroupText = styled(InputGroup.Text)<{
  isFocused: boolean;
  isDisabled: boolean;
}>`
  background-color: transparent;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  & > svg {
    color: ${({ theme }) => theme.colors.grey400};
  }
  border: ${({ $isFocused, theme, $errorType }) =>
    $isFocused
      ? `2px solid ${
          getErrorTypeColor(theme, $errorType) === theme.colors.grey900
            ? theme.colors.primary600
            : getErrorTypeColor(theme, $errorType)
        }; margin: -1px;`
      : `1px solid ${getErrorTypeColor(theme, $errorType)};`};
  border: ${({ $isDisabled, theme }) =>
    $isDisabled ? `1px solid ${theme.colors.grey300};` : ''};
  background-color: ${({ $isDisabled, theme }) =>
    $isDisabled ? `1px solid ${theme.colors.grey50};` : ''};
  border-left: 0px;
  cursor: pointer;
`;
const FormControlStyle = styled(Control)`
  display: flex;
  flex: 1;
  border-right: 0px;
  ::placeholder {
    border-right: 0px;
  }
  :focus {
    border-right: 0px;
  }
`;

function InputPassword({
  onFocus,
  onBlur,
  disabled,
  $errorType,
  ...args
}: IInputPasswordProps) {
  const [isFocused, setIsFocused] = useState(false);
  const [isPasswordType, setIsPasswordType] = useState(true);
  const onFocusSpy = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(true);
    return onFocus && onFocus(e);
  };
  const onBlurSpy = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(false);
    return onBlur && onBlur(e);
  };
  const onClick = () => {
    setIsPasswordType(!isPasswordType);
  };
  return (
    <InputGroup>
      <FormControlStyle
        {...args}
        disabled={disabled}
        $errorType={$errorType}
        onFocus={onFocusSpy}
        onBlur={onBlurSpy}
        type={isPasswordType ? 'password' : 'text'}
      />
      <InputGroupText
        $isFocused={isFocused}
        $isDisabled={disabled}
        $errorType={$errorType}
        onClick={onClick}
      >
        <Icon
          w={24}
          name={isPasswordType ? 'googleVisibilityOff' : 'googleVisibilityOn'}
        />
      </InputGroupText>
    </InputGroup>
  );
}

export default InputPassword;
