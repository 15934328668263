import styled, { css } from 'styled-components';

import { boxStyle } from 'components/atoms/Box/Box';

import { IFlexProps } from './Flex.interfaces';

export const flexStyle = css<IFlexProps>`
  display: flex;
  ${({ flex }) => (flex ? `flex: ${flex};` : '')};
  justify-content: ${({ justifyContent = 'start' }) => justifyContent};
  align-items: ${({ alignItems = 'start' }) => alignItems};
  flex-direction: ${({ flexDirection = 'row' }) => flexDirection};
  flex-wrap: ${({ flexWrap = 'nowrap' }) => flexWrap};
  ${boxStyle}
`;

const Flex = styled.div<IFlexProps>`
  ${flexStyle}
`;

export default Flex;
