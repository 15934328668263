import React, { FormEvent } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useTheme } from 'styled-components';

import Flex from 'components/atoms/Flex/Flex';
import FormCheck from 'components/atoms/FormCheck/FormCheck';
import FormControl from 'components/atoms/FormControl/FormControl';
import FormFeetback from 'components/atoms/FormFeetback/FormFeetback';
import Icon from 'components/atoms/Icon/Icon';
import { IOption, Select } from 'components/atoms/Select/Select';
import Text from 'components/atoms/Text/Text';
import Textarea from 'components/atoms/Textarea/Textarea';
import ActionModal, {
  ButtonsAlignmentName,
} from 'components/molecules/ActionModal/ActionModal';

import { Budget, PowerUps } from 'graph/generated.graphql';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { setModal } from 'redux/modal/modalSlice';
import { selectViewer } from 'redux/viewer/viewerSlice';
import { FormErrorMessages, FormErrorTypes } from 'utils/constants/forms';
import {
  useFormState,
  useFormValidation,
} from 'utils/hooks/useFormValidation/useFormValidation';

import { IRequestNewProjectFormProps } from './RequestNewProjectForm.interface';

enum FormFields {
  ProjectName = 'projectName',
  BriefDetails = 'briefDetails',
  BudgetForm = 'budget',
  PowerUpsForm = 'powerUps',
}

const FormFieldsList = [
  FormFields.ProjectName,
  FormFields.BriefDetails,
  FormFields.BudgetForm,
  FormFields.PowerUpsForm,
];
const options: IOption[] = [
  {
    label: '$7,500 – $10,000',
    value: Budget.Small,
    hideIcon: true,
  },
  {
    label: '$10,000 – $20,000',
    value: Budget.Medium,
    hideIcon: true,
  },
  {
    label: '$20,000 – $50,000',
    value: Budget.Large,
    hideIcon: true,
  },
  {
    label: '$50,000+',
    value: Budget.Maximum,
    hideIcon: true,
  },
];
function RequestNewProjectForm({ onModalClose }: IRequestNewProjectFormProps) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectViewer);

  const [formErrors, isFormValid, [setProjectNameError, setBriefDetailsError]] =
    useFormValidation(FormFieldsList);

  const [
    formState,
    [setProjectName, setBriefDetails, setBudget, setPowerUps],
    [formOnSubmit],
  ] = useFormState(FormFieldsList);

  const { isLoading, errorSlug } = formState;

  const validProjectName = (value: string) => {
    if (!value) {
      setProjectNameError({
        type: FormErrorTypes.Invalid,
        message: FormErrorMessages.PROJECT_NAME_BLANK,
      });
      return false;
    }
    if (value.length > 50) {
      setProjectNameError({
        type: FormErrorTypes.Invalid,
        message: FormErrorMessages.MAX_50_CHARACTERS,
      });
      return false;
    }
    setProjectNameError(null);
    return true;
  };

  const validBriefDetails = (value: string) => {
    if (!value) {
      setBriefDetailsError({
        type: FormErrorTypes.Invalid,
        message: FormErrorMessages.BRIEF_DETAILS_BLANK,
      });
      return false;
    }

    setBriefDetailsError(null);
    return true;
  };

  const onProjectNameTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProjectName(e.target.value);
    setProjectNameError(null);
  };

  const onBudgetChange = (newValue: any) => {
    setBudget(newValue.value);
  };

  const onBriefDetailsTextChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setBriefDetails(e.target.value);
    setBriefDetailsError(null);
  };

  const onPowerUpsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPowerUps({
      ...formState.powerUps,
      [e.target.value]: e.target.checked,
    });
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const { projectName, briefDetails, budget, powerUps } = formState;

    if (!validProjectName(projectName) || !validBriefDetails(briefDetails)) {
      return null;
    }

    formOnSubmit();

    const powerUpsResult = powerUps
      ? (Object.keys(powerUps).filter((id) => powerUps[id]) as PowerUps[])
      : undefined;
    dispatch(
      setModal({
        showCalendlyModal: true,
        requestNewProjectModalInfo: {
          companyId: user?.companyId,
          projectName,
          budget,
          briefDetails,
          powerUps: powerUpsResult,
        },
      })
    );
    onModalClose();

    return null;
  };
  return (
    <Form className="w-100" onSubmit={handleSubmit}>
      <Row className="gx-3">
        <Col>
          <Form.Group controlId="validationCustom01">
            <Form.Label>
              <Text sm semibold>
                Project Name *
              </Text>
            </Form.Label>
            <FormControl
              onChange={onProjectNameTextChange}
              placeholder="Project name"
            />
            <FormFeetback type={formErrors[FormFields.ProjectName]?.type}>
              {formErrors[FormFields.ProjectName]?.message}
            </FormFeetback>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="validationSelect">
            <Form.Label>
              <Flex gap={2}>
                <Text sm semibold>
                  Project Budget
                </Text>
                <Text sm regular>
                  (Optional)
                </Text>
              </Flex>
            </Form.Label>
            <Select
              isSearchable={false}
              placeholder="Your project budget"
              options={options}
              onChange={onBudgetChange}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="gx-3">
        <Col>
          <Form.Group controlId="validationCustom01">
            <Form.Label>
              <Text sm semibold>
                Description
              </Text>
            </Form.Label>
            <Textarea
              rows={5}
              onChange={onBriefDetailsTextChange}
              placeholder="Copy in the brief details here. Tell us what you’d like   to achieve with the video as well as a brief description."
            />
            <FormFeetback type={formErrors[FormFields.BriefDetails]?.type}>
              {formErrors[FormFields.BriefDetails]?.message}
            </FormFeetback>
          </Form.Group>
        </Col>
      </Row>
      <Row className="gx-3 mb-3">
        <Col>
          <Form.Group controlId="powerUps">
            <Form.Label>
              <Text sm semibold>
                <Icon
                  size={20}
                  name="flashOn"
                  color={theme.colors.primary600}
                />
                Power-ups
              </Text>
            </Form.Label>
            <FormCheck
              id={PowerUps.Captions}
              name={PowerUps.Captions}
              onChange={onPowerUpsChange}
              type="checkbox"
              value={PowerUps.Captions}
              label={
                <Flex gap={4}>
                  <Text sm medium color={theme.colors.grey900}>
                    Localisations
                  </Text>
                  <Text sm regular color={theme.colors.grey500}>
                    Translate your video into different languages{' '}
                  </Text>
                </Flex>
              }
            />
            <FormCheck
              id={PowerUps.Cutdowns}
              name={PowerUps.Cutdowns}
              onChange={onPowerUpsChange}
              type="checkbox"
              value={PowerUps.Cutdowns}
              label={
                <Flex gap={4}>
                  <Text sm medium color={theme.colors.grey900}>
                    Cutdowns
                  </Text>
                  <Text sm regular color={theme.colors.grey500}>
                    Deliver a more concise message (6s, 15s or 30s)
                  </Text>
                </Flex>
              }
            />
            <FormCheck
              id={PowerUps.Ratios}
              name={PowerUps.Ratios}
              value={PowerUps.Ratios}
              onChange={onPowerUpsChange}
              type="checkbox"
              label={
                <Flex gap={4}>
                  <Text sm medium color={theme.colors.grey900}>
                    Ratios
                  </Text>
                  <Text sm regular color={theme.colors.grey500}>
                    Perfect assets for socials (4:5, 1:1, 16:9 or 9:16)
                  </Text>
                </Flex>
              }
            />
          </Form.Group>
        </Col>
      </Row>
      <ActionModal
        primaryButtonProps={{
          text: 'New Project',
          type: 'submit',
          disabled: !isFormValid,
          isLoading,
        }}
        secondaryButtonProps={{ text: 'Cancel', onClick: onModalClose }}
        buttonsAlignment={ButtonsAlignmentName.RIGHT}
        error={errorSlug}
      />
    </Form>
  );
}

export default RequestNewProjectForm;
