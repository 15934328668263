import React from 'react';
import { Alert } from 'react-bootstrap';
import styled from 'styled-components';

import { IAlertProps } from './Alert.interface';

import Box from '../Box/Box';
import Flex from '../Flex/Flex';
import Icon from '../Icon/Icon';
import Text from '../Text/Text';

const AlertWrapper = styled(Box)`
  .alert-warning {
    background-color: ${({ theme }) => theme.colors.primary25};
    border-color: ${({ theme }) => theme.colors.primary300};
    color: ${({ theme }) => theme.colors.primary700};
  }
  .alert-success {
    background-color: ${({ theme }) => theme.colors.success25};
    border-color: ${({ theme }) => theme.colors.success300};
    color: ${({ theme }) => theme.colors.success700};
  }
  .alert-danger {
    background-color: ${({ theme }) => theme.colors.error25};
    border-color: ${({ theme }) => theme.colors.error300};
    color: ${({ theme }) => theme.colors.error700};
  }
`;
function AlertCustom({ children, iconName, ...args }: IAlertProps) {
  return (
    <AlertWrapper>
      <Alert {...args}>
        <Flex justifyContent="center" alignItems="center" gap={12}>
          {iconName && (
            <Box w={20}>
              <Icon size={20} name={iconName} />
            </Box>
          )}
          <Text sm medium>
            {children}
          </Text>
        </Flex>
      </Alert>
    </AlertWrapper>
  );
}

export { AlertCustom as Alert };
