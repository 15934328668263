import { GraphQLError } from 'graphql';

import { ErrorCodes, InputVariables } from 'utils/constants/errors';

/**
 * Check if GraphQLError with specified code
 */
const isGqlErrorCode = (error: GraphQLError, code: string): boolean =>
  error?.extensions?.code === code;

const isGqlErrorVariable = (error: GraphQLError, variable: string): boolean =>
  error?.extensions?.variable === variable;
const isGqlErrorProperty = (error: GraphQLError, property: string): boolean =>
  error?.extensions?.property === property;

const isCompanyNameExistError = (error: GraphQLError): boolean =>
  isGqlErrorCode(error, ErrorCodes.COMPANY_NAME_ALREADY_EXIST);

const isCompanyDomainExistError = (error: GraphQLError): boolean =>
  isGqlErrorCode(error, ErrorCodes.COMPANY_DOMAIN_ALREADY_EXIST);

const isCompanyNotExistError = (error: GraphQLError): boolean =>
  isGqlErrorCode(error, ErrorCodes.COMPANY_NOT_EXIST);

const isProjectNameExistError = (error: GraphQLError): boolean =>
  isGqlErrorCode(error, ErrorCodes.PROJECT_NAME_ALREADY_EXIST);

const isLinkAlreadyUsedError = (error: GraphQLError): boolean =>
  isGqlErrorCode(error, ErrorCodes.LINK_IS_USED);

const isAuthorizationError = (error: GraphQLError): boolean =>
  isGqlErrorCode(error, ErrorCodes.AUTHORIZATION_FAILED);
const isFileRequiresError = (error: GraphQLError): boolean => {
  if (
    isGqlErrorVariable(error, InputVariables.FILE) &&
    isGqlErrorCode(error, ErrorCodes.HC0018)
  ) {
    return true;
  }
  return false;
};
const isEmailValidError = (error: GraphQLError): boolean => {
  if (
    isGqlErrorProperty(error, InputVariables.EMAIL) &&
    isGqlErrorCode(error, ErrorCodes.PREDICATE_VALIDATOR)
  ) {
    return true;
  }
  return false;
};

const isTokenExpiredError = (error: GraphQLError): boolean =>
  isGqlErrorCode(error, ErrorCodes.TOKEN_EXPIRED);

const isTokenInvalidError = (error: GraphQLError): boolean =>
  isGqlErrorCode(error, ErrorCodes.TOKEN_INVALID);

const isSharedNotExist = (error: GraphQLError): boolean =>
  isGqlErrorCode(error, ErrorCodes.SHARED_LINK_NOT_EXIST);

const isEmailAllReadyExist = (error: GraphQLError): boolean =>
  isGqlErrorCode(error, ErrorCodes.EMAIL_ALREADY_USE);

const isRoleToHigh = (error: GraphQLError): boolean =>
  isGqlErrorCode(error, ErrorCodes.ROLE_TO_HIGH);

const isNoAssesToThisCompany = (error: GraphQLError): boolean =>
  isGqlErrorCode(error, ErrorCodes.NO_ACCESS_TO_THIS_COMPANY);

const isWrongPasswordError = (error: GraphQLError): boolean =>
  isGqlErrorCode(error, ErrorCodes.WRONG_PASSWORD);

const isMinimumLengthValidatorError = (error: GraphQLError): boolean =>
  isGqlErrorCode(error, ErrorCodes.MINIMUM_LENGTH_VALIDATOR);

const isAssetNameShouldBeWithOutExtension = (error: GraphQLError): boolean => {
  if (
    isGqlErrorProperty(error, InputVariables.ASSET_NAME) &&
    isGqlErrorCode(error, ErrorCodes.PREDICATE_VALIDATOR)
  ) {
    return true;
  }
  return false;
};

const isAssetNameExistError = (error: GraphQLError): boolean =>
  isGqlErrorCode(error, ErrorCodes.ASSET_NAME_ALREADY_EXIST);

const isOwnerTryDeleteHisAccountError = (error: GraphQLError): boolean =>
  isGqlErrorCode(error, ErrorCodes.OWNER_TRY_DELETE_HIS_ACCOUNT);

export {
  isGqlErrorCode,
  isGqlErrorProperty,
  isCompanyNameExistError,
  isCompanyNotExistError,
  isFileRequiresError,
  isCompanyDomainExistError,
  isEmailValidError,
  isTokenInvalidError,
  isTokenExpiredError,
  isAuthorizationError,
  isProjectNameExistError,
  isSharedNotExist,
  isEmailAllReadyExist,
  isRoleToHigh,
  isNoAssesToThisCompany,
  isWrongPasswordError,
  isMinimumLengthValidatorError,
  isAssetNameShouldBeWithOutExtension,
  isAssetNameExistError,
  isLinkAlreadyUsedError,
  isOwnerTryDeleteHisAccountError,
};
