import React from 'react';
import styled from 'styled-components';

import Icon from 'components/atoms/Icon/Icon';

const StyledIcon = styled(Icon)`
  -webkit-animation: spin 1.6s ease-in-out infinite;
  -moz-animation: spin 1.6s ease-in-out infinite;
  animation: spin 1.6s ease-in-out infinite;
  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

function Spinner(props: { size?: number; color?: string; style?: object }) {
  return <StyledIcon {...props} name="loading02" />;
}

export default Spinner;
