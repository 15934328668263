type FontPropsType = {
  bold?: boolean;
  semibold?: boolean;
  medium?: boolean;
  regular?: boolean;
};

type FontSizePropsType = {
  xs?: boolean;
  sm?: boolean;
  md?: boolean;
  lg?: boolean;
  xl?: boolean;
};
type TextType = 'text' | 'header';

type ThemeType = {
  typography: {
    weight: {
      bold: number;
      semibold: number;
      medium: number;
      regular: number;
    };
    fontSize: {
      text: number[];
      header: number[];
    };
    lineHeight: {
      text: number[];
      header: number[];
    };
  };
};

const getSize = (
  { xs, sm, md, lg, xl }: FontSizePropsType,
  type: TextType,
  textFieldType: 'fontSize' | 'lineHeight',
  theme: ThemeType
) => {
  const sizeArray = theme.typography[textFieldType][type];
  const size = { xs, sm, md, lg, xl };
  // @ts-ignore
  const index = Object.keys(size).findIndex((key) => !!size[key]);
  return sizeArray[index] || sizeArray[2];
};

const getFontWeight = (
  theme: ThemeType,
  { bold, semibold, medium, regular = true }: FontPropsType
) => {
  const {
    typography: { weight },
  } = theme;

  return (
    (bold && weight.bold) ||
    (semibold && weight.semibold) ||
    (medium && weight.medium) ||
    (regular && weight.regular)
  );
};

const getFontSizeFromTheme = (
  theme: ThemeType,
  sizes: FontSizePropsType,
  type: TextType
) => getSize(sizes, type, 'fontSize', theme);
const getLineHeightFromTheme = (
  theme: ThemeType,
  sizes: FontSizePropsType,
  type: TextType
) => getSize(sizes, type, 'lineHeight', theme);

const getFont = ({
  theme,
  sizes = { md: true },
  weight = { medium: true },
  type = 'text',
}: {
  theme: ThemeType;
  sizes?: FontSizePropsType;
  weight?: FontPropsType;
  type?: TextType;
}): string => `
font-weight: ${getFontWeight(theme, weight)};
font-size: ${getFontSizeFromTheme(theme, sizes, type)}px;
line-height: ${getLineHeightFromTheme(theme, sizes, type)}px;`;

export { getFontWeight, getFontSizeFromTheme, getLineHeightFromTheme, getFont };
