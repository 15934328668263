import React, { useEffect } from 'react';
import styled, { useTheme } from 'styled-components';

import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  selectPopupMessageInfo,
  selectShowPopupMessage,
  setModal,
} from 'redux/modal/modalSlice';

import { IPopupMessageProps } from './PopupMessage.interface';

import Flex from '../Flex/Flex';
import Text from '../Text/Text';

const PopupMessageWrapper = styled(Flex)<{ show: boolean }>`
  ${({ show }) => (show ? null : 'opacity: 0; pointer-events: none;')};
  transition: 600ms all ease;
  z-index: 3;
  position: fixed;
  bottom: 80px;
  left: 50%;
  transform: translate(-50%);
  background-color: ${({ theme }) => theme.colors.grey900};
  border-radius: 9px;
`;
function PopupMessage({ ...args }: IPopupMessageProps) {
  const theme = useTheme();
  const show = useAppSelector(selectShowPopupMessage);
  const info = useAppSelector(selectPopupMessageInfo);
  const dispatch = useAppDispatch();
  const timeToShow = info?.text === 'Download failed' ? 3000 : 800;

  useEffect(() => {
    setTimeout(() => {
      if (show) {
        dispatch(
          setModal({
            showPopupMessage: false,
          })
        );
      }
    }, timeToShow);
  }, [dispatch, show, timeToShow]);

  return (
    <PopupMessageWrapper show={!!show} px={12} py={3} {...args}>
      {info?.countOfSelected && (
        <Text sm regular>
          <b>{info?.countOfSelected}</b>{' '}
          {`${info?.countOfSelected === 1 ? 'item' : 'items'} selected `}
        </Text>
      )}
      <Text sm regular color={theme.colors.base}>
        {info?.text}
      </Text>
    </PopupMessageWrapper>
  );
}

export default PopupMessage;
