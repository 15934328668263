import React, { FormEvent, useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import Select, { OnChangeValue } from 'react-select';

import FormControl from 'components/atoms/FormControl/FormControl';
import FormFeetback from 'components/atoms/FormFeetback/FormFeetback';
import { IOption, Option } from 'components/atoms/Select/Select';
import Text from 'components/atoms/Text/Text';
import ActionModal, {
  ButtonsAlignmentName,
} from 'components/molecules/ActionModal/ActionModal';

import { Localizations } from 'graph/generated.graphql';
import { useAppSelector } from 'redux/hooks';
import {
  selectRequestNewLocalizationInfo,
  selectSelectedProject,
  setModal,
} from 'redux/modal/modalSlice';
import {
  FormErrorMessages,
  FormErrorTypes,
  LocalisationIcon,
} from 'utils/constants/forms';
import {
  useFormState,
  useFormValidation,
} from 'utils/hooks/useFormValidation/useFormValidation';
import { transformLocalizationToLocalizationName } from 'utils/transformers/transformLocalizationToLocalizationName';

import { IRequestNewLocalizationFormProps } from './RequestNewLocalizationForm.interface';

import MultiCountriesValueLabel, {
  SelectCountriesWrapper,
} from '../../../../components/molecules/MultiCountrySelect/MultiCountrySelect';
import {
  countriesOrder,
  sortCountriesOrder,
} from '../../../../utils/localization/localization';

enum FormFields {
  ProjectName = 'projectName',
  Localisation = 'localizations',
}

const FormFieldsList = [FormFields.ProjectName, FormFields.Localisation];

function RequestNewLocalizationForm({
  onModalClose,
}: IRequestNewLocalizationFormProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [formErrors, isFormValid, [_, setLocalisationError]] =
    useFormValidation(FormFieldsList);
  const info = useAppSelector(selectRequestNewLocalizationInfo);
  const initialFormValue = {
    [FormFields.ProjectName]: '',
    [FormFields.Localisation]: info?.localizations || [],
  };
  const dispatch = useDispatch();
  const selectedProject = useAppSelector(selectSelectedProject);
  const existingLocalization = selectedProject?.existingLocalizations;
  const pendingLocalization = selectedProject?.pendingLocalizations;
  const localizationInProject = existingLocalization?.concat(
    pendingLocalization || []
  );
  const [formState, [setProjectName, setLocalisation], [formOnSubmit]] =
    useFormState(FormFieldsList, initialFormValue);
  const { isLoading, errorSlug, localizations } = formState;

  const options: IOption[] = Object.values(Localizations)
    .filter((item) => !localizationInProject?.includes(item))
    .sort(sortCountriesOrder(countriesOrder))
    .map((item) => ({
      label: transformLocalizationToLocalizationName(item),
      value: item,
      icon: LocalisationIcon[item],
    }));

  const validLocalisation = (value: string[]) => {
    if (!value.length) {
      setLocalisationError({
        type: FormErrorTypes.Invalid,
        message: FormErrorMessages.NO_LOCALISATION_SELECTED,
      });
      return false;
    }
    setLocalisationError(null);
    return true;
  };

  const onProjectNameTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProjectName(e.target.value);
  };

  const onLocalizationselectChange = (
    values: OnChangeValue<IOption, boolean>
  ) => {
    const selected = (values as IOption[]).map((v) => v.value);
    setLocalisation(selected);
    validLocalisation(selected);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (!validLocalisation(localizations)) {
      return null;
    }

    formOnSubmit();
    dispatch(
      setModal({
        showCalendlyModal: true,
        requestLocalizationModalInfo: {
          projectId: info?.projectId,
          localizations,
        },
      })
    );
    onModalClose();
    return null;
  };
  const getValue = (): IOption | undefined =>
    info
      ? options.find((item: IOption) => item.value === info.localizations)
      : undefined;

  useEffect(() => {
    if (localizations) {
      setLocalisationError(null);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form
      className="w-100"
      noValidate
      validated={isFormValid}
      onSubmit={handleSubmit}
    >
      <Row className="gx-3">
        <Col>
          <Form.Group controlId="validationCustom01">
            <Form.Label>
              <Text sm semibold>
                Project Name
              </Text>
            </Form.Label>
            <FormControl
              placeholder="Project name"
              value={info?.projectName || ''}
              disabled
              $errorType={formErrors[FormFields.ProjectName]?.type}
              onChange={onProjectNameTextChange}
            />
            <FormFeetback type={formErrors[FormFields.ProjectName]?.type}>
              {formErrors[FormFields.ProjectName]?.message}
            </FormFeetback>
          </Form.Group>
        </Col>
      </Row>
      <Row className="gx-3">
        <Col>
          <Form.Group controlId="validationSelect">
            <Form.Label>
              <Text sm semibold>
                Localisations
              </Text>
            </Form.Label>
            <SelectCountriesWrapper>
              <Select
                isSearchable={false}
                isMulti
                placeholder="Select Localisations"
                classNamePrefix="countries-select"
                options={options}
                defaultValue={getValue()}
                components={{
                  Option,
                  MultiValueLabel: MultiCountriesValueLabel,
                }}
                onChange={onLocalizationselectChange}
              />
            </SelectCountriesWrapper>
            <FormFeetback type={formErrors[FormFields.Localisation]?.type}>
              {formErrors[FormFields.Localisation]?.message}
            </FormFeetback>
          </Form.Group>
        </Col>
      </Row>
      <ActionModal
        primaryButtonProps={{
          text: 'Book Meeting',
          isLoading,
          type: 'submit',
          disabled: !isFormValid,
        }}
        secondaryButtonProps={{ text: 'Cancel', onClick: onModalClose }}
        buttonsAlignment={ButtonsAlignmentName.RIGHT}
        error={errorSlug}
      />
    </Form>
  );
}

export default RequestNewLocalizationForm;
