export const buildLink = (
  pattern: string,
  args: Record<string, string>
): string => {
  const placeholders = pattern.match(/:[a-zA-Z0-9]+/g);

  if (placeholders?.length !== Object.keys(args).length) {
    throw new Error(
      'Number of arguments does not match number of placeholders in pattern.'
    );
  }

  let link = pattern;
  placeholders?.forEach((placeholder) => {
    const key = placeholder.substring(1);
    const value = args[key];
    if (!value) {
      throw new Error(`Missing value for placeholder '${placeholder}'`);
    }
    link = link.replace(placeholder, value);
  });

  return link;
};
