import React from 'react';
import styled, { useTheme } from 'styled-components';

import Box from 'components/atoms/Box/Box';
import Flex from 'components/atoms/Flex/Flex';
import Icon from 'components/atoms/Icon/Icon';
import Text from 'components/atoms/Text/Text';
import ActionModal, {
  ButtonsAlignmentName,
} from 'components/molecules/ActionModal/ActionModal';
import { Modal } from 'components/molecules/Modal/Modal';

import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  selectRequestNewLocalizationFinalModal,
  setModal,
} from 'redux/modal/modalSlice';

const IconWrapper = styled(Flex)`
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.success100};
`;
function RequestNewLocalizationFinalModal() {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const show = useAppSelector(selectRequestNewLocalizationFinalModal);

  const onModalClose = () => {
    dispatch(setModal({ showRequestLocalizationFinalModal: false }));
  };

  return (
    <Modal show={show} onHide={onModalClose} width={450}>
      <>
        <Flex
          fullWidth
          justifyContent="left"
          alignItems="center"
          mb={3}
          gap={8}
        >
          <IconWrapper
            w={32}
            h={32}
            justifyContent="center"
            alignItems="center"
          >
            <Icon
              color={theme.colors.success600}
              size={16}
              name="checkCircle"
            />
          </IconWrapper>
          <Text lg bold>
            Your project has been sent!
          </Text>
        </Flex>
        <Box mb={5}>
          <Text sm medium color={theme.colors.grey600}>
            You’ll get a response from the Vidico team within 24-hours.
          </Text>
        </Box>
        <ActionModal
          buttonsAlignment={ButtonsAlignmentName.RIGHT}
          primaryButtonProps={{
            text: 'Okay',
            type: 'button',
            onClick: onModalClose,
          }}
        />
      </>
    </Modal>
  );
}

export default RequestNewLocalizationFinalModal;
