import { GoogleOAuthProvider } from '@react-oauth/google';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { AuthProvider, TAuthConfig, TRefreshTokenExpiredEvent } from "react-oauth2-code-pkce";
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import App from 'App';

import { store } from 'redux/store';
import reportWebVitals from 'reportWebVitals';
import { BackgroundUploadProvider } from 'utils/context/backgroundUploadContext/backgroundUploadContext';
import { DragSelectProvider } from 'utils/context/dragSelectContext/dragSelectContext';
import { LogoutProvider } from 'utils/context/logoutContext/logoutContext';
import { mainTheme } from 'utils/theme/mainTheme';

import SubdomainProvider from './containers/subdomain';
import { ApolloProviderComponent } from './graph/apollo-provider';
import { getRedirectInProgress, setRedirectInProgress, removeToken } from './utils/localStorage/viewer';

// eslint-disable-next-line import/order
import './index.scss';

const REACT_APP_GOOGLE_OUATH_CLIENT_ID = process.env
  .REACT_APP_GOOGLE_OUATH_CLIENT_ID as string;

const authConfig: TAuthConfig = {
  clientId: process.env.REACT_APP_CLIENT_ID as string,
  authorizationEndpoint: `${process.env.REACT_APP_IDENTITY_URL}/connect/authorize`,
  tokenEndpoint: `${process.env.REACT_APP_API_URL}/users/me/token`,
  redirectUri: `${window.location.origin}/`,
  scope: 'openid profile videostore.api.scope offline_access',
  logoutEndpoint: `${process.env.REACT_APP_IDENTITY_URL}/connect/endsession`,
  autoLogin: false,
  storage: 'local',
  onRefreshTokenExpire: (event: TRefreshTokenExpiredEvent) => {
    event.login();
  },
}

const isRedirectInProgress = getRedirectInProgress() === 'true';
if (isRedirectInProgress) {
  removeToken();
  setRedirectInProgress('false');
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={REACT_APP_GOOGLE_OUATH_CLIENT_ID}>
      <AuthProvider authConfig={authConfig}>
        <SubdomainProvider>
          <ThemeProvider theme={mainTheme}>
            <BrowserRouter>
              <ApolloProviderComponent>
                <Provider store={store}>
                  <LogoutProvider>
                    <BackgroundUploadProvider>
                      <DragSelectProvider>
                        <App />
                      </DragSelectProvider>
                    </BackgroundUploadProvider>
                  </LogoutProvider>
                </Provider>
              </ApolloProviderComponent>
            </BrowserRouter>
          </ThemeProvider>
        </SubdomainProvider>
      </AuthProvider>
    </GoogleOAuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
