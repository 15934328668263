import React from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

import Button from 'components/atoms/Button/Button';
import Container from 'components/atoms/Container/Container';
import Flex from 'components/atoms/Flex/Flex';
import Icon from 'components/atoms/Icon/Icon';
import IconButton from 'components/atoms/IconButton/IconButton';
import RenameInput from 'components/atoms/RenameInput/RenameInput';
import Text from 'components/atoms/Text/Text';

import {
  AssetFragment,
  Roles,
  useAssetDownloadMutation,
  useMarkAssetsAsFavoriteMutation,
} from 'graph/generated.graphql';
import { reset } from 'redux/filter/filterSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectViewMetaDataInfo, setModal } from 'redux/modal/modalSlice';
import { selectViewer } from 'redux/viewer/viewerSlice';
import { RoutesPath } from 'utils/constants/routes';
import { buildLink } from 'utils/helpers/route';

import { hasS3AndMux } from '../../../../utils/assets/hasS3AndMux';

const HeaderStyled = styled.header`
  background-color: ${({ theme }) => theme.colors.grey900};
  min-height: 80px;
  border-bottom: 1.5px solid ${({ theme }) => theme.colors.grey400};
`;
const ButtonStyled = styled(Button)`
  background-color: ${({ theme }) => theme.colors.grey900};
  border: 1px solid ${({ theme }) => theme.colors.base};
  &:hover:enabled {
    background-color: ${({ theme }) => theme.colors.grey800};
  }
  &:disabled {
    background-color: ${({ theme }) => theme.colors.grey700};
  }
  &:focus {
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #f2f4f7;
  }
`;

function ViewAssetHeader({ asset }: { asset: AssetFragment }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const show = useAppSelector(selectViewMetaDataInfo);
  const viewer = useAppSelector(selectViewer);
  const role = viewer?.roles;
  const isVidicoAdmin = role === Roles.VidicoAdmin;
  const onClickHandler = () =>
    dispatch(setModal({ showViewMetaDataInfo: !show }));
  const handleBackButtonClick = () => {
    navigate(-1);
    dispatch(setModal({ showViewMetaDataInfo: false }));
  };
  const handleBackToProjectClick = () => {
    if (isSharePage) return;
    dispatch(setModal({ showViewMetaDataInfo: false }));
    dispatch(reset());
    navigate(linkToProject(asset));
  };
  const [markAsFavorite] = useMarkAssetsAsFavoriteMutation();
  const [assetsDownload, { loading: isDownloadLoading }] =
    useAssetDownloadMutation({
      onCompleted({ assetDownload }) {
        if (hasS3AndMux(assetDownload)) {
          dispatch(
            setModal({
              showVideoTypeSelectionModal: true,
              videoTypeFileToDownload: assetDownload.assets,
            })
          );
        } else {
          dispatch(
            setModal({
              showPopupMessage: true,
              popupMessageInfo: { text: 'Download started' },
              fileToDownload: assetDownload.assets,
            })
          );
        }
      },
      onError() {
        dispatch(
          setModal({
            showPopupMessage: true,
            popupMessageInfo: { text: 'Download failed' },
          })
        );
      },
    });
  const linkToProject = (assetData: AssetFragment) => {
    let link = '';
    if (role === Roles.VidicoAdmin) {
      const args = {
        companyId: assetData.companyId,
        projectId: assetData.projectId,
      };
      link = buildLink(RoutesPath.WorkspaceById, args);
    } else {
      const args = {
        projectId: assetData.projectId,
      };
      link = buildLink(RoutesPath.HomeProject, args);
    }
    return link;
  };

  const isViewAssetPath = useMatch(RoutesPath.ViewAssetById);
  const isViewAssetPathUser = useMatch(RoutesPath.HomeViewAssetById);
  const isSharePage = useMatch(RoutesPath.ViewSharedAssetById);
  const havePermissionToRemove =
    role === Roles.Admin || role === Roles.VidicoAdmin || role === Roles.Owner;
  return (
    <HeaderStyled>
      <Container>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          py={4}
          px={8}
          gap={12}
          flexWrap="wrap"
        >
          <Flex>
            <Button noStyle py={3} mr={5} onClick={handleBackButtonClick}>
              <Icon size={24} name="arrowBack" color={theme.colors.base} />
            </Button>
            <Flex flexDirection="column">
              <RenameInput asset={asset} />
              <Button
                noStyle
                style={isSharePage ? { cursor: 'default' } : {}}
                onClick={handleBackToProjectClick}
              >
                <Flex alignItems="center">
                  <Icon
                    size={20}
                    mr={1}
                    name="folder"
                    color={theme.colors.base}
                  />
                  <Text sm regular color={theme.colors.base}>
                    {asset.projectName}
                  </Text>
                </Flex>
              </Button>
            </Flex>
          </Flex>
          <Flex alignItems="center">
            <Flex mr={1} alignItems="center">
              {(isViewAssetPath || isViewAssetPathUser) && !isVidicoAdmin && (
                <IconButton
                  iconColor={theme.colors.base}
                  iconSize={24}
                  iconName={
                    viewer?.favorites?.find((f) => f.assetId === asset.id)
                      ? 'star01Filled'
                      : 'star01'
                  }
                  onClick={() =>
                    markAsFavorite({ variables: { assetsId: [asset.id] } })
                  }
                />
              )}
              <IconButton
                iconColor={theme.colors.base}
                iconSize={24}
                iconName="infoCircle"
                onClick={() => onClickHandler()}
              />
              {(isViewAssetPath || isViewAssetPathUser) &&
                havePermissionToRemove && (
                  <IconButton
                    iconColor={theme.colors.base}
                    iconSize={24}
                    iconName="delete"
                    onClick={() =>
                      dispatch(
                        setModal({
                          showRemoveAssetsModal: true,
                          assetsToRemove: [asset],
                        })
                      )
                    }
                  />
                )}
            </Flex>
            <ButtonStyled
              sm
              semibold
              round
              py={2}
              px={3}
              mr={4}
              color={theme.colors.base}
              onClick={() => {
                assetsDownload({
                  variables: {
                    assetsId: asset.id,
                    sharedLinkKey: isSharePage
                      ? isSharePage.params.key
                      : undefined,
                  },
                });
              }}
              isLoading={isDownloadLoading}
            >
              <Icon
                size={20}
                mr={2}
                name="download01"
                color={theme.colors.base}
              />
              Download
            </ButtonStyled>
            {(isViewAssetPath || isViewAssetPathUser) && (
              <Button
                round
                py={2}
                px={4}
                onClick={() =>
                  dispatch(setModal({ showSharedLinkModal: true }))
                }
              >
                <Flex mr={2}>
                  <Icon size={20} name="share01" />
                </Flex>
                <Text sm semibold>
                  Share
                </Text>
              </Button>
            )}
          </Flex>
        </Flex>
      </Container>
    </HeaderStyled>
  );
}

export default ViewAssetHeader;
