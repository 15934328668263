import React from 'react';
import styled, { keyframes, useTheme } from 'styled-components';

import Box from '../../atoms/Box/Box';
import Flex from '../../atoms/Flex/Flex';
import IconButton from '../../atoms/IconButton/IconButton';
import Text from '../../atoms/Text/Text';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(20px);
  }
`;

const StyledWrapper = styled(Flex)<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  animation: ${({ isOpen }) => (isOpen ? fadeIn : fadeOut)} 0.3s ease forwards;
  position: fixed;
  right: 40px;
  bottom: 40px;
  transform: translate(-50%, -50%);
  background: white;
  padding-top: 8px;
  padding-bottom: 1rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
`;

const StyledHeader = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  margin-left: 1rem;
  margin-right: 0;
`;

function ReferralRewardPopUp({
  isOpen,
  children,
  callBack,
}: {
  children: JSX.Element;
  callBack: () => void;
  isOpen: boolean;
}) {
  const theme = useTheme();
  return (
    <StyledWrapper isOpen={isOpen}>
      <Box w={396}>
        <StyledHeader>
          <Text medium>Earn Rewards! 🎉</Text>
          <IconButton
            iconSize={20}
            iconColor={theme.colors.black}
            iconName="x"
            onClick={() => callBack()}
          />
        </StyledHeader>

        {children}
      </Box>
    </StyledWrapper>
  );
}

export default ReferralRewardPopUp;
